import cx from 'classnames';
import { Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PreOfferUpcomingPaymentsKard from '../Kards/PreOfferUpcomingPaymentsKard';
import { OfferType } from '../utils/OfferType';
import { OfferIneligibilityReason } from '../utils/types';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import { ACCOUNT_QUERY } from '@core/components/ChargedOff/gql/queries';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import BalanceKard from '@core/components/ChargedOff/Kards/BalanceKard';
import BuildPaymentPlanKard from '@core/components/ChargedOff/Kards/BuildPaymentPlanKard';
import ChargedOffDashboardFooter from '@core/components/ChargedOff/Dashboards/ChargedOffDashboardFooter';
import { AccountQuery, ActivityState } from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import isNil from '@core/utils/isNil';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const PreOfferDashboard = () => {
  const { isDesktopLarge } = useUserDevice();
  const { data, loading } = useAccountIdQuery<AccountQuery>(ACCOUNT_QUERY);
  const navigate = useNavigate();
  const { isDebitEligible } = usePaymentStatus();

  useEffect(() => {
    TrackService.trackPage('Charged Off Non-Offer Dashboard', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  if (loading) return <LoadingSpinner />;

  const currentBalance = data?.account?.balanceInfo?.currentBalance;

  const hasScheduledPayment =
    !!data?.account?.upcomingPayments?.find(
      (upcomingPayment) => upcomingPayment.state === ActivityState.Scheduled,
    ) || !!data?.account?.autopay.length;

  const hasPendingPayment = !!data?.account?.upcomingPayments?.find(
    (upcomingPayment) => upcomingPayment.state === ActivityState.Pending,
  );

  const allowedIneligibilityReasons: string[] = [
    OfferIneligibilityReason.HAS_SCHEDULED_PAYMENT,
    OfferIneligibilityReason.HAS_PENDING_PAYMENT,
  ];

  const ineligibleForOffer = Boolean(
    data?.offerEligibility
      ?.filter((offer) => offer.offerType === OfferType.Settlement)
      .every(
        (settlementOffer) =>
          !settlementOffer.eligible &&
          !settlementOffer.ineligibleReasons.every((reason) =>
            allowedIneligibilityReasons.includes(reason),
          ),
      ),
  );

  const getKardBody = () =>
    hasScheduledPayment || hasPendingPayment ?
      <PreOfferUpcomingPaymentsKard scheduledPayments={hasScheduledPayment} />
    : <BuildPaymentPlanKard />;

  return (
    <>
      <div
        className={cx('flex', isDesktopLarge ? 'items-start' : 'flex-column')}
      >
        <div className={cx('mb2', isDesktopLarge && 'mr4 w-50')}>
          <BalanceKard testID="PreOfferDashboardBalanceKard" />
          {ineligibleForOffer &&
            !isNil(currentBalance) &&
            currentBalance > 0 && (
              <div className="flex-ns mt5">
                <Button
                  onPress={() => {
                    isDebitEligible ?
                      navigate('../payments/pay')
                    : navigate('../payments/pay/make-ach-payment');
                  }}
                  text="Make a payment"
                />
              </div>
            )}
        </div>
        {!ineligibleForOffer && (
          <div className={cx(isDesktopLarge && 'w-50')}>{getKardBody()}</div>
        )}
      </div>
      <ChargedOffDashboardFooter />
    </>
  );
};

export default PreOfferDashboard;
