import { Notification, Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useAutopay } from '../AutopayContext';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import PageWrapper from '@core/components/Page/PageWrapper';

const CancelAutoPaySuccess = () => {
  const { autopayPageTitle } = useAutopay();
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const onBackToSummary = () => navigate(summaryPath);

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={autopayPageTitle}
      trackingProperties={{
        pageName: 'Cancel Autopay: Success',
        featureName: 'Payments: Autopay',
      }}
    >
      <div className="mb5">
        <Notification level="success">
          You've canceled your autopay schedule. We've sent you a confirmation
          email.
        </Notification>
      </div>
      <div className="flex-ns">
        <Button variant="contained" text="Done" onPress={onBackToSummary} />
      </div>
    </PageWrapper>
  );
};

export default CancelAutoPaySuccess;
