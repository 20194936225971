import { P3, P4, B, Notification } from '@missionlane/compass-ui';
import { FormState } from 'react-hook-form';
import { useState } from 'react';
import { FormValues } from './VerifyRequestCode';
import { ResponsiveButton } from '@core/components/Button';
import { OtpFactorType, OtpFactor } from '@core/services/RiskAuthService';
import { HookFormRadioGroup } from '@core/components/Form';
import { TrackService } from '@core/services';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

const OPTIONS: { value: OtpFactorType; label: string }[] = [
  {
    value: 'sms',
    label: 'Verify via SMS',
  },
  {
    value: 'call',
    label: 'Verify via Voice Call',
  },
];

export interface VerifyRequestCodeFormProps {
  onSubmit: () => void;
  otpFactors: OtpFactor[] | undefined;
  formState: Pick<FormState<FormValues>, 'errors' | 'isSubmitting' | 'isValid'>;
  error: string | undefined;
}

const VerifyRequestCodeForm = ({
  otpFactors,
  onSubmit,
  formState,
  error,
}: VerifyRequestCodeFormProps) => {
  const [otp, setOtp] = useState<OtpFactorType>();
  const { errors: formErrors, isSubmitting, isValid } = formState;

  const filteredOptions = OPTIONS.filter((option) =>
    otpFactors?.map((otpFactor) => otpFactor.factorType).includes(option.value),
  );
  const handleButtonPress = () => {
    TrackService.click('Send Code');
    onSubmit();
  };
  if (!otpFactors) return <LoadingSpinner />;
  return (
    <div>
      <P3>
        <B>
          Before we update your address, we need to make sure it's really you.
        </B>{' '}
        Choose how you'd like to verify your identity below.
      </P3>

      <div className="mt4">
        <P3>
          <B color="ink">Verification Method</B>
        </P3>
      </div>

      <HookFormRadioGroup
        name="selectedOtp"
        options={filteredOptions}
        onChange={(otpValue) => {
          // @ts-ignore: should this be any in compass-ui
          setOtp(otpValue);
        }}
        value={otp}
        error={formErrors.selectedOtp?.message}
      />

      <div className="mt4">
        <P4>
          SMS or Voice Call can only be used if a mobile phone number has been
          configured.
        </P4>
      </div>

      {error && (
        <div className="mt4">
          <Notification level="error" variant="inline">
            {error}
          </Notification>
        </div>
      )}

      <div className="mt5">
        <ResponsiveButton
          text="Send Code"
          onPress={handleButtonPress}
          disabled={!isValid}
          loading={isSubmitting}
          variant="contained"
        />
      </div>
    </div>
  );
};

export default VerifyRequestCodeForm;
