import cx from 'classnames';
import { P1, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { PageHeaderWithSignOut } from '../Page/PageHeader';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import errorSvg from '@core/assets/crossroads.svg';
import PageWrapper from '@core/components/Page/PageWrapper';

const PageNotFound = () => {
  const navigate = useNavigate();
  const { isMobile } = useUserDevice();

  return (
    <>
      <PageHeaderWithSignOut centerContent />
      <PageWrapper
        centerContent
        withFooter
        trackingProperties={{
          pageName: 'PageNotFound',
          featureName: `Path: ${window.location.pathname}`,
        }}
      >
        <div
          className={cx(
            'flex',
            isMobile ? 'flex-column items-center' : 'justify-center',
          )}
        >
          <img
            src={errorSvg}
            alt="error"
            className={cx('db', isMobile ? 'mb4' : 'mr6')}
          />
          <div className={cx(!isMobile && 'ma4')}>
            <div className="mv4">
              <ResponsiveTypography
                type="HEADER"
                mobileLevel="H1"
                desktopLevel="H2"
              >
                Oops, wrong way
              </ResponsiveTypography>
            </div>
            <div className="mv4">
              <P1>
                The page you’re looking for <br />
                got lost.
              </P1>
            </div>
            <div className={cx(isMobile ? 'mb5' : 'mt5')}>
              <P1>
                Let’s take you <Link onPress={() => navigate('/')}>home</Link>.
              </P1>
            </div>
          </div>
        </div>
      </PageWrapper>
    </>
  );
};

export default PageNotFound;
