import { gql } from '@apollo/client';
import dayjs from 'dayjs';
import { H3, Spacer, Notification } from '@missionlane/compass-ui';
import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageWrapper from '../Page/PageWrapper';
import TransactionList from './TransactionList';
import './TransactionsPage.css';
import { TransactionFilters } from './TransactionFilters';
import { StatementsButton } from './StatementsButton';
import { TransactionsSearch } from './TransactionsSearch';
import { TRANSACTION_ITEM_FRAGMENT } from './TransactionItem';
import {
  ActivityType,
  TransactionsQuery,
  TransactionsQueryVariables,
} from '@core/graphql/globalTypes';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { MLFlags } from 'flags';

const TRANSACTIONS_QUERY = gql`
  query Transactions($accountId: String!, $fromDate: String, $toDate: String) {
    account(accountId: $accountId) {
      id
      activities(fromDate: $fromDate, toDate: $toDate) {
        ...TransactionItem
      }
    }
  }
  ${TRANSACTION_ITEM_FRAGMENT}
`;

const Transactions = () => {
  const { showNewOnboardingExperience } = useFlags<MLFlags>();
  const [searchOpen, setSearchOpen] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const [filterValue, setFilterValue] = useState<ActivityType | null>(null);

  const { data, loading, error } = useAccountIdQuery<
    TransactionsQuery,
    TransactionsQueryVariables
  >(TRANSACTIONS_QUERY, {
    variables: {
      // fetch 1 year of transactions
      fromDate: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
      toDate: dayjs().format('YYYY-MM-DD'),
    },
  });

  const filteredTransactions = data?.account?.activities.filter(
    ({ description, type }) => {
      if (searchValue) {
        return description.toLowerCase().includes(searchValue.toLowerCase());
      }
      if (filterValue) {
        return type === filterValue;
      }
      return true;
    },
  );

  const noTransactionsMessage =
    showNewOnboardingExperience ?
      {
        textPrimary: searchValue ? 'No transactions found' : 'Nothing Yet',
        textSecondary:
          searchValue ? undefined : (
            `You haven’t made any recent\npurchases. When you make one, you\ncan find it here!`
          ),
        classes: searchValue ? 'pv7' : 'pv6',
      }
    : {
        textPrimary:
          searchValue ? 'No transactions found.' : 'No transactions yet.',
        classes: 'mt4',
      };

  // throw the error so the ErrorBoundary in TransactionsPage can catch it
  if (error) throw error;

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <div className="flex items-center justify-end mb2">
        <TransactionsSearch
          searchOpen={searchOpen}
          searchValue={searchValue}
          setSearchOpen={setSearchOpen}
          setSearchValue={setSearchValue}
        />
        {!searchOpen && (
          <>
            <Spacer size="xs" />
            <TransactionFilters
              onSelectFilter={setFilterValue}
              selectedFilter={filterValue}
            />
          </>
        )}
      </div>
      {filteredTransactions ?
        <TransactionList
          transactions={filteredTransactions}
          noTransactionsMessage={noTransactionsMessage}
        />
      : <Notification level="warning">
          Sorry, we’re having trouble loading your transactions, but our team is
          looking into it. Check back in a bit.
        </Notification>
      }
    </>
  );
};

const TransactionsPage = () => {
  return (
    <PageWrapper
      trackingProperties={{
        pageName: 'All Transactions',
        featureName: 'Transactions',
      }}
    >
      <div className="flex items-center justify-between">
        <H3>Transactions</H3>
        <StatementsButton />
      </div>
      <Transactions />
    </PageWrapper>
  );
};

export default TransactionsPage;
