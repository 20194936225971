import { createContext, useContext, useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { useState } from 'react';
import { gql, makeVar, useQuery, useReactiveVar } from '@apollo/client';
import { PageHeader } from '../Page/PageHeader';
import Footer from '../General/Footer/Footer';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import Nav from './Nav/Nav';
import { SIDEBAR_NAVIGATION_FEATURE_ID } from './Nav/constants';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useTracking } from '@core/services/TrackService/useTracking';
import { CustomerAccountsQuery } from '@core/graphql/globalTypes';

const CUSTOMER_ACCOUNTS_QUERY = gql`
  query CustomerAccounts {
    customer {
      id
      accounts {
        id
      }
    }
  }
`;

export const activeAccountIdVar = makeVar<string | null>(null);
export const setActiveAccountId = (accountId: string) =>
  activeAccountIdVar(accountId);
export const resetActiveAccountId = () => activeAccountIdVar(null);

type NavContainerContext = { contentClassName: string | undefined };
const NavContainerContext = createContext<NavContainerContext>({
  contentClassName: undefined,
});

const NavContainer = () => {
  const { accountId: accountIdParam } = useParams();
  const activeAccountId = useReactiveVar(activeAccountIdVar);
  const { data, loading } = useQuery<CustomerAccountsQuery>(
    CUSTOMER_ACCOUNTS_QUERY,
  );
  const customerAccounts = data?.customer?.accounts;
  const { trackClick } = useTracking();
  const { isMobile } = useUserDevice();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const contentClassName = !isMobile ? 'ma4' : 'mb4';

  useEffect(() => {
    // Prevent the body from being scrollable when mobile menu is open
    document.body.style.overflow = isMobileMenuOpen ? 'hidden' : 'unset';
  }, [isMobileMenuOpen]);

  useEffect(() => {
    if (!activeAccountId) {
      if (accountIdParam) {
        setActiveAccountId(accountIdParam);
      } else if (customerAccounts?.length) {
        setActiveAccountId(customerAccounts[0].id);
      }
    }
  }, [accountIdParam, customerAccounts]);

  if (loading) return <LoadingSpinnerPage centerContent withFooter />;

  return (
    <>
      {isMobile && (
        <PageHeader
          onMenuOpen={() => {
            setIsMobileMenuOpen(true);
            trackClick({
              name: 'Mobile Sidebar Nav Opened',
              feature: SIDEBAR_NAVIGATION_FEATURE_ID,
            });
          }}
        />
      )}
      <div
        style={
          !isMobile ?
            {
              display: 'grid',
              gridTemplateColumns: '256px calc(100vw - 256px)',
            }
          : undefined
        }
      >
        <Nav
          isMobile={isMobile}
          isMobileOpen={isMobileMenuOpen}
          onMobileClose={() => setIsMobileMenuOpen(false)}
        />
        <NavContainerContext.Provider value={{ contentClassName }}>
          <Outlet />
        </NavContainerContext.Provider>
      </div>
      <Footer contentClassName={!isMobile ? 'ml8 mr4' : undefined} />
    </>
  );
};

export const useNavContainerContext = () => useContext(NavContainerContext);

export default NavContainer;
