export const getExperienceVariant = (
  offerVariant: string,
  flagVariant: string,
) => {
  if (flagVariant === 'INCENTIVE_A' || flagVariant === 'NON_INCENTIVE_A') {
    return `${offerVariant}A`;
  } else if (
    flagVariant === 'INCENTIVE_B' ||
    flagVariant === 'NON_INCENTIVE_B'
  ) {
    return `${offerVariant}B`;
  }
  return '';
};
