import cx from 'classnames';
import { gql, useQuery } from '@apollo/client';
import toTitleCase from '@core/utils/toTitleCase';
import { AddressQuery } from '@core/graphql/globalTypes';

export const ADDRESS_QUERY = gql`
  query Address {
    customer {
      contactInfo {
        address {
          street1
          street2
          city
          postalCode
          state
        }
      }
    }
  }
`;

interface CustomerAddressProps {
  className?: string;
}

const CustomerAddress = ({ className }: CustomerAddressProps) => {
  const { loading, data } = useQuery<AddressQuery>(ADDRESS_QUERY);
  const { street1, street2, city, state, postalCode } =
    data?.customer?.contactInfo?.address || {};

  if (loading) {
    return (
      <div className="pv3 ph4 fw7">
        Loading...
        <br />
        <br />
      </div>
    );
  }

  if (!street1 || !city || !state || !postalCode) {
    return null;
  }

  let street = toTitleCase(street1);

  if (street2) {
    street += ` ${toTitleCase(street2)}`;
  }

  return (
    <div className={cx('b pv3 ph4', className)}>
      {street}
      <br />
      {toTitleCase(city)}, {state} {postalCode}
    </div>
  );
};

export default CustomerAddress;
