import { P3, B, Link } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { getAmountDisplay } from '@payments/components/Autopay/utils/helpers';
import Checkbox from '@core/components/General/Checkbox/Checkbox';
import { PaymentType } from '@core/graphql/globalTypes';
import { cardSupportPhone } from '@core/utils/contact';
import { getOrdinalFromDayOfMonth } from '@core/utils/getOrdinalDate';
import './EftaV3.css';

interface EftaV3Props {
  fundingAccountLast4: string;
  amount: number;
  paymentType: PaymentType;
  dayOfMonth: number;
  onChange: (e: boolean) => void;
  destinationAccount?: string | null;
}

const EftaV3 = ({
  fundingAccountLast4,
  amount,
  paymentType,
  dayOfMonth,
  onChange,
  destinationAccount,
}: EftaV3Props) => {
  const infoDestAccountCopy =
    destinationAccount === '100002' ? 'WebBank' : (
      'Transportation Alliance Bank, Inc. dba TAB Bank (“TAB”)'
    );

  const eftaDestAccountCopy =
    destinationAccount === '100002' ? 'WebBank' : 'TAB Bank';
  return (
    <div className="efta">
      <div className="bg-haze-lightest pa3 h4 overflow-scroll">
        <div className="eftaText">
          <P3 color="ink" style={{ marginTop: 0 }}>
            <B>AUTOMATIC PAYMENT AUTHORIZATION</B>
          </P3>
          <p>
            <P3 color="ink">
              By clicking “Submit”, you are enrolling in the automatic payment
              program (the “Program”) with {infoDestAccountCopy}, the creditor
              on your Account and issuer of your credit card, and Mission Lane
              LLC (“Mission Lane”), the servicer. You are authorizing the
              funding and payment of your credit card account through electronic
              fund transfers, pursuant to the following automatic payment
              authorization (“Auto Pay Authorization”).
            </P3>
          </p>
          <ol type="1">
            <li>
              <P3 color="ink">
                <u>Electronic Payments Authorization.</u> You hereby authorize
                and direct Mission Lane (which includes, for the purpose of this
                Auto Pay Authorization, successors, assigns, agents, and service
                providers), on behalf of {eftaDestAccountCopy}, to originate ACH
                credit and debit entries, to transfer funds to or from your bank
                account ending in {fundingAccountLast4} or any substitute
                deposit account that you or your financial institution later
                supply you (“your Bank Account”), or a subsequent financial
                institution you designate (both “Your Financial Institution”)
                and to debit that account for the
                {getAmountDisplay(paymentType, amount)} on the{' '}
                {getOrdinalFromDayOfMonth(dayOfMonth)} of each month (“Payment
                Date”).
              </P3>
            </li>
            <li>
              <P3 color="ink">
                <u>Automated Payment Amount.</u>
                <ol type="a">
                  <li>
                    <B>Minimum Payment.</B> If you select the “Minimum Payment”
                    automatic payment option, Mission Lane will debit your Bank
                    Account for “Total Minimum Payment Due” as shown on your
                    billing statement, less any payments already made, or your
                    current balance – whichever is the lower of the two amounts
                    (“Minimum Payment”).
                  </li>
                  <li>
                    <B>Statement Balance.</B> If you select the “Statement
                    Balance” automatic payment option, Mission Lane will debit
                    your Bank Account for the statement balance as shown on your
                    monthly billing statement, less any payments already made,
                    or the current balance as of the Payment Date – whichever is
                    the lower of the two amounts (“Statement Balance”).
                  </li>
                  <li>
                    <B>Fixed Amount.</B> If you select the “Fixed Amount”
                    automatic payment option, Mission Lane will debit your Bank
                    Account for the fixed amount that you selected (“Fixed
                    Amount”). If the Fixed Amount is less than the Total Minimum
                    Payment Due, you understand that the Minimum Payment amount
                    will be debited from your Bank Account. If the Fixed Amount
                    is greater than the current balance, Mission Lane will debit
                    the current balance.
                  </li>
                  <li>
                    You understand that each such debit will occur on the
                    Payment Date. You will ensure that there are sufficient
                    funds in your Bank Account on the Payment Date to pay the
                    amount of the debit. If your account is past due, you may be
                    responsible for making an additional payment to bring your
                    account current. You understand that if your account is
                    cancelled and there is an outstanding balance on your
                    account, Mission Lane will continue to automatically debit
                    your Bank Account unless you terminate participation in the
                    Program in accordance with the procedures set forth in
                    paragraph 5 below.
                  </li>
                </ol>
              </P3>
            </li>
            <li>
              <P3 color="ink">
                <u>Authorization to Correct Errors and Modify Payments.</u> In
                the event there is an error in seeking any payment you
                authorized, you authorize Mission Lane to correct the error by
                initiating an electronic debit or credit, as applicable, to your
                Bank Account in the amount of the error on or after the date any
                such error occurs. Instead of or in addition to any payments you
                authorized, you authorize Mission Lane to seek payment from your
                Bank Account for any amount and on any date that you
                subsequently confirm by phone or email.
              </P3>
            </li>
            <li>
              <P3 color="ink">
                <u>Bank-Imposed Fees.</u> You understand that Your Financial
                Institution may charge you a fee if a payment is returned
                unpaid, and you agree that Mission Lane is not liable to you
                regarding any such fees.
              </P3>
            </li>
            <li>
              <P3 color="ink">
                <u>Canceling this Authorization.</u> You may cancel your
                participation in the Program by managing your payments after
                logging into your online account, sending Mission Lane a message
                after logging into your online account, calling Mission Lane at{' '}
                {cardSupportPhone}, or writing Mission Lane LLC, P.O. Box
                105286, Atlanta, GA 30304. You agree to notify Mission Lane that
                you wish to revoke this Auto Pay Authorization in such time and
                manner so as to afford Mission Lane a reasonable opportunity
                (typically three business days) to act on your request. You
                understand that this Auto Pay Authorization will remain in full
                force and effect until you notify Mission Lane that you wish to
                revoke this Auto Pay Authorization.
              </P3>
              <p>
                <P3 color="ink">
                  You understand Mission Lane may choose not to execute your
                  automatic payment or cancel your participation in the Program,
                  and will provide notice to you upon doing so, if payment(s)
                  from your Bank Account are returned or if your credit card
                  account is restricted due to suspicious activity.
                </P3>
              </p>
            </li>
            <li>
              <P3 color="ink">
                <u>Miscellaneous.</u> You certify that you are an authorized
                signer on your Bank Account. If you inadvertently transpose a
                digit or make a similar error in providing Mission Lane with
                information about a payment method, you will notify Mission Lane
                and provide the correct Bank Account information. You authorize
                Mission Lane to correct the error after verifying the
                information.
              </P3>
            </li>
          </ol>
        </div>
      </div>
      <div className="mv2">
        <P3>
          You acknowledge, understand, and agree to the terms of this Auto Pay
          Authorization and, by clicking below, agree to be bound by them. You
          acknowledge that you had the opportunity to print or save this Auto
          Pay Authorization.
        </P3>
        <p>
          <P3>
            <B>Print and save a copy of this authorization for your records:</B>
            {`\n`}
            <Link
              onPress={() => {
                if (navigator.userAgent.match(/safari/i)) {
                  document.execCommand('print', false, '');
                } else {
                  window.print();
                }
              }}
            >
              Automatic Payment Authorization
            </Link>
          </P3>
        </p>
      </div>
      <div className="flex flex-row items-start">
        <div className="mr2">
          <Checkbox
            id="eftaV3Authorization"
            onChange={(checked) => onChange(checked)}
            defaultChecked={false}
            disabled={false}
            name="eftaV3Authorization"
          />
        </div>
        <P3 style={{ marginTop: 0, paddingTop: 0 }}>
          I have read and agree to the terms and conditions of the Auto Pay
          Authorization on {dayjs().format('MM/DD/YYYY')}.
        </P3>
      </div>
    </div>
  );
};

export default EftaV3;
