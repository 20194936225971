import { useFlags } from 'launchdarkly-react-client-sdk';
import HasPastDueBalancePaymentBanner from '../HasPastDueBalancePaymentBanner/HasPastDueBalancePaymentBanner';
import ChargedOffBanner from './ChargedOffBanner';
import ReplacementCardBanner from './ReplacementCardBanner';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';

const AuthenticatedBanners = () => {
  const { isAuthenticated } = useRiskAuth();
  const { showHcrExperience, enableAccountSummaryCardArt } = useFlags();

  if (!isAuthenticated) return null;

  return (
    <div>
      {showHcrExperience && <ChargedOffBanner />}
      {enableAccountSummaryCardArt && <HasPastDueBalancePaymentBanner />}
      <ReplacementCardBanner />
    </div>
  );
};

export default AuthenticatedBanners;
