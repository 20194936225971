import { useEffect } from 'react';
import { OktaResponseError } from './types';
import { LoginLink } from './Login/LoginLink';
import DefaultAuthError, { AuthError } from './DefaultAuthError';
import { MLError } from '@core/services';

/*
Okta Error Codes: https://developer.okta.com/docs/reference/error-codes/
 */

export const invalidOtpErrorMessage = 'Code is incorrect, please try again.';
const errorsHandledInFormTextInput = ['E0000068'];
export const oktaErrorsToIgnore = ['E0000004', 'E0000080'];

export const notifyOktaAuthError = (error: OktaResponseError) => {
  if (!oktaErrorsToIgnore.includes(error.errorCode)) {
    MLError.report({
      error,
      name: `${error.errorCode}: ${error.errorSummary}`,
      prefix: 'OktaAuthError',
    });
  }
};

interface OktaErrorMessageProps {
  error: OktaResponseError;
  onReset?: () => void;
}

export const OktaErrorMessage = ({ error, onReset }: OktaErrorMessageProps) => {
  const { errorCode } = error;

  useEffect(() => {
    notifyOktaAuthError(error);
  }, [error]);

  // do not show any error messaging when we already show them in the text input
  if (errorsHandledInFormTextInput.includes(errorCode)) {
    return null;
  }

  if (errorCode === 'E0000011' && onReset) {
    return (
      <CustomOktaAuthError error={error} name="Login Time Out">
        One time passcode expired. Please{' '}
        <LoginLink onClick={onReset}>login again.</LoginLink>
      </CustomOktaAuthError>
    );
  }

  if (errorCode === 'E0000080') {
    return (
      <CustomOktaAuthError error={error} name="Reset Password Invalid Password">
        Password cannot be your current password
      </CustomOktaAuthError>
    );
  }

  if (errorCode === 'E0000004') {
    return (
      <CustomOktaAuthError error={error} name="Login Credential Failure">
        We don't recognize that email or password. Please try again.
      </CustomOktaAuthError>
    );
  }

  return <DefaultAuthError error={error} />;
};

interface CustomOktaAuthErrorProps {
  error: OktaResponseError;
  name: string;
  children: React.ReactNode;
}

const CustomOktaAuthError = ({
  name,
  error,
  children,
}: CustomOktaAuthErrorProps) => (
  <AuthError
    name={name}
    error={{
      name,
      message: error.errorSummary,
      code: error.errorCode,
    }}
  >
    {children}
  </AuthError>
);
