import { Icon } from '@missionlane/compass-ui';
import track, { EventNames } from '../track';
import { useSpendBonus } from '@clip/SpendBonus/hooks';

import './DismissOfferButton.css';

export const TEST_ID = 'dismiss-offer-button';

export const DismissOfferButton = (): JSX.Element | null => {
  const spendBonusResult = useSpendBonus();

  if (
    spendBonusResult.isEnabled === false ||
    spendBonusResult.spendBonus.offerId === null
  ) {
    return null;
  }

  const {
    spendBonus: { offerId, rewardType, status },
    hideOfferLoading,
    hideOffer,
  } = spendBonusResult;

  const handleClick = () => {
    if (hideOfferLoading) return;

    track({
      eventName: EventNames.HideOffer,
      rewardType,
      status,
    });

    hideOffer({ offerId });
  };

  return (
    <div
      className="dismiss-offer-button__wrapper"
      onClick={handleClick}
      data-testid={TEST_ID}
      role="button"
    >
      <Icon
        color={hideOfferLoading ? 'greyLight' : 'blue'}
        name="close"
        size={24}
      />
    </div>
  );
};
