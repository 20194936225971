import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ModifyPINForm } from './ModifyPINForm';
import { ModifyPINSuccess } from './ModifyPINSuccess';
import TrackService from '@core/services/TrackService/TrackService';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const PAGE_NAME = 'Change PIN';

const ModifyPINPage = () => {
  const { customerId, accountId } = useCustomerAndAccountIdentifiers();
  const location = useLocation();
  const { isMobile } = useUserDevice();

  /** To prevent URL manipulation, we check for the prevPath state. If not present or doesn't
   * match the current modify-pin path, we can assume the user attempted to directly navigate to
   * modify-pin?success=true, and we will redirect them back to the Form Page instead
   */
  const successFromFormPath = location.state?.prevPath === location.pathname;
  const showSuccessMessage = location.search.includes('success=true');

  useEffect(() => {
    if (customerId) {
      const segmentTrackingProperties = { customerId, accountId };
      if (showSuccessMessage && successFromFormPath) {
        TrackService.page(`${PAGE_NAME} Success`, segmentTrackingProperties);
      } else {
        TrackService.page(PAGE_NAME, segmentTrackingProperties);
      }
    }
  }, [showSuccessMessage, customerId]);

  if (showSuccessMessage && !successFromFormPath) {
    // navigate back to the base url `modify-pin`
    return <Navigate to="." />;
  }

  return (
    <PageWrapper
      contentClassName={!isMobile && !showSuccessMessage ? 'w6' : undefined}
    >
      {showSuccessMessage ?
        <ModifyPINSuccess />
      : <ModifyPINForm />}
    </PageWrapper>
  );
};

export default ModifyPINPage;
