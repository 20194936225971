import { useLazyQuery } from '@apollo/client';
import { useEffect } from 'react';
import { useAccount } from '@core/components/Auth/AccountContext';
import { GET_CUSTOMER_ID_QUERY } from '@core/queries/getCustomerIdQuery';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import { GetCustomerIdQuery } from '@core/graphql/globalTypes';

export const useCustomerAndAccountIdentifiers = () => {
  const { accountId } = useAccount();
  const [getCustomerId, { data }] = useLazyQuery<GetCustomerIdQuery>(
    GET_CUSTOMER_ID_QUERY,
  );
  const { isAuthenticated } = useRiskAuth();

  useEffect(() => {
    if (isAuthenticated) {
      getCustomerId();
    }
  }, [isAuthenticated]);

  const customerId = data?.customer?.id;

  return {
    accountId,
    customerId,
  };
};
