import { useNavigate } from 'react-router-dom';
import OfferFundingDetails from '../PaymentPlanFlow/OfferFundingDetails';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { useEditOfferFundingAccountContext } from '../contexts/EditOfferFundingAccountContext';
import PageWrapper from '@core/components/Page/PageWrapper';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import BackLink from '@core/components/General/BackLink';
import PageTitle from '@core/components/Page/PageTitle/PageTitle';

const EditOfferFundingAccount = () => {
  const navigate = useNavigate();
  const { activeOffer } = useSettlementOffers();
  const { selectedFundingAccountId, setSelectedFundingAccountId } =
    useEditOfferFundingAccountContext();
  if (!selectedFundingAccountId) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper>
      <BackLink className="pb3" destination="../summary" />
      {/* We cant pass as a prop cause the back link needs to be above */}
      <PageTitle title="Edit Funding Account" />
      <OfferFundingDetails
        isEditing
        nextStep={() => navigate('../summary')}
        offer={activeOffer}
        initialSelectedFundingAccountId={selectedFundingAccountId}
        onChange={setSelectedFundingAccountId}
      />
    </PageWrapper>
  );
};

export default EditOfferFundingAccount;
