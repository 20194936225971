import { ComponentType } from 'react';
import { SpendBonusStatus } from '@core/graphql/globalTypes';

import { useSpendBonus } from '@clip/SpendBonus/hooks';

import {
  SpendBonusMissedPayment,
  SpendBonusInProgress,
  SpendBonusIneligible,
  SpendBonusIncomplete,
  SpendBonusSuccess,
  SpendBonusPending,
} from '@clip/SpendBonus/components';

const contentMap: {
  [key in SpendBonusStatus]: ComponentType | null;
} = {
  [SpendBonusStatus.InProgress]: SpendBonusInProgress,
  [SpendBonusStatus.Pending]: SpendBonusPending,
  [SpendBonusStatus.Approved]: SpendBonusSuccess,
  [SpendBonusStatus.Completed]: SpendBonusSuccess,
  [SpendBonusStatus.Ineligible]: SpendBonusIneligible,
  [SpendBonusStatus.Declined]: SpendBonusIneligible,
  [SpendBonusStatus.MissedPayment]: SpendBonusMissedPayment,
  [SpendBonusStatus.NotCompleted]: SpendBonusIncomplete,
  [SpendBonusStatus.OptedOut]: null,
};

export const SpendBonus = () => {
  const spendBonusResult = useSpendBonus();

  if (
    spendBonusResult.isEnabled === false ||
    spendBonusResult.spendBonus.status == null
  ) {
    return null;
  }

  const Content = contentMap[spendBonusResult.spendBonus.status];

  if (Content == null) return null;

  return <Content />;
};
