import { useEffect } from 'react';
import dayjs from 'dayjs';
import { GestureResponderEvent } from 'react-native';
import OfferKard from './OfferKard';
import { ProactiveMulticardOffer as ProactiveMulticardOfferType } from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';
import { formatUSD } from '@core/utils/formatters';

export const PROACTIVE_MULTICARD_OFFER_EVENT = 'PROACTIVE_MULTICARD_OFFER';
const PROACTIVE_MULTICARD_APPLY_LINK = `${process.env.REACT_APP_APPLY_URL}/flow/application`;

const OFFER_TYPE = {
  CATEGORY_REWARDS: 'CATEGORY_REWARDS',
  CASHBACK: 'CASHBACK',
  STANDARD: 'STANDARD',
} as const;

type OfferType = keyof typeof OFFER_TYPE;

type OfferCopy = {
  title: string;
  body: (offer: ProactiveMulticardOfferType) => string;
};

export const isOfferType = (offerType = ''): offerType is OfferType => {
  const maybeOfferType = offerType as OfferType;
  return Boolean(OFFER_TYPE[maybeOfferType]);
};

const getRewardPercentageCopy = (rewardPercentage?: string | null) => {
  const percentage = parseFloat(rewardPercentage || '0');
  const formattedPercentage = parseFloat(percentage.toFixed(1));
  return `${formattedPercentage}%`;
};

const getCreditLineCopy = (creditLimitDollars?: number | null) => {
  const preformatted = formatUSD(`${creditLimitDollars}` || '0');
  return preformatted ? preformatted.split('.')[0] : '';
};

const cashBackOfferCopy: OfferCopy = {
  title: 'You prequalify for our exclusive Silver Line Visa®',
  body: (offer: ProactiveMulticardOfferType) =>
    `Unlimited ${getRewardPercentageCopy(
      offer.rewardsPercentage,
    )} cash back and a credit line up to ${getCreditLineCopy(
      offer.maxCreditLimit || 0,
    )}.`,
};

const categoryRewardsOfferCopy: OfferCopy = {
  title: 'You prequalify for a premier Gold Line Visa®',
  body: (offer: ProactiveMulticardOfferType) => {
    const rewards: string[] = [...(offer.rewardCategories || [])];
    const lastReward = rewards.pop();
    const rewardCategoryCopy = `${rewards.join(', ')}, and ${lastReward}`;

    return `Credit line up to ${getCreditLineCopy(
      offer.maxCreditLimit,
    )}, ${getRewardPercentageCopy(
      offer.totalCategoryRewardsPercentage,
    )} cash back on ${rewardCategoryCopy}.`;
  },
};

const standardOfferCopy: OfferCopy = {
  title: 'You prequalify for the Green Line Visa®',
  body: (offer: ProactiveMulticardOfferType) =>
    `No annual fee with credit lines up to ${getCreditLineCopy(
      offer.maxCreditLimit,
    )}.`,
};

const OFFER_TYPE_COPY: Record<OfferType, OfferCopy> = {
  CASHBACK: cashBackOfferCopy,
  CATEGORY_REWARDS: categoryRewardsOfferCopy,
  STANDARD: standardOfferCopy,
} as const;

export const getActiveOfferCopy = (
  offer: ProactiveMulticardOfferType | null,
) => {
  if (!offer || !isOfferType(offer.offerType)) {
    return null;
  }

  const activeOfferCopy = OFFER_TYPE_COPY[offer.offerType];

  return {
    ...activeOfferCopy,
    body: activeOfferCopy.body(offer),
  };
};

interface ProactiveMultcardOfferProps {
  offerCopy: {
    title: string;
    body: string;
  };
  onOfferDismiss: () => void;
}

const ProactiveMulticardOffer = ({
  offerCopy,
  onOfferDismiss,
}: ProactiveMultcardOfferProps) => {
  useEffect(() => {
    TrackService.trackEvent('Offer Viewed', {
      feature: PROACTIVE_MULTICARD_OFFER_EVENT,
    });
  }, []);

  const onClickClose = () => {
    TrackService.trackClick(`Dismiss ${PROACTIVE_MULTICARD_OFFER_EVENT} tile`, {
      feature: PROACTIVE_MULTICARD_OFFER_EVENT,
    });

    localStorage.setItem(PROACTIVE_MULTICARD_OFFER_EVENT, dayjs().toString());
    onOfferDismiss();
  };

  const onClickCta = (e: GestureResponderEvent) => {
    e.preventDefault();
    TrackService.trackClick('See If You Qualify', {
      feature: PROACTIVE_MULTICARD_OFFER_EVENT,
    });
    window.open(PROACTIVE_MULTICARD_APPLY_LINK, '_blank')?.focus();
  };

  return (
    <OfferKard
      title={offerCopy.title}
      description={offerCopy.body}
      onClickClose={onClickClose}
      onClickCta={onClickCta}
    />
  );
};

export default ProactiveMulticardOffer;
