import { useQuery } from '@apollo/client';
import { createContext, useContext, type PropsWithChildren } from 'react';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import { GET_CUSTOMER_ID_QUERY } from '@core/queries/getCustomerIdQuery';
import {
  GetCustomerIdQuery,
  GetCustomerIdQueryVariables,
} from '@core/graphql/globalTypes';

const CustomerContext = createContext<{ id?: string }>({
  id: undefined,
});

export const useCustomer = () => useContext(CustomerContext);

export const CustomerProvider = ({ children }: PropsWithChildren) => {
  const { data, loading } = useQuery<
    GetCustomerIdQuery,
    GetCustomerIdQueryVariables
  >(GET_CUSTOMER_ID_QUERY);

  if (loading) {
    return <LoadingSpinnerPage centerContent withFooter />;
  }

  const value = data?.customer || {};

  return (
    <CustomerContext.Provider value={value}>
      {children}
    </CustomerContext.Provider>
  );
};
