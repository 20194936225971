import { B, BubbleIcon, P3 } from '@missionlane/compass-ui';
import PaymentsOverviewItem from '@payments/components/PaymentsOverview/PaymentsOverviewItem';
import { ScheduledPayment } from '@core/utils/convertAutopayToScheduledPayment';

const NoActivity = () => (
  <div className="bg-haze-lightest flex flex-column items-center pv4 ph3 mt3 ph0-ns">
    <BubbleIcon name="dollarSign" bubbleColor="inkLightest" iconColor="ink" />
    <P3 color="ink">
      <B>Nothing Yet</B>
    </P3>
    <div className="w-60-ns tc">
      <P3>
        You haven't made any payments. When you make one, you can find it here!
      </P3>
    </div>
  </div>
);

interface Props {
  payments: ScheduledPayment[];
}

const PaymentsOverviewItems = ({ payments }: Props) => {
  if (!payments.length) {
    return <NoActivity />;
  }

  return (
    <ul className="bg-white pa0 mv0">
      {payments.map((payment) => (
        <PaymentsOverviewItem key={payment.id} payment={payment} />
      ))}
    </ul>
  );
};

export default PaymentsOverviewItems;
