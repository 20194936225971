import { Icon, P3, Spacing } from '@missionlane/compass-ui';
import { centsToDollars } from '@core/utils/centsToDollars';

interface PendingRewardsRedemptionProps {
  pendingAmount: number;
}

const PendingRewardsRedemption = ({
  pendingAmount,
}: PendingRewardsRedemptionProps) => {
  return (
    <div className="flex flex-row items-center">
      <Icon
        style={{ marginRight: Spacing.xs }}
        color="green"
        name="checkCircleLight"
      />
      <P3 color="green">{centsToDollars(pendingAmount)} redeemed (pending)</P3>
    </div>
  );
};

export default PendingRewardsRedemption;
