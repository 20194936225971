import cx from 'classnames';
import {
  P2,
  B,
  Icon,
  Notification,
  P3,
  Button,
  H4,
} from '@missionlane/compass-ui';
import Kard from '@core/components/General/Kard/Kard';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { centsToDollars } from '@core/utils/centsToDollars';
import { formatDate, pluralize } from '@core/utils/formatters';
import '../PaymentPlanFlow/PaymentPlanFlow.css';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface EstimatedPaymentKardProps {
  settlementBalance: number;
  durationMonths: number;
  offerEndDate: string;
  onSchedulePayments: () => void;
}

const EstimatedPaymentKard = ({
  settlementBalance,
  durationMonths,
  offerEndDate,
  onSchedulePayments,
}: EstimatedPaymentKardProps) => {
  const paymentAmountPerMonth = settlementBalance / durationMonths;
  const { isDesktopLarge } = useUserDevice();
  return (
    <Kard>
      <div className="mb3">
        <H4 color="ink">
          <B>Estimated Monthly Payment</B>
        </H4>
      </div>
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P2">
        <span className="mr1 ">
          <Icon name="calendar" size="s" color="ink" />
        </span>
        If you start today, you’ll owe{' '}
        <B color="ink" testID="NumberOfMonthlyPayments">
          {durationMonths} monthly {pluralize('payment', durationMonths)}
        </B>{' '}
        of about:
      </ResponsiveTypography>
      <div className="flex flex-row items-end mt2 mb3">
        <ResponsiveTypography
          type="HEADER"
          mobileLevel="H1"
          desktopLevel="H3"
          testID="MonthlyPayment"
        >
          {centsToDollars(paymentAmountPerMonth)}
        </ResponsiveTypography>
        <div className="ml2 mb1 mb0-ns">
          <P2>per month</P2>
        </div>
      </div>
      <div className="pb3">
        <Notification level="info" variant="inline">
          <B>If you accept this offer,</B> you'll be able to edit individual
          payments as long as the complete balance is paid by{' '}
          <B>{formatDate(offerEndDate, 'MMMM D, YYYY')}</B>.
        </Notification>
      </div>
      <div className="pt2 bt payment-plan-border">
        <P3>
          You can make payments on a monthly, twice a month, or weekly schedule.
        </P3>
        <div className={cx('mt3', isDesktopLarge && 'flex')}>
          <Button
            text="Schedule My Payments"
            onPress={() => onSchedulePayments()}
          ></Button>
        </div>
      </div>
    </Kard>
  );
};

export default EstimatedPaymentKard;
