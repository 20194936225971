import { PROACTIVE_MULTICARD_OFFER_EVENT } from '@clip/Multicard/ProactiveMulticardOffer';
import ProactiveMulticardTile from '@clip/Multicard/ProactiveMulticardTile';
import ReactiveMulticardTile, {
  REACTIVE_MULTICARD_TILE_EVENT,
} from '@clip/Multicard/ReactiveMulticardTile';
import { CarouselTile } from '@core/components/Carousel/Carousel';
import CreditProtectionTile, {
  CREDIT_PROTECTION_TILE_EVENT,
} from '@core/components/CreditProtection/CreditProtectionTile';
import { successStorageKey } from '@core/components/MyProgressOnboarding/MyProgressOnboardingPage';
import OnboardingOpportunityTile, {
  ONBOARDING_TILE_EVENT,
} from '@core/components/Onboarding/OnboardingOpportunityTile';
import ReferralsTile, {
  REFERRALS_TILE_EVENT,
} from '@core/components/Referrals/ReferralsTile';
import { OpportunityTileId } from '@core/graphql/globalTypes';

import AddPaymentMethodTile, {
  ADD_PAYMENT_METHOD_TILE_EVENT,
} from './OpportunityTiles/AddPaymentMethodTile';
import AutopayTileV2, {
  TRY_AUTOPAY_TILE_EVENT,
} from './OpportunityTiles/AutopayTile';

export type TileEventName =
  | typeof TRY_AUTOPAY_TILE_EVENT
  | typeof CREDIT_PROTECTION_TILE_EVENT
  | typeof ADD_PAYMENT_METHOD_TILE_EVENT
  | typeof ONBOARDING_TILE_EVENT
  | typeof PROACTIVE_MULTICARD_OFFER_EVENT
  | typeof REACTIVE_MULTICARD_TILE_EVENT
  | typeof REFERRALS_TILE_EVENT;

export const opportunityTilesComponentsMap: {
  [key in OpportunityTileId]: CarouselTile['component'];
} = {
  [OpportunityTileId.Autopay]: AutopayTileV2,
  [OpportunityTileId.CreditProtection]: CreditProtectionTile,
  [OpportunityTileId.FundingAccount]: AddPaymentMethodTile,
  [OpportunityTileId.Onboarding]: OnboardingOpportunityTile,
  [OpportunityTileId.ProactiveMulticard]: ProactiveMulticardTile,
  [OpportunityTileId.PushNotifications]: () => null, // NOT SUPPORTED
  [OpportunityTileId.ReactiveMulticard]: ReactiveMulticardTile,
  [OpportunityTileId.Referrals]: ReferralsTile,
};

export const getDismissedAt = ({
  creditProtectionMarketing,
  referralsMarketing,
}: {
  creditProtectionMarketing: string | null;
  referralsMarketing: string | null;
}): {
  [key in OpportunityTileId]: string | null;
} => ({
  [OpportunityTileId.Autopay]: localStorage.getItem(TRY_AUTOPAY_TILE_EVENT),
  [OpportunityTileId.CreditProtection]:
    // TODO: EEC-183 - Remove this
    creditProtectionMarketing === 'DISABLED' ? 'DISABLED' : (
      localStorage.getItem(CREDIT_PROTECTION_TILE_EVENT)
    ),
  [OpportunityTileId.FundingAccount]: localStorage.getItem(
    ADD_PAYMENT_METHOD_TILE_EVENT,
  ),
  [OpportunityTileId.Onboarding]:
    localStorage.getItem(ONBOARDING_TILE_EVENT) ||
    localStorage.getItem(successStorageKey),
  [OpportunityTileId.PushNotifications]: 'NOT SUPPORTED',
  [OpportunityTileId.ProactiveMulticard]: localStorage.getItem(
    PROACTIVE_MULTICARD_OFFER_EVENT,
  ),
  [OpportunityTileId.ReactiveMulticard]: localStorage.getItem(
    REACTIVE_MULTICARD_TILE_EVENT,
  ),
  [OpportunityTileId.Referrals]:
    // TODO: EEC-183 - Remove this
    referralsMarketing === 'DISABLED' ? 'DISABLED' : (
      localStorage.getItem(REFERRALS_TILE_EVENT)
    ),
});
