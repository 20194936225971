import { gql, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import PageWrapper from '../Page/PageWrapper';
import LoadingSpinner from '../General/LoadingSpinner';
import CardholderInformation from './CardholderInformation';
import FinancialInformation from './FinancialInformation';
import UpdateInformationLink from './UpdateInformationLink';
import { GetMyProfileInfoQuery } from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';

export const GET_MY_PROFILE_INFO_QUERY = gql`
  query GetMyProfileInfo {
    customer {
      id
      contactInfo {
        address {
          city
          postalCode
          state
          street1
          street2
        }
        email
        phoneNumber
      }
      financialInfo {
        annualIncome
        monthlyLiving
        eligibleForUpdate
      }
    }
  }
`;

const MyProfilePageInner = () => {
  const { data, error, loading } = useQuery<GetMyProfileInfoQuery>(
    GET_MY_PROFILE_INFO_QUERY,
    { errorPolicy: 'all' },
  );

  useEffect(() => {
    const customerId = data?.customer?.id;
    if (customerId) {
      TrackService.page('My profile', {
        customerId,
      });
    }
  }, [data?.customer]);

  if (loading) return <LoadingSpinner />;

  if (!data?.customer) {
    throw error;
  }

  const {
    customer: { contactInfo, financialInfo },
  } = data;
  const { address, email, phoneNumber } = contactInfo;

  return (
    <>
      <CardholderInformation
        email={email}
        phoneNumber={phoneNumber}
        address={address}
      />
      <UpdateInformationLink />
      <FinancialInformation financialInfo={financialInfo} />
    </>
  );
};

const MyProfilePage = () => (
  <PageWrapper isSingleColumn pageTitle="My Profile">
    <MyProfilePageInner />
  </PageWrapper>
);

export default MyProfilePage;
