import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import ExternalLink from '@core/components/General/ExternalLink';

const LINK_CLASSNAME = 'no-underline blue b hover-indigo tp-all td-1 pointer';

interface LoginLinkOnClickProps extends LoginLinkBaseProps {
  to?: undefined;
  onClick: (e: React.MouseEvent) => void;
  external?: undefined;
}

interface LoginLinkToProps extends LoginLinkBaseProps {
  to: string;
  onClick?: undefined;
  external?: boolean;
}

interface LoginLinkBaseProps {
  onClick?: (e: React.MouseEvent) => void;
  children: ReactNode;
  to?: string;
  external?: boolean;
}

// LoginLink can either have "to" or "onClick", but not both
type LoginLinkProps = LoginLinkToProps | LoginLinkOnClickProps;

export const LoginLink = ({
  onClick,
  to,
  children,
  external,
}: LoginLinkProps) => {
  function handleMouseDown(e: React.MouseEvent<HTMLAnchorElement>) {
    e.preventDefault();
    onClick?.(e);
  }

  if (external && to) {
    return (
      <ExternalLink to={to} className={LINK_CLASSNAME}>
        {children}
      </ExternalLink>
    );
  }

  return (
    <Link
      className={LINK_CLASSNAME}
      onMouseDown={handleMouseDown}
      to={to || '#'}
    >
      {children}
    </Link>
  );
};
