import dayjs from 'dayjs';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { useMemo, useState } from 'react';
import cx from 'classnames';
import { Icon } from '@missionlane/compass-ui';
import Modal from '../Modal/Modal';
import { useTracking } from '@core/services/TrackService/useTracking';

dayjs.extend(isSameOrBefore);
dayjs.extend(isSameOrAfter);

const defaultDateTitles = {
  [dayjs().startOf('day').toISOString()]: 'Today',
};

export interface DatepickerProps {
  selectedDate: Date | undefined;
  onChange: (date: Date) => void;
  isOpen: boolean;
  onClose: () => void;
  onOpen?: () => void;
  /** Header text for the modal */
  headerText?: string;
  /** Earliest allowed visible date */
  minDate?: Date;
  /** Latest allowed visible date */
  maxDate?: Date;
  /** Dates that should not be selectable */
  unavailableDates?: Date[];
  /** Dictionary of titles indexed by an ISO string of the date that they should render above */
  dateTitles?: Record<string, string>;
}

type Day = {
  display: string;
  value: Date;
  disabled: boolean;
  title?: string;
};

type Week = Day[];

const Datepicker = ({
  selectedDate,
  onChange,
  isOpen,
  onClose,
  onOpen,
  headerText,
  minDate,
  maxDate,
  unavailableDates = [],
  dateTitles,
}: DatepickerProps) => {
  const { trackEvent } = useTracking();
  const [currentMonth, setCurrentMonth] = useState(
    dayjs(selectedDate || new Date())
      .startOf('month')
      .toDate(),
  );

  const mergedDefaultAndCustomDateTitles: DatepickerProps['dateTitles'] = {
    ...defaultDateTitles,
    ...dateTitles,
  };

  const currentMonthWeeks = useMemo<Week[]>(() => {
    const weeks: Week[] = [];
    let currentWeek: Week = [];
    let currentDate = dayjs(currentMonth).startOf('month').startOf('week');
    const endDate = dayjs(currentMonth).endOf('month').endOf('week');
    while (currentDate.isSameOrBefore(endDate, 'day')) {
      const startOfCurrentDate = currentDate.startOf('day');
      const isUnavailable = !!unavailableDates.find((unavailableDate) =>
        dayjs(unavailableDate).isSame(currentDate, 'day'),
      );
      const isBeforeMinDate = !!minDate && currentDate.isBefore(minDate, 'day');
      const isAfterMaxDate = !!maxDate && currentDate.isAfter(maxDate, 'day');
      const isInCurrentMonth = currentDate.isSame(currentMonth, 'month');

      currentWeek.push({
        display: currentDate.date().toString(),
        value: startOfCurrentDate.toDate(),
        disabled:
          isUnavailable ||
          isBeforeMinDate ||
          isAfterMaxDate ||
          !isInCurrentMonth,
        title:
          mergedDefaultAndCustomDateTitles[startOfCurrentDate.toISOString()],
      });
      currentDate = currentDate.add(1, 'day');
      // Indicates start of a new week
      if (currentDate.day() === 0) {
        weeks.push(currentWeek);
        currentWeek = [];
      }
    }

    return weeks;
  }, [
    currentMonth,
    unavailableDates,
    minDate,
    maxDate,
    mergedDefaultAndCustomDateTitles,
  ]);

  const canGoPrevious =
    !minDate || minDate.getMonth() < currentMonth.getMonth();
  const canGoNext = !maxDate || maxDate.getMonth() > currentMonth.getMonth();

  const handleGoPrevious = () => {
    const newCurrentMonth = dayjs(currentMonth).subtract(1, 'month');
    setCurrentMonth(newCurrentMonth.toDate());
  };

  const handleGoNext = () => {
    const newCurrentMonth = dayjs(currentMonth).add(1, 'month');
    setCurrentMonth(newCurrentMonth.toDate());
  };

  const handleDateSelected = (date: Date) => {
    onChange(date);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        trackEvent({ feature: 'Datepicker', eventName: 'Closed' });
        onClose();
      }}
      onOpen={() => {
        onOpen?.();
        trackEvent({ feature: 'Datepicker', eventName: 'Opened' });
      }}
      headerText={headerText}
    >
      <table className="g-white w-100 pa2 pa3-l tc ink">
        <thead>
          <tr className="ink-30">
            <td className="pa2">Sun</td>
            <td className="pa2">Mon</td>
            <td className="pa2">Tue</td>
            <td className="pa2">Wed</td>
            <td className="pa2">Thu</td>
            <td className="pa2">Fri</td>
            <td className="pa2">Sat</td>
          </tr>
          <tr>
            <td className="pv3">
              {canGoPrevious && (
                <a onClick={handleGoPrevious} className="pointer">
                  <Icon name="arrowLeft" color="blue" />
                </a>
              )}
            </td>
            <td className="pv3" colSpan={5}>
              {dayjs(currentMonth).format('MMMM YYYY')}
            </td>
            <td className="pv3">
              {canGoNext && (
                <a onClick={handleGoNext} className="pointer">
                  <Icon name="arrowRight" color="blue" />
                </a>
              )}
            </td>
          </tr>
        </thead>
        <tbody>
          {currentMonthWeeks.map((week, index) => (
            <tr className="f6" key={index}>
              {week.map(({ value, ...otherProps }) => (
                <Day
                  key={value.toISOString()}
                  onClick={() => handleDateSelected(value)}
                  isSelected={dayjs(value).isSame(selectedDate)}
                  {...otherProps}
                />
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </Modal>
  );
};

interface DayProps {
  display: string;
  isSelected: boolean;
  onClick: () => void;
  disabled: boolean;
  title?: string;
}

const Day = ({ display, isSelected, onClick, disabled, title }: DayProps) => (
  <td className="relative pv1" tabIndex={!isSelected ? -1 : undefined}>
    {title && !isSelected && (
      <div className="ink-50 absolute left-0 f8 w-100 center top-0 nt2">
        {title}
      </div>
    )}
    <a
      className={cx('inline-flex items-center justify-center h2 w2 br-100', {
        'ink-30': disabled && !isSelected,
        pointer: !disabled,
        'bg-blue white': isSelected,
      })}
      onClick={() => {
        if (!disabled) {
          onClick();
        }
      }}
    >
      {display}
    </a>
  </td>
);

export default Datepicker;
