import cx from 'classnames';
import confetti, { Options as CanvasConfettiOptions } from 'canvas-confetti';
import { useEffect } from 'react';
import { B, Colors, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import BalanceKard from '../Kards/BalanceKard';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import ChargedOffDashboardFooter from './ChargedOffDashboardFooter';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { ChargedOffBannerVariant as FaqVariant } from '@core/components/Banners/AuthenticatedBanners/ChargedOffBanner';
import { TrackService } from '@core/services';

const FulfilledPIFDashboard = () => {
  const { isMobile, isDesktopLarge } = useUserDevice();
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.trackPage('Charged Off Dashboard - PIF Offer Complete', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });

    const confettiTimer = setTimeout(() => {
      fireConfettiCannons([
        {
          particleRatio: 0.25,
          spread: 26,
          startVelocity: 55,
        },
        {
          particleRatio: 0.2,
          spread: 60,
        },
        {
          particleRatio: 0.35,
          spread: 100,
          decay: 0.91,
          scalar: 0.8,
        },
        {
          particleRatio: 0.1,
          spread: 120,
          startVelocity: 25,
          decay: 0.92,
          scalar: 1.2,
        },
        {
          particleRatio: 0.1,
          spread: 120,
          startVelocity: 45,
        },
      ]);
    }, 1000);

    // Cleanup any outstanding timer or animation on unmount
    return () => {
      clearTimeout(confettiTimer);
      confetti.reset();
    };
  }, []);

  const congratulatoryKardHeader: KardHeader = {
    level: isMobile ? 'H2' : 'H3',
    textPrimary: 'You did it! Your account balance is',
    textSecondary: 'paid in full.',
    className: 'mb3',
  };

  return (
    <>
      <div className={cx('flex', !isDesktopLarge && 'flex-column')}>
        <div className={cx(isDesktopLarge ? 'w-50 mr4' : 'mb2')}>
          <BalanceKard />
        </div>
        <div className={cx(isDesktopLarge && 'w-50')}>
          <Kard header={congratulatoryKardHeader}>
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P3"
            >
              <B color="ink">Congratulations!</B> Paying off your full account
              balance is a huge step in your credit journey.{' '}
              <Link
                onPress={() =>
                  navigate('../payment-plan/faq', {
                    state: {
                      faqVariant: FaqVariant.FULFILLED_PIF,
                    },
                  })
                }
              >
                What happens now?
              </Link>
            </ResponsiveTypography>
          </Kard>
        </div>
      </div>
      <ChargedOffDashboardFooter />
    </>
  );
};

type ConfettiCannon = {
  particleRatio: number;
} & CanvasConfettiOptions;

/**
 * Takes in a list of canvas-confetti configs and fires them off one by one
 */
const fireConfettiCannons = async (confettiCannons: ConfettiCannon[]) => {
  const colors = [
    Colors.pine,
    Colors.pine, // use twice as much primary color as anything else
    Colors.yellow,
    Colors.green,
    Colors.greenLight,
  ];

  const defaultOptions: CanvasConfettiOptions = {
    colors,
    // use two thirds squares, one third circles
    shapes: ['square', 'square', 'circle'],
  };
  return await Promise.all(
    confettiCannons.map(async ({ particleRatio, ...otherOptions }) => {
      await confetti({
        ...defaultOptions,
        ...otherOptions,
        particleCount: Math.floor(particleRatio * 200),
      });
    }),
  );
};

export default FulfilledPIFDashboard;
