import { useState } from 'react';
import { gql, useQuery } from '@apollo/client';
import { Navigate, useLocation } from 'react-router-dom';
import ChangeAddressSuccessPage from './ChangeAddressSuccessPage/ChangeAddressSuccessPage';
import ChangeAddressPage from './ChangeAddressPage';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { GetChangeAddressMfaQuery } from '@core/graphql/globalTypes';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

export const GET_CHANGE_ADDRESS_MFA_QUERY = gql`
  query GetChangeAddressMfa {
    customer {
      id
      contactInfo {
        addressChangeEligibility {
          isEligible
          mfaRequired
        }
      }
    }
  }
`;

const ChangeAddressFlow = () => {
  const location = useLocation();
  const { data, loading } = useQuery<GetChangeAddressMfaQuery>(
    GET_CHANGE_ADDRESS_MFA_QUERY,
    {
      // Need to request it everytime to handle post /verify redirect
      fetchPolicy: 'network-only',
    },
  );
  const [changeAddressPage, setChangeAddressPage] = useState<
    'CHANGE_ADDRESS_FORM' | 'CHANGE_ADDRESS_SUCCESS'
  >('CHANGE_ADDRESS_FORM');

  const handleSuccess = () => {
    setChangeAddressPage('CHANGE_ADDRESS_SUCCESS');
  };

  if (loading) return <LoadingSpinner />;

  const addressChangeEligibility =
    data?.customer?.contactInfo?.addressChangeEligibility;

  if (!addressChangeEligibility) {
    throw new Error(
      'ChangeAddressFlow: unable to get addressChangeEligibility data',
    );
  }

  const { mfaRequired, isEligible } = addressChangeEligibility;

  if (!isEligible) {
    return <Navigate to="/" />;
  }

  if (mfaRequired) {
    const preservedPrevState = location.state;

    return (
      <Navigate
        to="/verify"
        replace
        state={{ ...preservedPrevState, from: '/change-address' }}
      />
    );
  }

  switch (changeAddressPage) {
    case 'CHANGE_ADDRESS_SUCCESS':
      return <ChangeAddressSuccessPage />;
    case 'CHANGE_ADDRESS_FORM':
    default:
      return <ChangeAddressPage onSuccess={handleSuccess} />;
  }
};

const ChangeAddressFlowWithWrapper = withPageWrapper(ChangeAddressFlow, {
  pageTitle: 'Edit address',
});

export default ChangeAddressFlowWithWrapper;
