import { useFlags } from 'launchdarkly-react-client-sdk';
import { H4, P3, P1, Button } from '@missionlane/compass-ui';
import cx from 'classnames';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import houseCoins from '../../assets/houseCoins.svg';
import { FormButtonContainer } from './FormButtonContainer';
import {
  IncomeUpdateForm,
  IncomeUpdateError,
  SuccessAnimation,
} from '@clip/IncomeUpdate/components';
import { useIncomeUpdate } from '@clip/IncomeUpdate/hooks';
import { formatCurrency } from '@clip/IncomeUpdate/utils';
import { IncomeUpdateTrackingNames } from '@clip/IncomeUpdate/lib';
import './IncomeUpdateBanner.css';
import OfferDetailsModal from '@clip/IncomeUpdate/components/OfferDetailsModal';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

/**
 * getDesktopTitleText -  this function determines which title text to display based on the pie offer.
 *
 * @param {number} pieAmount - the amount in dollars of the pie offer
 * @returns {string} - the relevent title text for desktop devices
 */
export const getDesktopTitleText = (pieAmount?: number) => {
  if (pieAmount) {
    return `You could get a credit limit increase up to $${formatCurrency(
      pieAmount.toString(),
    )}!`;
  }

  return 'Take a minute to update your financial info';
};

/**
 * getDesktopDescriptionText - this function determines the description text for desktop devices
 *
 * @returns {string} - the relevent description text for desktop devices
 */
export const getDesktopDescriptionText = () => {
  return 'To consider you for a credit limit increase, we need your latest financial information.';
};

/**
 * getMobileTitleText -  this function determines which title text to display based on the pie offer.
 *
 * @param {number} pieAmount - the amount in dollars of the pie offer
 * @returns {string} - the relevent title text for mobile devices
 */
export const getMobileTitleText = (pieAmount?: number) => {
  if (pieAmount) {
    return `You could get a credit limit increase up to $${formatCurrency(
      pieAmount.toString(),
    )}`;
  }

  return 'Keep your financial info up to date';
};

/**
 * getMobileDescriptionText -  this function determines which description text to display based on whether or not the customer
 * has a pie offer.
 *
 * @param {boolean} hasPieOffer - whether or not the customer has a pie offer
 * @returns {string} - the relevent description text for mobile devices
 */
export const getMobileDescriptionText = (hasPieOffer: boolean) => {
  if (hasPieOffer) {
    return 'Update your financial info so we can consider you for a credit limit increase.';
  }

  return 'To consider you for future credit limit increases, we need your latest financial information.';
};

/**
 * IncomeUpdateBanner - renders the income update banner, located on the landing page.
 * Only displayed when showClipIncomeUpdateV2 and promptForIncomeUpdate flags are true.
 * @returns {React.ReactNode}
 */
export const IncomeUpdateBanner = () => {
  const { isDesktopLarge, isMobile } = useUserDevice();
  const { promptForIncomeUpdate } = useFlags();
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const {
    customerQueryData,
    customerQueryError,
    customerQueryLoading,
    updateSuccess,
    updateError,
    pieAmount,
    onSuccess,
    onError,
  } = useIncomeUpdate({
    trackingName: IncomeUpdateTrackingNames.ToutIncome,
    enabled: promptForIncomeUpdate,
  });

  if (
    !promptForIncomeUpdate ||
    !customerQueryData ||
    customerQueryLoading ||
    customerQueryError ||
    !customerQueryData.customer.financialInfo?.shouldGetCLIPTout
  ) {
    return null;
  }

  const hasPie = !!pieAmount;

  if (isMobile) {
    return (
      <div className="mobile-container flex flex-column pv3 ph4 mb3 br2 shadow-2">
        <H4>{getMobileTitleText(pieAmount)}</H4>
        <div className="flex mobile-text-image-gap mb2">
          <div className="mobile-text-container mt2">
            <P1>{getMobileDescriptionText(hasPie)}</P1>
          </div>
          <div className="mobile-image-container">
            <img src={houseCoins} alt="Income" />
          </div>
        </div>
        <div className="income-update-banner__button-container--mobile">
          <Button
            testID="update-income-mobile-button"
            variant="outlined"
            text="Update"
            onPress={() => {
              TrackService.click(IncomeUpdateTrackingNames.GreenCardIncome);
              navigate('/update-income');
            }}
          />
          {hasPie && (
            <>
              <Button
                onPress={() => {
                  TrackService.click(IncomeUpdateTrackingNames.SeeOfferDetails);
                  setIsOpen(true);
                }}
                variant="text"
                text="See Offer Details"
                size="small"
              />
              <OfferDetailsModal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
              />
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(
        'desktop-container flex mb4 bg-white br2 shadow-2',
        updateSuccess && 'justify-center',
        !isDesktopLarge && 'flex-column',
      )}
    >
      {updateSuccess ?
        <div className="success-animation-container flex self-center">
          <div className="flex flex-column items-center">
            <div className="mb3">
              <SuccessAnimation />
            </div>
            <H4>You're all set!</H4>
            <div className="success-description-container mt3">
              <P3>
                We'll send an email if you're eligible for an increase. It may
                take up to one statement cycle.
              </P3>
            </div>
          </div>
        </div>
      : <>
          <div
            className={cx(
              'desktop-image-container flex bg-washed-green pt6 pb5 ph5',
              !isDesktopLarge && 'justify-center',
            )}
          >
            <img className="mt2 mh1 mb5" src={houseCoins} alt="Income" />
          </div>
          <div className="flex flex-column pa5">
            {updateError ?
              <IncomeUpdateError />
            : null}
            {updateError || updateSuccess ? null : (
              <div>
                <div className="mb2">
                  <H4>{getDesktopTitleText(pieAmount)}</H4>
                </div>
                <div className="mb1">
                  <P3>{getDesktopDescriptionText()}</P3>
                </div>
                <IncomeUpdateForm
                  annualIncome={
                    customerQueryData.customer.financialInfo.annualIncome
                  }
                  monthlyLiving={
                    customerQueryData.customer.financialInfo.monthlyLiving
                  }
                  renderFormButtonContainer={FormButtonContainer}
                  onSuccess={onSuccess}
                  onError={onError}
                  hasPie={hasPie}
                  layout="row"
                />
              </div>
            )}
          </div>
        </>
      }
    </div>
  );
};
