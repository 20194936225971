import LoginInfoSection from './LoginInfoSection';
import ExternalLink from '@core/components/General/ExternalLink';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';

export const ContactUsMessage = () => (
  <LoginInfoSection
    headerText="Need help?"
    message={
      <>
        Contact us at{' '}
        <PhoneLink className="inline-link" phoneNumber={cardSupportPhone} /> or{' '}
        <ExternalLink
          to="https://www.missionlane.com/contact-us?topic=help%2Botp"
          className="inline-link"
        >
          send us a message
        </ExternalLink>
        .
      </>
    }
  />
);
