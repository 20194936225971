import { H3, H5, Icon, P3 } from '@missionlane/compass-ui';
import { Link } from 'react-router-dom';
import { Address } from '@core/graphql/globalTypes';

interface StatementsPreferencesConfirmAddressProps {
  accountId?: string;
  address?: Partial<Address>;
  isEligible?: boolean;
}

export const StatementsPreferencesConfirmAddress = ({
  accountId,
  address,
  isEligible,
}: StatementsPreferencesConfirmAddressProps) => {
  if (typeof address === 'undefined')
    return (
      <div className="flex mt5">
        <div className="flex mt5">
          <H3>Unable to load address</H3>
        </div>
      </div>
    );

  const { street1, street2, city, state, postalCode } = address;
  return (
    <>
      <H5 color="ink">We'll mail paper statements to:</H5>
      <div className="flex justify-between content-center mt3">
        <div>
          <P3>{street1}</P3>
          <div>
            {street2 && (
              <>
                <P3>{street2}</P3>
                <br />
              </>
            )}
            <P3>{`${city}, ${state} ${postalCode}`}</P3>
          </div>
        </div>
        {Boolean(isEligible) && (
          <Link
            to={'/change-address'}
            state={{ statementsAddressAccountId: accountId }}
          >
            <Icon name="edit" color="blue" />
          </Link>
        )}
      </div>
    </>
  );
};

export default StatementsPreferencesConfirmAddress;
