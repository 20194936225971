import { gql } from '@apollo/client';
import { P3 } from '@missionlane/compass-ui';
import { CardTermsRewards } from '../../../../graphql/globalTypes';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';
import { RewardsCategories } from './RewardsCategories';

export const CARD_TERMS_REWARDS = gql`
  fragment CardTermsRewards on Account {
    id
    rewards {
      ... on Rewards {
        isRewardsCard
        baseRate
        categoryRewards {
          id
          categoryDescription
          categoryRate
        }
      }
      ...ErrorFragment
    }
  }
`;

const REWARDS_LABEL = 'Cash back rewards';

interface RewardsDetailsProps {
  rewards: CardTermsRewards.Rewards;
}

const RewardsDetails = ({ rewards }: RewardsDetailsProps) => {
  if (rewards.__typename === 'Rewards') {
    const { isRewardsCard, categoryRewards, baseRate } = rewards;

    // if it's not a rewards card, render nothing
    if (!isRewardsCard) {
      return null;
    }

    // rewards card with category-specific rewards
    if (categoryRewards?.length) {
      return (
        <DescriptionList.Item
          label={REWARDS_LABEL}
          subTitle={
            <RewardsCategories
              categories={categoryRewards}
              baseRate={baseRate}
            />
          }
        />
      );
    }

    // rewards card without category-specific rewards
    return <DescriptionList.Item label={REWARDS_LABEL} value={baseRate} />;
  }

  // rewards card with an error fetching data
  if (rewards.__typename === 'ErrorResult') {
    return (
      <DescriptionList.Item
        label={REWARDS_LABEL}
        subTitle={
          <P3 color="mustard">We can't load your rewards info right now</P3>
        }
      />
    );
  }

  return null;
};

export default RewardsDetails;
