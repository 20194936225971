import { Notification } from '@missionlane/compass-ui';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { CancelPaymentContext } from './CancelPaymentContext';

import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

const CancelPaymentSuccess = () => {
  const {
    paymentToCancel: { amount, confirmationId, fundingAccount, date },
  } = useContext(CancelPaymentContext);
  const accountSummaryPath = useAccountSummaryPath();
  const navigate = useNavigate();

  if (!amount || !confirmationId || !fundingAccount || !date) {
    return <GenericFallbackFullPage />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Cancel Payment', useLast4: true }}
      trackingProperties={{
        featureName: 'Payments: Cancel Payment',
        pageName: 'Cancel Payment: Success',
      }}
    >
      <div className="sans-serif lh-copy">
        <ResponsiveTypography type="HEADER" mobileLevel="H3" desktopLevel="H4">
          Payment Canceled
        </ResponsiveTypography>
        <div className="mv4">
          <Notification variant="inline" level="success">
            We&rsquo;ve sent you an email which includes your confirmation
            number ({confirmationId}) and cancellation details.
          </Notification>
        </div>
        <PaymentConfirmationButtons
          leftAlign
          text="Done"
          cancelButtonText="Schedule a new payment"
          onSubmit={() => {
            navigate(accountSummaryPath);
          }}
          onCancel={() => {
            navigate('../../../../payments/pay/make-ach-payment');
          }}
        />
      </div>
    </PageWrapper>
  );
};

export default CancelPaymentSuccess;
