import { useMediaQuery } from 'react-responsive';

const MOBILE_DEVICE_MAX_WIDTH_PX = 833;
const DESKTOP_LARGE_MIN_WIDTH_PX = 1200;
const TACHYONS_NOT_SMALL_MIN_WIDTH_EM = 30;

export const useUserDevice = () => {
  const isMobile = useMediaQuery({
    query: `(max-width: ${MOBILE_DEVICE_MAX_WIDTH_PX}px)`,
  });
  const isDesktopLarge = useMediaQuery({
    query: `(min-width: ${DESKTOP_LARGE_MIN_WIDTH_PX}px)`,
  });

  const isTachyonsNotSmall = useMediaQuery({
    query: `(min-width: ${TACHYONS_NOT_SMALL_MIN_WIDTH_EM}em)`,
  });

  return { isMobile, isDesktopLarge, isTachyonsNotSmall };
};
