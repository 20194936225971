import { Spacer } from '@missionlane/compass-ui';
import { Fragment } from 'react';
import { CardTermsRewards } from '../../../../graphql/globalTypes';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';

interface RewardsCategoriesProps {
  categories: CardTermsRewards.CategoryRewards[];
  baseRate: string;
}
export const RewardsCategories = ({
  categories,
  baseRate,
}: RewardsCategoriesProps) => {
  const categoriesWithBase = [
    ...categories,
    {
      id: 'all',
      categoryRate: baseRate,
      categoryDescription: 'All other purchases',
    },
  ];

  return (
    <DescriptionList>
      {categoriesWithBase.map((category, index) => (
        <Fragment key={`category-line-item-${category.id}`}>
          {index !== 0 && <Spacer size="s" />}
          <DescriptionList.DetailItem
            label={category.categoryDescription}
            value={category.categoryRate}
          />
        </Fragment>
      ))}
    </DescriptionList>
  );
};
