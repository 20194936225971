import { gql, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';
import sortBy from 'lodash/sortBy';
import { useState } from 'react';
import './ManageFundingAccounts.css';
import { useNavigate } from 'react-router-dom';
import AddPlaidAccount from '../AddPlaidAccount/AddPlaidAccount';
import { BankAccountFlow } from '../AddBankAccount/AddAccount';
import AddAccountListItem from './AddAccountListItem';
import FundingAccountListItem from './FundingAccountListItem';
import { TrackService } from '@core/services';
import { useAccount } from '@core/components/Auth/AccountContext';
import { ManageFundingAccounts as ManageFundingAccountNs } from '@core/graphql/globalTypes';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import PageWrapper from '@core/components/Page/PageWrapper';

const manageFundingAccountsQuery = gql`
  query ManageFundingAccounts {
    fundingAccounts {
      id
      numberLast4
      bankName
      isDefault
      balances {
        balance
      }
    }
  }
`;

export const MANAGE_FUNDING_ACCOUNT_FEATURE_NAME = 'Manage bank accounts';

const ManageFundingAccounts = () => {
  const { manageFundingAccounts } = useFlags();
  const { accountId } = useAccount();
  const [banners, setBanners] = useState<React.ComponentType[]>([]);
  const navigate = useNavigate();

  const { data, loading, error } = useQuery<
    ManageFundingAccountNs.Query,
    ManageFundingAccountNs.Variables
  >(manageFundingAccountsQuery, {
    skip: !manageFundingAccounts,
  });

  if (error) {
    throw error;
  }

  const fundingAccounts =
    !!data?.fundingAccounts?.length &&
    sortBy(data.fundingAccounts, (account) => !account.isDefault);

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Manage bank accounts' }}
      banners={banners}
      trackingProperties={{
        pageName: 'Manage bank accounts',
        featureName: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
      }}
    >
      {loading && <LoadingSpinner />}
      {fundingAccounts && (
        <div className="flex flex-column mb2 gap-8">
          {fundingAccounts.map((fa) => (
            <FundingAccountListItem
              key={fa.id}
              fundingAccount={fa}
              canDeleteFundingAccount={fundingAccounts.length > 1}
              setBanners={setBanners}
            />
          ))}
        </div>
      )}
      {!loading && (
        <div className="flex flex-column gap-8">
          <div className="ba b--grey-lightest br3 pa3 pointer">
            <AddPlaidAccount
              onOpenModal={() => {
                TrackService.trackClick('Select add account through plaid', {
                  feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
                });
              }}
              onConnectAccount={() => {
                TrackService.trackClick('Add plaid account', {
                  feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
                });
              }}
            />
          </div>
          <AddAccountListItem
            label="Add a Bank Account"
            description="Connect account manually with a routing and account number"
            onClick={() => {
              TrackService.trackClick('Select add account manually', {
                feature: MANAGE_FUNDING_ACCOUNT_FEATURE_NAME,
              });
              navigate(`/account/${accountId}/add-bank-account`, {
                state: {
                  currentFlow: BankAccountFlow.MANAGE_FUNDING_ACCOUNTS,
                },
              });
            }}
          />
        </div>
      )}
    </PageWrapper>
  );
};

export default ManageFundingAccounts;
