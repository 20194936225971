import dayjs from 'dayjs';
import {
  ServicingOffersPaymentPlan,
  ServicingOffersTransaction,
} from '@core/graphql/globalTypes';

export const getOfferEndDate = (
  offerPaymentPlan: ServicingOffersPaymentPlan,
) => {
  // TODO: Fix the schema type in apollo so that the items in the tx array are not nullable.
  // Then, remove the type casting here.
  const finalOfferPaymentDate = (
    offerPaymentPlan.transactions[
      offerPaymentPlan.transactions.length - 1
    ] as ServicingOffersTransaction
  ).date;

  return dayjs(finalOfferPaymentDate)
    .startOf('day')
    .add(1, 'day')
    .toISOString();
};
