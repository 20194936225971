import { gql } from '@apollo/client';

export const opportunityTilesQuery = gql`
  query OpportunityTiles($accountId: String!, $dismissedAt: DismissedAt) {
    opportunityTiles(accountId: $accountId, dismissedAt: $dismissedAt) {
      variant
      tiles {
        id
      }
    }
  }
`;
