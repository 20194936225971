import { P2, Link } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { TrackService } from '../../services';
import { FEATURE_NAME as MANAGE_CARD } from '../ManageCard/constants';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import SuccessPanel from '@core/components/General/SuccessPanel/SuccessPanel';

export const ModifyPINSuccess = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const navigate = useNavigate();
  const location = useLocation();

  const handleMoreInfoLink = () => {
    const infoLinkUrl = `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/articles/360025445093-Can-I-use-my-credit-card-for-a-cash-advance-`;

    const win = window.open(infoLinkUrl, '_blank');
    win?.focus();
    TrackService.click(
      'Modify PIN Success Page: More Info Link',
      customerAndAccountIds,
    );
  };

  const handleDoneClick = () => {
    if (location.state.referrer === MANAGE_CARD) {
      navigate('../../manage-card', { replace: true });
      TrackService.trackClick(
        'Modify PIN Success Page: Back to Manage card Button',
        { feature: 'Modify PIN' },
      );
    } else {
      navigate('../../details', { replace: true });
      TrackService.trackClick(
        'Modify PIN Success Page: Back to Account details Button',
        { feature: 'Modify PIN' },
      );
    }
  };

  return (
    <SuccessPanel
      title="Success!"
      actionButton={{ text: 'Done', onPress: handleDoneClick }}
    >
      <P2>
        You can now use your new PIN for cash advances at ATMs, but keep in mind
        that these transactions can be costly.
      </P2>
      <P2>
        Check out <Link onPress={handleMoreInfoLink}>this article</Link> for
        more information.
      </P2>
    </SuccessPanel>
  );
};
