import { H3, H2, BubbleIcon, P3 } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import SectionFallbackUI from '@core/components/GenericFallbacks/SectionFallbackUI';
import { centsToDollars } from '@core/utils/centsToDollars';
import { getUTC } from '@core/utils/timezones';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { ErrorBoundary } from '@core/services/Error/Error';

// TODO: This should be replaced with a compass inline notification component
const FallbackUI = () => (
  <div className="mb3">
    <SectionFallbackUI />
  </div>
);

const PaymentKardSubHeader = ({
  isPastDue,
  minimumDue,
  nextStatementCloseDate,
  printDueDate,
  isLate,
}: PaymentKardHeaderProps) => {
  const { isTachyonsNotSmall } = useUserDevice();
  const PaymentDueWrapper = isTachyonsNotSmall ? H3 : H2;
  const formattedDueDate = getUTC(printDueDate).format('MMMM D');
  const noMinDue = minimumDue === 0 || !minimumDue;
  let paymentDue: string;
  let daysPastDue: string;

  if (printDueDate && isLate) {
    const daysLate = dayjs().diff(printDueDate, 'day');

    if (isLate && daysLate === 1) {
      daysPastDue = `Due ${daysLate} day ago`;
    }
    if (isLate && daysLate > 1 && daysLate <= 3) {
      daysPastDue = `Due ${daysLate} days ago`;
    }
    if (isLate && daysLate > 3) {
      daysPastDue = 'Due now';
    }
  }

  if (noMinDue) {
    paymentDue = 'No minimum due';
  } else if (isPastDue) {
    paymentDue = `${centsToDollars(minimumDue)} Total Minimum Due`;
  } else {
    paymentDue = `${centsToDollars(minimumDue)} Minimum`;
  }

  const getIconColor = () => {
    if (noMinDue) {
      return 'green';
    }
    if (isLate) {
      return 'mustard';
    }
    if (isPastDue) {
      return 'red';
    }
    return 'blue';
  };

  const getBubbleColors = () => {
    if (noMinDue) {
      return 'greenWashed';
    }
    if (isLate) {
      return 'mustardWashed';
    }
    if (isPastDue) {
      return 'redWashed';
    }
    return 'blueWashed';
  };

  const getSubHeaderText = () => {
    if (minimumDue === 0) {
      return `All good for ${formattedDueDate}`;
    }

    if (daysPastDue) {
      return daysPastDue;
    }

    return `Due ${formattedDueDate}`;
  };

  return (
    <div className="flex mb3">
      <div className="mr3">
        <BubbleIcon
          name={isLate || !noMinDue ? 'dollarSign' : 'check'}
          iconColor={getIconColor()}
          bubbleColor={getBubbleColors()}
        />
      </div>
      <div>
        {paymentDue && (
          <div className="mb2-ns">
            <PaymentDueWrapper>{paymentDue}</PaymentDueWrapper>
          </div>
        )}
        {printDueDate && nextStatementCloseDate && !isPastDue && (
          <P3 style={{ margin: 0 }}>{getSubHeaderText()}</P3>
        )}
      </div>
    </div>
  );
};

interface PaymentKardHeaderProps {
  isPastDue: boolean | undefined;
  minimumDue: number | undefined;
  nextStatementCloseDate: string | null | undefined;
  pastDueBalance: number | undefined;
  printDueDate: string | null | undefined;
  isLate: boolean;
}

const BoundPaymentKardSubHeader = ({
  isPastDue,
  minimumDue,
  nextStatementCloseDate,
  pastDueBalance,
  printDueDate,
  isLate,
}: PaymentKardHeaderProps) => {
  return (
    <ErrorBoundary fallback={FallbackUI}>
      <PaymentKardSubHeader
        isPastDue={isPastDue}
        minimumDue={minimumDue}
        nextStatementCloseDate={nextStatementCloseDate}
        pastDueBalance={pastDueBalance}
        printDueDate={printDueDate}
        isLate={isLate}
      />
    </ErrorBoundary>
  );
};

export default BoundPaymentKardSubHeader;
