import {
  RadioGroup,
  RadioGroupProps,
  TextField,
} from '@missionlane/compass-ui';
import { RadioValue } from '@missionlane/compass-ui/lib/typescript/components/Input/RadioButton/Radio';
import dayjs from 'dayjs';
import CurrencyTextField from '@core/components/General/TextField/CurrencyTextField';
import dollarsToCents from '@core/utils/dollarsToCents';
import { CreateOfferFlowQuestionType } from '@core/graphql/globalTypes';
import DatepickerWithInput from '@core/components/General/Datepicker/DatepickerWithInput';

export enum ExtendedAnswerInputTypes {
  Date = 'Date',
}

export type AnswerInputType =
  | CreateOfferFlowQuestionType
  | ExtendedAnswerInputTypes;
export const AnswerInputType = {
  ...CreateOfferFlowQuestionType,
  ...ExtendedAnswerInputTypes,
};

export interface OfferAnswerProps {
  value?: string;
  onChange: (value: string) => void;
  type?: AnswerInputType;
  options?: RadioGroupProps['options'];
  unavailableDates?: Date[];
  name?: string;
  minStartDaysFromNow?: number;
  error?: boolean;
  setError?: (e: boolean) => void;
}

const OfferAnswerInput = ({
  value,
  onChange,
  options = [],
  unavailableDates = [],
  type = AnswerInputType.Text,
  name = '',
  minStartDaysFromNow = 2,
  error,
  setError,
}: OfferAnswerProps) => {
  const handleCurrencyChange = (val: number) => {
    onChange?.(val.toString());
  };

  const handleDateSelected = (selectedDate: Date) => {
    if (error) setError?.(false);
    onChange?.(selectedDate.toISOString());
  };

  switch (type) {
    case AnswerInputType.Currency:
      return (
        <CurrencyTextField
          amount={dollarsToCents(value || '')}
          onChangeAmount={handleCurrencyChange}
          maxLength={8}
        />
      );
    case AnswerInputType.Select:
      return (
        <RadioGroup
          onChange={(radioVal: RadioValue) => onChange?.(String(radioVal))}
          name={name}
          value={value}
          options={options}
        />
      );
    case AnswerInputType.Date:
      return (
        <DatepickerWithInput
          datePickerProps={{
            headerText: 'Select payment date',
            selectedDate: value ? dayjs(value).toDate() : undefined,
            onChange: handleDateSelected,
            minDate: dayjs().add(minStartDaysFromNow, 'day').toDate(),
            maxDate: dayjs().add(30, 'days').toDate(),
            unavailableDates,
          }}
          inputProps={{
            onError: (errors) => setError?.(!!errors.length),
            testID: 'PaymentPlanDateSelect',
          }}
        />
      );
    default:
      return (
        <TextField
          value={value}
          onChangeText={onChange}
          name={name}
          testID={name}
        />
      );
  }
};

export default OfferAnswerInput;
