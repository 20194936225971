import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import VerifyRequestCodeForm from './VerifyRequestCodeForm';
import {
  OtpFactor,
  MultifactorAuthenticationState,
  OtpFactorType,
  RiskAuthService,
} from '@core/services/RiskAuthService';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import { TrackService } from '@core/services';

const schema = yup.object().shape({
  selectedOtp: yup.string().required(),
});

export type FormValues = {
  selectedOtp: OtpFactorType;
};

interface VerifyRequestCodeProps {
  onChangeMfaState: (mfa: MultifactorAuthenticationState) => void;
  onSetSelectedOtpFactor: (selectedOtpfactor: OtpFactor) => void;
}

export const VerifyRequestCode = ({
  onChangeMfaState,
  onSetSelectedOtpFactor,
}: VerifyRequestCodeProps) => {
  const { otpRequest } = useRiskAuth();
  const methods = useForm<FormValues>({
    mode: 'all',
    resolver: yupResolver(schema),
  });
  const { handleSubmit, formState, getValues } = methods;
  const [otpFactors, setOtpFactors] = useState<OtpFactor[]>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    TrackService.page('Verify Request Code');
    const getOtpFactors = async () => {
      const result = await RiskAuthService.otpFactors();
      if (result.success) {
        setOtpFactors(result.factors);
      } else {
        setError(result.error);
      }
    };
    getOtpFactors();
  }, []);

  const verifyRequestCodeHandleSubmit = handleSubmit(async () => {
    TrackService.track({ event: 'Verify Request Code Submitted' });
    const { selectedOtp } = getValues();
    const selectedOtpFactor = otpFactors?.find(
      ({ factorType }) => factorType === selectedOtp,
    );

    if (!selectedOtpFactor) {
      throw new Error(
        'selected otp could not find the otp value from otp values from Risk Auth Service evaluate',
      );
    }
    const response = await otpRequest(selectedOtpFactor, 'UPDATE_ADDRESS');
    if (response.error) {
      TrackService.track({ event: 'Verify Enter Code Failed' });
      setError(response.error);
    } else {
      TrackService.track({ event: 'Verify Enter Code Successful' });
      onSetSelectedOtpFactor(selectedOtpFactor);
      onChangeMfaState('MFA_CHALLENGE');
    }
  });

  return (
    <FormProvider {...methods}>
      <VerifyRequestCodeForm
        onSubmit={verifyRequestCodeHandleSubmit}
        otpFactors={otpFactors}
        formState={formState}
        error={error}
      />
    </FormProvider>
  );
};

export default VerifyRequestCode;
