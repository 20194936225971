import { Notification, P3 } from '@missionlane/compass-ui';
import { getUTC } from '@core/utils/timezones';

interface PaymentMessageProps {
  cardLast4: string | null;
  isImmediatePayment: boolean;
  paymentDate?: string | null;
}

const PaymentMessage = ({
  cardLast4,
  isImmediatePayment,
  paymentDate,
}: PaymentMessageProps) => {
  const getMessage = () => {
    if (isImmediatePayment) {
      return 'You won’t be able to cancel this payment once you submit it, so make sure you review the above information before you move on.';
    }
    return `You can edit or cancel this payment until 11:59 p.m. ET on ${
      paymentDate ?
        getUTC(paymentDate).subtract(1, 'days').format('MMMM DD, YYYY')
      : 'the day before the payment date'
    }.`;
  };

  return (
    <div>
      <Notification level="warning">{getMessage()}</Notification>
      <div className="mt5">
        <P3>
          By submitting this payment, you are authorizing this one-time ACH
          electronic debit toward payment of your credit card
          {cardLast4 && ` ending in ${cardLast4}`}.
        </P3>
      </div>
    </div>
  );
};

export { PaymentMessage };
