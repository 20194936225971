import { Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import { TrackService } from '@core/services';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';

const StreakFooter = ({ children }: { children: React.ReactNode }) => (
  <p className="mt0 mb0">{children}</p>
);

const Footer = () => {
  const { uiState, isInGracePeriod, clipStatus } = useClipOffer();
  const navigate = useNavigate();

  if (clipStatus === ClipOfferStatusCodes.MISSED_PAYMENT) {
    return null;
  }

  if (isInGracePeriod) {
    return (
      <StreakFooter>
        <Button
          onPress={() => {
            TrackService.click(
              'Clip Tracker Relaunch Control: Is in grace period: Click make a payment',
              {
                uiState,
              },
            );
            navigate('../payments/pay');
          }}
          text="Make a Payment"
        />
      </StreakFooter>
    );
  }

  return null;
};

export default Footer;
