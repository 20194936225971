import PageWrapper, {
  PageWrapperProps,
} from '@core/components/Page/PageWrapper';

export const withPageWrapper = <P extends object>(
  Component: React.ComponentType<P>,
  pageWrapperProps?: Omit<PageWrapperProps, 'children'>,
) => {
  const WithPageWrapper = (props: P) => {
    return (
      <PageWrapper {...pageWrapperProps}>
        <Component {...(props as P)} />
      </PageWrapper>
    );
  };
  return WithPageWrapper;
};
