import { gql } from '@apollo/client';
import { Notification } from '@missionlane/compass-ui';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import GenericFallbackKard from '../GenericFallbacks/GenericFallbackKard';
import TransactionList from '../Transactions/TransactionList';
import { useAccount } from '../Auth/AccountContext';
import { TRANSACTION_ITEM_FRAGMENT } from '../Transactions/TransactionItem';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import TrackingLink from '@core/components/General/TrackingLink';
import {
  ActivitiesQueryQuery,
  ActivitiesQueryQueryVariables,
} from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';
import { ErrorBoundary } from '@core/services/Error/Error';

const NUMBER_OF_TRANSACTIONS_TO_DISPLAY = 10;

export const ACTIVITIES_QUERY = gql`
  query ActivitiesQuery($accountId: String!) {
    account(accountId: $accountId) {
      id
      # fetches 1mo activities by default
      activities {
        ...TransactionItem
      }
    }
    accountDetails(accountId: $accountId) {
      id
      isPaperlessStatement
    }
    customer {
      id
      accounts {
        id
        statuses {
          canUpdateStatementPreference
        }
      }
    }
  }
  ${TRANSACTION_ITEM_FRAGMENT}
`;

const RecentActivityKard = () => {
  const navigate = useNavigate();
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { showNewOnboardingExperience, paperlessStatementEnabled } = useFlags();
  const { accountId } = useAccount();
  const { data, loading, error } = useAccountIdQuery<
    ActivitiesQueryQuery,
    ActivitiesQueryQueryVariables
  >(ACTIVITIES_QUERY, { fetchPolicy: 'cache-and-network' });

  const activities = data?.account?.activities;
  const canUpdateStatementPreference = data?.customer?.accounts.find(
    (acct) => acct.id === accountId,
  )?.statuses.canUpdateStatementPreference;
  const isPaperlessStatement = data?.accountDetails?.isPaperlessStatement;

  const recentActivityKardHeader = configureRecentActivityKardHeader(
    paperlessStatementEnabled,
    Boolean(canUpdateStatementPreference),
    Boolean(isPaperlessStatement),
    navigate,
  );
  const noTransactionsMessage =
    showNewOnboardingExperience ?
      {
        textPrimary: 'Nothing Yet',
        textSecondary: `You haven’t made any recent\npurchases. When you make one, you\ncan find it here!`,
        classes: 'pv6',
      }
    : {
        textPrimary: 'No transactions yet.',
        classes: 'mt4',
      };

  if (loading) {
    return (
      <Kard header={recentActivityKardHeader}>
        <LoadingSpinner />
      </Kard>
    );
  } else if (error) {
    return (
      <Kard header={recentActivityKardHeader}>
        <ActivityError />
      </Kard>
    );
  } else {
    return (
      <Kard header={recentActivityKardHeader}>
        <TransactionList
          transactions={activities}
          numberToShow={NUMBER_OF_TRANSACTIONS_TO_DISPLAY}
          noTransactionsMessage={noTransactionsMessage}
        />
        {Boolean(activities?.length) && (
          <ActivityLink {...customerAndAccountIds} />
        )}
      </Kard>
    );
  }
};

const recentActivityErrorMessage =
  'Sorry, we’re having trouble loading your recent transactions, but our team is looking into it. Check back in a bit.';

const BoundRecentActivityKard = () => (
  <ErrorBoundary
    fallback={() => (
      <GenericFallbackKard
        header={{ textPrimary: 'Transactions', level: 'H4' }}
        errorMessage={recentActivityErrorMessage}
      />
    )}
  >
    <RecentActivityKard />
  </ErrorBoundary>
);

export default BoundRecentActivityKard;

interface ActivityLinkProps {
  customerId?: string;
  accountId: string;
}
const ActivityLink = ({ customerId, accountId }: ActivityLinkProps) => (
  <div className="flex justify-end pt2 pt3-ns">
    <TrackingLink
      to="../transactions"
      trackingName="See all transactions"
      trackingProperties={{ customerId, accountId }}
    >
      See all
    </TrackingLink>
  </div>
);

const ActivityError = () => (
  <Notification level="warning">{recentActivityErrorMessage}</Notification>
);

const configureRecentActivityKardHeader = (
  paperlessLDFlag: boolean,
  canCHUpdate: boolean,
  isCurrentlyPaperless: boolean,
  navigatorFunc: NavigateFunction,
): KardHeader => {
  const header: KardHeader = {
    textPrimary: 'Recent transactions',
    level: 'H4',
  };
  if (paperlessLDFlag && canCHUpdate && !isCurrentlyPaperless) {
    return {
      ...header,
      action: {
        label: 'Go paperless',
        onClick: () => {
          TrackService.click('Activity list: Go paperless');
          navigatorFunc('../statements/preferences');
        },
        iconName: 'leaf',
      },
    };
  }

  return header;
};
