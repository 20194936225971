import { gql, useQuery } from '@apollo/client';
import { H4 } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { CreditCardsList } from './CreditCardsList';
import { checkIfSomeCreditCardsAreHydrated } from './helpers';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import {
  GetCreditCardsSectionData,
  GetCreditCardsSectionDataQuery,
} from '@core/graphql/globalTypes';

const GET_CREDIT_CARDS_SECTION_DATA_QUERY = gql`
  query GetCreditCardsSectionData {
    creditCards {
      id
      availableCredit
      currentBalance
      cardLast4
      name
      cardArtUrl
      securedCardInfo {
        currentAmount {
          value
        }
      }
      cardLockStatus {
        isLocked
      }
    }
  }
`;

interface Props {
  hasPendingCreditCard: boolean;
}

const CreditCardsSection = ({ hasPendingCreditCard }: Props) => {
  const { loading, data } = useQuery<GetCreditCardsSectionDataQuery>(
    GET_CREDIT_CARDS_SECTION_DATA_QUERY,
  );
  const { enableAccountSummaryCardArt } = useFlags();

  const creditCards = data?.creditCards;
  if (
    !loading &&
    (!creditCards ||
      !creditCards?.length ||
      !checkIfSomeCreditCardsAreHydrated(creditCards))
  ) {
    throw new Error('No credit card data to display');
  }

  // checking if all of the accounts have a cardArtUrl to know which experience to display
  const allEqual = (cc?: GetCreditCardsSectionData.Query['creditCards']) =>
    cc?.every(({ cardArtUrl }) => typeof cardArtUrl === 'string');

  const hasDynamicCardArt =
    allEqual(creditCards) && enableAccountSummaryCardArt;

  return (
    <div
      className={
        hasDynamicCardArt && 'flex flex-column justify-center items-center'
      }
    >
      <div className={hasDynamicCardArt && 'self-start'}>
        <H4>Credit cards</H4>
      </div>
      {loading ?
        <LoadingSpinner />
      : <CreditCardsList
          creditCards={creditCards}
          showPendingCreditCard={hasPendingCreditCard}
        />
      }
    </div>
  );
};

export default CreditCardsSection;
