import { Colors } from '@missionlane/compass-ui';
import PaymentStreak from '../../PaymentStreak/PaymentStreak';
import Title from './Title';
import Body from './Body';
import Footer from './Footer';
import Kard from '@core/components/General/Kard/Kard';
import PaymentPending from '@clip/ClipProgress/components/PaymentPending/PaymentPending';

export const STREAK_TEST_ID = 'STREAK_TEST_ID';

const Streak = () => {
  return (
    <Kard className="mb3 w-100" testID={STREAK_TEST_ID}>
      <PaymentStreak />
      <div
        className="w-100 bb bw--1px mt3 mb3"
        style={{ borderColor: Colors.greyLightest }}
      />
      <PaymentPending>
        Your recent payment is currently pending. Your streak will update by
        your statement end date.
      </PaymentPending>
      <Title />
      <Body />
      <Footer />
    </Kard>
  );
};

export default Streak;
