import { ColorNames, Colors } from '@missionlane/compass-ui';
import cx from 'classnames';

interface ProgressBarProps {
  backgroundColor: ColorNames;
  color: ColorNames;
  progress: number;
  className?: string;
  heightRem?: number;
}

export const getProgressFillWidth = (
  progress: ProgressBarProps['progress'],
) => {
  if (progress < 0) return '0%';
  if (progress > 1) return '100%';
  return `${progress * 100}%`;
};

const ProgressBar = ({
  backgroundColor,
  color,
  progress,
  heightRem = 0.75,
  className,
}: ProgressBarProps) => (
  <div
    className={cx('w-100 br3', className)}
    style={{
      height: `${heightRem}rem`,
      backgroundColor: Colors[backgroundColor],
    }}
  >
    <span
      className="db h-inherit br3"
      style={{
        width: getProgressFillWidth(progress),
        backgroundColor: Colors[color],
      }}
    />
  </div>
);

export default ProgressBar;
