import { useFlags } from 'launchdarkly-react-client-sdk';
import { Notification, B } from '@missionlane/compass-ui';
import { useState } from 'react';
import { convertCurrencyInputToInt } from '../../utils';
import { isMonthlyLivingValid } from './MonthlyLivingWarning';

const hasVariationOfFiftyPercent = (curr: number, next: number) => {
  const fiftyPercent = curr / 2;
  const fiftyOver = curr + fiftyPercent;
  const fiftyUnder = curr - fiftyPercent;

  return next >= fiftyOver || next <= fiftyUnder;
};

const isAnomalous = ({
  currentAnnualIncome,
  currentMonthlyLiving,
  nextAnnualIncome,
  nextMonthlyLiving,
}: {
  currentMonthlyLiving: number;
  currentAnnualIncome: number;
  nextMonthlyLiving: number;
  nextAnnualIncome: number;
}): boolean => {
  let incomeOk = true;
  let monthlyOk = true;

  if (
    currentAnnualIncome > 0 &&
    hasVariationOfFiftyPercent(currentAnnualIncome, nextAnnualIncome)
  ) {
    incomeOk = false;
  }

  if (
    currentMonthlyLiving > 0 &&
    hasVariationOfFiftyPercent(currentMonthlyLiving, nextMonthlyLiving)
  ) {
    monthlyOk = false;
  }

  return !incomeOk || !monthlyOk;
};

type CheckForAnomaly = {
  currentMonthlyLiving?: number | null;
  currentAnnualIncome?: number | null;
  nextMonthlyLiving: string;
  nextAnnualIncome: string;
  field: 'annualIncome' | 'monthlyLiving';
};

export const useAnomalousIncomeOrMonthlyLiving = () => {
  const { enableMonthlyLivingWarning } = useFlags();
  const [anomalyDetected, setAnomalyDetected] = useState({
    annualIncome: false,
    monthlyLiving: false,
  });

  return {
    anomalyDetected,
    checkForAnomaly: (p: CheckForAnomaly): void => {
      if (
        (enableMonthlyLivingWarning &&
          !isMonthlyLivingValid(p.nextMonthlyLiving)) ||
        (!p.currentAnnualIncome && !p.currentMonthlyLiving) ||
        p.nextAnnualIncome.length === 0 ||
        p.nextMonthlyLiving.length === 0
      ) {
        setAnomalyDetected({
          ...anomalyDetected,
          [p.field]: false,
        });
        return;
      }

      const anomalous = isAnomalous({
        currentAnnualIncome: p.currentAnnualIncome || 0,
        currentMonthlyLiving: p.currentMonthlyLiving || 0,
        nextMonthlyLiving: convertCurrencyInputToInt(p.nextMonthlyLiving) || 0,
        nextAnnualIncome: convertCurrencyInputToInt(p.nextAnnualIncome) || 0,
      });

      if (anomalous) {
        setAnomalyDetected({
          ...anomalyDetected,
          [p.field]: true,
        });

        return;
      }

      setAnomalyDetected({
        ...anomalyDetected,
        [p.field]: false,
      });
    },
  };
};

export const ANOMALOUS_INCOME_OR_MONTHLY_LIVING_WARNING_TEST_ID =
  'ANOMALOUS_INCOME_OR_MONTHLY_LIVING_WARNING_TEST_ID';

const AnomalousIncomeOrMonthlyLivingWarning = () => {
  return (
    <div className="mb4">
      <Notification variant="inline" level="warning">
        <B testID={ANOMALOUS_INCOME_OR_MONTHLY_LIVING_WARNING_TEST_ID}>
          It looks like your figures have changed a lot from what you last
          reported.
        </B>{' '}
        Please check that they're correct before submitting.
      </Notification>
    </div>
  );
};

export default AnomalousIncomeOrMonthlyLivingWarning;
