import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ActivityState } from '@core/graphql/globalTypes';
import { getET } from '@core/utils/timezones';
dayjs.extend(isSameOrAfter);

interface CanCancelPaymentArgs {
  state: ActivityState;
  date: string;
}

export function canCancelPayment({ state, date }: CanCancelPaymentArgs) {
  const isScheduled = state === ActivityState.Scheduled;
  const isTooLate = getET().isSameOrAfter(getET(date));

  return isScheduled && !isTooLate;
}
