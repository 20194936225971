import { B, Button, H2, Icon, P2 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { TrackService } from '@core/services';
import { UpdatePhone } from '@core/graphql/globalTypes';

interface ChangePhoneSuccessProps {
  updatedPhoneNumber: UpdatePhone.UpdatePhone['phoneNumber'];
}

const ChangePhoneSuccess = ({
  updatedPhoneNumber,
}: ChangePhoneSuccessProps) => {
  const navigate = useNavigate();

  useEffect(() => {
    TrackService.page('Change Phone Success');
  }, []);

  return (
    <div className="flex flex-auto flex-column flex-row-ns mv2">
      <div className="tc tl-ns mb4 mr4">
        <Icon name="checkCircleLight" color="green" size={80} />
      </div>
      <div>
        <div className=" ml2-ns">
          <H2>Success!</H2>
          <br />
          <P2>
            We've updated your phone number for every card you have with us.
            We’ll send all future correspondence to{' '}
            <B testID="UpdatedPhoneNumber" color="ink">
              {updatedPhoneNumber}
            </B>
            .
          </P2>
        </div>
        <div className="pt4-ns fl-ns center mt4 mt0-ns" style={{ width: 258 }}>
          <Button
            onPress={() => {
              TrackService.click('Change Phone Success: Done');
              navigate('/', { replace: true });
            }}
            text="Done"
          />
        </div>
      </div>
    </div>
  );
};

export default ChangePhoneSuccess;
