import { Link } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { MLFlags } from 'flags';

interface ReferralHeaderProps {
  statementCreditPerReferral: number;
  maximumStatementCredit: number;
  onClickLink: () => void;
  isIncentiveOffer: boolean;
}
//exported for test
export const getCopy = (
  flagVariant: string,
  statementCreditPerReferral: number,
  maximumStatementCredit: number,
) => {
  if (flagVariant === 'INCENTIVE_A' || flagVariant === 'INCENTIVE_B') {
    return `Refer a friend and get a $${
      statementCreditPerReferral / 100
    } statement credit if they open a Mission Lane Visa® Credit Card Account. You can earn up to $${
      maximumStatementCredit / 100
    }!`;
  } else if (flagVariant === 'NON_INCENTIVE_A') {
    return 'Share your referral link today and help your friends on their financial journey.';
  } else if (flagVariant === 'NON_INCENTIVE_B') {
    return 'Share your referral link today and let’s grow our community together.';
  } else {
    return '';
  }
};

const ReferralHeader = ({
  onClickLink,
  statementCreditPerReferral,
  maximumStatementCredit,
  isIncentiveOffer,
}: ReferralHeaderProps) => {
  const { referralsMarketing } = useFlags<MLFlags>();

  return (
    <div className="mv4">
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        {getCopy(
          referralsMarketing,
          statementCreditPerReferral,
          maximumStatementCredit,
        )}{' '}
        {isIncentiveOffer && <Link onPress={onClickLink}>Terms apply.</Link>}
      </ResponsiveTypography>
    </div>
  );
};

export default ReferralHeader;
