import TrackingLink, { TrackingLinkProps } from '../TrackingLink';
import missionLaneLogoSquare from '@core/assets/missionLaneLogoSquare.svg';

interface FooterLogoProps {
  trackingProperties: TrackingLinkProps['trackingProperties'];
}

export const FooterLogo = ({ trackingProperties }: FooterLogoProps) => (
  <TrackingLink
    to="/"
    trackingName="Footer: Mission Lane logo"
    trackingProperties={trackingProperties}
  >
    <img
      src={missionLaneLogoSquare}
      alt="Mission Lane Logo"
      style={{ width: 100 }}
    />
  </TrackingLink>
);
