import { gql } from '@apollo/client';

export const REFERRALS_QUERY = gql`
  query ReferralOffers($accountId: String!) {
    referralOffers(accountId: $accountId) {
      data {
        offerId
        statementCreditPerReferral
        maximumStatementCredit
        invitationCode
        isIncentiveOffer
        referralsApproved
        totalEarned
        expirationDate
        offerVariant
      }
    }
  }
`;
