import React, { useState } from 'react';
import { List, ListItem } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { PaymentMethod } from './types';

type PaymentMethodOptionsProps = {
  availableMethods: PaymentMethod[];
};

const PaymentMethodOptions: React.FC<PaymentMethodOptionsProps> = ({
  availableMethods,
}) => {
  const navigate = useNavigate();
  const [selected, setSelected] = useState('');

  const onMethodSelect = (id: string) => {
    setSelected(id);
    const method = availableMethods.find((it) => it.name === id)!;
    navigate(method.url);
  };

  return (
    <div className="mw7">
      <List variant="selectable" onChange={onMethodSelect} selected={selected}>
        {availableMethods.map((method) => (
          <ListItem
            key={method.name}
            id={method.name}
            label={method.title}
            description={method.blurb}
            iconProps={{
              icon: method.iconName,
              iconColor: 'blue',
              rightIcon: 'forward',
              rightIconColor: 'blue',
            }}
          />
        ))}
      </List>
    </div>
  );
};

export default PaymentMethodOptions;
