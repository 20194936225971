import { B, Icon, Link } from '@missionlane/compass-ui';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

interface BackLinkProps {
  onGoBack?: () => void;
  destination?: string;
  replace?: boolean;
  className?: string;
}

const BackLink = ({
  onGoBack,
  destination,
  replace,
  className,
}: BackLinkProps) => {
  const navigate = useNavigate();
  const config: NavigateOptions | undefined =
    replace ? { replace: true } : undefined;
  return (
    <div className={cx('flex', className)}>
      <Link
        style={{ textDecorationLine: 'none' }}
        onPress={() => {
          if (destination) {
            navigate(destination, config);
          } else {
            navigate(-1);
          }

          onGoBack && onGoBack();
        }}
      >
        <div className="flex items-center">
          <Icon name="back" color="blue" size={18} />
          <div className="ml1">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              color="blue"
            >
              <B>Back</B>
            </ResponsiveTypography>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default BackLink;
