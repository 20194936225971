import { useEffect, useRef, useState } from 'react';
import { Spacer, Notification } from '@missionlane/compass-ui';
import { gql, useMutation } from '@apollo/client';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import PageWrapper from '../../Page/PageWrapper';
import { UnlockCardCopy } from './UnlockCardCopy';
import { LockCardCopy } from './LockCardCopy';
import { LockCardTile } from './LockCardTile';
import { CreditCard } from './CreditCard';
import { LockCardError } from './LockCardError';
import { lockCardAnimation } from './lockCardAnimation';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useAccount } from '@core/components/Auth/AccountContext';
import { useTracking } from '@core/services/TrackService/useTracking';
import {
  GetCardLockStatusQuery,
  LockCardMutation,
  UnlockCardMutation,
} from '@core/graphql/globalTypes';

const cardLockStatusFragment = gql`
  fragment CardLockStatus on AccountDetails {
    cardLockStatus {
      isLocked
    }
  }
`;

const getCardLockStatusQuery = gql`
  query GetCardLockStatus($accountId: String!) {
    accountDetails(accountId: $accountId) {
      id
      cardArtUrl
      cardLast4
      ...CardLockStatus
    }
  }
  ${cardLockStatusFragment}
`;

const lockCardMutation = gql`
  mutation LockCard($accountId: String!) {
    lockCard(accountId: $accountId) {
      accountId
    }
  }
`;

const unlockCardMutation = gql`
  mutation UnlockCard($accountId: String!) {
    unlockCard(accountId: $accountId) {
      accountId
    }
  }
`;

export const LockCard = () => {
  const { accountId } = useAccount();
  const { trackEvent, trackError } = useTracking();
  const [isLocked, setIsLocked] = useState<boolean | null>();
  const [showAnimation, setShowAnimation] = useState<boolean>(false);
  const lockAnimationRef = useRef<LottieRefCurrentProps>(null);

  const {
    data,
    loading,
    error: cardLockStatusError,
  } = useAccountIdQuery<GetCardLockStatusQuery>(getCardLockStatusQuery, {
    onCompleted: (completedData) => {
      setIsLocked(completedData?.accountDetails?.cardLockStatus?.isLocked);
    },
    onError: (error) =>
      trackError({
        name: 'Card Lock Status Error',
        feature: 'Lock Card',
        error,
      }),
  });

  // lock card mutation
  const [lockCard, { error: lockCardError }] = useMutation<LockCardMutation>(
    lockCardMutation,
    {
      variables: { accountId },
      update: (cache) => {
        cache.writeFragment({
          id: `AccountDetails:${accountId}`,
          fragment: cardLockStatusFragment,
          data: {
            cardLockStatus: {
              ...data?.accountDetails?.cardLockStatus,
              isLocked: true,
            },
          },
        });
      },
      onError: (error) => {
        // we assume success in the UI, so if it fails, set it back to the original value
        setIsLocked(false);
        trackError({ name: 'Lock Card Error', feature: 'Lock Card', error });
      },
    },
  );

  // unlock card mutation
  const [unlockCard, { error: unlockCardError }] =
    useMutation<UnlockCardMutation>(unlockCardMutation, {
      variables: { accountId },
      update: (cache) => {
        cache.writeFragment({
          id: `AccountDetails:${accountId}`,
          fragment: cardLockStatusFragment,
          data: {
            cardLockStatus: {
              ...data?.accountDetails?.cardLockStatus,
              isLocked: false,
            },
          },
        });
      },
      onError: (error) => {
        // we assume success in the UI, so if it fails, set it back to the original value
        setIsLocked(true);
        trackError({ name: 'Unlock Card Error', feature: 'Lock Card', error });
      },
    });

  /** Calls the lock or unlock mutation, sets the lock status in state, and tracks events */
  const onUpdateLockStatus = () => {
    setShowAnimation(true);
    if (isLocked) {
      setIsLocked(false);
      unlockCard();
      trackEvent({ eventName: 'Unlocked card', feature: 'Lock Card' });
    } else {
      setIsLocked(true);
      lockCard();
      trackEvent({ eventName: 'Locked card', feature: 'Lock Card' });
    }
  };

  // play the animation on lock status change
  useEffect(() => {
    if (isLocked) {
      lockAnimationRef.current?.playSegments([220, 300], true);
    } else {
      lockAnimationRef.current?.playSegments([70, 150], true);
    }
  }, [isLocked]);

  const { accountDetails } = data || {};

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      pageTitle="Lock Card"
      isSingleColumn
      trackingProperties={{ pageName: 'Lock Card', featureName: 'Lock Card' }}
    >
      <div className="tc bg-haze-lightest pv3 br3">
        <CreditCard cardArtUrl={data?.accountDetails?.cardArtUrl} />
      </div>
      <Spacer size="xm" />
      <LockCardTile
        last4={accountDetails?.cardLast4}
        isLocked={Boolean(isLocked)}
        setIsLocked={onUpdateLockStatus}
      />
      {cardLockStatusError && (
        <Notification variant="inline" level="error">
          There was an error loading your card lock status. Please try again
          later.
        </Notification>
      )}
      <Spacer size="xm" />
      {showAnimation ?
        <div className="flex justify-center">
          <Lottie
            autoplay={false}
            lottieRef={lockAnimationRef}
            loop={false}
            style={{ width: '240px' }}
            animationData={lockCardAnimation}
            onComplete={() => setShowAnimation(false)}
          />
        </div>
      : <>
          <LockCardError
            error={lockCardError?.message || unlockCardError?.message}
          />
          {isLocked ?
            <UnlockCardCopy />
          : <LockCardCopy />}
          <Spacer size="m" />
        </>
      }
      {/* <LockCardQuestionsLink /> TODO when FAQ page is ready */}
    </PageWrapper>
  );
};
