import { Route, Routes, useLocation } from 'react-router-dom';
import { ProtectedRoute } from '../App/Routes/ProtectedRoute';
import ActivationForm from './ActivationForm';
import ActivationSuccess from './ActivationSuccess';
import GetReady from './GetReady';
import PageWrapper from '@core/components/Page/PageWrapper';

const ActivationFeature = () => {
  const location = useLocation();

  return (
    <PageWrapper
      pageTitle="Activate card"
      trackingProperties={{ pageName: 'Activate card' }}
    >
      <Routes>
        <Route path="/" element={<ActivationForm />} />
        <Route
          path="success"
          element={
            <ProtectedRoute
              blocked={!location.state?.prevPath?.includes('activate')}
              redirectTo=".."
            >
              <ActivationSuccess />
            </ProtectedRoute>
          }
        />
        <Route path="get-ready" element={<GetReady />} />
      </Routes>
    </PageWrapper>
  );
};

export default ActivationFeature;
