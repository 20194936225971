import {
  GetCreditCardsSectionDataQuery,
  HomePage as HomePageQuery,
} from '@core/graphql/globalTypes';
import isNil from '@core/utils/isNil';

export const checkIfSomeCreditCardsAreHydrated = (
  creditCards: GetCreditCardsSectionDataQuery['creditCards'],
) =>
  creditCards.some(
    (cc) => !isNil(cc.currentBalance) && !isNil(cc.availableCredit),
  );

/**
 * Checks if there is a credit application with a null accountId, which is an
 * identifier for an account that has not yet been created in ECS
 *
 * @returns {HomePageQuery.CreditApplications | undefined}
 * Either the pending account or undefined if none exists
 */
export const findPendingAccountFromApprovedCreditApplications = (
  creditApplications: HomePageQuery.CreditApplications[] | null | undefined,
) =>
  creditApplications &&
  creditApplications.find((creditApp) => creditApp.accountId === null);
