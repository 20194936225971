import { Notification } from '@missionlane/compass-ui';
import { useEffect } from 'react';
import {
  ErrorEventTrackingProperties,
  useTracking,
} from '@core/services/TrackService/useTracking';

const DEFAULT_AUTH_ERROR =
  "Sorry, we're having trouble signing you in right now due to technical issues on our end. Please try again in a bit.";
interface AuthErrorProps {
  /** full error for reporting */
  error: ErrorEventTrackingProperties['error'];
  /** message displayed to the user */
  children: React.ReactNode;
  /** shorthand error name for CJM tracking */
  name: string;
}

export const AuthError = ({ error, name, children }: AuthErrorProps) => {
  const { trackError } = useTracking();

  useEffect(() => {
    trackError({
      name,
      feature: 'Login',
      error,
    });
  }, []);

  return (
    <div className="mt4 mb3">
      <Notification level="error">{children}</Notification>
    </div>
  );
};

interface Props {
  /** used for tracking only */
  error: AuthErrorProps['error'];
}
const DefaultAuthError = ({ error }: Props) => {
  return (
    <AuthError
      error={{ ...error, code: 'E001' }}
      name="Login Default Tech Issue"
    >
      {DEFAULT_AUTH_ERROR}
    </AuthError>
  );
};

export default DefaultAuthError;
