import { Outlet, Routes as ReactRoutes, Route } from 'react-router-dom';

import { useOktaAuth } from '@okta/okta-react';
import { MfaEnter } from '../../Auth/MFA/MfaEnter/MfaEnter';
import { MfaRequest } from '../../Auth/MFA/MfaRequest/MfaRequest';
import { ProtectedRoute } from './ProtectedRoute';
import { AuthenticatedRoutes } from './AuthenticatedRoutes';
import LoginCallback from './LoginCallback/LoginCallback';
import RequireAuth from './RequireAuth';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { ForgotPasswordFlow, LoginFlow, Logout } from '@core/components/Auth';
import { useRiskAuth } from '@core/components/Auth/RiskAuthProvider';
import { Sentry } from '@core/services';
import ThreeDSConfirmation from '@payments/components/MakePayment/PayWithCard/ThreeDSConfirmation';
import LoginContainer from '@core/components/Auth/Login/LoginContainer';
import PageWrapper from '@core/components/Page/PageWrapper';
import { PageHeader } from '@core/components/Page/PageHeader';

const Routes = () => {
  const { authState } = useOktaAuth();
  const { isAuthenticated } = useRiskAuth();

  if (authState?.isAuthenticated === null) {
    return <LoadingSpinnerPage withFooter />;
  }

  const RouterRoutes = window.isPlaywright ? ReactRoutes : Sentry.Routes;

  return (
    <RouterRoutes>
      <Route path="/signin" element={<LoginFlow />} />
      <Route path="/signin/callback" element={<LoginCallback />} />
      <Route
        path="/mfa/*"
        element={
          <ProtectedRoute blocked={!!isAuthenticated}>
            <PageHeader centerContent />
            <PageWrapper centerContent withFooter>
              <LoginContainer header="Log in to your account">
                <Outlet />
              </LoginContainer>
            </PageWrapper>
          </ProtectedRoute>
        }
      >
        <Route path="request" element={<MfaRequest />} />
        <Route path="enter" element={<MfaEnter />} />
      </Route>
      <Route path="/signout" element={<Logout />} />
      <Route
        path="/forgot-password"
        element={
          <ProtectedRoute blocked={!!isAuthenticated}>
            <ForgotPasswordFlow />
          </ProtectedRoute>
        }
      />
      <Route
        path="/make-card-payment-confirmed"
        element={<ThreeDSConfirmation />}
      />
      <Route
        path="/*"
        element={
          <RequireAuth>
            <AuthenticatedRoutes />
          </RequireAuth>
        }
      />
    </RouterRoutes>
  );
};

export default Routes;
