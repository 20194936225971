import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { OFFER_PAYMENTS_QUERY } from '../gql/queries';
import { useAccount } from '@core/components/Auth/AccountContext';
import {
  ActivityState,
  OfferAggregate,
  OfferPayments,
  OfferPaymentsQuery,
  OfferPaymentsQueryVariables,
  SettlementOfferState,
} from '@core/graphql/globalTypes';
import { getUTC } from '@core/utils/timezones';

export const useReturnedPaymentExperience = (
  offer: OfferAggregate | null | undefined,
  payments?: OfferPayments.Payments[],
) => {
  const { accountId } = useAccount();

  const { data, loading, error } = useQuery<
    OfferPaymentsQuery,
    OfferPaymentsQueryVariables
  >(OFFER_PAYMENTS_QUERY, {
    variables: {
      accountId,
      fromDate: dayjs(offer?.data.acceptedAt).format('YYYY-MM-DD'),
      toDate: getUTC().format('YYYY-MM-DD'), // acceptedAt is in UTC
    },
    skip:
      !offer ||
      !accountId ||
      Boolean(payments) ||
      offer.data.state !== SettlementOfferState.InProgress ||
      !offer.data.acceptedAt,
  });
  const offerPayments = payments || data?.account?.payments;
  let hasReturnedPayment = false;
  let scheduledPayments: OfferPayments.Payments[] = [];
  let showReturnedPaymentExperience = false;

  if (offerPayments && offer?.data.paymentPlan) {
    hasReturnedPayment = Boolean(
      offerPayments.find((payment) => payment.state === ActivityState.Returned),
    );

    if (hasReturnedPayment) {
      scheduledPayments = offerPayments.filter(
        (payment) => payment.state === 'SCHEDULED',
      );

      const scheduledPaymentsTotal = scheduledPayments.reduce(
        (total, payment) => (total += Math.abs(payment.amount)),
        0,
      );
      const upcomingPaymentPlanTxsTotal =
        offer.data.paymentPlan.transactions.reduce((total, tx) => {
          if (tx && dayjs(tx.date).isAfter(dayjs())) {
            total += tx.amount;
          }
          return total;
        }, 0);

      const totalScheduledAndUpcoming =
        scheduledPaymentsTotal + upcomingPaymentPlanTxsTotal;

      showReturnedPaymentExperience =
        totalScheduledAndUpcoming < offer.data.remainingBalance;
    }
  }

  return {
    showReturnedPaymentExperience,
    loading,
    error,
  };
};
