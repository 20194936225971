import { ReactNode } from 'react';
import ResponsiveTypography from '../General/ResponsiveTypography';
import './Referrals.css';
import { Link } from '@missionlane/compass-ui';
type ReferralStep = {
  stepNum: string;
  title: string;
  body: ReactNode;
};

const StepItem = ({ stepNum, title, body }: ReferralStep) => {
  return (
    <div className="flex flex-row mt4">
      <div className="step-number mr4">
        <ResponsiveTypography type="HEADER" mobileLevel="H3" desktopLevel="H4">
          {stepNum}
        </ResponsiveTypography>
      </div>
      <div className="flex flex-column">
        <ResponsiveTypography type="HEADER" mobileLevel="H4" desktopLevel="H5">
          {title}
        </ResponsiveTypography>
        {body}
      </div>
    </div>
  );
};

interface ReferralsInstructionsProps {
  onClickTerms: () => void;
  isIncentiveOffer: boolean;
  statementCreditPerReferral: number;
}

const ReferralsInstructions = ({
  onClickTerms,
  isIncentiveOffer,
  statementCreditPerReferral,
}: ReferralsInstructionsProps) => {
  const buildReferralSteps = (
    isIncentiveOffer: boolean,
    statementCreditPerReferral: number,
  ) => {
    const referralSteps: ReferralStep[] = [
      {
        stepNum: '1',
        title: 'Share your code',
        body: (
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            Either copy-and-paste your personal referral link and share it via
            text or on social media, or tap the big blue button.
          </ResponsiveTypography>
        ),
      },
    ];

    if (isIncentiveOffer) {
      referralSteps.push({
        stepNum: '2',
        title: 'Your friend gets a Mission Lane credit card',
        body: (
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            Your friend must follow the link you shared when they fill out the
            Mission Lane application. If they do AND they are approved...
          </ResponsiveTypography>
        ),
      });

      referralSteps.push({
        stepNum: '3',
        title: 'You receive a statement credit',
        body: (
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            Your ${statementCreditPerReferral / 100} statement credit will show
            up in your account within 8 weeks! You can track your total earned
            here or in the Mission Lane Mobile App. Terms apply.{' '}
            <Link onPress={onClickTerms}>
              See Mission Lane Referral Bonus Terms
            </Link>{' '}
            for details.
          </ResponsiveTypography>
        ),
      });
    } else {
      referralSteps.push({
        stepNum: '2',
        title: 'Your friend gets a Mission Lane credit card',
        body: (
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            Your friend follows the link you shared when they fill out the
            Mission Lane application. And if they’re approved...
          </ResponsiveTypography>
        ),
      });
      referralSteps.push({
        stepNum: '3',
        title: 'You both enjoy Mission Lane',
        body: (
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P3"
          >
            They have a new great credit card and the knowledge that their
            friend is looking out for them. You have the satisfaction of helping
            them along their financial path.
          </ResponsiveTypography>
        ),
      });
    }

    return referralSteps;
  };

  return (
    <div>
      <ResponsiveTypography type="HEADER" mobileLevel="H3" desktopLevel="H4">
        Here’s how it works:
      </ResponsiveTypography>
      <div>
        {buildReferralSteps(isIncentiveOffer, statementCreditPerReferral).map(
          (step, i) => {
            return <StepItem key={i} {...step} />;
          },
        )}
      </div>
    </div>
  );
};

export default ReferralsInstructions;
