import { ApolloError, useQuery } from '@apollo/client';
import { useFlags } from 'launchdarkly-react-client-sdk';

import currency from 'currency.js';
import { SpendBonus } from './types';
import { GetSpendBonusQuery, RewardType } from '@core/graphql/globalTypes';
import { getSpendBonusQuery } from '@clip/SpendBonus/api';
import { useAccountSummary } from '@clip/ClipProgress/hooks/useAccountSummary';
import { useAccount } from '@core/components/Auth/AccountContext';

export type UseSpendBonusQueryReturnDisabled = {
  spendBonusLoading: boolean;
  isEnabled: false;
};

export type OptOutSpendBonusVars = {
  offerId: string;
};

export type UseSpendBonusQueryReturnEnabled = {
  spendBonusLoading: boolean;
  spendBonus: SpendBonus;
  isEnabled: true;
  showSpendBonusProgress?: boolean;
  spendBonusError?: ApolloError;
  newCreditLimit?: string;
  creditLimit?: string;
};

export type UseSpendBonusQueryReturn =
  | UseSpendBonusQueryReturnDisabled
  | UseSpendBonusQueryReturnEnabled;

export const useSpendBonusQuery = (): UseSpendBonusQueryReturn => {
  const { showSpendBonus, showSpendBonusProgress } = useFlags();
  const { data: accountSummaryData } = useAccountSummary();
  const { accountId } = useAccount();

  const spendBonusQueryResult = useQuery<GetSpendBonusQuery>(
    getSpendBonusQuery,
    {
      variables: { accountId },
      skip: !showSpendBonus,
    },
  );

  if (
    showSpendBonus === false ||
    spendBonusQueryResult.data == null ||
    spendBonusQueryResult.data.spendBonus == null ||
    spendBonusQueryResult.data.spendBonus.eligible === false ||
    spendBonusQueryResult.data.spendBonus.status == null ||
    spendBonusQueryResult.data.spendBonus.offerId == null ||
    spendBonusQueryResult.data.spendBonus.reward == null ||
    spendBonusQueryResult.data.spendBonus.rewardType == null ||
    spendBonusQueryResult.data.spendBonus.dueDate == null ||
    spendBonusQueryResult.data.spendBonus.spendTarget == null ||
    accountSummaryData == null
  ) {
    return {
      isEnabled: false,
      spendBonusLoading: spendBonusQueryResult.loading,
    };
  }

  const spendBonus = spendBonusQueryResult.data.spendBonus as SpendBonus;

  const { reward, rewardType } = spendBonusQueryResult.data.spendBonus;

  const creditLimitInDollars = currency(
    accountSummaryData.account.pricingDetails.creditLimit,
    { fromCents: true },
  );

  const creditLimit = currency(creditLimitInDollars, { precision: 0 }).format();

  const newCreditLimit =
    creditLimitInDollars && reward && rewardType === RewardType.Clip ?
      currency(creditLimitInDollars, { precision: 0 }).add(reward).format()
    : undefined;

  return {
    isEnabled: true,
    showSpendBonusProgress, // LM-5667
    spendBonusLoading: spendBonusQueryResult.loading,
    spendBonusError: spendBonusQueryResult.error,
    newCreditLimit,
    creditLimit,
    spendBonus,
  };
};
