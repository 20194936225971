import cx from 'classnames';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import dayjs from 'dayjs';
import { renderToStaticMarkup } from 'react-dom/server';
import NavLinks from '../NavLinks';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import OfferQuestion from './OfferQuestion';
import { getDisclosures } from './Disclosures';
import { AnswerInputType } from './OfferAnswerInput';
import {
  SettlementPaymentFrequency,
  ServicingOffersPaymentSource,
  UpdateOfferMutation,
  MutationUpdateOfferArgs,
  ServicingOfferChannel,
} from '@core/graphql/globalTypes';
import { UPDATE_OFFER } from '@core/components/ChargedOff/gql/mutations';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import LoadingModal from '@core/components/General/LoadingModal';
import { createXhtmlDocFromHtmlString } from '@core/utils/createXhtmlDocFromHtml';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

enum Step {
  SELECT_CADENCE = 'SELECT_CADENCE',
  SELECT_DATE = 'SELECT_DATE',
}

interface CadenceAndDateProps extends PaymentPlanStepProps {
  fundingAccountLast4?: string;
}

const CadenceAndDate = ({
  nextStep,
  previousStep,
  offer,
  fundingAccountLast4,
}: CadenceAndDateProps) => {
  const { isMobile } = useUserDevice();
  const [step, setStep] = useState<Step>(Step.SELECT_CADENCE);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalDisplayComplete, setModalDisplayComplete] = useState(false);
  const [createPaymentPlanComplete, setCreatePaymentPlanComplete] =
    useState(false);

  useEffect(() => {
    if (step === Step.SELECT_CADENCE) {
      TrackService.trackPage('Create Program - Payment Cadence', {
        feature: SETTLEMENTS_FEATURE_NAME,
      });

      return;
    }

    if (step === Step.SELECT_DATE) {
      TrackService.trackPage('Create Program - First Payment Date', {
        feature: SETTLEMENTS_FEATURE_NAME,
      });

      return;
    }
  }, [step]);

  const [paymentCadence, setPaymentCadence] =
    useState<SettlementPaymentFrequency>(SettlementPaymentFrequency.Monthly);

  const isMonthlyCadenceSelected =
    paymentCadence === SettlementPaymentFrequency.Monthly;
  // Dates we dont want to be selectable when monthly cadence is selected
  const unavailableDates = getDatesBetween29thAnd31stForRange(
    dayjs().toDate(),
    dayjs().add(30, 'days').toDate(),
  );

  useEffect(() => {
    if (createPaymentPlanComplete && modalDisplayComplete) {
      nextStep?.();
    }
  }, [createPaymentPlanComplete, modalDisplayComplete]);

  const [createPaymentPlan, { error }] = useMutation<
    UpdateOfferMutation,
    MutationUpdateOfferArgs
  >(UPDATE_OFFER, {
    onCompleted: () => {
      setCreatePaymentPlanComplete(true);
    },
    fetchPolicy: 'no-cache',
  });
  const { originalSettlementBalance, durationMonths, acceptanceExpiration } =
    offer?.data || {};
  if (
    error ||
    !offer ||
    !originalSettlementBalance ||
    !durationMonths ||
    !acceptanceExpiration
  ) {
    return <GenericFallbackFull />;
  }

  const offerEndDate = dayjs().add(durationMonths, 'months').toISOString();

  const minDaysFromNow = dayjs(acceptanceExpiration)
    .add(1, 'day')
    .diff(dayjs(), 'day');

  const handleInitialDateSubmitted = async (date: string) => {
    const startDate = dayjs(date).format('YYYY-MM-DD');

    setIsModalOpen(true);
    setTimeout(() => {
      setModalDisplayComplete(true);
    }, 2000);

    const disclosures = renderToStaticMarkup(
      <div>
        {getDisclosures(
          originalSettlementBalance,
          offerEndDate,
          fundingAccountLast4,
        ).map((disclosure) => disclosure.content)}
      </div>,
    );

    await createPaymentPlan({
      variables: {
        offerId: offer.offerId,
        channel: ServicingOfferChannel.Web,
        requestBody: {
          paymentPlanCreate: {
            authorizationText: createXhtmlDocFromHtmlString(disclosures),
            source: ServicingOffersPaymentSource.Web,
            startDate,
            frequency: paymentCadence,
          },
          fundingAccountUpdate: null,
          paymentPlanUpdate: null,
          acceptOffer: false,
          cancelOffer: false,
          declineOffer: false,
        },
      },
    });
  };

  const goBack =
    step === Step.SELECT_CADENCE ?
      previousStep
    : () => setStep(Step.SELECT_CADENCE);

  return (
    <div className={cx('flex flex-column pt2 pt0-ns', !isMobile && 'w6')}>
      <NavLinks onGoBack={goBack} />
      <div className="mt3 w-100 payment-plan-kard">
        {step === Step.SELECT_CADENCE && (
          <OfferQuestion
            questionText="What’s your preferred payment schedule?"
            inputType={AnswerInputType.Select}
            selectOptions={[
              { label: 'Monthly', value: SettlementPaymentFrequency.Monthly },
              {
                label: 'Every two weeks',
                value: SettlementPaymentFrequency.Bimonthly,
              },
              { label: 'Weekly', value: SettlementPaymentFrequency.Weekly },
              { label: 'One time', value: SettlementPaymentFrequency.OneTime },
            ]}
            onAnswerSubmitted={(cadence) => {
              setPaymentCadence(cadence as SettlementPaymentFrequency);
              setStep(Step.SELECT_DATE);
            }}
          />
        )}
        {step === Step.SELECT_DATE && (
          <OfferQuestion
            questionText="Next, select your first payment date"
            inputType={AnswerInputType.Date}
            onAnswerSubmitted={handleInitialDateSubmitted}
            minStartDaysFromNow={minDaysFromNow}
            unavailableDates={
              isMonthlyCadenceSelected ? unavailableDates : undefined
            }
          />
        )}
        <LoadingModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          primaryText="We’re creating your payment schedule."
        />
      </div>
    </div>
  );
};

/**
 * Gets all the days that fall on the 29th thru the 31st day
 * of the month for a given range
 */
const getDatesBetween29thAnd31stForRange = (startDate: Date, endDate: Date) => {
  let currentDate = dayjs(startDate);
  const endOfDateRange = dayjs(endDate);
  const result: Date[] = [];

  while (currentDate.isSameOrBefore(endOfDateRange)) {
    if (currentDate.date() >= 29 && currentDate.date() <= 31) {
      result.push(currentDate.toDate());
    }

    currentDate = currentDate.add(1, 'day');
  }

  return result;
};

export default CadenceAndDate;
