import { useFlags } from 'launchdarkly-react-client-sdk';
import MLCard from '@core/assets/MLCard.svg';
import { GetCardLockStatus } from '@core/graphql/globalTypes';

interface CreditCardProps {
  cardArtUrl: GetCardLockStatus.AccountDetails['cardArtUrl'] | undefined;
}

export const CreditCard = ({ cardArtUrl }: CreditCardProps) => {
  const { enableDynamicCardArt } = useFlags();

  return (
    <img
      width="300px"
      src={cardArtUrl && enableDynamicCardArt ? cardArtUrl : MLCard}
      alt="Mission Lane credit card"
    />
  );
};
