import { Notification, Spacer } from '@missionlane/compass-ui';

import { Tile, TileDetail } from './TransactionTile';
import { MerchantDetails } from './MerchantDetails';
import { TransactionDate } from './TransactionDate';
import { TransactionAmount } from './TransactionAmount';
import { PaymentAccount } from './PaymentAccount';
import { TransactionStatus } from './TransactionStatus';
import {
  ActivityState,
  TransactionMethod,
  Transactions,
} from '@core/graphql/globalTypes';
import PageWrapper from '@core/components/Page/PageWrapper';
import {
  TestSeed,
  useTestProperties,
} from '@core/utils/hooks/useTestProperties';
import BackLink from '@core/components/General/BackLink';

interface Props {
  transaction: Transactions.Activities;
}

const PaymentDetailPage = ({ transaction }: Props) => {
  const testProperties = useTestProperties(TestSeed.SEED_1);

  if (transaction.__typename !== 'Payment') {
    return (
      <Notification level="error">
        There was a problem loading your transaction. Please try again later.
      </Notification>
    );
  }

  const { fundingAccount, amount, date, state, reference, transactionMethod } =
    transaction;

  const isDebitCardPayment = transactionMethod === TransactionMethod.Debit;

  return (
    <PageWrapper
      isSingleColumn
      trackingProperties={{
        pageName: 'Transaction detail',
        featureName: 'Transactions',
        testProperties,
      }}
    >
      <BackLink className="pb3" />
      <div className="flex flex-column items-center mb3">
        <TransactionAmount amount={Math.abs(amount)} positive />
        <MerchantDetails merchant={{ name: 'Payment' }} />
        <TransactionDate
          date={date}
          isPending={transaction.state === ActivityState.Pending}
          isPayment
        />
      </div>
      <TransactionStatus status={state} />
      {fundingAccount && (
        <>
          <Spacer size="s" />
          <PaymentAccount fundingAccount={fundingAccount} />
        </>
      )}
      {isDebitCardPayment && (
        <>
          <Spacer size="s" />
          <Tile>
            <TileDetail title="Payment Type" value="Debit Card" />
          </Tile>
        </>
      )}
      {reference && (
        <>
          <Spacer size="s" />
          <Tile>
            <TileDetail title="Confirmation Number" value={reference} />
          </Tile>
        </>
      )}
    </PageWrapper>
  );
};

export default PaymentDetailPage;
