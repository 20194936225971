import { useEffect } from 'react';
import currency from 'currency.js';
import dayjs from 'dayjs';

import track, { EventNames } from '../track';
import { OptOutModal } from '../OptOutModal';
import { TEST_ID, COPY } from './consts';
import { SpendBonusProgressBar } from '@clip/SpendBonus/components/SpendBonusProgressBar';
import { RewardType, SpendBonusStatus } from '@core/graphql/globalTypes';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import Kard from '@core/components/General/Kard/Kard';

import '../../assets/SpendBonus.css';

export const SpendBonusInProgress = () => {
  const spendBonusResult = useSpendBonus();

  useEffect(() => {
    track({
      eventName: EventNames.OfferViewed,
      status: SpendBonusStatus.InProgress,
      rewardType: RewardType.Clip,
    });
  }, []);

  if (spendBonusResult.isEnabled === false) {
    return null;
  }

  const {
    newCreditLimit,
    showSpendBonusProgress,
    spendBonus: { reward, dueDate, spendTarget },
  } = spendBonusResult;

  const formattedDueDate = dayjs(dueDate).format('MMMM D, YYYY');
  const formattedReward = currency(reward, { precision: 0 }).format();
  const formattedSpendTarget = currency(spendTarget, { precision: 0 }).format();

  return (
    <Kard
      header={{ textPrimary: COPY.TITLE, level: 'H4' }}
      testID={TEST_ID.SPEND_BONUS__IN_PROGRESS}
      className="mb3 w-100"
    >
      <h5
        className="spend-bonus__header"
        data-testid={TEST_ID.SPEND_BONUS__IN_PROGRESS__HEADER__CLIP}
      >
        You could boost your credit limit to{' '}
        <span className="spend-bonus__header--highlight">{newCreditLimit}</span>{' '}
        or more.
      </h5>
      {showSpendBonusProgress && <SpendBonusProgressBar />}
      <p
        className="spend-bonus__message"
        data-testid={TEST_ID.SPEND_BONUS__IN_PROGRESS__MESSAGE__CLIP}
      >
        Did you know that using your Mission Lane Visa® responsibly can help
        you earn a higher credit limit? Spend{' '}
        <span className="spend-bonus__message--highlight">
          {formattedSpendTarget}
        </span>{' '}
        on Purchases by {formattedDueDate} to be considered for a{' '}
        <span className="spend-bonus__message--highlight">
          {formattedReward}
        </span>{' '}
        credit limit increase.
      </p>
      <br />
      <OptOutModal />
    </Kard>
  );
};
