import { Tile, TileDetail } from './TransactionTile';
import toTitleCase from '@core/utils/toTitleCase';
import { Activity } from '@core/graphql/globalTypes';

interface Props {
  status: Activity['state'];
}

export const TransactionStatus = ({ status }: Props) => (
  <Tile>
    <TileDetail title="Status" value={toTitleCase(status)} />
  </Tile>
);
