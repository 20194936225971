import { gql, useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { H4, P3 } from '@missionlane/compass-ui';
import { Notification } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import AutoPayPaymentDisplay from '../AutoPayPaymentDisplay';
import { GET_AUTOPAY } from '../queries';
import { useAutopay } from '../AutopayContext';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import { useAccount } from '@core/components/Auth/AccountContext';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import {
  CancelAutopayMutation,
  CancelAutopayMutationVariables,
} from '@core/graphql/globalTypes';
import PageWrapper from '@core/components/Page/PageWrapper';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

const CANCEL_AUTOPAY_MUTATION = gql`
  mutation CancelAutopay($scheduleId: String!) {
    cancelAutopay(scheduleId: $scheduleId) {
      ...AutopayFields
    }
  }
  ${AUTOPAY_FIELDS}
`;

const CancelAutoPayConfirmation = () => {
  const navigate = useNavigate();
  const { accountId } = useAccount();
  const { autopayContext, autopayLoading, autopayPageTitle } = useAutopay();
  const [cancelAutopay, { data: cancelAutopayResponse, error, loading }] =
    useMutation<CancelAutopayMutation, CancelAutopayMutationVariables>(
      CANCEL_AUTOPAY_MUTATION,
      {
        variables: { scheduleId: autopayContext?.autopay?.id || '' },
        refetchQueries: [{ query: GET_AUTOPAY, variables: { accountId } }],
      },
    );

  useEffect(() => {
    if (cancelAutopayResponse?.cancelAutopay) {
      navigate('../canceled');
    }
  }, [cancelAutopayResponse]);

  const { amount, paymentType, dayOfMonth, fundingAccount, nextPaymentDate } =
    autopayContext?.autopay || {};

  if (loading || autopayLoading) {
    return <LoadingSpinnerPage />;
  }

  if (amount === undefined || !paymentType || !dayOfMonth || !fundingAccount) {
    return <GenericFallbackFull />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={autopayPageTitle}
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName: 'Cancel Autopay: Confirm',
      }}
    >
      <H4>Are you sure?</H4>
      <P3>
        Please review and confirm that you want to cancel this automatic
        payment.
      </P3>
      <div className="pt3">
        <AutoPayPaymentDisplay
          amount={amount}
          paymentType={paymentType}
          dayOfMonth={dayOfMonth}
          fundingAccount={fundingAccount}
          paymentDateLabel="Next Payment"
          nextPaymentDate={nextPaymentDate}
        />
      </div>
      {error && (
        <Notification variant="inline" level="error">
          {error.message || (error as unknown as string)}
        </Notification>
      )}
      <PaymentConfirmationButtons
        onSubmit={cancelAutopay}
        onCancel={() => navigate('../manage')}
        disabled={loading}
        text="Yes, Cancel Autopay"
        cancelButtonText="Go Back"
        leftAlign
        critical
      />
    </PageWrapper>
  );
};

export default CancelAutoPayConfirmation;
