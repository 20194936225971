import cx from 'classnames';
import { B } from '@missionlane/compass-ui';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { formatCardArtUrl, formatCardName } from '@core/utils/cardArt';

interface NavCardArtProps {
  cardArtUrl: string;
  cardName: string | null | undefined;
  cardLast4: string | null | undefined;
  classNames?: string;
}

export const NavCardArt = ({
  cardArtUrl,
  cardName,
  cardLast4,
  classNames,
}: NavCardArtProps) => {
  const formattedCardName = formatCardName(cardName);
  const cardNameAndLast4 =
    cardLast4 ? `${formattedCardName} ...${cardLast4}` : formattedCardName;
  return (
    <div
      className={cx('flex flex-column ph4', classNames)}
      data-testid="NavCardArt"
    >
      <img
        src={formatCardArtUrl(cardArtUrl)}
        alt={formattedCardName}
        width={83}
        height={52}
      />
      <ResponsiveTypography
        className="flex"
        type="PARAGRAPH"
        mobileLevel="P1"
        desktopLevel="P3"
        color="white"
      >
        <B>{cardNameAndLast4}</B>
      </ResponsiveTypography>
    </div>
  );
};
