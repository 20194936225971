import { Button } from '@missionlane/compass-ui';
import { useState } from 'react';
import { type FormButtonContainerProps } from '../../../components';
import { IncomeUpdateTrackingNames } from '../../../lib';
import OfferDetailsModal from '@clip/IncomeUpdate/components/OfferDetailsModal';
import { TrackService } from '@core/services';

import './FormButtonContainer.css';

export const FormButtonContainer = ({
  submitDisabled,
  onSubmit,
  loading,
  hasPie,
}: FormButtonContainerProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="form-button-container__container">
      {hasPie && (
        <>
          <Button
            onPress={() => {
              TrackService.click(IncomeUpdateTrackingNames.SeeOfferDetails);
              setIsOpen(true);
            }}
            variant="text"
            text="See Offer Details"
            size="small"
          />
          <OfferDetailsModal isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
      )}

      <Button
        onPress={onSubmit}
        variant="outlined"
        text="Submit"
        size="standard"
        loading={loading}
        disabled={submitDisabled}
      />
    </div>
  );
};
