import { B, Notification } from '@missionlane/compass-ui';
import { useState } from 'react';

interface RedemptionBannerProps {
  isSuccess: boolean;
}

export const RedemptionBanner = ({ isSuccess }: RedemptionBannerProps) => {
  const [dismissed, setDismissed] = useState(false);

  const headline =
    isSuccess ?
      <>
        <B>Redeemed! </B>
        Your balance will update in 2-3 business days.
      </>
    : <>Error redeeming rewards</>;

  return (
    <Notification
      level={isSuccess ? 'success' : 'error'}
      show={!dismissed}
      variant="banner"
      onDismiss={() => setDismissed(true)}
    >
      {headline}
    </Notification>
  );
};
