import { P3 } from '@missionlane/compass-ui';
import Kard, { KardHeader } from '@core/components/General/Kard/Kard';

interface GenericFallbackKardProps {
  className?: string;
  errorMessage?: string;
  header?: KardHeader;
}

const DEFAULT_MESSAGE =
  "Sorry, we're having some tech trouble but our team is looking into it. Check back in a bit.";

const GenericFallbackKard = ({
  className,
  header,
  errorMessage = DEFAULT_MESSAGE,
}: GenericFallbackKardProps) => (
  <Kard
    className={className}
    header={
      header && {
        ...header,
        className: header.className || 'mb3',
      }
    }
  >
    <P3>{errorMessage}</P3>
  </Kard>
);

export default GenericFallbackKard;
