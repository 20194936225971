import {
  createContext,
  useState,
  ReactNode,
  useContext,
  useEffect,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useSettlementOffers } from '../utils/useSettlementOffers';

interface EditOfferFundingAccountContext {
  selectedFundingAccountId: number | undefined;
  setSelectedFundingAccountId: (newValue: number | undefined) => void;
}

const EditOfferFundingAccountContext =
  createContext<EditOfferFundingAccountContext>({
    selectedFundingAccountId: undefined,
    setSelectedFundingAccountId: () => {},
  });

export const EditOfferFundingAccountProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { activeOffer } = useSettlementOffers();

  const location = useLocation();
  const newlyAddedFundingAccountId = location.state?.newDefaultFundingAccountId;
  const [selectedFundingAccountId, setSelectedFundingAccountId] =
    useState<number>();

  useEffect(() => {
    if (newlyAddedFundingAccountId) {
      setSelectedFundingAccountId(newlyAddedFundingAccountId);
    } else if (activeOffer?.data.fundingAccountId) {
      setSelectedFundingAccountId(activeOffer.data.fundingAccountId);
    }
  }, [activeOffer, newlyAddedFundingAccountId]);

  return (
    <EditOfferFundingAccountContext.Provider
      value={{ selectedFundingAccountId, setSelectedFundingAccountId }}
    >
      {children}
    </EditOfferFundingAccountContext.Provider>
  );
};

export const useEditOfferFundingAccountContext = () =>
  useContext(EditOfferFundingAccountContext);
