import { gql } from '@apollo/client';
import { Icon, P3 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PageWrapper from '../Page/PageWrapper';
import { useAccount } from '../Auth/AccountContext';
import EditPaperlessPreference from './StatementsPreferences/EditPaperlessPreference';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  StatementsQuery,
  StatementsQueryVariables,
} from '@core/graphql/globalTypes';
import List from '@core/components/General/List/List';
import { formatDate } from '@core/utils/formatters';

export const STATEMENTS_QUERY = gql`
  query Statements($accountId: String!) {
    statementLinks(accountId: $accountId) {
      statements {
        id
        documentUrl
        date
      }
    }
    accountDetails(accountId: $accountId) {
      id
      isPaperlessStatement
    }
    customer {
      id
      accounts {
        id
        statuses {
          canUpdateStatementPreference
        }
      }
    }
  }
`;

export const Statements = () => {
  const navigate = useNavigate();
  const { accountId } = useAccount();
  const { paperlessStatementEnabled } = useFlags();
  const { data, loading, error } = useAccountIdQuery<
    StatementsQuery,
    StatementsQueryVariables
  >(STATEMENTS_QUERY);
  const { statementLinks, accountDetails, customer } = data || {};
  const { statements } = statementLinks || {};
  const isPaperlessStatement = accountDetails?.isPaperlessStatement;
  const canUpdateStatementPreference = customer?.accounts?.find(
    (acct) => acct.id === accountId,
  )?.statuses?.canUpdateStatementPreference;
  const showPaperlessCTA =
    Boolean(canUpdateStatementPreference) && Boolean(paperlessStatementEnabled);

  if (loading) return <LoadingSpinner />;

  if (error) throw error;

  const isNoStatements = !statements || !statements.length;

  return (
    <div>
      {showPaperlessCTA && (
        <EditPaperlessPreference
          isPaperless={Boolean(isPaperlessStatement)}
          onPress={() =>
            navigate('./preferences', { state: { fromStatements: true } })
          }
        />
      )}
      {isNoStatements ?
        <div className="mt5">
          <P3>You don't have any statements available.</P3>
        </div>
      : <List className="mt4">
          {statements.map((statement) => (
            <a
              key={statement.id}
              href={statement.documentUrl}
              className="blue hover-indigo fw5 no-underline pointer flex justify-between link pv3"
              target="_blank"
              rel="noreferrer"
            >
              {formatDate(statement.date, 'MMMM YYYY')}
              <Icon name="download" color="blue" />
            </a>
          ))}
        </List>
      }
    </div>
  );
};

const StatementsPage = () => (
  <PageWrapper
    isSingleColumn
    pageTitle="Statements"
    trackingProperties={{ pageName: 'Statements', featureName: 'Statements' }}
  >
    <Statements />
  </PageWrapper>
);

export default StatementsPage;
