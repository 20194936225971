import cx from 'classnames';
import { Button, P3 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import PaymentKardAutopayLink from './PaymentKardAutoPayLink';
import PaymentsOverviewLink from '@payments/components/PaymentKard/PaymentsOverviewLink';
import { TrackingLinkProps } from '@core/components/General/TrackingLink';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface Props {
  trackingProperties: TrackingLinkProps['trackingProperties'];
  disableAutopayLink?: boolean;
  autopayOn?: boolean;
  isDebitEligible: boolean;
  isPastDue?: boolean | undefined;
  isPaymentsOverview?: boolean;
  canMakePayment?: boolean;
}

export const PaymentKardFooter = ({
  trackingProperties,
  disableAutopayLink = true,
  autopayOn = true,
  isDebitEligible,
  isPastDue,
  isPaymentsOverview,
  canMakePayment,
}: Props) => {
  const { isMobile } = useUserDevice();
  const navigate = useNavigate();
  const onPressPay = () => {
    const pathname =
      isDebitEligible ? '../payments/pay' : '../payments/pay/make-ach-payment';
    TrackService.click('Make Payment', trackingProperties);
    navigate(pathname);
  };

  return (
    <>
      <div className={cx('flex-ns', { mt3: !canMakePayment })}>
        <Button
          size={isMobile ? 'small' : 'standard'}
          onPress={onPressPay}
          text="Make a Payment"
          disabled={!canMakePayment}
        />
      </div>
      {!isPaymentsOverview && (
        <div
          className={`flex flex-row justify-between pt3 ${
            isPastDue ? 'pb5' : ''
          }`}
        >
          <PaymentsOverviewLink trackingProperties={trackingProperties} />
          {!disableAutopayLink && (
            <PaymentKardAutopayLink
              trackingProperties={trackingProperties}
              autopayOn={autopayOn}
            />
          )}
        </div>
      )}
      {!isPaymentsOverview && isPastDue && (
        <div className="pt2 pt5-m pt5-l bt b--ink-20 bw1">
          <P3 style={styles.label}>
            This is an attempt to collect on a debt and any information obtained
            will be used for that purpose.
          </P3>
        </div>
      )}
    </>
  );
};

export default PaymentKardFooter;

const styles = {
  label: {
    margin: 0,
  },
};
