import { ColorNames, Link, LoadingIndicator } from '@missionlane/compass-ui';
import { gql, useQuery } from '@apollo/client';
import {
  ProactiveMulticardOffer as ProactiveMulticardOfferType,
  ProactiveMulticardTileQuery,
  ProactiveMulticardTileQueryVariables,
} from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';
import mlSilverCardOffer from '@core/assets/mlSilverCardOffer.svg';
import mlGoldCardOffer from '@core/assets/mlGoldCardOffer.svg';

import { formatUSD } from '@core/utils/formatters';
import {
  CLOSE_BUTTON_WIDTH,
  OpportunityTileCTA,
  OpportunityTileCloseIcon,
  OpportunityTileContainer,
  OpportunityTileDiagonalBackground,
  OpportunityTileGradient,
} from '@core/components/OpportunityTileCarousel/OpportunityTiles/LayoutComponents';
import { CarouselTileProps } from '@core/components/Carousel/Carousel';

export const PROACTIVE_MULTICARD_TILE_EVENT = 'PROACTIVE_MULTICARD_OFFER';
const PROACTIVE_MULTICARD_APPLY_LINK = `${process.env.REACT_APP_APPLY_URL}/flow/application`;

export const PROACTIVE_MULTICATD_TILE_QUERY = gql`
  query ProactiveMulticardTile {
    proactiveOffers {
      eligible
      expirationDate
      offers {
        offerType
        rewardsPercentage
        categoryRewardsPercentage
        totalCategoryRewardsPercentage
        rewardCategories
        maxCreditLimit
      }
    }
  }
`;

const OFFER_TYPE = {
  CATEGORY_REWARDS: 'CATEGORY_REWARDS',
  CASHBACK: 'CASHBACK',
} as const;

type OfferType = keyof typeof OFFER_TYPE;

type OfferCopy = {
  title: string;
  body: (offer: ProactiveMulticardOfferType) => string;
};

export const isOfferType = (offerType = ''): offerType is OfferType => {
  const maybeOfferType = offerType as OfferType;
  return Boolean(OFFER_TYPE[maybeOfferType]);
};

const getRewardPercentageCopy = (rewardPercentage?: string | null) => {
  const percentage = parseFloat(rewardPercentage || '0');
  const formattedPercentage = parseFloat(percentage.toFixed(1));
  return `${formattedPercentage}%`;
};

const getCreditLineCopy = (creditLimitDollars?: number | null) => {
  const preformatted = formatUSD(`${creditLimitDollars}` || '0');
  return preformatted ? preformatted.split('.')[0] : '';
};

const cashBackOfferCopy: OfferCopy = {
  title: 'Upgrade to our Silver Line Visa®',
  body: (offer: ProactiveMulticardOfferType) =>
    `Unlimited ${getRewardPercentageCopy(
      offer.rewardsPercentage,
    )} cash back and a credit line up to ${getCreditLineCopy(
      offer.maxCreditLimit || 0,
    )}.`,
};

const categoryRewardsOfferCopy: OfferCopy = {
  title: 'Upgrade to our Gold Line Visa®',
  body: (offer: ProactiveMulticardOfferType) => {
    const rewards: string[] = [...(offer.rewardCategories || [])];
    const lastReward = rewards.pop();
    const rewardCategoryCopy = `${rewards.join(', ')}, and ${lastReward}`;

    return `${getRewardPercentageCopy(
      offer.totalCategoryRewardsPercentage,
    )} cash back on ${rewardCategoryCopy}. Credit line up to ${getCreditLineCopy(
      offer.maxCreditLimit,
    )}.`;
  },
};

const OFFER_TYPE_COPY: Record<OfferType, OfferCopy> = {
  CASHBACK: cashBackOfferCopy,
  CATEGORY_REWARDS: categoryRewardsOfferCopy,
} as const;

export const getActiveOfferContent = (
  offer: ProactiveMulticardOfferType | null,
) => {
  if (!offer || !isOfferType(offer.offerType)) {
    return null;
  }

  const activeOfferCopy = OFFER_TYPE_COPY[offer.offerType];

  const tileBackground: ProactiveMulticardTileBackground =
    offer.offerType === 'CASHBACK' ?
      {
        image: mlSilverCardOffer,
        backgroundColor: 'greyWashed',
        gradient: 'silverGradient',
      }
      : {
        image: mlGoldCardOffer,
        backgroundColor: 'yellowWashed',
        gradient: 'goldGradient',
      };

  return {
    ...activeOfferCopy,
    body: activeOfferCopy.body(offer),
    background: tileBackground,
  };
};

type ProactiveMulticardTileBackground = {
  backgroundColor: ColorNames;
  image: string;
  gradient: OpportunityTileGradient;
};

const ProactiveMulticardTile = ({
  onDismiss,
  isVisible,
}: CarouselTileProps) => {
  const { data, loading } = useQuery<
    ProactiveMulticardTileQuery,
    ProactiveMulticardTileQueryVariables
  >(PROACTIVE_MULTICATD_TILE_QUERY);

  const onClickCta = () => {
    TrackService.trackClick('See If You Qualify', {
      feature: PROACTIVE_MULTICARD_TILE_EVENT,
    });
    window.open(PROACTIVE_MULTICARD_APPLY_LINK, '_blank')?.focus();
  };

  const proactiveOffers = data?.proactiveOffers?.offers;
  const offerContent =
    proactiveOffers && getActiveOfferContent(proactiveOffers[0]);

  if (loading) {
    return <LoadingIndicator />;
  }

  if (!offerContent) {
    // Unlikely to happen, but if there is no offer content, we should not render the tile
    // Apollo should handle this case and not return this tile if there is no offer
    return null;
  }

  return (
    <OpportunityTileContainer
      trackingProperties={{ feature: PROACTIVE_MULTICARD_TILE_EVENT }}
      backgroundColor={offerContent.background.backgroundColor}
      isVisible={isVisible}
    >
      <OpportunityTileDiagonalBackground
        background={offerContent.background.gradient}
        imgAlt="new ML credit card"
        imgSource={offerContent.background.image}
        imgWidth={IMAGE_WIDTH}
        imageRight={IMAGE_RIGHT}
        imageBottom={IMAGE_BOTTOM}
      />
      <OpportunityTileCTA
        trackingName={PROACTIVE_MULTICARD_TILE_EVENT}
        contentSafeMargin={CONTENT_SAFE_MARGIN}
        title={offerContent.title}
        text={offerContent.body}
        onButtonClick={onClickCta}
        buttonLabel="Apply now"
      />
      <Link
        onPress={() => onDismiss(PROACTIVE_MULTICARD_TILE_EVENT)}
        style={{
          zIndex: 2,
        }}
      >
        <OpportunityTileCloseIcon />
      </Link>
    </OpportunityTileContainer>
  );
};

export default ProactiveMulticardTile;

const IMAGE_WIDTH = 87;
const IMAGE_RIGHT = 10;
const CONTENT_SAFE_MARGIN = IMAGE_WIDTH - CLOSE_BUTTON_WIDTH;
const IMAGE_BOTTOM = 10;
