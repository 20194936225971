import { useEffect } from 'react';
import currency from 'currency.js';
import dayjs from 'dayjs';

import { DismissOfferButton } from '../DismissOfferButton';
import track, { EventNames } from '../track';
import { TEST_ID, COPY } from './consts';
import { RewardType, SpendBonusStatus } from '@core/graphql/globalTypes';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import Kard from '@core/components/General/Kard/Kard';

import '../../assets/SpendBonus.css';

export const SpendBonusIneligible = () => {
  const spendBonusResult = useSpendBonus();

  useEffect(() => {
    track({
      eventName: EventNames.OfferViewed,
      status: SpendBonusStatus.Ineligible,
      rewardType: RewardType.Clip,
    });
  }, []);

  if (
    spendBonusResult.isEnabled === false ||
    spendBonusResult.spendBonus.spendProgress == null
  ) {
    return null;
  }

  const {
    spendBonus: { dueDate, spendTarget },
  } = spendBonusResult;

  const formattedDueDate = dayjs(dueDate).format('MMMM D');
  const formattedSpendProgress = currency(spendTarget, {
    precision: 0,
  }).format();

  return (
    <Kard
      header={{
        textPrimary: COPY.TITLE_CLIP,
        className: 'mb2 mr5',
        level: 'H4',
      }}
      testID={TEST_ID.SPEND_BONUS__INELIGIBLE}
      className="mb3 w-100 relative"
    >
      <DismissOfferButton />
      <p
        className="spend-bonus__message"
        data-testid={TEST_ID.SPEND_BONUS__INELIGIBLE__MESSAGE__CLIP}
      >
        You spent {formattedSpendProgress} by {formattedDueDate}, but your
        account wasn’t eligible at the time of evaluation. Your account will be
        regularly evaluated for other offers like credit limit increases in the
        future.
      </p>
    </Kard>
  );
};
