import { useForm, FormProvider } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { AuthnTransaction } from '@okta/okta-auth-js';
import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Notification, P3, Spacer } from '@missionlane/compass-ui';
import { isOktaError, OktaResponseError, OTPFactor } from '../types';
import LoginActionButtons from '../Login/LoginActionButtons';
import { LoginLink } from '../Login/LoginLink';
import LoginInfoSection from '../Login/LoginInfoSection';
import { ContactUsMessage } from '../Login/ContactUsMessage';
import SendCodeOptions from '../Login/SendCodeOptions';
import { usernameSchema } from '../validation';
import LoginContainer from '../Login/LoginContainer';
import NewCustomerLink from './NewCustomerLink';
import SupportPhoneLink from '@core/components/General/SupportPhoneLink';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';
import { TrackService } from '@core/services';
import { HookFormTextField } from '@core/components/Form';

const validationSchema = Yup.object().shape({
  username: usernameSchema,
});

interface FormValues {
  username: string;
  factorType: OTPFactor;
}

interface ForgotPasswordProps {
  onSuccess: (transaction: AuthnTransaction) => void;
  onCancel?: () => void;
  tokenError: boolean;
}

const clearStoredUsername = () => localStorage.setItem('username', '');

const ForgotPassword = ({
  onSuccess,
  onCancel,
  tokenError,
}: ForgotPasswordProps) => {
  const storedUsername = localStorage.getItem('username') || '';
  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues: { username: storedUsername, factorType: 'sms' },
  });
  const {
    handleSubmit: hookFormSubmit,
    setValue,
    watch: subscribeToValue,
    formState: { errors, isSubmitting, isValid },
  } = form;

  const [authError, setAuthError] = useState<OktaResponseError>();
  const { oktaAuth } = useOktaAuth();

  const handleSubmit = hookFormSubmit(async ({ username, factorType }) => {
    // clear prior status
    setAuthError(undefined);
    try {
      const transaction = await oktaAuth.forgotPassword({
        username,
        factorType,
      });
      onSuccess(transaction);
    } catch (e: unknown) {
      if (isOktaError(e)) {
        setAuthError(e);
      } else {
        throw e;
      }
    }
  });

  useEffect(() => {
    TrackService.page('Forgot Password');
  }, []);

  return (
    <LoginContainer header="Reset Password">
      <FormProvider {...form}>
        <div className="mb3 mb4-ns">
          <P3>
            To keep your account safe, we need to confirm your identity. First,
            we'll send a security code to the phone number associated with the
            email address you provided.
          </P3>
          <div className="mt4 mb4 mb5-ns">
            <HookFormTextField
              autoFocus
              disabled={tokenError}
              accessibilityLabel="email"
              name="username"
              label="Email"
              error={errors.username?.message}
              onChangeText={(text) => {
                // Assume that if the user backspaces this out manually, then the stored
                // username is not their email
                if (!text && storedUsername) clearStoredUsername();
              }}
            />{' '}
            {storedUsername && (
              <div className="mt2">
                <LoginLink
                  onClick={() => {
                    clearStoredUsername();
                    setValue('username', '');
                  }}
                >
                  Not your email?
                </LoginLink>
              </div>
            )}
          </div>
        </div>

        <SendCodeOptions
          fieldName="factorType"
          onChange={(selected) => {
            setValue('factorType', selected);
          }}
          selected={subscribeToValue('factorType')}
          disabled={tokenError}
        />

        <Spacer size="m" />
        {authError && (
          <div className="mb4 mb5-ns">
            <Notification level="error">
              Sorry there's an issue on our end. Resend your code or give us a
              call at <PhoneLink phoneNumber={cardSupportPhone} />.
            </Notification>
          </div>
        )}

        {tokenError && (
          <div className="mb4 mb5-ns">
            <Notification level="error">
              Your reset link has expired. Please give us a call at{' '}
              <SupportPhoneLink
                shouldCallRecoveries={false}
                feature="Forgot Password: Recovery Token Error"
              />{' '}
              to receive a new link.
            </Notification>
          </div>
        )}

        <LoginActionButtons
          submitButton={{
            onPress: handleSubmit,
            loading: isSubmitting,
            text: 'Send my code',
            disabled: !isValid,
          }}
          onCancel={onCancel}
        />

        <div className="mv5 mt5-ns">
          <NewCustomerLink />
        </div>

        <LoginInfoSection
          headerText="Why am I seeing this?"
          message="For added security, Mission Lane requires a one-time
              security code to be sent to your phone, so that we can be
              confident that only you can request a password reset for your account."
        />

        <ContactUsMessage />
      </FormProvider>
    </LoginContainer>
  );
};

export default ForgotPassword;
