import CryptoJS from 'crypto-js';
import { useCustomerAndAccountIdentifiers } from './useCustomerAndAccountIdentifiers';
import { TrackServiceTestProperties } from '@core/services/TrackService/TrackService';

/*
This file is used to generate random numbers for AB testing purposes.
The numbers generated here are used to determine which variant of a test a user will see.
*/

//More seeds can be added as needed for mutliple tests
export enum TestSeed {
  /**
   * @deprecated use SEED_1_V2 instead
   */
  SEED_1,
  SEED_1_V2,
}

const generateDetermisticNumber = (
  seed: string,
  minValue = 0,
  maxValue = 999,
  v2 = false, //Temporary
): number => {
  // Create a hash using SHA-256
  const hash = CryptoJS.SHA256(seed);

  // Convert the hash to a large integer
  /*
  If v2 is true, only use the first 8 characters of the hash.
  This is a temporary solution to fix a percision issue that exists between javascript and snowflake
  We want the number generated to be equivalent to the number generated in snowflake. 
  Deprecatiion of the V2 convention and it's strategy outline here: https://missionlane.atlassian.net/browse/EEC-38
  */
  const hashInt =
    v2 ?
      parseInt(hash.toString(CryptoJS.enc.Hex).substring(0, 8), 16)
    : parseInt(hash.toString(CryptoJS.enc.Hex), 16);

  // Calculate the output range
  const outputRange = maxValue - minValue + 1;

  // Map the large integer to a value within the desired range
  const randomNumber = (hashInt % outputRange) + minValue;

  return randomNumber;
};

export const useTestProperties = (
  seed: TestSeed,
  testName?: string,
  _customerId?: string,
): TrackServiceTestProperties | undefined => {
  let customerId = _customerId;

  if (!_customerId) {
    const { customerId: customerIdFromHook } =
      useCustomerAndAccountIdentifiers();
    customerId = customerIdFromHook;
  }

  if (!customerId) {
    return undefined;
  }

  switch (seed) {
    case TestSeed.SEED_1:
      return {
        testName: testName,
        variant: {
          name: 'TEST_NUM_1',
          value: generateDetermisticNumber(customerId),
        },
      };
    case TestSeed.SEED_1_V2:
      return {
        testName: testName,
        variant: {
          name: 'TEST_NUM_1_V2',
          value: generateDetermisticNumber(
            customerId,
            undefined,
            undefined,
            true,
          ),
        },
      };
    default:
      return undefined;
  }
};
