import cx from 'classnames';
import { BubbleIcon, Colors, P2, P3 } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import dayjs from 'dayjs';
import TransactionItem from './TransactionItem';
import { Transactions } from '@core/graphql/globalTypes';

interface Props {
  transactions?: Transactions.Activities[];
  numberToShow?: number;
  showDividers?: boolean;
  noTransactionsMessage: {
    textPrimary: string;
    textSecondary?: string;
    classes?: string;
  };
}

const TransactionList = ({
  transactions = [],
  numberToShow,
  noTransactionsMessage,
}: Props) => {
  const { showNewOnboardingExperience } = useFlags();

  const sortedTxs = transactions.slice().sort((a, b) => {
    if (dayjs(a.date).isBefore(b.date)) return 1;
    else if (dayjs(a.date).isAfter(b.date)) return -1;
    else return 0;
  });

  if (sortedTxs.length) {
    return (
      <div>
        {sortedTxs.slice(0, numberToShow).map((transaction) => (
          <TransactionItem key={transaction.id} transaction={transaction} />
        ))}
      </div>
    );
  } else {
    const { textPrimary, textSecondary, classes } = noTransactionsMessage;
    if (showNewOnboardingExperience) {
      return (
        <div
          className={cx(
            'flex items-center justify-center flex-column tc',
            classes && classes,
          )}
          style={{
            backgroundColor: Colors.hazeLightest,
          }}
        >
          <BubbleIcon
            bubbleColor="inkLightest"
            iconColor="ink"
            name="document"
          />
          <P2
            color="ink"
            style={{
              fontWeight: 'bold',
            }}
          >
            {textPrimary}
          </P2>
          {textSecondary && (
            <div>
              <P3 color="inkLight">{textSecondary}</P3>
            </div>
          )}
        </div>
      );
    } else {
      return (
        <p className={cx('ink mt0 mb4', classes && classes)}>{textPrimary}</p>
      );
    }
  }
};

export default TransactionList;
