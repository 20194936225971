import { useContext, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { v4 as uuidv4 } from 'uuid';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { ApolloError, useMutation } from '@apollo/client';
import { H4, Notification } from '@missionlane/compass-ui';
import { Navigate, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PaymentDisplay from './PaymentDisplay';
import { PaymentDateId } from './types';
import { MAKE_IMMEDIATE_PAYMENT, SCHEDULE_PAYMENT } from './gql/mutations';
import { UPCOMING_PAYMENTS_QUERY } from './gql/queries';
import { PaymentMessage } from './PaymentMessage';
import { useMakePayment } from './MakePaymentContext';
import { PaymentConfirmationButtons } from './PaymentConfirmationButtons';
import { EditPaymentLink } from '@payments/components/Payments/EditPaymentLink';
import { AccountInformationContext } from '@core/components/AccountInformation/AccountInformationProvider';
import {
  AccountType,
  MakeImmediatePaymentMutation,
  SchedulePaymentMutation,
  Source,
  TransactionMethod,
} from '@core/graphql/globalTypes';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { ACTIVITIES_QUERY } from '@core/components/RecentActivityKard/RecentActivityKard';
import { useAccount } from '@core/components/Auth/AccountContext';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';
import { ClipOfferUiStates } from '@clip/ClipProgress/hooks/types/ClipOffer';
import {
  GET_PAYMENT_STATUS,
  usePaymentStatus,
} from '@payments/hooks/usePaymentStatus';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLError } from '@core/services';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import PageWrapper from '@core/components/Page/PageWrapper';
import { getUTC } from '@core/utils/timezones';

dayjs.extend(isSameOrAfter);

const PaymentConfirmation = () => {
  const { accountId } = useAccount();
  const summaryPath = useAccountSummaryPath();
  const { lm7537EnablePaymentSuccessScreen } = useFlags();
  const { trackError } = useTracking();
  const {
    paymentValues: {
      amount,
      amountId,
      fundingAccount,
      last4,
      paymentDate,
      paymentDateId,
      isFormFilled,
    },
    updatePaymentValues,
    isChargedOff,
  } = useMakePayment();

  const { minimumDue } = usePaymentStatus();
  const { uiState, currentPaymentStreak } = useClipOffer();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [paymentsError, setPaymentsError] = useState<ApolloError>();
  const { enableRefresh } = useContext(AccountInformationContext);
  const navigate = useNavigate();
  const isImmediatePayment = paymentDateId === PaymentDateId.immediately;
  const selectedFundingAccount = fundingAccount;
  const formattedPaymentDate = getUTC(paymentDate).format('YYYY-MM-DD');

  const idempotencyKey = uuidv4();

  //restart the flow if no payment context does not exist. This can occur on page refresh.
  useEffect(() => {
    const paymentContextExists = !!selectedFundingAccount?.id && amount !== 0;
    if (!paymentContextExists) {
      navigate('../');
    }
  }, []);

  const [makePayment, { error: immediatePaymentError }] =
    useMutation<MakeImmediatePaymentMutation>(MAKE_IMMEDIATE_PAYMENT, {
      variables: {
        accountId,
        amount: amount,
        fundingAccountId: selectedFundingAccount?.id,
        idempotencyKey,
        txMethod: TransactionMethod.Ach,
        source: Source.Web,
      },
      refetchQueries: [
        { query: UPCOMING_PAYMENTS_QUERY, variables: { accountId } },
        { query: ACTIVITIES_QUERY, variables: { accountId } },
        { query: GET_PAYMENT_STATUS, variables: { accountId } },
      ],
      onCompleted: () => enableRefresh?.(),
    });

  const [createScheduledPayment, { error: scheduledPaymentError }] =
    useMutation<SchedulePaymentMutation>(SCHEDULE_PAYMENT, {
      variables: {
        accountId,
        source: Source.Web,
        fundingAccountId: selectedFundingAccount?.id,
        paymentType: amountId,
        accountType: AccountType.CreditCard,
        amount: amount,
        paymentDate: formattedPaymentDate,
      },
      refetchQueries: [
        { query: UPCOMING_PAYMENTS_QUERY, variables: { accountId } },
      ],
    });

  useEffect(() => {
    const error = immediatePaymentError || scheduledPaymentError;
    if (error) {
      error.message =
        "Sorry, we're having some tech trouble. Please try again.";
      setPaymentsError(error);
      if (immediatePaymentError) {
        trackError({
          name: 'Immediate ACH Payment Failure',
          feature: 'Make ACH Payment',
          error: {
            code: 'PAY0001',
            message: immediatePaymentError.message,
            name: 'Immediate ACH Payment Failure',
          },
        });
      }
      if (scheduledPaymentError) {
        trackError({
          name: 'Scheduled ACH Payment Failure',
          feature: 'Make ACH Payment',
          error: {
            code: 'PAY0002',
            message: scheduledPaymentError.message,
            name: 'Scheduled ACH Payment Failure',
          },
        });
      }
    }
  }, [immediatePaymentError, scheduledPaymentError]);

  const handleSubmit = async () => {
    setDisabled(true);
    try {
      if (isImmediatePayment) {
        await makeImmediatePayment();
      } else {
        await makeScheduledPayment();
      }
    } catch (error) {
      if (error instanceof Error)
        MLError.report({
          name: error.name,
          error,
        });

      setDisabled(false);
    }
  };

  const makeImmediatePayment = async () => {
    const paymentResponse = await makePayment();
    if (paymentResponse.data) {
      updatePaymentValues({
        reference: paymentResponse.data.makePayment.reference,
        paymentDate: paymentResponse.data.makePayment.date,
      });

      if (
        (uiState === ClipOfferUiStates.STREAK ||
          uiState === ClipOfferUiStates.TEST) &&
        currentPaymentStreak !== null &&
        amount &&
        minimumDue &&
        amount >= minimumDue &&
        lm7537EnablePaymentSuccessScreen
      ) {
        navigate('../payment-success-streak');
      } else {
        navigate('../payment-submitted');
      }
    }
  };

  const makeScheduledPayment = async () => {
    const paymentResponse = await createScheduledPayment();
    if (paymentResponse.data) {
      updatePaymentValues({
        reference: paymentResponse.data.createScheduledPayment.confirmationId,
        paymentDate: paymentDate,
      });
      navigate('../payment-submitted');
    }
  };

  const handleEdit = () => navigate('..');

  if (!isFormFilled) {
    return <Navigate to=".." />;
  }

  if (disabled) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Make a payment', useLast4: true }}
      trackingProperties={{
        featureName: 'Payments: Make Payment',
        pageName: 'Make a Payment: Confirm',
      }}
    >
      <div className="ph3 ph0-l">
        <H4>Review Payment Details</H4>
        <PaymentDisplay
          amount={amount}
          fundingAccount={selectedFundingAccount}
          isImmediate={isImmediatePayment}
          paymentDate={paymentDate}
          className="mv5"
        />
        <div className="mb5 nt2 ml4">
          <EditPaymentLink onClick={handleEdit} />
        </div>
        <PaymentMessage
          cardLast4={last4 || ''}
          isImmediatePayment={isImmediatePayment}
          paymentDate={paymentDate}
        />
        {paymentsError && (
          <div className="mb4">
            <Notification variant="inline" level="error">
              {paymentsError.message}
            </Notification>
          </div>
        )}
        <PaymentConfirmationButtons
          text={isImmediatePayment ? 'Submit Payment' : 'Schedule Payment'}
          onSubmit={handleSubmit}
          onCancel={() => {
            if (isChargedOff) {
              navigate(summaryPath);
            } else {
              navigate('../../..');
            }
          }}
          disabled={disabled}
          leftAlign
        />
      </div>
    </PageWrapper>
  );
};

export default PaymentConfirmation;
