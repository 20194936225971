import { P3, Spacer } from '@missionlane/compass-ui';
import Modal from '@core/components/General/Modal/Modal';

export const COPY = {
  TITLE: 'More Details',
  P1: 'To increase your chances of getting a credit limit increase, you must use your card, make your first six monthly payments on time and keep your account in good standing, such as by not being delinquent, over the credit limit, or in a restricted status.',
  P2: 'Additional credit requirements apply to be considered for a credit limit increase, including maintaining your ability to pay.',
};

const OfferDetailsModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal
      headerText={COPY.TITLE}
      onClose={onClose}
      isOpen={isOpen}
      maxWidth="640px"
      primaryButton={{
        onPress: onClose,
        text: 'Done',
      }}
    >
      <P3>{COPY.P1}</P3>
      <Spacer size="l" />
      <P3>{COPY.P2}</P3>
    </Modal>
  );
};

export default OfferDetailsModal;
