import { ColorNames } from '@missionlane/compass-ui';
import { PropsWithChildren } from 'react';
import { Last4PageTitle } from './Last4PageTitle';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

type BaseTitle = {
  primaryText: string;
  secondaryText?: string;
  useLast4?: true;
};

export interface DefaultTitle extends BaseTitle {
  useLast4?: never;
}
export interface TitleWithLast4 extends BaseTitle {
  secondaryText?: never;
  useLast4: true;
}

export type Title = DefaultTitle | TitleWithLast4;

export interface PageTitleProps {
  title: string | Title;
}

const PageTitle = ({ title }: PageTitleProps) => {
  if (typeof title === 'string') {
    return (
      <PageTitleWrapper>
        <PageTitleText color="pine">{title}</PageTitleText>
      </PageTitleWrapper>
    );
  }
  const { primaryText, secondaryText, useLast4 } = title;

  if (useLast4) {
    return <Last4PageTitle primaryText={primaryText} />;
  }

  return (
    <PageTitleWrapper>
      <PageTitleText color="pine">{primaryText}</PageTitleText>
      {secondaryText && (
        <PageTitleText color="green">&nbsp;{secondaryText}</PageTitleText>
      )}
    </PageTitleWrapper>
  );
};

export const PageTitleWrapper = ({ children }: PropsWithChildren) => (
  <div className="flex flex-wrap mb3 mb4-ns">{children}</div>
);

export const PageTitleText = ({
  color,
  children,
}: PropsWithChildren<{ color: ColorNames }>) => (
  <ResponsiveTypography
    color={color}
    type="HEADER"
    mobileLevel="H2"
    desktopLevel="H3"
  >
    {children}
  </ResponsiveTypography>
);

export default PageTitle;
