import { Icon, P3 } from '@missionlane/compass-ui';
import { DynamicAccountSummaryKardProps } from './DynamicAccountSummaryKard';
import { formatCardName } from '@core/utils/cardArt';

interface DynamicCardArtHeaderProps
  extends Pick<
    DynamicAccountSummaryKardProps,
    'cardName' | 'last4' | 'isLocked' | 'onUnlock'
  > {
  isLightTheme: boolean;
  showManageCard: boolean;
}

const DynamicCardArtHeader = ({
  cardName,
  last4,
  onUnlock,
  isLocked,
  isLightTheme,
  showManageCard,
}: DynamicCardArtHeaderProps) => {
  return (
    <div className="flex justify-between items-center">
      <div>
        <P3 color={isLightTheme ? 'ink' : 'white'}>
          {formatCardName(cardName)}
        </P3>
        <P3 color={isLightTheme ? 'green' : 'white'}>
          {last4 ? `...${last4}` : ''}
        </P3>
      </div>
      {isLocked && showManageCard && (
        <div
          onClick={onUnlock}
          className={`${isLightTheme ? 'bg-washed-green-ns' : 'bg-white'} pa1 h2 w2 br-100 flex justify-center items-center`}
        >
          <Icon name="lock" color="ink" />
        </div>
      )}
    </div>
  );
};

export default DynamicCardArtHeader;
