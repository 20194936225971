import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { gql, useMutation } from '@apollo/client';
import { P2, P3, Notification } from '@missionlane/compass-ui';
import { UPCOMING_PAYMENTS_QUERY } from '../MakePayment/gql/queries';
import { useCancelPayment } from './CancelPaymentContext';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import PaymentDisplay from '@payments/components/MakePayment/PaymentDisplay';
import { canCancelPayment } from '@payments/utils/canCancelPayment';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { CancelPaymentQuery } from '@core/graphql/globalTypes';
import { FUNDING_ACCOUNT_FIELDS } from '@payments/graphql/fundingAccountQueries';
import { useAccount } from '@core/components/Auth/AccountContext';
import PageWrapper from '@core/components/Page/PageWrapper';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

export const CANCEL_PAYMENT_QUERY = gql`
  query CancelPayment($accountId: String!) {
    account(accountId: $accountId) {
      id
      upcomingPayments {
        amount
        date
        description
        fundingAccount {
          ...FundingAccountFields
        }
        id
        reference
        state
        type
      }
      cardDetails {
        last4
      }
    }
  }
  ${FUNDING_ACCOUNT_FIELDS}
`;

export const CANCEL_SCHEDULED_PAYMENT = gql`
  mutation CancelScheduled($scheduleId: String!) {
    cancelScheduledPayment(scheduleId: $scheduleId) {
      amount
      id
      fundingAccount {
        id
        bankName
        numberLast4
      }
      nextPaymentDate
      confirmationId
    }
  }
`;

const CancelPaymentConfirmation = () => {
  const { paymentToCancel, setPaymentToCancel } = useCancelPayment();
  const [displayError, setDisplayError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { accountId } = useAccount();
  const { id } = useParams<{ id?: string }>();

  const { data, error, loading } =
    useAccountIdQuery<CancelPaymentQuery>(CANCEL_PAYMENT_QUERY);

  const [cancelScheduledPayment] = useMutation(CANCEL_SCHEDULED_PAYMENT, {
    variables: { scheduleId: id },
    refetchQueries: [
      { query: UPCOMING_PAYMENTS_QUERY, variables: { accountId } },
    ],
    onCompleted: (cancelledPayment) => {
      setPaymentToCancel({
        ...paymentToCancel,
        confirmationId: cancelledPayment.cancelScheduledPayment.confirmationId,
      });
      navigate('success');
    },
    onError: () => {
      setDisplayError('Cancellation unsuccessful, please try again later.');
    },
  });

  const payment = data?.account?.upcomingPayments.find(
    (upcomingPayment) => upcomingPayment.id === id,
  );

  const handleSubmit = async () => {
    if (payment ? !canCancelPayment(payment) : true) {
      setDisplayError(
        'Cannot cancel payment past 11:59 p.m. ET one day before it’s scheduled',
      );
      return;
    }

    setPaymentToCancel({
      id: id,
      amount: payment?.amount,
      fundingAccount: payment?.fundingAccount,
      date: date,
    });

    cancelScheduledPayment();
  };

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  if (error || !payment) {
    return <GenericFallbackFullPage />;
  }

  const { amount, date, fundingAccount } = payment;

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Scheduled Payments', useLast4: true }}
      trackingProperties={{
        featureName: 'Payments: Cancel Payment',
        pageName: 'Cancel Payment: Confirm',
      }}
    >
      <ResponsiveTypography
        className="mb2"
        type="HEADER"
        mobileLevel="H3"
        desktopLevel="H4"
      >
        Are you sure?
      </ResponsiveTypography>
      <P2 style={{ margin: 0 }}>
        Please review and confirm that you want to cancel this scheduled
        payment.
      </P2>
      <PaymentDisplay
        amount={amount}
        paymentDate={date}
        fundingAccount={fundingAccount}
        className="ml0 mv4"
      />
      <div className="mb2">
        <P3 style={{ margin: 0 }}>
          Payments can be canceled up until 11:59 p.m. ET one day before payment
          date.
        </P3>
      </div>
      {displayError && (
        <Notification variant="inline" level="error">
          {displayError}
        </Notification>
      )}
      <PaymentConfirmationButtons
        text="Yes, Cancel Payment"
        cancelButtonText="Go Back"
        onSubmit={handleSubmit}
        onCancel={() => navigate('..')}
        critical
        leftAlign
      />
    </PageWrapper>
  );
};

export default CancelPaymentConfirmation;
