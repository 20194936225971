import { B, P3 } from '@missionlane/compass-ui';

const CashAdvanceText = () => (
  <P3>
    Your PIN is <B color="ink">only</B> for cash advances. You don’t need it to
    make purchases.
  </P3>
);

export default CashAdvanceText;
