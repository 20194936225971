import { I, List, ListItem, P3 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import gql from 'graphql-tag';
import PageWrapper from '../Page/PageWrapper';
import { LoadingSpinnerPage } from '../General/LoadingSpinner';
import { FEATURE_NAME } from './constants';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useTracking } from '@core/services/TrackService/useTracking';
import { ManageCardPageQuery } from '@core/graphql/globalTypes';

const MANAGE_CARD_PAGE_QUERY = gql`
  query ManageCardPage($accountId: String!) {
    account(accountId: $accountId) {
      id
      isEligibleForActivation
      statuses {
        canModifyPin
      }
      cardDetails {
        last4
        cardLockStatus {
          isLocked
          isEligible
        }
      }
    }
  }
`;

export const ManageCard = () => {
  const navigate = useNavigate();
  const { trackClick } = useTracking();
  const { showModifyPin } = useFlags();
  const { data, loading } = useAccountIdQuery<ManageCardPageQuery>(
    MANAGE_CARD_PAGE_QUERY,
    { fetchPolicy: 'cache-and-network' },
  );

  const handleSelection = (id: 'LockCard' | 'ChangePIN' | 'ActivateCard') => {
    switch (id) {
      case 'LockCard':
        trackClick({ name: 'Lock card', feature: FEATURE_NAME });
        return navigate('./lock-card');
      case 'ChangePIN':
        trackClick({ name: 'Change PIN', feature: FEATURE_NAME });
        return navigate('./modify-pin', { state: { referrer: FEATURE_NAME } });
      case 'ActivateCard':
        trackClick({ name: 'Activate card', feature: FEATURE_NAME });
        return navigate('../activate');
      default:
        return null;
    }
  };

  const canModifyPin = showModifyPin && data?.account?.statuses?.canModifyPin;
  const canLockCard = data?.account?.cardDetails?.cardLockStatus?.isEligible;
  // we display the activate card list item when the card is locked, even though
  // the card cannot actually be activated when it's locked. This way we can
  // prompt the user to unlock their card and activate.
  const canActivateCard =
    data?.account?.isEligibleForActivation ||
    data?.account?.cardDetails.cardLockStatus.isLocked;

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle="Manage Card"
      trackingProperties={{
        pageName: FEATURE_NAME,
        featureName: FEATURE_NAME,
      }}
    >
      <List variant="selectable" onChange={handleSelection}>
        {canActivateCard && (
          <ListItem
            id="ActivateCard"
            label="Activate card"
            description="Activate your card to begin using it."
            iconProps={{
              icon: 'lightbulb',
              iconColor: 'blue',
              rightIcon: 'forward',
              rightIconColor: 'blue',
            }}
          />
        )}
        {canLockCard && (
          <ListItem
            id="LockCard"
            label="Lock card"
            description="Prevent card from being used."
            iconProps={{
              icon: 'lock',
              iconColor: 'blue',
              rightIcon: 'forward',
              rightIconColor: 'blue',
            }}
          />
        )}
        {canModifyPin && (
          <ListItem
            id="ChangePIN"
            label="Change PIN"
            description="Change your PIN for cash advances."
            iconProps={{
              icon: 'key',
              iconColor: 'blue',
              rightIcon: 'forward',
              rightIconColor: 'blue',
            }}
          />
        )}
      </List>

      {!canModifyPin && !canLockCard && !canActivateCard && (
        <P3>
          <I>No card actions are available at this time.</I>
        </P3>
      )}
    </PageWrapper>
  );
};
