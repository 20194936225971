import cx from 'classnames';
import ResponsiveTypography from '../General/ResponsiveTypography';
interface ReferralsDataProps {
  referralsApproved: number;
  totalEarned: number;
  maximumStatementCredit: number;
  isIncentiveOffer: boolean;
}

const ReferralsData = ({
  referralsApproved,
  totalEarned,
  maximumStatementCredit,
  isIncentiveOffer,
}: ReferralsDataProps) => {
  return (
    <div className="mv4 flex flex-row justify-between">
      <div
        className={cx('w-50 mr2 pa3 bg-haze-lightest br3', {
          'w-30-ns': !isIncentiveOffer,
        })}
      >
        <ResponsiveTypography type="HEADER" mobileLevel="H4" desktopLevel="H5">
          Approved
          <br />
          Referrals
        </ResponsiveTypography>
        <ResponsiveTypography //TODO: Add dynamic data
          type="HEADER"
          mobileLevel="H2"
          desktopLevel="H3"
        >
          {referralsApproved}
        </ResponsiveTypography>
      </div>
      {isIncentiveOffer && (
        <div className="w-50 ml2 pa3 bg-haze-lightest br3">
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H4"
            desktopLevel="H5"
          >
            Total
            <br />
            Earned
          </ResponsiveTypography>
          <div className="flex flex-row items-center">
            <ResponsiveTypography //TODO: Add dynamic data
              type="HEADER"
              mobileLevel="H2"
              desktopLevel="H3"
            >
              ${totalEarned / 100}
            </ResponsiveTypography>
            <ResponsiveTypography
              type="HEADER"
              mobileLevel="H3"
              desktopLevel="H4"
              color="inkLight"
            >
              /${maximumStatementCredit / 100}
            </ResponsiveTypography>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralsData;
