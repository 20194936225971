import { Button, Link } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

const CreditProtectionIneligiblePage = () => {
  const navigate = useNavigate();

  const onClickReturnHome = () => {
    navigate('../summary');
  };

  const onClickContactLink = () => {
    window.open(process.env.REACT_APP_ZENDESK_URL, '_blank');
  };

  return (
    <>
      <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
        You’re ineligible to enroll in Mission Lane Credit Protection right now
      </ResponsiveTypography>
      <div className="mt3 mb4">
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P3"
        >
          We encourage you to check back later. If you have any questions,{' '}
          <Link onPress={onClickContactLink}>please reach out.</Link>
        </ResponsiveTypography>
      </div>
      <div className="flex-ns">
        <Button
          variant="contained"
          text="Return Home"
          onPress={onClickReturnHome}
          size="small"
        />
      </div>
    </>
  );
};

const CreditProtectionIneligiblePageWithWrapper = withPageWrapper(
  CreditProtectionIneligiblePage,
  {
    isSingleColumn: true,
    trackingProperties: { pageName: 'Credit Protection Ineligible' },
  },
);

export default CreditProtectionIneligiblePageWithWrapper;
