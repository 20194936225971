import { Routes, Route, Navigate } from 'react-router-dom';

import MyProfilePage from '../../MyProfile/MyProfilePage';
import { FeatureRoute } from './FeatureRoute';
import ChangeAddressFlow from '@core/components/ChangeAddress/ChangeAddressFlow';
import { ZendeskExternalRoute } from '@core/components/ExternalRoutes';
import MfaVerifyFlow from '@core/components/Auth/MFA/MfaVerifyFlow/MfaVerifyFlow';
import PlaidOAuth from '@payments/components/Plaid/PlaidOAuth';
import { EditFinancialInformation } from '@clip/IncomeUpdate';
import ChangePhonePage from '@core/components/ChangePhonePage/ChangePhonePage';
import PageNotFound from '@core/components/ErrorBoundary/PageNotFound';

import NavContainer from '@core/components/Navigation/NavContainer';
import CreditProtectionRedirect from '@core/components/CreditProtection/CreditProtectionRedirect';

export const CustomerRoutes = () => (
  <Routes>
    <Route element={<NavContainer />}>
      <Route path="verify" element={<FeatureRoute flag="showMfa" />}>
        <Route index element={<MfaVerifyFlow />} />
      </Route>
      <Route
        path="change-address"
        element={<FeatureRoute flag="showChangeAddress" />}
      >
        <Route index element={<ChangeAddressFlow />} />
      </Route>
      <Route
        path="change-phone"
        element={<FeatureRoute flag="showChangePhone" />}
      >
        <Route index element={<ChangePhonePage />} />
      </Route>
      <Route path="update-income" element={<EditFinancialInformation />} />

      <Route path="my-profile" element={<MyProfilePage />} />
    </Route>
    <Route path="funding-account-validation" element={<PlaidOAuth />} />
    <Route path="plaid-login-clip-oauth" element={<PlaidOAuth />} />
    <Route path="zendesk" element={<ZendeskExternalRoute />} />
    <Route
      path="credit-protection/redirect"
      element={<CreditProtectionRedirect />}
    />

    {/**
     * This route is meant to redirect to the home page when the user clicks on
     * the "my-progress" universal deep-link and do not have the app installed.
     **/}
    <Route path="my-progress" element={<Navigate replace to="/" />} />
    {/* This is no longer a valid url but can be accessed from old email links */}
    <Route path="katabat" element={<Navigate replace to="/" />} />
    {/**
     * Keep this route at the end of the list to catch all other routes
     **/}
    <Route path="/*" element={<PageNotFound />} />
  </Routes>
);
