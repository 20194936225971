import { useSettlementOffers } from '../utils/useSettlementOffers';
import PreOfferDashboard from './PreOfferDashboard';
import ExistingOfferDashboard from './ExistingOfferDashboard/ExistingOfferDashboard';
import { ExistingThirdPartyPlanDashboard } from './ExistingThirdPartyPlanDashboard';
import FulfilledPIFDashboard from './FulfilledPIFDashboard';
import FulfilledSIFDashboard from './FulfilledSIFDashboard';
import {
  SettlementOfferState,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import isNil from '@core/utils/isNil';

const ChargedOffDashboard = () => {
  const {
    activeOffer,
    isInKatabat,
    currentBalance,
    isInDSCProgram,
    loading,
    error,
  } = useSettlementOffers({
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <LoadingSpinner />;

  if (error || isNil(currentBalance)) {
    throw new Error('ChargedOffDashboard: Failed to fetch offer data');
  }

  let DashboardElement: React.JSX.Element;

  if (isInKatabat || isInDSCProgram) {
    DashboardElement = (
      <ExistingThirdPartyPlanDashboard isInKatabat={isInKatabat} />
    );
  } else if (activeOffer?.data.state === SettlementOfferState.Fulfilled) {
    if (
      activeOffer.data.subType === SettlementOfferSubtype.Pif ||
      currentBalance <= 0
    ) {
      DashboardElement = <FulfilledPIFDashboard />;
    } else {
      DashboardElement = <FulfilledSIFDashboard />;
    }
  } else if (
    activeOffer?.data.state === SettlementOfferState.InProgress ||
    activeOffer?.data.state === SettlementOfferState.PendingCloseOut
  ) {
    DashboardElement = <ExistingOfferDashboard offer={activeOffer} />;
  } else {
    DashboardElement = <PreOfferDashboard />;
  }

  return DashboardElement;
};

export default ChargedOffDashboard;
