import Lockup from '@core/components/General/Lockup';
import PageWrapper from '@core/components/Page/PageWrapper';

const Loading = () => <Lockup id="loading">Loading...</Lockup>;

export const LoadingPage = () => (
  <PageWrapper>
    <Loading />
  </PageWrapper>
);

export default Loading;
