import type { FormState } from 'react-hook-form';
import { Link, Notification, P3 } from '@missionlane/compass-ui';
import HookFormTextField from '../../../../Form/HookFormTextField/HookFormTextField';
import { OtpFactor } from '../../../../../services/RiskAuthService';
import type { FormValues } from './VerifyEnterCode';
import { ResponsiveButton } from '@core/components/Button';
import { TrackService } from '@core/services';

export interface VerifyEnterCodeFormProps {
  onSubmit: () => void;
  resendCode: () => void;
  formState: Pick<FormState<FormValues>, 'errors' | 'isSubmitting' | 'isValid'>;
  selectedOtpFactor: OtpFactor | undefined;
  errorMessage: string | undefined;
  resendCodeSuccessMessage: string | undefined;
}

export const VerifyEnterCodeForm = ({
  formState,
  resendCode,
  onSubmit,
  errorMessage,
  selectedOtpFactor,
  resendCodeSuccessMessage,
}: VerifyEnterCodeFormProps) => {
  const { errors: formErrors, isSubmitting, isValid } = formState;
  const otpMethod = selectedOtpFactor?.factorType === 'sms' ? 'SMS' : 'Voice';

  const handleButtonPress = () => {
    TrackService.click('Verify Code');
    onSubmit();
  };

  return (
    <div>
      <div className="mb2">
        <HookFormTextField
          name={'otp'}
          label={`Enter verification code sent via ${otpMethod}`}
          error={formErrors.otp?.message}
        />
      </div>
      <P3>
        Didn’t recieve a code?&nbsp;
        <Link onPress={resendCode}>Resend Code</Link>
      </P3>
      {errorMessage && (
        <div className="mt4">
          <Notification level="error" variant="inline">
            {errorMessage}
          </Notification>
        </div>
      )}
      {resendCodeSuccessMessage && (
        <div className="mt4">
          <Notification level="success" variant="inline">
            {resendCodeSuccessMessage}
          </Notification>
        </div>
      )}
      <div className="mt5">
        <ResponsiveButton
          text="Verify Code"
          onPress={handleButtonPress}
          loading={isSubmitting}
          disabled={!isValid}
          variant="contained"
        />
      </div>
    </div>
  );
};
