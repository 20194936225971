import { useRef, useState, useCallback, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { gql } from '@apollo/client';
import { H3, Button, Notification } from '@missionlane/compass-ui';
import { useOktaAuth } from '@okta/okta-react';
import {
  FieldType,
  InputMode,
  SecureForm,
  Validation,
  ValidationErrorOverrides,
} from '../SecureForm';
import type { SecureFormProps } from '../SecureForm';
import { MLError, TrackService } from '../../services';
import CashAdvanceText from './CashAdvanceText';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { cardSupportPhone } from '@core/utils/contact';
import ApiErrors from '@core/utils/ApiErrors';
import { ModifyPinMutationVariables } from '@core/graphql/globalTypes';

const modifyPINMutation = gql`
  mutation ModifyPIN($accountId: String!, $pin: String!) {
    modifyPIN(accountId: $accountId, pin: $pin) {
      success
    }
  }
`;

export const rejectionMessage = {
  cookieMissing: `We couldn't change your PIN.\n\nPlease check your browser settings and make sure that third-party cookies are enabled or allowed. If the error continues, give us a call at ${cardSupportPhone}.`,
  default: 'Something went wrong. Try again with a different PIN.',
  invalid:
    "Your PIN can't consist of only one number (like 2222) or 4 consecutive numbers (like 1234 or 4321).",
  pinMatchCardNumberOrDate:
    "We couldn't change your PIN. Please make sure your PIN doesn't match any consecutive digits within your card number or expiration date.",
  pinMismatch: "This doesn't match the new PIN you entered.",
  unknown:
    'There was an unknown error while updating your PIN, please try again.  If you continue to experience this issue send us a message.',
  technical:
    'We ran into a technical issue while updating your PIN, please try again.  If you continue to experience this issue send us a message.',
};

const combinedPINValidator =
  /^(?!0123|1234|2345|3456|4567|5678|6789|7890|0987|9876|8765|7654|6543|5432|4321|3210|0000|1111|2222|3333|4444|5555|6666|7777|8888|9999)\d{4}$/;

const numericOnlyReplacePattern = '/[^0-9]+/g';

enum Field {
  PIN = 'PIN',
  ConfirmPIN = 'ConfirmPIN',
}

const modifyPINFormConfig: SecureFormProps['formConfig'] = {
  [Field.PIN]: {
    ariaLabel: 'New PIN',
    autoFocus: true,
    hideValue: true,
    // Does not work when hideValue is toggled, open issue here:
    // https://github.com/verygoodsecurity/vgs-collect-js/issues/48
    inputMode: InputMode.Numeric,
    maxLength: 4,
    label: 'New PIN',
    replacePattern: [numericOnlyReplacePattern],
    type: FieldType.Text,
    validations: [Validation.Required, `${combinedPINValidator}`],
  },
  [Field.ConfirmPIN]: {
    ariaLabel: 'Confirm New PIN',
    hideValue: true,
    inputMode: InputMode.Numeric, // See comment above
    maxLength: 4,
    label: 'Confirm New PIN',
    replacePattern: [numericOnlyReplacePattern],
    type: FieldType.Text,
    validations: [
      {
        type: Validation.Compare,
        params: {
          field: 'PIN',
          function: 'match',
        },
      },
    ],
  },
};

const validationErrorMessageOverrides: ValidationErrorOverrides = {
  [Validation.Regex]: rejectionMessage.invalid,
  [Validation.Compare]: rejectionMessage.pinMismatch,
};

export const ModifyPINForm = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { authState } = useOktaAuth();
  const { accountId, customerId } = useCustomerAndAccountIdentifiers();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isDisabled, setIsDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef<SecureForm>(null);
  const apiErrorRef = useRef<HTMLDivElement>(null);

  const handleUpdate = useCallback(() => {
    setIsDisabled(!formRef.current?.isReady || !formRef.current?.isValid);
  }, [formRef]);

  const handleSubmit = useCallback(() => {
    TrackService.click('Change PIN - Confirm button', {
      customerId,
      accountId,
    });
    setIsSubmitting(true);
    setErrorMessage('');
    formRef.current?.submit<ModifyPinMutationVariables>({
      mutation: modifyPINMutation,
      variables: (formValues) => ({
        accountId,
        pin: formValues[Field.PIN],
      }),
      onSuccess: (status, response) => {
        const { errors, error, data } = response;
        setIsSubmitting(false);
        if (data?.modifyPIN?.success) {
          navigate('.?success=true', {
            state: {
              prevPath: location.pathname,
              referrer: location.state?.referrer,
            },
          });
        } else {
          formRef.current?.reset?.();
          formRef.current?.focusOn(Field.PIN);
          if (error || errors?.length) {
            const apiErrorMessage = error?.message || errors?.[0].message;
            if (apiErrorMessage === rejectionMessage.pinMatchCardNumberOrDate) {
              TrackService.track({
                event:
                  'PIN Contains Numbers in Card Number and Expiration Date',
                properties: {
                  error: apiErrorMessage,
                  user: {
                    accountId,
                    customerId,
                  },
                },
              });
              setErrorMessage(apiErrorMessage);
            } else {
              MLError.report({
                name: 'Modify PIN - Error Returned',
                message: `status code: ${status} - ${JSON.stringify({
                  error,
                  errors,
                })}`,
              });
              TrackService.track({
                event: 'Generic invalid PIN attempt',
                properties: {
                  error: apiErrorMessage,
                  user: {
                    accountId,
                    customerId,
                  },
                },
              });
              setErrorMessage(rejectionMessage.technical);
            }
          } else if (
            parseInt(status) === 401 &&
            (response as unknown as string) === 'Jwt is missing'
          ) {
            MLError.report({
              name: 'Modify PIN - No Error Returned',
              message: `Status Code: ${status} - PIN failed, no error thrown - isAuthenticated: ${
                authState?.isAuthenticated
              } - Okta Error: ${JSON.stringify(
                authState?.error,
              )} - Response: ${response}`,
            });
            TrackService.track({
              event: 'Invalid PIN attempt, no error thrown',
              properties: {
                error: 'PIN failed, no error thrown',
                user: {
                  accountId,
                  customerId,
                },
              },
            });
            setErrorMessage(rejectionMessage.cookieMissing);
          } else {
            MLError.report({
              name: 'Modify PIN - No Error Returned',
              message: `Status Code: ${status} - PIN failed, no error thrown - isAuthenticated: ${
                authState?.isAuthenticated
              } - Okta Error: ${JSON.stringify(
                authState?.error,
              )} - Response: ${response}`,
            });
            TrackService.track({
              event: 'Invalid PIN attempt, no error thrown',
              properties: {
                error: 'PIN failed, no error thrown',
                user: {
                  accountId,
                  customerId,
                },
              },
            });
            setErrorMessage(rejectionMessage.unknown);
          }
        }
      },
      onError: (errors) => {
        setIsSubmitting(false);
        MLError.report({
          name: 'Modify PIN - onError',
          message: JSON.stringify(errors),
        });
        TrackService.track({
          event: 'Generic invalid PIN attempt',
          properties: {
            error: rejectionMessage.default,
            user: {
              accountId,
              customerId,
            },
          },
        });
        setErrorMessage(rejectionMessage.default);
      },
    });
  }, [formRef.current, authState]);

  useEffect(() => {
    if (errorMessage && apiErrorRef.current) {
      apiErrorRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }, [errorMessage]);

  return (
    <div className="flex flex-column mb5">
      <H3>Change PIN</H3>
      <CashAdvanceText />
      <SecureForm
        cName={process.env.REACT_APP_VGS_ACCOUNT_MANAGEMENT_CNAME}
        vaultId={process.env.REACT_APP_VGS_ACCOUNT_MANAGEMENT_VAULT_ID}
        formConfig={modifyPINFormConfig}
        onUpdate={handleUpdate}
        ref={formRef}
        validationErrorMessageOverrides={validationErrorMessageOverrides}
      />
      {errorMessage && !isSubmitting && (
        <div className="mt4" ref={apiErrorRef}>
          <Notification variant="inline" level="error">
            <ApiErrors
              error={errorMessage}
              linkText=" send us a message."
              phone={cardSupportPhone}
              url="https://support.missionlane.com/hc/en-us/requests/new"
            />
          </Notification>
        </div>
      )}
      <div className="flex flex-column mt4 justify-start-ns items-center-ns flex-row-ns">
        <div className="mr3-ns">
          <Button
            text="Confirm"
            onPress={handleSubmit}
            loading={isSubmitting}
            disabled={isDisabled}
          />
        </div>
        <Button
          text="Cancel"
          variant="text"
          onPress={() => {
            TrackService.click('Modify PIN Form: Cancel Button', {
              customerId,
              accountId,
            });
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
};
