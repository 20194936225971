import { useMutation } from '@apollo/client';
import { useEffect } from 'react';
import { CREATE_OFFER_FLOW } from '../gql/mutations';
import {
  CreateOfferFlowAction,
  CreateOfferFlowMutation,
  CreateOfferFlowMutationVariables,
} from '@core/graphql/globalTypes';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';

interface Props {
  flowId?: string;
  onOfferGenerated?: () => void;
}

export const useOfferQuestions = ({ onOfferGenerated, flowId }: Props) => {
  const { accountId } = useCustomerAndAccountIdentifiers();
  const [getOfferFlow, { loading, error, data }] = useMutation<
    CreateOfferFlowMutation,
    CreateOfferFlowMutationVariables
  >(CREATE_OFFER_FLOW);
  const {
    canBacktrack,
    question: currentQuestion,
    sessionId,
  } = data?.createOfferFlow || {};

  // Start the flow by getting the first question
  useEffect(() => {
    if (accountId && flowId) {
      getQuestionOrOffer({
        flowId,
        accountId,
        sessionId: null,
        answer: null,
        action: null,
      });
    }
  }, [accountId]);

  const getQuestionOrOffer = (variables: CreateOfferFlowMutationVariables) => {
    getOfferFlow({
      variables,
      onCompleted: (offerData) => {
        if (offerData?.createOfferFlow?.offer) {
          onOfferGenerated?.();
        }
      },
    });
  };

  const submitAnswer = (answer: string) => {
    if (flowId && accountId && sessionId) {
      getQuestionOrOffer({
        flowId,
        accountId,
        sessionId,
        answer,
        action: null,
      });
    }
  };

  const handleBacktrack = () => {
    if (canBacktrack && flowId && accountId && sessionId) {
      getQuestionOrOffer({
        flowId,
        accountId,
        sessionId,
        action: CreateOfferFlowAction.Backtrack,
        answer: null,
      });
    }
  };

  return {
    currentQuestion,
    handleBacktrack,
    canBacktrack,
    submitAnswer,
    loading,
    error,
  };
};
