import { Notification, Spacer, H4 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PaymentMessage } from '../PaymentMessage';
import PaymentDisplay from '../PaymentDisplay';
import { PaymentConfirmationButtons } from '../PaymentConfirmationButtons';
import { PaymentMethodName } from '@payments/components/Pay/Pay';
import { useTracking } from '@core/services/TrackService/useTracking';

interface PaymentReviewProps {
  amount: number;
  acctLast4?: string;
  handleSubmit: () => void;
  submissionError: string | null;
}

export const PaymentReview: React.FC<PaymentReviewProps> = ({
  amount,
  acctLast4,
  handleSubmit,
  submissionError,
}) => {
  const navigate = useNavigate();
  const { trackError } = useTracking();
  useEffect(() => {
    if (submissionError) {
      trackError({
        name: 'Debit Card Payment Failure',
        feature: 'Make Debit Card Payment',
        error: {
          code: 'PAY0003',
          message: submissionError,
          name: 'Debit Card Payment Failure',
        },
      });
    }
  }, []);

  return (
    <>
      <div className="ph3 ph0-l">
        <H4>Review Payment Details</H4>
        <PaymentDisplay
          amount={amount}
          isImmediate
          paymentMethod={PaymentMethodName.DEBIT_CARD}
          className="ml0 mv4"
        />
        {submissionError ?
          <Notification variant="inline" level="error">
            <span>{submissionError}</span>
          </Notification>
        : <PaymentMessage cardLast4={acctLast4 || ''} isImmediatePayment />}
        <Spacer size="xm" direction="vertical" />
        <PaymentConfirmationButtons
          text={submissionError ? 'Edit Payment Details' : 'Submit Payment'}
          onSubmit={handleSubmit}
          onCancel={() => {
            navigate('..');
          }}
        />
      </div>
    </>
  );
};
