import {
  SubmitCardPaymentFailure,
  SubmitCardPaymentResponse,
  SubmitCardPaymentSuccess,
} from './types';

/*
 * Since this request is a gql mutation, it will come back with a 200
 * as long as it was parsed by the gql server, even if the downstream
 * request had an error. Anything other than a 200 means the request
 * failed before it got to the gql server.
 */
const isValidStatus = (status: number | undefined): boolean =>
  status !== 200 && status !== undefined;

export const DEFAULT_CARD_ERROR_MESSAGE =
  'We had trouble processing your payment. Please make sure your information is correct, or check back later.';

export const isSubmitCardPaymentFailure = (
  response: SubmitCardPaymentResponse,
): response is SubmitCardPaymentFailure =>
  (response as SubmitCardPaymentFailure).errors !== undefined ||
  isValidStatus((response as SubmitCardPaymentFailure).status);

export const getSubmitCardFailureMessage = (
  response?: SubmitCardPaymentResponse,
) => {
  if (!response || isSubmitCardPaymentSuccess(response)) {
    return null;
  } else if (
    !!response &&
    isSubmitCardPaymentFailure(response) &&
    response.errors.length
  ) {
    return response.errors[0].message;
  } else {
    return DEFAULT_CARD_ERROR_MESSAGE;
  }
};

export const isSubmitCardPaymentSuccess = (
  response: SubmitCardPaymentResponse,
): response is SubmitCardPaymentSuccess =>
  (response as SubmitCardPaymentSuccess).id !== undefined;
