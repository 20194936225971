import OfferTransactions from '../OfferTransactions';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import PageWrapper from '@core/components/Page/PageWrapper';
import BackLink from '@core/components/General/BackLink';

const ReviewOfferTransactions = () => {
  const { activeOffer, loading } = useSettlementOffers();

  if (loading) return <LoadingSpinnerPage />;
  if (
    !activeOffer ||
    !activeOffer.data.paymentPlan?.transactions ||
    !activeOffer.data.paymentPlan.transactions.length
  )
    return <GenericFallbackFullPage />;

  return (
    <PageWrapper isSingleColumn>
      <BackLink className="pb3" />
      <OfferTransactions
        transactions={activeOffer?.data.paymentPlan.transactions}
      />
    </PageWrapper>
  );
};

export default ReviewOfferTransactions;
