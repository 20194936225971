import { useEffect } from 'react';
import dayjs from 'dayjs';

import track, { EventNames } from '../track';
import { OptOutModal } from '../OptOutModal';
import { TEST_ID, COPY } from './consts';
import { SpendBonusProgressBar } from '@clip/SpendBonus/components/SpendBonusProgressBar';
import { RewardType, SpendBonusStatus } from '@core/graphql/globalTypes';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import Kard from '@core/components/General/Kard/Kard';

import '../../assets/SpendBonus.css';

export const SpendBonusPending = () => {
  const spendBonusResult = useSpendBonus();

  useEffect(() => {
    track({
      eventName: EventNames.OfferViewed,
      status: SpendBonusStatus.Pending,
      rewardType: RewardType.Clip,
    });
  }, []);

  if (spendBonusResult.isEnabled === false) {
    return null;
  }

  const {
    showSpendBonusProgress,
    spendBonus: { dueDate },
  } = spendBonusResult;

  const formattedDueDate = dayjs(dueDate).format('MMMM D');

  return (
    <Kard
      header={{ textPrimary: COPY.TITLE_CLIP, level: 'H4' }}
      testID={TEST_ID.SPEND_BONUS__PENDING}
      className="mb3 w-100"
    >
      <p
        className="spend-bonus__message"
        data-testid={TEST_ID.SPEND_BONUS__PENDING__MESSAGE__CLIP}
      >
        Your account will be evaluated for a credit limit increase once this
        offer ends on {formattedDueDate}. Keep paying on time and keep your
        account in good standing to be eligible.
      </p>
      <br />
      {showSpendBonusProgress && <SpendBonusProgressBar />}
      <OptOutModal />
    </Kard>
  );
};
