import { MutableRefObject, useState } from 'react';
import { H4 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import { MakePaymentPaymentType } from '../types';
import { PaymentConfirmationButtons } from '../PaymentConfirmationButtons';
import PaymentAmount from '../PaymentAmount';
import { PaymentFormLayout } from '../PaymentFormLayout';
import BankCardDetails from '@payments/components/BankCardDetails';
import {
  MakePayment,
  PaymentType,
  UpcomingPayments,
} from '@core/graphql/globalTypes';
import { IBankCardDetails } from '@payments/components/BankCardDetails/types';
import { getmaxPaymentAmount } from '@payments/utils/maxPaymentAmount';

interface PaymentInputProps {
  setReviewing: (r: boolean) => void;
  paymentInfo: MakePayment.PaymentInfo;
  upcomingPayments?: UpcomingPayments.UpcomingPayments[];
  autopay: MakePayment.Autopay[];
  amount: number;
  paymentType: MakePaymentPaymentType;
  setAmountData: (p: PaymentType, a: number | null) => void;
  bankCardFormRef: MutableRefObject<IBankCardDetails | null>;
  statuses?: MakePayment.Statuses;
  currentBalance: number;
  error?: ApolloError;
  loading?: boolean;
}

export const PaymentInput = ({
  setReviewing,
  paymentInfo,
  upcomingPayments,
  autopay,
  amount,
  paymentType,
  setAmountData,
  bankCardFormRef,
  statuses,
  currentBalance,
  error,
  loading,
}: PaymentInputProps) => {
  const [cardIsValid, setCardIsValid] = useState<boolean>(false);
  const [amountIsValid, setAmountIsValid] = useState<boolean>(false);
  const navigate = useNavigate();
  /**
   * Set payment amount and type in parent, updating local
   * validity state as a side effect
   */
  const onAmountChange = (type: PaymentType, amt: number | null) => {
    setAmountIsValid(amt != null);
    setAmountData(type, amt);
  };

  const handleSubmit = () => setReviewing(true);

  return (
    <PaymentFormLayout
      autopay={autopay[0]}
      upcomingPayments={upcomingPayments}
      statuses={statuses}
      error={error}
      loading={loading}
    >
      <>
        <div>
          <div className="mb5">
            <H4>1. Select payment amount</H4>
          </div>
          <PaymentAmount
            paymentInfo={paymentInfo}
            onChange={onAmountChange}
            selected={paymentType}
            amount={amount}
            maxPaymentAmount={getmaxPaymentAmount(
              upcomingPayments,
              currentBalance,
            )}
            isImmediate={true}
          />
        </div>
        <div>
          <div className="mv5">
            <H4>2. Card Details</H4>
          </div>
          <BankCardDetails
            setValidCard={setCardIsValid}
            ref={bankCardFormRef}
            path="/credit/ui/graphql"
            cname={process.env.REACT_APP_VGS_COLLECT_PAYMENTS_CNAME}
          />
        </div>
        <div className="mt5">
          <PaymentConfirmationButtons
            disabled={!cardIsValid || !amountIsValid}
            onSubmit={handleSubmit}
            text="Review Payment"
            onCancel={() => {
              navigate('../..');
            }}
          />
        </div>
      </>
    </PaymentFormLayout>
  );
};
