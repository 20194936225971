import * as Yup from 'yup';
import { Form, Formik, FormikErrors } from 'formik';
import { useNavigate } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import { ApolloError, useQuery } from '@apollo/client';
import { H4, Notification } from '@missionlane/compass-ui';
import { BankAccountFlow } from '../BankAccount/AddBankAccount/AddAccount';
import { getmaxPaymentAmount } from '../../utils/maxPaymentAmount';
import {
  MakePaymentPaymentDateId,
  MakePaymentPaymentType,
  PaymentDateId,
} from './types';
import { UPCOMING_PAYMENTS_QUERY } from './gql/queries';
import { PaymentConfirmationButtons } from './PaymentConfirmationButtons';
import PaymentAmount from './PaymentAmount';
import PaymentDate from './PaymentDate';
import { PaymentFormLayout } from './PaymentFormLayout';
import { useMakePayment } from './MakePaymentContext';
import {
  NO_PAYMENT_AMOUNT,
  NO_PAYMENT_BANK,
  NO_PAYMENT_DATE,
} from '@core/utils/constants';
import {
  FundingAccounts,
  FundingAccountsQuery,
  MakePayment,
  PaymentType,
  UpcomingPaymentsQuery,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import { ACH_FUNDING_ACCOUNTS_QUERY } from '@payments/graphql/fundingAccountQueries';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import AccountPicker from '@payments/components/BankAccount/AccountPicker/AccountPicker';
import { getET } from '@core/utils/timezones';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';

const PaymentErrors = ({ errors }: { errors: FormikErrors<FormValues> }) => {
  const mappedErrors = Object.keys(errors).map(
    (errorKey) => errors[errorKey as keyof FormValues],
  );

  if (mappedErrors.length) {
    return (
      <div>
        {mappedErrors.map((error, key) => (
          <div className="mb4 tr" key={key}>
            <Notification level="error" variant="inline">
              {error}
            </Notification>
          </div>
        ))}
      </div>
    );
  }

  return null;
};

export const LESS_THAN_CURRENT_BALANCE = `Payment can't be greater than your current balance`;

export const LESS_THAN_MAX_ALLOWED = `Payment can't be $12,000.00 or greater`;

const DEFAULT_MAX_PAYMENT_AMOUNT = 1200000;

interface FormValues {
  amount: number;
  amountId: MakePaymentPaymentType;
  amountHelperText: string;
  customPaymentDate: string | null;
  fundingAccount: FundingAccounts.FundingAccounts | null;
  paymentDateId: MakePaymentPaymentDateId | null;
  paymentDate: string | null;
}

interface Props {
  showPaymentsOutage?: boolean;
  cardDetails?: MakePayment.CardDetails;
  paymentInfo?: MakePayment.PaymentInfo;
  loading: boolean;
  autopay?: MakePayment.Autopay;
  statuses?: MakePayment.Statuses;
}

const FundingAccountsError = () => {
  return (
    <GenericFallbackFull>
      <p className="lh-copy mb0">
        Sorry we're having trouble retrieving your payment methods, but our team
        is looking into it. Check back in a bit.
      </p>
    </GenericFallbackFull>
  );
};

const PaymentForm = ({
  showPaymentsOutage,
  loading,
  paymentInfo,
  autopay,
  cardDetails,
  statuses,
}: Props) => {
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const { trackError } = useTracking();
  const {
    paymentValues,
    updatePaymentValues,
    remainingMinDueValues: { isMinDueSchedulePrompt, optimisticMinDueAmount },
    isChargedOff,
  } = useMakePayment();
  const {
    data: upcomingPaymentsQueryData,
    loading: upcomingPaymentsQueryLoading,
    error: upcomingPaymentsQueryError,
  } = useAccountIdQuery<UpcomingPaymentsQuery>(UPCOMING_PAYMENTS_QUERY);
  const {
    data: fundingAccountsQueryData,
    loading: fundingAccountsQueryLoading,
    error: fundingAccountsQueryError,
  } = useQuery<FundingAccountsQuery>(ACH_FUNDING_ACCOUNTS_QUERY);

  const { upcomingPayments, balanceInfo } =
    upcomingPaymentsQueryData?.account || {};
  const upcomingPaymentsError =
    !upcomingPayments || !!upcomingPaymentsQueryError;
  const { fundingAccounts } = fundingAccountsQueryData || {};
  const fundingAccountsError = !fundingAccounts || fundingAccountsQueryError;

  const maxPaymentAmount =
    getmaxPaymentAmount(upcomingPayments, balanceInfo?.currentBalance || 0) ??
    DEFAULT_MAX_PAYMENT_AMOUNT;

  const validationSchema = Yup.object().shape({
    paymentDateId: Yup.string().nullable(),
    amount: Yup.number()
      .nullable()
      .required(NO_PAYMENT_AMOUNT)
      .when(['paymentDate'], (paymentDate) => {
        return dayjs(paymentDate).isSame(getET(), 'day') ?
            Yup.number()
              .moreThan(0, NO_PAYMENT_AMOUNT)
              .lessThan(
                (maxPaymentAmount ?? DEFAULT_MAX_PAYMENT_AMOUNT) + 1,
                LESS_THAN_CURRENT_BALANCE,
              )
          : Yup.number()
              .moreThan(0, NO_PAYMENT_AMOUNT)
              .lessThan(DEFAULT_MAX_PAYMENT_AMOUNT, LESS_THAN_MAX_ALLOWED);
      }),
    paymentDate: Yup.string().nullable().required(NO_PAYMENT_DATE),
    fundingAccount: Yup.object().nullable().required(NO_PAYMENT_BANK),
  });

  function onSubmit(values: FormValues) {
    updatePaymentValues({
      amount: values.amount,
      amountHelperText: paymentValues.amountHelperText || '',
      amountId:
        values.amountId === 'PAST_DUE_BALANCE' ?
          'ONE_TIME_FIXED'
        : values.amountId,
      cardName: cardDetails?.name || '',
      customPaymentDate: values.customPaymentDate,
      last4: cardDetails?.last4 || '',
      fundingAccount: values.fundingAccount,
      paymentDate: values.paymentDate,
      paymentDateId: values.paymentDateId,
      isFormFilled: true,
    });
    navigate('review-payment');
  }

  const isLoading =
    loading || upcomingPaymentsQueryLoading || fundingAccountsQueryLoading;

  if (isLoading) return <LoadingSpinner />;
  if (fundingAccountsError) {
    trackError({
      name: 'Failed to load funding accounts',
      feature: 'Make ACH Payment',
      error: {
        code: 'PAY0004',
        message:
          fundingAccountsError instanceof ApolloError ?
            fundingAccountsError.message
          : 'Failed to load funding accounts',
        name: 'Failed to load funding accounts',
      },
    });
    return <FundingAccountsError />;
  }

  return (
    <div className="bg-white nb6 mb7 ph0-l mt4">
      <Formik
        initialValues={{
          amount: paymentValues.amount || 0,
          amountHelperText: paymentValues.amountHelperText || '',
          amountId: paymentValues.amountId || null,
          customPaymentDate: paymentValues.customPaymentDate || null,
          fundingAccount:
            paymentValues.fundingAccount ||
            (fundingAccounts || []).find(
              (account: FundingAccounts.FundingAccounts) => account.isDefault,
            ) ||
            null,
          paymentDateId: paymentValues.paymentDateId || null,
          paymentDate: paymentValues.paymentDate || null,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {({ errors, values, setFieldValue, isValid, handleSubmit }) => (
          <PaymentFormLayout
            statuses={statuses}
            autopay={autopay}
            upcomingPayments={upcomingPayments}
            loading={upcomingPaymentsQueryLoading}
            error={upcomingPaymentsQueryError}
          >
            <Form>
              <div>
                <div className="mb5">
                  <H4>1. Select payment amount</H4>
                </div>
                <PaymentAmount
                  paymentInfo={paymentInfo || ({} as MakePayment.PaymentInfo)}
                  amount={values.amount}
                  onChange={(amountId: PaymentType, amount: number | null) => {
                    setFieldValue('amountId', amountId);
                    setFieldValue('amount', amount);
                  }}
                  selected={values.amountId}
                  maxPaymentAmount={maxPaymentAmount}
                  isImmediate={
                    values.paymentDateId === PaymentDateId.immediately
                  }
                  isMinDueSchedulePrompt={isMinDueSchedulePrompt}
                  optimisticMinDueAmount={optimisticMinDueAmount}
                />
              </div>
              <div>
                <div className="mv5">
                  <H4>2. Select a payment date</H4>
                </div>
                <PaymentDate
                  showWarning={
                    !upcomingPaymentsQueryLoading && !!upcomingPaymentsError
                  }
                  amountType={values.amountId}
                  paymentInfo={paymentInfo || ({} as MakePayment.PaymentInfo)}
                  upcomingPayments={upcomingPayments}
                  customPaymentDate={values.customPaymentDate}
                  immediatePaymentDate={getEarliestPaymentDate(
                    showPaymentsOutage,
                  ).format('YYYY-MM-DD')}
                  onChange={(
                    paymentDateId: FormValues['paymentDateId'],
                    paymentDate: string | null,
                  ) => {
                    setFieldValue('paymentDateId', paymentDateId);
                    setFieldValue('paymentDate', paymentDate);
                  }}
                  onCustomDateChange={(date: string) =>
                    setFieldValue('customPaymentDate', date)
                  }
                  selected={values.paymentDateId}
                  showPaymentsOutage={showPaymentsOutage}
                  isMinDueSchedulePrompt={isMinDueSchedulePrompt}
                />
              </div>
              <div>
                <div className="mv5">
                  <H4>3. Pay from</H4>
                </div>
                <AccountPicker
                  onChange={(fundingAccount) =>
                    setFieldValue('fundingAccount', fundingAccount)
                  }
                  fundingAccounts={fundingAccounts || []}
                  selectedId={values.fundingAccount?.id}
                  currentFlow={BankAccountFlow.MAKE_PAYMENT}
                />
              </div>
              <PaymentErrors errors={errors} />
              <div className="mt5">
                <PaymentConfirmationButtons
                  onSubmit={handleSubmit}
                  onCancel={() => {
                    if (isChargedOff) {
                      navigate(summaryPath);
                    } else {
                      navigate('../..');
                    }
                  }}
                  disabled={!isValid}
                  text="Review payment"
                  leftAlign
                />
              </div>
            </Form>
          </PaymentFormLayout>
        )}
      </Formik>
    </div>
  );
};

export default PaymentForm;

function getEarliestPaymentDate(showPaymentsOutage?: boolean): Dayjs {
  if (showPaymentsOutage) {
    return dayjs().add(1, 'day');
  }
  return dayjs();
}
