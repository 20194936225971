import { H4, Notification } from '@missionlane/compass-ui';
import { PaymentMethodName } from '../Pay/Pay';
import PaymentDisplay from './PaymentDisplay';
import { PaymentsSuccessButtons } from './PaymentsSuccessButtons';
import { FundingAccounts } from '@core/graphql/globalTypes';

interface PaymentSuccessContentProps {
  amount: number | undefined;
  className?: string;
  confirmationNumber: string | null | undefined;
  fundingAccount?: FundingAccounts.FundingAccounts | null;
  isImmediate?: boolean;
  isPaymentSuccess?: boolean;
  paymentDate?: string | null;
  paymentMethod?: PaymentMethodName;
}

const PaymentSuccessContent = ({
  amount,
  confirmationNumber,
  fundingAccount,
  isImmediate,
  paymentDate,
  paymentMethod,
}: PaymentSuccessContentProps) => (
  <>
    <div className="ph3 ph0-l">
      <H4>You're all set!</H4>
      <PaymentDisplay
        amount={amount}
        fundingAccount={fundingAccount}
        isImmediate={isImmediate}
        isPaymentSuccess={true}
        paymentDate={paymentDate}
        paymentMethod={paymentMethod}
        className="mv5"
      />
      <Notification level="success">
        Thanks for your payment! We've sent you an email which includes your
        confirmation number {confirmationNumber} and payment details.
      </Notification>
      <PaymentsSuccessButtons
        isDebitCardPayment={paymentMethod === PaymentMethodName.DEBIT_CARD}
      />
    </div>
  </>
);

export default PaymentSuccessContent;
