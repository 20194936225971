import { gql } from '@apollo/client';

export const getOnboardingProgressDetailsQuery = gql`
  query GetOnboardingProgressDetails($accountId: String!) {
    account(accountId: $accountId) {
      isEligibleForActivation
    }
    onboardingProgress(accountId: $accountId) {
      accountId
      hasMadePayment
      hasUsedCard
      isCardActivated
      isFundingAccountAdded
      isOlderThan90Days
    }
  }
`;
