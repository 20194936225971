import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import { OfferType } from './OfferType';
import {
  OfferAggregate,
  OffersQuery,
  SettlementOfferState,
} from '@core/graphql/globalTypes';

dayjs.extend(isSameOrAfter);
const activeSettlementOfferStatuses = [
  // Offer is pending customer accept...
  SettlementOfferState.NotReady, // ...and does not yet have a payment plan or funding account
  SettlementOfferState.Ready, // ...and does have a payment plan and funding account
  // Offer has been accepted, has payment plan in progress
  SettlementOfferState.InProgress,
  // Offer payment plan is complete
  SettlementOfferState.PendingCloseOut, // ...but we are waiting 2 weeks to validate no returned payments
  SettlementOfferState.Fulfilled,
];

const filterOffersByStatus = (
  offers?: OfferAggregate[] | null,
  states: SettlementOfferState[] = [],
) => {
  if (!offers) {
    return [];
  }

  return offers.filter(
    (offer) =>
      states.includes(offer.data?.state) &&
      offer.offerType === OfferType.Settlement,
  );
};

const hasOnlyCanceledOffers = (offers: OffersQuery['offers'] | undefined) => {
  if (!offers || !offers.length) return false;

  return offers.every(
    (offer) => offer.data?.state === SettlementOfferState.Canceled,
  );
};

export {
  filterOffersByStatus,
  hasOnlyCanceledOffers,
  activeSettlementOfferStatuses,
};
