import cx from 'classnames';
import dayjs from 'dayjs';
import { ReactNode } from 'react';
import { P3, P4 } from '@missionlane/compass-ui';

import { FooterNav } from './FooterNav';
import { FooterLogo } from './FooterLogo';
import { SocialMediaLinks } from './SocialMediaLinks';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';

/* Temporary font-family wrapper till we get this font in compass-ui */
const MonospaceText = ({ children }: { children: ReactNode }) => (
  <span className="monospace">{children}</span>
);

interface Props {
  contentClassName?: string;
}
const Footer = ({ contentClassName }: Props) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { isMobile } = useUserDevice();
  const ResponsiveParagraph = isMobile ? P3 : P4;
  return (
    <footer className="w-100 bg-pine">
      <div
        className={cx(
          'flex ph4',
          isMobile ? 'pv5 flex-column' : 'pv6',
          contentClassName,
        )}
      >
        <div className="flex flex-column">
          <FooterNav trackingProperties={customerAndAccountIds} />
          <ResponsiveParagraph color="white">
            <MonospaceText>
              &copy; {dayjs().format('YYYY')} Mission Lane LLC.{' '}
              <span className="nowrap">All Rights Reserved.</span>{' '}
              <span className="nowrap">NMLS #1857501</span>
            </MonospaceText>
          </ResponsiveParagraph>
          <div className="flex measure-wide">
            <ResponsiveParagraph color="white">
              <MonospaceText>
                Mission Lane LLC does business in Arizona under the trade name
                Mission Lane Card Services LLC.
              </MonospaceText>
            </ResponsiveParagraph>
          </div>
        </div>

        <div className={cx('tr ml-auto', isMobile && 'mt5')}>
          <FooterLogo trackingProperties={customerAndAccountIds} />
          <SocialMediaLinks />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
