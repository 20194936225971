import { useState } from 'react';
import { P3 } from '@missionlane/compass-ui';
import { FieldErrors } from 'react-hook-form';
import { AddressType } from './types';
import { UpdateAddressFormValues } from '@core/components/ChangeAddress/AddressForm/AddressForm';
import { HookFormTextField } from '@core/components/Form';

const addressAutocomplete = async (search: string) => {
  const searchComponent = `search=${encodeURIComponent(search)}`;
  const url = `https://us-autocomplete-pro.api.smartystreets.com/lookup?${searchComponent}&key=${process.env.REACT_APP_SMARTY_STREETS_TOKEN}`;
  const response = await fetch(url);
  const data = await response.json();

  return data;
};

interface AutocompleteProps {
  street1: string | undefined;
  onSelect: (address: AddressType) => void;
  errors: FieldErrors<UpdateAddressFormValues>;
}

const AddressAutocomplete = ({
  errors,
  onSelect,
  street1,
}: AutocompleteProps) => {
  const [items, setItems] = useState<Array<AddressType>>([]);

  const showSuggestions = street1?.length !== 0 && items?.length !== 0;

  const onChangeTextAsync = async (inputValue?: string) => {
    if (!inputValue) {
      setItems([]);
      return;
    }

    const { suggestions: smartySuggestions } =
      await addressAutocomplete(inputValue);
    if (smartySuggestions !== undefined) {
      setItems([...smartySuggestions]);
    } else {
      setItems([]);
    }
  };

  return (
    <div className="relative">
      <HookFormTextField
        onChangeText={onChangeTextAsync}
        name="street1"
        label="Street Address"
        error={errors?.street1?.message}
        testID="street1"
        placeholder=""
      />
      <div
        className={`hidden z-1 max-height-100 w-100 bw--1-5px b--washed-ink br bl absolute bg-white ${showSuggestions && 'bb'}`}
      >
        {showSuggestions &&
          items.map((item, index) => {
            const { street_line, secondary, city, state, zipcode } = item;
            const address = `${street_line} ${secondary} ${city} ${state} ${zipcode}`;

            const showBottomBorder = items.length - 1 > index;

            const handleOnPress = () => {
              onSelect(item);
              setItems([]);
            };

            return (
              <div
                className={`pa3 bw--1-5px b--washed-ink ${showBottomBorder && 'bb'}`}
                key={`${item.street_line}-${item.secondary}-${index}`}
              >
                <P3 color="ink" onPress={handleOnPress}>
                  {address}
                </P3>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AddressAutocomplete;
