import { MutationHookOptions, useMutation } from '@apollo/client';
import { CREATE_ASSET_REPORT_MUTATION } from '../network/mutations/createCFUAssetReport';
import {
  CreateCashFlowUnderwritingAssetReportMutation,
  CreateCashFlowUnderwritingAssetReportMutationVariables,
} from '@core/graphql/globalTypes';

interface CreateCFUAssetReportCallbacks {
  onCompleted: MutationHookOptions['onCompleted'];
  onError: MutationHookOptions['onError'];
}

export const useCreateCFUAssetReportMutation = (
  callbacks: CreateCFUAssetReportCallbacks,
) =>
  useMutation<
    CreateCashFlowUnderwritingAssetReportMutation,
    CreateCashFlowUnderwritingAssetReportMutationVariables
  >(CREATE_ASSET_REPORT_MUTATION, callbacks);
