import cx from 'classnames';
import { B, Button } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import Kard from '@core/components/General/Kard/Kard';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';

interface PreOfferUpcomingPaymentsKardProps {
  scheduledPayments: boolean;
}

const PreOfferUpcomingPaymentsKard = ({
  scheduledPayments,
}: PreOfferUpcomingPaymentsKardProps) => {
  const navigate = useNavigate();
  const { isDebitEligible } = usePaymentStatus();
  const { isDesktopLarge } = useUserDevice();

  return (
    <Kard>
      <div className="flex flex-column">
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          <span>
            It looks like you have a{' '}
            <span style={{ color: 'var(--green)' }}>
              {scheduledPayments ? 'scheduled payment' : 'pending payment'}.
            </span>
          </span>
        </ResponsiveTypography>
        <div className="mt3 mb4">
          <ResponsiveTypography
            color="inkLight"
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P2"
          >
            Once your payment’s been posted, come back to see your{' '}
            <B color="ink">flexible payment plan options!</B> In the meantime,
            you can make another payment.
          </ResponsiveTypography>
        </div>
        <div className={cx(isDesktopLarge && 'flex')}>
          <Button
            onPress={() => {
              isDebitEligible ?
                navigate('../payments/pay')
              : navigate('../payments/pay/make-ach-payment');
            }}
            text="Make another payment"
          />
        </div>
      </div>
    </Kard>
  );
};

export default PreOfferUpcomingPaymentsKard;
