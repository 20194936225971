import cx from 'classnames';
import PageWrapper from '@core/components/Page/PageWrapper';
import '../PaymentPlanFlow/PaymentPlanFlow.css';
import { useNavigate } from 'react-router-dom';
import { B, Button, Notification } from '@missionlane/compass-ui';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { usePaymentPlanContext } from '../contexts/PaymentPlanContext';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import DatepickerWithInput from '@core/components/General/Datepicker/DatepickerWithInput';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import CurrencyTextField from '@core/components/General/TextField/CurrencyTextField';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { TrackService } from '@core/services';
import BackLink from '@core/components/General/BackLink';

const EditOfferTransaction = () => {
  const { isMobile, isDesktopLarge } = useUserDevice();
  const { paymentPlan, setPaymentPlan, updateTransactions } =
    usePaymentPlanContext();
  const { amount, date, state, newDate } = paymentPlan?.transactionToEdit || {
    amount: 0,
    date: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    newDate: dayjs().add(1, 'day').format('YYYY-MM-DD'),
    state: 'ADD',
  };
  const [hasSameDate, setHasSameDate] = useState(false);
  const [dateInputError, setDateInputError] = useState(false);
  const txDate = dayjs(date).format('MM/DD/YYYY');
  const navigate = useNavigate();

  const onConfirm = () => {
    TrackService.trackClick('Edit Payment - Confirm Payment Schedule', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
    updateTransactions();
    navigate('./confirm');
  };

  useEffect(() => {
    const dateAlreadyExists = paymentPlan?.updatedTransactions?.some(
      (tx) => tx?.date === newDate && tx?.date !== date,
    );
    setHasSameDate(!!dateAlreadyExists);
  }, [newDate]);

  const onChangeAmount = (val: number) => {
    setPaymentPlan({
      transactionToEdit: {
        amount: val,
        date,
        state,
        newDate,
      },
    });
  };

  const onChangeDate = (incomingDate: Date) => {
    if (dateInputError) setDateInputError(false);
    const formattedDate = dayjs(incomingDate).format('YYYY-MM-DD');
    setPaymentPlan({
      transactionToEdit: {
        amount,
        date,
        state,
        newDate: formattedDate,
      },
    });
  };

  if (!paymentPlan?.transactionToEdit && state !== 'ADD') {
    return <GenericFallbackFullPage />;
  }

  return (
    <PageWrapper
      trackingProperties={{
        featureName: SETTLEMENTS_FEATURE_NAME,
        pageName:
          state === 'ADD' ?
            'Edit Payment - Add Payment Page'
          : 'Edit Payment - Edit Payment Page',
      }}
    >
      <div className={cx('flex flex-column', !isMobile && 'w6')}>
        <BackLink
          destination="../"
          onGoBack={() => {
            setPaymentPlan({ transactionToEdit: undefined });
          }}
        />
        <div className="flex flex-column">
          <CurrencyTextField
            amount={amount}
            onChangeAmount={onChangeAmount}
            clearable
            maxLength={8}
          />
          <div className="mt2">
            <DatepickerWithInput
              datePickerProps={{
                headerText: 'Select payment date',
                selectedDate:
                  newDate ? dayjs(newDate).toDate() : dayjs(txDate).toDate(),
                onChange: onChangeDate,
                minDate: dayjs().add(1, 'day').toDate(),
                maxDate:
                  paymentPlan?.expiration ?
                    dayjs(paymentPlan?.expiration).toDate()
                  : undefined,
              }}
              inputProps={{
                label: 'Date',
                onError: (errors) => setDateInputError(!!errors.length),
              }}
            />
          </div>
          {hasSameDate && (
            <div className="pt3">
              <Notification variant="inline" level="error">
                <span>
                  You already have a payment scheduled for this date. Please{' '}
                  <B>select a different date.</B>
                </span>
              </Notification>
            </div>
          )}
        </div>
      </div>
      <div className={cx('flex mt4', !isDesktopLarge && 'flex-column')}>
        <div className={cx(isDesktopLarge && 'mr2')}>
          <Button
            onPress={onConfirm}
            disabled={hasSameDate || amount <= 0 || dateInputError}
            text="Confirm Payment Details"
          />
        </div>
        <Button
          onPress={() => {
            if (state === 'ADD') {
              TrackService.trackClick('Edit Payment - Payment Added', {
                feature: SETTLEMENTS_FEATURE_NAME,
              });

              navigate('../');
            } else {
              TrackService.trackClick('Edit Payment - Payment Deleted', {
                feature: SETTLEMENTS_FEATURE_NAME,
              });

              navigate('./delete');
            }
          }}
          variant="text"
          text={state === 'ADD' ? 'Cancel' : 'Delete This Payment'}
        />
      </div>
    </PageWrapper>
  );
};

export default EditOfferTransaction;
