import { Spacer } from '@missionlane/compass-ui';
import { FuturePurchaseAprRow } from './FuturePurchaseAprRow';
import IntroPurchaseAprRow from './IntroPurchaseAprRow';
import { DescriptionList } from '@core/components/General/DescriptionList/DescriptionList';
import { CardTermsPurchaseApr } from '@core/graphql/globalTypes';

export const INTRO_PURCHASE_APR_LABEL = 'Intro Purchase APR through';
export interface PurchaseAprsSubtitleProps {
  currentPurchaseApr: string | JSX.Element;
  introductoryPurchaseApr: CardTermsPurchaseApr.IntroductoryPurchaseApr;
}

export const PurchaseAprsSubtitle = ({
  currentPurchaseApr,
  introductoryPurchaseApr,
}: PurchaseAprsSubtitleProps) => {
  return (
    <DescriptionList>
      <IntroPurchaseAprRow
        introductoryPurchaseApr={introductoryPurchaseApr}
        currentPurchaseApr={currentPurchaseApr}
      />
      <Spacer size="s" />
      <FuturePurchaseAprRow
        introductoryPurchaseApr={introductoryPurchaseApr}
        purchaseApr={currentPurchaseApr}
      />
    </DescriptionList>
  );
};
