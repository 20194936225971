import currency from 'currency.js';
import dayjs from 'dayjs';
import { ClipOfferStatusCodes } from '@clip/ClipProgress/hooks/types/ClipOffer';
import useClipOffer from '@clip/ClipProgress/hooks/useClipOffer';

const ClipOfferKardTitle = ({ children }: { children: React.ReactNode }) => (
  <h4 style={{ fontSize: 20 }} className="mb3">
    {children}
  </h4>
);

const InProgressTitle = () => {
  const { evaluationDate, isInGracePeriod, userFirstName } = useClipOffer();

  if (isInGracePeriod) {
    return (
      <ClipOfferKardTitle>
        {userFirstName}, make a payment now to stay on time!
      </ClipOfferKardTitle>
    );
  }

  return (
    <ClipOfferKardTitle>
      You could boost your credit limit as soon as{' '}
      <span className="green">{dayjs(evaluationDate).format('MMMM')}.</span>
    </ClipOfferKardTitle>
  );
};

const EvaluationPendingTitle = () => {
  const { userFirstName } = useClipOffer();

  return (
    <ClipOfferKardTitle>
      {userFirstName}, great job making on time payments!
    </ClipOfferKardTitle>
  );
};

const ApprovedTitle = () => {
  const { currentCreditLimit } = useClipOffer();

  if (!currentCreditLimit) return null;

  return (
    <ClipOfferKardTitle>
      Congrats! You just boosted your credit limit to{' '}
      <span className="green">
        {currency(currentCreditLimit, { precision: 0 }).format()}.
      </span>
    </ClipOfferKardTitle>
  );
};

const OverlimitTitle = () => (
  <ClipOfferKardTitle>
    You still have a chance at a credit limit increase!
  </ClipOfferKardTitle>
);

const IneligibleTitle = () => (
  <ClipOfferKardTitle>
    We can't increase your credit limit right now.
  </ClipOfferKardTitle>
);

const MissedPaymentTitle = () => (
  <ClipOfferKardTitle>
    We can't increase your credit limit right now.
  </ClipOfferKardTitle>
);

const TITLE_CONTENT: Record<ClipOfferStatusCodes, () => JSX.Element | null> = {
  [ClipOfferStatusCodes.IN_PROGRESS]: InProgressTitle,
  [ClipOfferStatusCodes.EVALUATION_PENDING]: EvaluationPendingTitle,
  [ClipOfferStatusCodes.APPROVED]: ApprovedTitle,
  [ClipOfferStatusCodes.INELIGIBLE]: IneligibleTitle,
  [ClipOfferStatusCodes.MISSED_PAYMENT]: MissedPaymentTitle,
  [ClipOfferStatusCodes.OVERLIMIT]: OverlimitTitle,
  [ClipOfferStatusCodes.PENDING_PAYMENT]: InProgressTitle,
} as const;

const Title = () => {
  const { clipStatus } = useClipOffer();

  const Content =
    clipStatus && TITLE_CONTENT[clipStatus as ClipOfferStatusCodes];

  if (!Content) {
    return null;
  }

  return <Content />;
};

export default Title;
