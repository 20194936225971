import { useEffect } from 'react';
import { P3, B, Link } from '@missionlane/compass-ui';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import PageWrapper from '@core/components/Page/PageWrapper';
import { ChargedOffBannerVariant as FaqPageVariant } from '@core/components/Banners/AuthenticatedBanners/ChargedOffBanner';
import { TrackService } from '@core/services';
import BackLink from '@core/components/General/BackLink';
import Accordian from '@core/components/General/Accordian/Accordian';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { AccordianItemProps } from '@core/components/General/Accordian/AccordianItem';

const defaultFaqQuestions: AccordianItemProps[] = [
  {
    title: 'My account is charged off. What does that mean?',
    children: (
      <div>
        <P3>
          This means your account is permanently closed, and you won’t be able
          to reopen it. Your account is charged off because you’ve missed 7
          minimum payments.
        </P3>
        <div className="mt3">
          <P3>
            You’re still responsible for paying off your account balance, but we
            won’t send you additional statements.
          </P3>
        </div>
      </div>
    ),
  },
  {
    title: 'What happens if I can’t pay?',
    children: (
      <div>
        <P3>
          We’re here to help. We offer flexible payment plans that you can set
          up online in just a few minutes. You can also give us a call at&nbsp;
          <B color="ink">888-695-8536</B> to discuss your options.
        </P3>
        <div className="mt3">
          <P3>
            You can also visit{' '}
            <Link
              onPress={() =>
                window.open('https://www.missionlane.com/resources', '_blank')
              }
            >
              missionlane.com/resources
            </Link>{' '}
            to find help with groceries, utilities, prescriptions, and more at
            no cost.
          </P3>
        </div>
      </div>
    ),
  },
  {
    title: 'Do you have payment plans?',
    children: (
      <div>
        <P3>
          Yes, we can help you build a flexible payment plan. Just answer a few
          questions about your preferred payment schedule, and we’ll make you an
          offer right away.
        </P3>
        <div className="mt3">
          <P3>
            You can enroll in a plan online, or give us a call at{' '}
            <B color="ink">888-695-8536</B> to discuss your options.
          </P3>
        </div>
      </div>
    ),
  },
  {
    title: 'Do I still have to pay interest and late fees?',
    children: (
      <P3>
        Your account{' '}
        <B color="ink">won’t accrue additional interest or late fees</B> now
        that it’s charged off. You’re still responsible for paying your current
        account balance, which includes prior fees or interest.
      </P3>
    ),
  },
];

const FaqPage = () => {
  // TODO: Once we have the copy, this should be dynamic based on the variant
  // which should be set by the state of the offer
  const variant = FaqPageVariant.FULFILLED_PIF;
  let faqQuestions: AccordianItemProps[];
  switch (variant) {
    case FaqPageVariant.FULFILLED_PIF:
      faqQuestions = defaultFaqQuestions;
      break;
    default:
      faqQuestions = defaultFaqQuestions;
  }

  useEffect(() => {
    TrackService.trackPage('Charged Off FAQ', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  return (
    <PageWrapper isSingleColumn>
      <BackLink className="pb3" />
      <ResponsiveTypography
        className="mb3"
        type="HEADER"
        mobileLevel="H2"
        desktopLevel="H3"
      >
        Frequently Asked Questions
      </ResponsiveTypography>
      <Accordian items={faqQuestions} />
    </PageWrapper>
  );
};

export default FaqPage;
