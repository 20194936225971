import {
  ApolloError,
  DocumentNode,
  LazyQueryHookOptions,
  NetworkStatus,
  OperationVariables,
  TypedDocumentNode,
  useLazyQuery,
} from '@apollo/client';
import { useEffect, useRef } from 'react';
import { useAccount } from '../../components/Auth/AccountContext';

export interface UseAccountIdQueryResult<TData> {
  data: TData | undefined;
  previousData?: TData;
  error?: ApolloError;
  loading: boolean;
  networkStatus: NetworkStatus;
  called: boolean;
}

export const noAccountIdError = 'No accountId found';

interface AccountIdVariables extends OperationVariables {
  accountId: string;
}

export const useAccountIdQuery = <
  TData = any,
  TVariables extends AccountIdVariables = AccountIdVariables,
>(
  query: DocumentNode | TypedDocumentNode<any, TVariables>,
  options: Omit<
    LazyQueryHookOptions<TData, Omit<TVariables, 'accountId'>>,
    'defaultOptions' | 'nextFetchPolicy'
  > & { skip?: boolean } = {},
): UseAccountIdQueryResult<TData> => {
  // subscribe to accountId
  const loadingRef = useRef<boolean>(true);
  const { accountId } = useAccount();

  // setup lazyQuery
  const [sendQuery, result] = useLazyQuery<TData, AccountIdVariables>(query, {
    ...options,
    variables: {
      ...options.variables,
      accountId,
    },
  });

  // Call query when accountId is not undefined
  useEffect(() => {
    if (accountId && options.skip !== true) {
      sendQuery();
    }
    loadingRef.current = false;
  }, [accountId]);

  if (!accountId) {
    const newError = new ApolloError({
      errorMessage: noAccountIdError,
    });
    return { ...result, error: newError };
  }

  return { ...result, loading: loadingRef.current || result.loading };
};
