import { Button, Spacer } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { TrackService } from '@core/services';

interface Props {
  onSubmit: () => void;
  disabled?: boolean;
  text: string;
  onCancel?: () => void;
  leftAlign?: boolean;
  cancelButtonText?: string;
  critical?: boolean;
}

export const PaymentConfirmationButtons = ({
  onSubmit,
  disabled,
  text,
  onCancel,
  leftAlign = false,
  cancelButtonText,
  critical = false,
}: Props) => {
  const navigate = useNavigate();
  const trackingName = text.toLowerCase();

  function handleCancel() {
    TrackService.click(`Cancel ${trackingName}`);
    if (onCancel) {
      onCancel();
    } else {
      navigate('..');
    }
  }

  function handleSubmit() {
    TrackService.click(`Submit ${trackingName}`);
    onSubmit();
  }

  if (leftAlign) {
    return (
      <div className="flex flex-row-ns flex-column mt3 ">
        <Button
          onPress={handleSubmit}
          disabled={disabled}
          text={text}
          variant={critical ? 'critical' : 'contained'}
        />
        <Spacer size="s" direction="horizontal" />
        <Button
          variant="text"
          onPress={handleCancel}
          text={cancelButtonText ? cancelButtonText : 'Cancel'}
        />
      </div>
    );
  }

  {
    return (
      <div className="flex flex-column flex-row-ns justify-end-ns mt5">
        <div className="flex order-1 order-0-ns justify-center">
          <Button variant="text" onPress={handleCancel} text="Cancel" />
          <Spacer size="s" direction="horizontal" />
        </div>
        {/* temporarily use tc class to center button text */}
        {/* this should be removed when addressed in compass: */}
        {/* https://github.com/missionlane/compass-ui/issues/256 */}
        <div className="order-0 order-1-ns tc">
          <Button onPress={handleSubmit} disabled={disabled} text={text} />
        </div>
      </div>
    );
  }
};
