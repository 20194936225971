import {
  OfferAggregate,
  ServicingOffersTransaction,
  SettlementOfferState,
  UpdateOfferMutation,
  MutationUpdateOfferArgs,
  ServicingOfferChannel,
} from '@core/graphql/globalTypes';
import { Button } from '@missionlane/compass-ui';
import { useMutation } from '@apollo/client';

import './ExistingOfferDashboard.css';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import cx from 'classnames';
import { SETTLEMENTS_FEATURE_NAME } from '../../utils/events';
import { useReturnedPaymentExperience } from '../../utils/useReturnedPaymentExperience';
import { OFFERS_QUERY } from '../../gql/queries';
import { UPDATE_OFFER } from '../../gql/mutations';
import FundingAccountKard from '../../Kards/FundingAccountKard';
import UpcomingPaymentKard from '../../Kards/UpcomingPaymentKard';
import OfferDetailsKard from '../../Kards/OfferDetailsKard';
import { CancelMyPlanModal } from './CancelMyPlanModal/CancelMyPlanModal';
import { TrackService } from '@core/services';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

interface ExistingOfferDashboardProps {
  offer?: OfferAggregate;
}

const createClassName = (element: string, modifier?: string) => {
  const modifierClass = modifier ? `--${modifier}` : '';
  return `existing-offer-dashboard__${element}` + modifierClass;
};

const ExistingOfferDashboard = ({ offer }: ExistingOfferDashboardProps) => {
  const [isCancelMyPlanModalOpen, setIsCancelMyPlanModalOpen] = useState(false);
  const [cancelOffer, { loading, error }] = useMutation<
    UpdateOfferMutation,
    MutationUpdateOfferArgs
  >(UPDATE_OFFER, {
    refetchQueries: [OFFERS_QUERY],
  });

  const {
    showReturnedPaymentExperience,
    loading: offerPaymentsLoading,
    error: offerPaymentsError,
  } = useReturnedPaymentExperience(offer);

  const isPendingCloseOut =
    offer?.data.state === SettlementOfferState.PendingCloseOut;

  useEffect(() => {
    TrackService.trackPage('Charged Off Dashboard - Offer via Pathfinder', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  if (loading || offerPaymentsLoading) {
    return <LoadingSpinner />;
  }

  if (error || !offer || offerPaymentsError) {
    return <GenericFallbackFull />;
  }

  const handleOfferCancelled = async () => {
    TrackService.trackClick('Cancel Program - Program Cancelled', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });

    await cancelOffer({
      variables: {
        offerId: offer.offerId,
        channel: ServicingOfferChannel.Web,
        requestBody: {
          paymentPlanCreate: null,
          paymentPlanUpdate: null,
          fundingAccountUpdate: null,
          acceptOffer: false,
          cancelOffer: true,
          declineOffer: false,
        },
      },
    });
  };

  const txs = (offer.data?.paymentPlan?.transactions ||
    []) as ServicingOffersTransaction[];

  const upcomingPaymentsList = txs.filter((transaction) =>
    dayjs(transaction.date).isAfter(dayjs()),
  );
  const fundingAccountId = offer.data?.fundingAccountId;

  return (
    <>
      <div className={cx(createClassName('container'))}>
        <div className="offer-details-kard">
          <OfferDetailsKard
            offer={offer}
            offerEndDate={offer.data.expiration}
          />
        </div>
        <div className="upcoming-payment-kard">
          <UpcomingPaymentKard
            upcomingPaymentList={upcomingPaymentsList}
            hasReturnedPayment={showReturnedPaymentExperience}
          />
        </div>
        <div className="funding-account-kard">
          <FundingAccountKard fundingAccountId={fundingAccountId} />
        </div>
      </div>
      {!isPendingCloseOut && (
        // TODO: Add a red text button variant to compass-ui
        <div
          className={cx(
            createClassName('cancel-plan-button'),
            'flex justify-center justify-start-ns pv3 w-100',
          )}
        >
          <Button
            variant="text"
            size="small"
            text="Cancel My Plan"
            onPress={() => setIsCancelMyPlanModalOpen(true)}
          />
        </div>
      )}
      <CancelMyPlanModal
        isOpen={isCancelMyPlanModalOpen}
        onClose={() => setIsCancelMyPlanModalOpen(false)}
        onSubmit={handleOfferCancelled}
      />
    </>
  );
};

export default ExistingOfferDashboard;
