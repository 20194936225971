import cx from 'classnames';
import { useQuery } from '@apollo/client';
import { B, Icon, Spacing } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import EstimatedPaymentKard from '../Kards/EstimatedPaymentKard';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import { GET_CUSTOMER_NAME_QUERY } from '@payments/components/Autopay/queries';
import { GetCustomerNameQuery } from '@core/graphql/globalTypes';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import ChargedOffDashboardFooter from '@core/components/ChargedOff/Dashboards/ChargedOffDashboardFooter';
import OfferDetailsKard from '@core/components/ChargedOff/Kards/OfferDetailsKard';
import { centsToDollars } from '@core/utils/centsToDollars';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

const OfferOverview = ({ offer, nextStep }: PaymentPlanStepProps) => {
  const { isDesktopLarge } = useUserDevice();
  const { data: customerNameData, loading } = useQuery<GetCustomerNameQuery>(
    GET_CUSTOMER_NAME_QUERY,
  );

  useEffect(() => {
    TrackService.trackPage('Create Program - Offer Generated', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  if (loading || offer === undefined) {
    return <LoadingSpinner />;
  }

  if (
    offer === null ||
    !offer.data.durationMonths ||
    !offer.data.originalSettlementBalance
  ) {
    throw new Error('OfferOverview: Missing required offer data');
  }

  const firstName = customerNameData?.customer?.contactInfo?.firstName;
  const { durationMonths, originalSettlementBalance, atpUnderSIF } = offer.data;
  const offerEndDate = dayjs().add(durationMonths, 'months').toISOString();
  const { header, subHeader } = getHeaderAndSubHeader(
    originalSettlementBalance,
    offerEndDate,
    firstName,
    atpUnderSIF,
  );

  return (
    <>
      <div className="mb2">
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          {header}
        </ResponsiveTypography>
      </div>
      <div className="mb3">
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          {subHeader}
        </ResponsiveTypography>
      </div>
      <div className={cx('flex', !isDesktopLarge && 'flex-column')}>
        <div className={cx('mb3', isDesktopLarge && 'w-50 mr2')}>
          <OfferDetailsKard offer={offer} offerEndDate={offerEndDate} />
        </div>
        <div className={cx(isDesktopLarge && 'w-50 ml1')}>
          <EstimatedPaymentKard
            settlementBalance={originalSettlementBalance}
            offerEndDate={offerEndDate}
            durationMonths={durationMonths}
            onSchedulePayments={() => nextStep?.()}
          />
        </div>
      </div>
      <ChargedOffDashboardFooter
        CallToAction={
          <div
            className="mb2"
            style={{
              display: 'grid',
              gridAutoFlow: 'column',
              alignItems: 'center',
              gap: Spacing.s,
              justifyContent: 'start',
            }}
          >
            <Icon
              ariaHidden
              focusable={false}
              name="phone"
              color="green"
              size="s"
            />
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
            >
              Need more help?
            </ResponsiveTypography>
          </div>
        }
        Disclaimer={
          <ResponsiveTypography
            className="mt2"
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P2"
          >
            If you have any questions or want to explore other plan options,{' '}
            <B style={{ color: 'var(--ink)' }}>
              please give us a call at 1-888-695-8536.
            </B>
          </ResponsiveTypography>
        }
      />
    </>
  );
};

const getHeaderAndSubHeader = (
  originalSettlementBalance: number,
  offerEndDate: string,
  firstName: string | undefined,
  atpUnderSIF: boolean | null,
) => {
  const formattedSettlementAmount = centsToDollars(originalSettlementBalance);
  const formattedExpiration = dayjs(offerEndDate).format('MMMM D');

  // Every header starts with the same text
  let header = `Good news`;
  let subHeader: string;

  if (firstName) {
    header += `, ${firstName}`;
  }

  // Whether a firstName is defined or not, the first sentence should always end in an exclamation point
  header += '!';

  // This value is null when the offer is a PIF
  if (atpUnderSIF === null) {
    header += ' You qualify for an offer.';
    subHeader = `If you pay ${formattedSettlementAmount} by ${formattedExpiration}, we’ll consider your account paid in full.`;
  } else {
    // SIF offers always have this as the second sentence of their header
    header += ' You qualify for a settlement offer.';

    if (atpUnderSIF === true) {
      subHeader = `We can’t give you the exact plan you wanted, but here’s what we can offer. If you pay ${formattedSettlementAmount} by ${formattedExpiration}, we’ll forgive the rest of your balance.`;
    } else {
      subHeader = `If you pay ${formattedSettlementAmount} by ${formattedExpiration}, we’ll forgive the rest of your balance.`;
    }
  }

  return { header, subHeader };
};

export default OfferOverview;
