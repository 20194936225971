import { B, Button, Spacing, Colors } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileInvoiceDollar,
  faEnvelopeOpenDollar,
  faMoneyCheckDollarPen,
  faMoneyBill,
  faCreditCard,
} from '@fortawesome/pro-regular-svg-icons';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { ReactNode, useEffect } from 'react';
import cx from 'classnames';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import { centsToDollars } from '@core/utils/centsToDollars';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { TrackService } from '@core/services';

interface Disclosure {
  icon: IconDefinition;
  title?: string;
  content?: ReactNode;
}

interface DisclosuresProps {
  originalSettlementBalance: number;
  endDate: string;
  fundingAccountLast4?: string;
  onOfferAccepted: () => void;
}

const Disclosures = ({
  originalSettlementBalance,
  endDate,
  fundingAccountLast4,
  onOfferAccepted,
}: DisclosuresProps) => {
  const { isMobile } = useUserDevice();
  const disclosures: Disclosure[] = getDisclosures(
    originalSettlementBalance,
    endDate,
    fundingAccountLast4,
  );

  useEffect(() => {
    TrackService.trackPage('Create Program - Disclosures', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  return (
    <div className={cx('mb6', !isMobile && 'w6')}>
      <div className="ph2 pv2 flex flex-column" style={{ gap: Spacing.xm }}>
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          Last step! Before you accept, review these disclosures.
        </ResponsiveTypography>
        <span>
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P2"
          >
            By selecting <B color="ink">Accept My Offer</B>, I acknowledge:{' '}
          </ResponsiveTypography>
        </span>
        {disclosures.map((disclosure) => {
          return (
            <div key={disclosure.title}>
              <div className="flex items-center mb2" style={{ gap: Spacing.s }}>
                <FontAwesomeIcon
                  icon={disclosure.icon}
                  style={{ color: Colors.inkLight }}
                  size="lg"
                />
                <ResponsiveTypography
                  type="HEADER"
                  mobileLevel="H3"
                  desktopLevel="H4"
                  color="ink"
                >
                  {disclosure.title}
                </ResponsiveTypography>
              </div>
              <ResponsiveTypography
                type="PARAGRAPH"
                mobileLevel="P1"
                desktopLevel="P2"
              >
                {disclosure.content}
              </ResponsiveTypography>
            </div>
          );
        })}
        <div className={cx({ flex: !isMobile })}>
          <Button text="Accept My Offer" onPress={onOfferAccepted} />
        </div>
      </div>
    </div>
  );
};

export default Disclosures;

export const getDisclosures = (
  settlementAmount: number,
  offerEndDate: string,
  fundingAccountLast4?: string,
): Disclosure[] => {
  const formattedAmount = centsToDollars(settlementAmount);
  const formattedDate = dayjs(offerEndDate).format('MMMM DD, YYYY');
  return [
    {
      icon: faCreditCard,
      title: 'Acceptance of an ACH Settlement',
      content: (
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          I’m accepting a settlement offer in the amount of {formattedAmount},
          which I’ll pay by {formattedDate}.
        </ResponsiveTypography>
      ),
    },
    {
      icon: faMoneyBill,
      title: 'Full Payment Is Required',
      content: (
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          If I don’t pay the total settlement amount by the final payment due
          date, the program will be null and void and the original account
          balance will be due at that point.
        </ResponsiveTypography>
      ),
    },
    {
      icon: faMoneyCheckDollarPen,
      title: 'My Account Will Be Settled in Full',
      content: (
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          Within 10 business days of program completion, the account will be
          considered Settled in Full. It may also take the credit bureaus up to
          30 days from the following statement cycle date to adjust credit
          reporting.
        </ResponsiveTypography>
      ),
    },
    {
      icon: faEnvelopeOpenDollar,
      title: 'Overpayment Goes Towards My Balance',
      content: (
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          If I make a payment that’s higher than the agreed settlement amount,
          the overpayment will be applied to the outstanding balance on my
          account.
        </ResponsiveTypography>
      ),
    },
    {
      icon: faFileInvoiceDollar,
      title: 'There May Be Tax Consequences',
      content: (
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
        >
          There may be tax consequences as a result of a settlement.  Please
          consult with a tax professional for advice or if you have questions.
        </ResponsiveTypography>
      ),
    },
    {
      icon: faMoneyCheckDollarPen,
      title: 'I Agree to Make Payments',
      content: (
        <div>
          <ResponsiveTypography
            type="PARAGRAPH"
            mobileLevel="P1"
            desktopLevel="P2"
          >
            I authorize Mission Lane to make recurring electronic debits from
            the bank account I provide towards my Mission Lane Visa account
            ending in {fundingAccountLast4} for the aforementioned dates and
            amounts. I understand that I may edit or cancel a scheduled payment
            until 11:59pm ET on the day before the payment date. You can edit or
            cancel payments by logging into your account online and clicking
            “Review and edit payments.”
          </ResponsiveTypography>
          <div className="mt3">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
            >
              I understand that I may edit or cancel a scheduled payment until
              11:59pm ET on the day before the payment date. You can edit or
              cancel payments by logging into your account online and clicking
              “Review and edit payments.”
            </ResponsiveTypography>
          </div>
        </div>
      ),
    },
  ];
};
