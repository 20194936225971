import { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Notification } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import EftaV3 from '../EFTA/Autopay/EftaV3';
import AutoPayPaymentDisplay from './AutoPayPaymentDisplay';
import { useAutopay } from './AutopayContext';
import { ADD_UPDATE_AUTOPAY_QUERY, GET_CUSTOMER_NAME_QUERY } from './queries';
import { CREATE_AUTOPAY, UDPATE_AUTOPAY } from './mutations';
import {
  AccountType,
  CreateAutopayMutation,
  CreateAutopayMutationVariables,
  GetCustomerNameQuery,
  PaymentType,
  Source,
  UpdateAutopayMutation,
  UpdateAutopayMutationVariables,
} from '@core/graphql/globalTypes';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccount } from '@core/components/Auth/AccountContext';
import { useTracking } from '@core/services/TrackService/useTracking';
import { MLError } from '@core/services';
import { GET_PAYMENT_STATUS } from '@payments/hooks/usePaymentStatus';

const AutoPayConfirm = () => {
  const { data, loading } = useQuery<GetCustomerNameQuery>(
    GET_CUSTOMER_NAME_QUERY,
  );
  const { accountId } = useAccount();
  const { trackError } = useTracking();
  const navigate = useNavigate();
  const { autopayFlowType, autopayContext, setAutopay, autopayPageTitle } =
    useAutopay();
  const [errors, setErrors] = useState<string[]>([]);
  const [eftaAgreed, setEftaAgreed] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [createAutopay] = useMutation<
    CreateAutopayMutation,
    CreateAutopayMutationVariables
  >(CREATE_AUTOPAY, {
    onCompleted: (createdAutopay) => {
      setAutopay({
        confirmationId: createdAutopay.createAutopay.confirmationId,
        autopay: {
          ...autopayContext?.autopay,
          nextPaymentDate: createdAutopay.createAutopay.nextPaymentDate,
        },
      });
      navigate('../success');
    },
    onError: (autopayError) => {
      setDisabled(false);
      setErrors([autopayError.message]);
      trackError({
        name: 'Failed to create autopay',
        feature: 'Create Autopay',
        error: {
          code: 'PAY0011',
          message: autopayError.message,
          name: 'Failed to create autopay',
        },
      });
    },
    refetchQueries: [
      { query: ADD_UPDATE_AUTOPAY_QUERY, variables: { accountId } },
      { query: GET_PAYMENT_STATUS, variables: { accountId } },
    ],
  });

  const [updateAutopay] = useMutation<
    UpdateAutopayMutation,
    UpdateAutopayMutationVariables
  >(UDPATE_AUTOPAY, {
    onCompleted: (updatedAutopay) => {
      setAutopay({
        confirmationId: updatedAutopay.updateAutopay.confirmationId,
        autopay: {
          ...autopayContext?.autopay,
          nextPaymentDate: updatedAutopay.updateAutopay.nextPaymentDate,
        },
      });
      navigate('../success');
    },
    onError: (autopayError) => {
      setDisabled(false);
      setErrors([autopayError.message]);
      trackError({
        name: 'Failed to update autopay',
        feature: 'Update Autopay',
        error: {
          code: 'PAY0010',
          message: autopayError.message,
          name: 'Failed to update autopay',
        },
      });
    },
    refetchQueries: [
      { query: ADD_UPDATE_AUTOPAY_QUERY, variables: { accountId } },
    ],
  });

  const { customer } = data || {};
  const { contactInfo } = customer || {};

  const { id, amount, paymentType, dayOfMonth, fundingAccount } =
    autopayContext?.autopay || {};

  useEffect(() => {
    if (!loading && !contactInfo) {
      MLError.report({
        name: 'Custom Error',
        message:
          '[AutoPayConfirm]: Customer contact information not found, cannot generate EFTA agreement',
      });

      trackError({
        name: 'Failed to load autopay EFTA',
        feature: 'Create and update autopay',
        error: {
          code: 'PAY0012',
          message:
            'Customer contact information not found, cannot generate EFTA agreement',
          name: 'Failed to load autopay EFTA',
        },
      });
    }
  }, [loading, contactInfo]);

  if (
    !contactInfo ||
    amount === undefined ||
    !paymentType ||
    !fundingAccount ||
    !dayOfMonth
  ) {
    // Customer contact information not found, cannot generate EFTA agreement
    // logged to MLError in useEffect above
    return <GenericFallbackFullPage />;
  }

  function handleSubmit() {
    setErrors([]);
    setDisabled(true);
    if (!eftaAgreed) {
      setErrors(['Check the box to accept the Auto Pay Authorization terms.']);
      setDisabled(false);
      return;
    }

    const eftaHtml = document.getElementsByClassName('efta')[0];
    const eftaXhtmlDoc = createXhtmlDocFromHtmlString(eftaHtml.innerHTML);

    if (autopayFlowType === 'SET_UP') {
      createAutopay({
        variables: {
          accountId,
          source: Source.Web,
          authorizationText: eftaXhtmlDoc,
          fundingAccountId: fundingAccount?.id,
          paymentType: paymentType || PaymentType.Unknown,
          accountType: AccountType.CreditCard,
          amount: amount || 0,
          dayOfMonth: dayOfMonth || 0,
        },
      });
    } else {
      updateAutopay({
        variables: {
          accountId,
          source: Source.Web,
          authorizationText: eftaXhtmlDoc,
          fundingAccountId: fundingAccount?.id,
          paymentType: paymentType || PaymentType.Unknown,
          amount: amount || 0,
          dayOfMonth: dayOfMonth || 0,
          id: id || '',
        },
      });
    }
  }

  if (loading || disabled) {
    return <LoadingSpinnerPage />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={autopayPageTitle}
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName: 'Set Up Autopay: Confirm',
      }}
    >
      <AutoPayPaymentDisplay
        amount={amount}
        paymentType={paymentType}
        dayOfMonth={dayOfMonth}
        fundingAccount={fundingAccount}
        paymentDateLabel="First payment"
      />
      <div className="blue-1 pt2">
        <EftaV3
          fundingAccountLast4={fundingAccount.numberLast4}
          amount={amount}
          paymentType={paymentType}
          dayOfMonth={dayOfMonth}
          onChange={setEftaAgreed}
          destinationAccount={autopayContext?.destinationAccount}
        />
      </div>
      {errors?.map((error, key) => (
        <div key={key} className="p2">
          <Notification variant="inline" level="error">
            {error}
          </Notification>
        </div>
      ))}
      <PaymentConfirmationButtons
        onSubmit={handleSubmit}
        disabled={disabled}
        text="Submit"
        onCancel={() => navigate('..')}
        cancelButtonText="Go Back"
        leftAlign
      />
    </PageWrapper>
  );
};

export default AutoPayConfirm;

function createXhtmlDocFromHtmlString(htmlString: string) {
  const document = new DOMParser().parseFromString(htmlString, 'text/html');
  const xhtmlString = new XMLSerializer().serializeToString(document);

  return `<!DOCTYPE html>${xhtmlString}`;
}
