import { Link } from '@missionlane/compass-ui';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

export const CREDIT_PROTECTION_FAQ_4_TITLE =
  'Does this service protect me from fraudulent activity on my card account?';

const CreditProtectionFAQ_4 = () => {
  return (
    <div className="mt4">
      <ResponsiveTypography type="PARAGRAPH" mobileLevel="P1" desktopLevel="P3">
        Whether or not you sign up for Credit Protection, your Mission Lane
        Visa® Credit Card account is protected from fraud under{' '}
        <Link
          onPress={() =>
            window.open('https://usa.visa.com/support/consumer/security.html')
          }
        >
          Visa’s Zero Liability Policy.
        </Link>
        <br />
        <br />
        Credit Protection is designed to help you with card payments in times of
        financial hardship.
      </ResponsiveTypography>
    </div>
  );
};

export default CreditProtectionFAQ_4;
