import { Navigate } from 'react-router-dom';
import { useMakePayment } from './MakePaymentContext';
import PaymentSuccessContent from './PaymentSuccessContent';
import PageWrapper from '@core/components/Page/PageWrapper';
import { SubmitCardPaymentSuccess } from '@payments/components/BankCardDetails/types';
import { getET } from '@core/utils/timezones';
interface Props {
  debitPaymentInfo?: SubmitCardPaymentSuccess;
}

const PaymentSuccess = ({ debitPaymentInfo }: Props) => {
  const {
    paymentValues: {
      amount,
      fundingAccount,
      paymentDate,
      reference,
      isFormFilled,
    },
  } = useMakePayment();

  const { amount: debitAmount, date: debitPaymentDate } =
    debitPaymentInfo || {};

  const isSameDayPayment = getET().isSame(paymentDate, 'day');

  if (!isFormFilled) {
    return <Navigate to=".." />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Make a Payment', useLast4: true }}
      trackingProperties={{
        pageName: 'Make a Payment: Success',
        featureName: 'Payments: Make Payment',
      }}
    >
      <PaymentSuccessContent
        amount={debitPaymentInfo ? debitAmount : amount}
        confirmationNumber={reference}
        fundingAccount={fundingAccount}
        isImmediate={debitPaymentInfo ? true : isSameDayPayment}
        paymentDate={debitPaymentInfo ? debitPaymentDate : paymentDate}
      />
    </PageWrapper>
  );
};

export default PaymentSuccess;
