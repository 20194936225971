import { ReactNode, useEffect } from 'react';
import { B, Button } from '@missionlane/compass-ui';
import { SETTLEMENTS_FEATURE_NAME } from '../../../utils/events';
import Modal, { ModalProps } from '@core/components/General/Modal/Modal';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { TrackService } from '@core/services';

interface CancelMyPlanModalProps {
  isOpen: boolean;
  onSubmit: () => Promise<void>;
  onClose: ModalProps['onClose'];
}

export const CancelMyPlanModal = ({
  isOpen,
  onSubmit,
  onClose,
}: CancelMyPlanModalProps) => {
  useEffect(() => {
    TrackService.trackPage('Cancel Program - View Cancel Page', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  return (
    <Modal maxWidth="612px" isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-column justify-center mt3">
        <ResponsiveTypography
          className="mb3"
          type="HEADER"
          mobileLevel="H2"
          desktopLevel="H4"
        >
          Are you sure you’d like to cancel?
        </ResponsiveTypography>
        <Paragraph>
          We're here to help if this plan isn't working for you.
        </Paragraph>
        <Paragraph>
          You can cancel this plan whenever you'd like, but{' '}
          <B color="ink">
            we recommend giving us a call at 1-888-695-8536 to discuss your
            other options.
          </B>
        </Paragraph>
        <Paragraph>
          Your current offer will no longer apply if you cancel this plan.
        </Paragraph>
        <div className="flex flex-row-ns flex-column">
          <Button
            text="Yes, I'd like to cancel"
            variant="critical"
            onPress={onSubmit}
          />
          <Button text="Go Back" variant="text" onPress={onClose} />
        </div>
      </div>
    </Modal>
  );
};

const Paragraph = ({ children }: { children: ReactNode }) => (
  <ResponsiveTypography
    className="mb3"
    type="PARAGRAPH"
    mobileLevel="P1"
    desktopLevel="P3"
  >
    {children}
  </ResponsiveTypography>
);
