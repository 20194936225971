import { BubbleIcon, Button, P3, Spacer } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import { useTracking } from '@core/services/TrackService/useTracking';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

export const AutopayPrompt = () => {
  const { trackEvent, trackClick } = useTracking();
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const { set, get } = useLocalStorage();
  const { isTachyonsNotSmall } = useUserDevice();

  useEffect(() => {
    trackEvent({
      eventName: 'Offer Viewed',
      name: 'Autopay offer',
      feature: 'Payments: Make Payment: Autopay offer',
    });
  }, []);

  const dismissedCount = get('dismissedAutopayPromptCount') || 0;

  const setUpAutopay = () => {
    trackClick({
      name: 'Set up',
      feature: 'Payments: Make Payment: Autopay offer',
    });
    navigate('../../autopay');
  };

  const dismissAutopayPrompt = () => {
    const newDismissedCount = dismissedCount + 1;
    trackClick({
      name: 'No thanks',
      feature: 'Payments: Make Payment: Autopay offer',
      dismissedCount: newDismissedCount,
    });
    // increment the dismissed count in localstorage
    set('dismissedAutopayPromptCount', newDismissedCount);
    navigate(summaryPath);
  };

  return (
    <PageWrapper isSingleColumn>
      <div className="flex">
        <BubbleIcon
          name="autopay"
          bubbleColor="greenWashed"
          iconColor="green"
          size={isTachyonsNotSmall ? 80 : 40}
        />
        <Spacer size="xm" />
        <div>
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H2"
            desktopLevel="H3"
          >
            Set up recurring payments with Autopay?
          </ResponsiveTypography>
          <Spacer size="m" />
          <P3>
            An autopay plan can simply act as a backup should you need it. You
            can always make additional payments or edit your autopay plan.
          </P3>
          <Spacer size="m" />
          <div className="flex flex-wrap-reverse">
            <Button
              variant="text"
              text="No thanks"
              onPress={dismissAutopayPrompt}
            />
            <Spacer size="m" />
            <Button size="small" text="Set up" onPress={setUpAutopay} />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
