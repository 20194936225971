import { P3, B } from '@missionlane/compass-ui';
import { formatPhoneNumber } from '@core/components/ChangePhonePage/ChangePhone/ChangePhoneForm/helper';

interface Props {
  name: string | null;
  phoneNumber: string;
}

export const MerchantPhone = ({ name, phoneNumber }: Props) => (
  <P3>
    <B color="ink">{name}:</B> {formatPhoneNumber(phoneNumber)}
  </P3>
);
