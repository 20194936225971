import { H4, P2, Link, Button, Fonts } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { gql } from '@apollo/client';
import cx from 'classnames';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import Kard, { KardProps } from '@core/components/General/Kard/Kard';
import { centsToDollars } from '@core/utils/centsToDollars';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  BalanceKardQuery,
  SettlementOfferState,
  SettlementOfferSubtype,
} from '@core/graphql/globalTypes';
import { LoadingSpinnerKard } from '@core/components/General/LoadingSpinner';
import isNil from '@core/utils/isNil';
import AlertMessageWithLink from '@core/components/AccountSummaryCard/AlertMessageWithLink';
import { MLError } from '@core/services';

const BALANCE_KARD_QUERY = gql`
  query BalanceKard($accountId: String!) {
    account(accountId: $accountId) {
      id
      balanceInfo {
        currentBalance
      }
      statuses {
        isDebitEligible
      }
    }
  }
`;
interface Props {
  isPaymentsOverview?: boolean;
  testID?: string;
}

/** Handles rendering the balance info for pre offer or post-fulfilled offer experiences */
const BalanceKard = ({
  isPaymentsOverview = false,
  testID = 'BalanceKard',
}: Props) => {
  const navigate = useNavigate();
  const { data, loading, error } =
    useAccountIdQuery<BalanceKardQuery>(BALANCE_KARD_QUERY);
  const {
    activeOffer,
    currentBalance,
    loading: offerQueryLoading,
    error: offerQueryError,
  } = useSettlementOffers();
  const offerLoading = offerQueryLoading || activeOffer === undefined;

  if (loading || offerLoading) return <LoadingSpinnerKard />;

  if (error || offerQueryError || isNil(currentBalance)) {
    MLError.report({ name: 'BalanceKard: No offer data to display' });
    return (
      <Kard header={{ textPrimary: 'Total Balance Due', level: 'H4' }}>
        <AlertMessageWithLink
          linkText="message us."
          message="We can't load your balance right now. Please try again later or"
          url={`${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests/new`}
        />
      </Kard>
    );
  }
  const isDebitEligible = !!data?.account?.statuses.isDebitEligible;
  const isFulfilledOffer =
    !!activeOffer && activeOffer.data.state === SettlementOfferState.Fulfilled;
  const isSIF =
    !!activeOffer && activeOffer.data.subType === SettlementOfferSubtype.Sif;
  // If the customer paid off their balance after fulfilling a sif they should see pif experience
  const isSIFWithNonZeroBalance = isSIF && currentBalance > 0;
  const isFulfilledSIF = isFulfilledOffer && isSIFWithNonZeroBalance;
  const settledBalance =
    isFulfilledSIF ?
      activeOffer.data.originalSettlementBalance -
      activeOffer.data.remainingBalance
    : undefined;
  const forgivenBalance =
    isFulfilledSIF ?
      activeOffer.data.originalOutstandingBalance -
      activeOffer.data.originalSettlementBalance +
      activeOffer.data.remainingBalance
    : undefined;

  const header: KardProps['header'] = {
    level: 'H4',
    textPrimary: forgivenBalance ? 'Balance Overview' : 'Total Balance Due',
    className: 'mb2 mb3-ns',
  };

  return (
    <Kard header={header} testID={testID}>
      <div className={cx({ 'flex justify-between w-100': !!isFulfilledSIF })}>
        <div className="flex flex-column">
          <ResponsiveTypography
            type="HEADER"
            mobileLevel="H1"
            desktopLevel="H2"
            color={isFulfilledOffer ? 'ink' : 'red'}
            style={{ margin: 0 }}
          >
            {!isNil(settledBalance) ?
              centsToDollars(settledBalance)
            : centsToDollars(currentBalance)}
          </ResponsiveTypography>
          {isFulfilledOffer ?
            isSIFWithNonZeroBalance ?
              <P2 style={{ margin: 0 }}>Settled balance</P2>
            : <P2 style={{ margin: 0 }}>Paid in full</P2>
          : <P2 style={{ margin: 0 }}>
              Your account is charged off and can't be used.{' '}
              <Link onPress={() => navigate('../payment-plan/faq')}>
                What does this mean?
              </Link>
            </P2>
          }
        </div>
        {isFulfilledSIF && !isNil(forgivenBalance) && (
          <div className="flex flex-column justify-end items-end tr">
            <H4 style={{ fontFamily: Fonts.semiBold }} color="inkLight">
              {centsToDollars(forgivenBalance)}
            </H4>
            <P2 style={{ margin: 0 }} color="inkLight">
              Forgiven balance
            </P2>
          </div>
        )}
      </div>
      {isPaymentsOverview && !activeOffer && currentBalance > 0 && (
        <div className="flex-ns mt3">
          <Button
            onPress={() => {
              isDebitEligible ?
                navigate('../payments/pay')
              : navigate('../payments/pay/make-ach-payment');
            }}
            text="Make a payment"
          />
        </div>
      )}
    </Kard>
  );
};

export default BalanceKard;
