import { ReactNode, useEffect } from 'react';
import cx from 'classnames';
import { useQuery } from '@apollo/client';
import { B, Button } from '@missionlane/compass-ui';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { PAYMENT_PLAN_QUERY, PaymentPlanQueryType } from '@core/queries';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import GenericFallbackFull from '@core/components/GenericFallbacks/GenericFallbackFull';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { TrackService } from '@core/services';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';

interface Props {
  isInKatabat?: boolean;
}

export const ExistingThirdPartyPlanDashboard = ({ isInKatabat }: Props) => {
  const { isMobile } = useUserDevice();
  const { customerId, accountId } = useCustomerAndAccountIdentifiers();
  const { data, loading, error } = useQuery<PaymentPlanQueryType>(
    PAYMENT_PLAN_QUERY,
    {
      variables: { accountId },
    },
  );

  useEffect(() => {
    TrackService.trackPage('Charged Off Dashboard - Offer via Katabat', {
      feature: SETTLEMENTS_FEATURE_NAME,
    });
  }, []);

  const katabatPlanUrl = data?.account?.paymentPlanUrl;

  if (loading) {
    <LoadingSpinner />;
  }

  if ((isInKatabat && !katabatPlanUrl) || error) {
    <GenericFallbackFull />;
  }

  return (
    <div className={cx({ w6: !isMobile })}>
      <ResponsiveTypography
        className="mb3"
        type="HEADER"
        mobileLevel="H2"
        desktopLevel="H3"
      >
        It looks like you’ve got a payment plan.
      </ResponsiveTypography>
      <ResponsiveTypography
        className={cx({
          mb5: isInKatabat,
        })}
        type="PARAGRAPH"
        mobileLevel="P1"
        desktopLevel="P3"
      >
        <InkBold>Bravo,</InkBold> you’re on your way to{' '}
        <InkBold>being rid of your debt!</InkBold>
      </ResponsiveTypography>
      {isInKatabat && (
        <div className="flex">
          <Button
            text="See My Plan"
            onPress={() => {
              if (katabatPlanUrl) {
                TrackService.click('Katabat SSO', { customerId, accountId });
                window.location.href = katabatPlanUrl;
              }
            }}
          />
        </div>
      )}
    </div>
  );
};

const InkBold = ({ children }: { children: ReactNode }) => (
  <B color="ink">{children}</B>
);
