import {
  createContext,
  useContext,
  useEffect,
  useState,
  type PropsWithChildren,
} from 'react';
import dayjs from 'dayjs';
import { useLazyQuery, useQuery } from '@apollo/client';
import { OFFERS_QUERY, OFFER_PAYMENTS_QUERY } from '../gql/queries';
import { sortDates } from '../utils/sortDates';
import { useReturnedPaymentExperience } from '../utils/useReturnedPaymentExperience';
import { OfferType } from '../utils/OfferType';
import { useAccount } from '@core/components/Auth/AccountContext';
import {
  ActivityState,
  OfferPaymentsQuery,
  OfferPaymentsQueryVariables,
  Offers,
  OffersQuery,
  OffersQueryVariables,
  SettlementOfferState,
} from '@core/graphql/globalTypes';
import { getUTC } from '@core/utils/timezones';

export type PaymentPlanTransactionState =
  | 'ADD'
  | 'EDIT'
  | 'DELETE'
  | ActivityState;

export type PaymentPlanTransaction = {
  amount: number;
  date: string;
  state: PaymentPlanTransactionState;
} | null;

type TransactionToEdit = PaymentPlanTransaction & {
  newDate?: string;
};

export type PaymentPlanContextData = {
  offerId: string | null;
  offerState: SettlementOfferState | null;
  transactions: PaymentPlanTransaction[];
  updatedTransactions: PaymentPlanTransaction[];
  transactionToEdit: TransactionToEdit | null;
  subType: string | null;
  fundingAccounts: Offers.FundingAccounts[] | null;
  fundingAccountId: string | null;
  cardLast4: string | null;
  firstName: string;
  expiration: string | null;
  remainingBalance: number | null;
  currentBalance: number | null;
  pendingPaymentAmount: number | null;
  totalScheduledAmount: number | null;
  statuses: {
    overRemainingBalanceSIF: boolean;
    underRemainingBalance: boolean;
    overCurrentBalance: boolean;
  };
};

type PaymentPlanContextType = {
  paymentPlan?: Partial<PaymentPlanContextData>;
  payments: PaymentPlanTransaction[] | undefined;
  setPaymentPlan: (paymentPlan: Partial<PaymentPlanContextData>) => void;
  loading: boolean;
  updateTransactions: () => void;
  showReturnedPaymentExperience: boolean;
};

export const PaymentPlanContext = createContext<PaymentPlanContextType>({
  paymentPlan: {},
  payments: undefined,
  setPaymentPlan: () => {},
  loading: true,
  updateTransactions: () => null,
  showReturnedPaymentExperience: false,
});

export const PaymentPlanProvider = ({ children }: PropsWithChildren) => {
  const { accountId } = useAccount();
  const [offerLoading, setOfferLoading] = useState(true);
  const [paymentsLoading, setPaymentsLoading] = useState(true);
  const [paymentPlanState, setPaymentPlanState] =
    useState<Partial<PaymentPlanContextData>>();
  const [payments, setPayments] = useState<PaymentPlanTransaction[]>();
  const { data } = useQuery<OffersQuery, OffersQueryVariables>(OFFERS_QUERY, {
    variables: {
      offerType: OfferType.Settlement,
      accountId: accountId,
    },
    skip: !accountId || paymentPlanState !== undefined,
    onCompleted: (data) => {
      const offer = getInProgressOffer(data);
      if (offer?.createdAt) {
        getPayments({
          variables: {
            accountId,
            fromDate: dayjs(offer.data.acceptedAt).format('YYYY-MM-DD'),
            toDate: getUTC().format('YYYY-MM-DD'), // acceptedAt is in UTC
          },
        });
      }

      const txList = offer?.data?.paymentPlan?.transactions || [];

      const pendingPaymentAmount =
        data.account?.upcomingPayments?.reduce((totalAmount, payment) => {
          if (payment.state === ActivityState.Pending) {
            return totalAmount + Number(payment.amount);
          }
          return totalAmount;
        }, 0) || 0;

      // spread operator is needed because the "sort" method mutates the original array which throws an error
      const transactions = [...txList]
        .sort((tx1, tx2) => sortDates(tx1?.date, tx2?.date))
        .reduce((result: PaymentPlanTransaction[], transaction) => {
          if (transaction && dayjs(transaction.date).isAfter(dayjs())) {
            const tx: PaymentPlanTransaction = {
              date: transaction.date,
              amount: transaction.amount,
              state: 'EDIT',
            };
            return [...result, tx];
          }
          return result;
        }, []);
      setPaymentPlan({
        offerId: offer?.offerId,
        offerState: offer?.data.state,
        transactions,
        updatedTransactions: transactions,
        subType: offer?.data?.subType,
        fundingAccounts: data?.fundingAccounts,
        fundingAccountId: offer?.data?.fundingAccountId,
        cardLast4: data.account?.cardDetails?.last4,
        firstName: data.customer?.contactInfo?.firstName,
        expiration: offer?.data?.expiration,
        remainingBalance: offer?.data?.remainingBalance,
        currentBalance: data.account?.balanceInfo?.currentBalance,
        pendingPaymentAmount,
      });
      setOfferLoading(false);
    },
  });

  const [getPayments, { data: paymentsData }] = useLazyQuery<
    OfferPaymentsQuery,
    OfferPaymentsQueryVariables
  >(OFFER_PAYMENTS_QUERY, {
    onCompleted: (data) => {
      const paymentList = data.account?.payments || [];
      //spread operator is needed because the "sort" method mutates the original array which throws an error
      const payments = [...paymentList]
        ?.sort((payment1, payment2) => sortDates(payment1.date, payment2.date))
        .map((payment) => {
          return {
            date: payment.date,
            amount: Math.abs(payment.amount),
            state: payment.state,
          };
        });
      setPayments(payments);
      setPaymentsLoading(false);
    },
  });

  const {
    showReturnedPaymentExperience,
    loading: returnedPaymentsQueryLoading,
  } = useReturnedPaymentExperience(
    data && getInProgressOffer(data),
    paymentsData?.account?.payments,
  );

  useEffect(() => {
    if (
      paymentPlanState?.updatedTransactions &&
      typeof paymentPlanState?.remainingBalance === 'number' &&
      typeof paymentPlanState?.currentBalance === 'number' &&
      typeof paymentPlanState?.pendingPaymentAmount === 'number'
    ) {
      const {
        updatedTransactions,
        remainingBalance,
        currentBalance,
        pendingPaymentAmount,
        subType,
      } = paymentPlanState;

      const totalScheduledAmount =
        updatedTransactions.reduce((totalAmount, tx) => {
          if (tx) {
            return totalAmount + tx.amount;
          }
          return totalAmount;
        }, 0) || 0;

      const adjustedCurrentBalance =
        currentBalance - Math.abs(pendingPaymentAmount);
      const underRemainingBalance = totalScheduledAmount < remainingBalance;
      const overRemainingBalanceSIF =
        subType === 'SIF' &&
        totalScheduledAmount > remainingBalance &&
        totalScheduledAmount < adjustedCurrentBalance;
      const overCurrentBalance = totalScheduledAmount > adjustedCurrentBalance;

      setPaymentPlanState({
        ...paymentPlanState,
        totalScheduledAmount,
        statuses: {
          overRemainingBalanceSIF,
          underRemainingBalance,
          overCurrentBalance,
        },
      });
    }
  }, [paymentPlanState?.updatedTransactions]);

  const setPaymentPlan = (paymentPlan: Partial<PaymentPlanContextData>) =>
    setPaymentPlanState({ ...paymentPlanState, ...paymentPlan });

  const updateTransactions = () => {
    if (paymentPlanState?.updatedTransactions) {
      let newTxList: PaymentPlanTransaction[] | undefined = [];
      switch (paymentPlanState?.transactionToEdit?.state) {
        case 'EDIT':
          newTxList = paymentPlanState.updatedTransactions.map((tx) => {
            if (
              tx?.date === paymentPlanState?.transactionToEdit?.date &&
              paymentPlanState?.transactionToEdit
            ) {
              return {
                state: tx?.state,
                amount: paymentPlanState?.transactionToEdit?.amount,
                date:
                  paymentPlanState?.transactionToEdit?.newDate ?
                    paymentPlanState?.transactionToEdit?.newDate
                  : paymentPlanState?.transactionToEdit?.date,
              } as PaymentPlanTransaction;
            }
            return tx;
          });
          break;
        case 'ADD':
          if (
            paymentPlanState?.transactionToEdit?.amount &&
            paymentPlanState?.transactionToEdit?.newDate
          ) {
            newTxList = [
              ...paymentPlanState.updatedTransactions,
              {
                state: 'EDIT',
                amount: paymentPlanState.transactionToEdit.amount,
                date:
                  paymentPlanState?.transactionToEdit?.newDate ?
                    paymentPlanState?.transactionToEdit?.newDate
                  : paymentPlanState?.transactionToEdit?.date,
              },
            ];
          }
          break;
        case 'DELETE':
          newTxList = paymentPlanState.updatedTransactions.filter(
            (tx) => tx?.date !== paymentPlanState?.transactionToEdit?.date,
          );
          break;
      }

      newTxList?.sort((tx1, tx2) => sortDates(tx1?.date, tx2?.date));
      setPaymentPlan({
        updatedTransactions: newTxList,
      });
    }
  };

  return (
    <PaymentPlanContext.Provider
      value={{
        paymentPlan: paymentPlanState,
        payments,
        setPaymentPlan,
        loading:
          offerLoading || paymentsLoading || returnedPaymentsQueryLoading,
        updateTransactions,
        showReturnedPaymentExperience,
      }}
    >
      {children}
    </PaymentPlanContext.Provider>
  );
};

export const usePaymentPlanContext = () => useContext(PaymentPlanContext);

const getInProgressOffer = (data: OffersQuery) => {
  return data.offers?.find(
    (offer) =>
      offer.data.state === SettlementOfferState.InProgress ||
      offer.data.state === SettlementOfferState.PendingCloseOut,
  );
};
