import { ModalProps } from '../General/Modal/Modal';
import { ReplacementCardModalProps } from './ReplacementCardModal';
import { IssuanceState } from '@core/graphql/globalTypes';

type ReplacementCardModalIssuanceState =
  ReplacementCardModalProps['issuanceState'];

const MailedHeaderText = 'Your new card is coming soon!';

const IssuanceStateToHeaderTextMap: Record<string, string> = {
  [`${IssuanceState.Mailed}`]: MailedHeaderText,
  [`${IssuanceState.PreMailed}`]: MailedHeaderText,
  [`${IssuanceState.Delayed}`]: 'Still waiting for your card?',
  [`${IssuanceState.Returned}`]: "We couldn't deliver your card",
};

export const mapIssuanceStateToModalHeader = (
  issuanceState: ReplacementCardModalIssuanceState,
) =>
  issuanceState ?
    IssuanceStateToHeaderTextMap[issuanceState]
  : "It's not you. It's us.";

type ReplacementCardModalOnPressHandlers = {
  handleNavigateToActivationForm: () => void;
  handleClose: () => void;
};

export const getModalPrimaryButtonByIssuanceState = (
  issuanceState: ReplacementCardModalIssuanceState,
  onPressHandlers: ReplacementCardModalOnPressHandlers,
): ModalProps['primaryButton'] => {
  let text = 'Activate Card';
  let onPress = onPressHandlers.handleNavigateToActivationForm;
  if (issuanceState === IssuanceState.Returned) {
    text = 'Close';
    onPress = onPressHandlers.handleClose;
  }
  return {
    text,
    onPress,
  };
};
