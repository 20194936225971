import { AuthnTransaction } from '@okta/okta-auth-js';
import { useForm, FormProvider } from 'react-hook-form';
import { P3 } from '@missionlane/compass-ui';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Notification, Spacer } from '@missionlane/compass-ui';
import { isOktaError, OktaResponseError, OTPFactor } from '../types';
import LockedOut from '../LockedOut';
import { invalidOtpErrorMessage } from '../OktaErrorMessage';
import LoginInfoSection from '../Login/LoginInfoSection';
import { LoginLink } from '../Login/LoginLink';
import LoginActionButtons from '../Login/LoginActionButtons';
import { ContactUsMessage } from '../Login/ContactUsMessage';
import LoginContainer from '../Login/LoginContainer';
import NewCustomerLink from './NewCustomerLink';
import PhoneLink from '@core/components/General/PhoneLink';
import { cardSupportPhone } from '@core/utils/contact';
import { TrackService } from '@core/services';
import { HookFormTextField } from '@core/components/Form';

const validationSchema = Yup.object().shape({
  otp: Yup.string().nullable().required('This field is required.'),
});

interface FormValues {
  otp: string;
}

const defaultValues: FormValues = {
  otp: '',
};

interface RecoveryChallengeProps {
  authTransaction: AuthnTransaction;
  onSuccess: (transaction: AuthnTransaction) => void;
  onCancel: () => void;
}

const RecoveryChallenge = ({
  authTransaction,
  onCancel,
  onSuccess,
}: RecoveryChallengeProps) => {
  const form = useForm<FormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'all',
    defaultValues,
  });
  const {
    handleSubmit: hookFormSubmit,
    clearErrors,
    formState: { errors, isSubmitting, isValid },
  } = form;

  const [authError, setAuthError] = useState<OktaResponseError>();
  const [reSentOtpSuccess, setReSentOtpSuccess] = useState(false);
  const [isInternalError, setIsInternalError] = useState(false);

  useEffect(() => {
    TrackService.page('Recovery Challenge');
  }, []);

  const resetForm = () => {
    setReSentOtpSuccess(false);
    setAuthError(undefined);
    setIsInternalError(false);
    clearErrors();
  };

  const handleSubmit = hookFormSubmit(async ({ otp }) => {
    resetForm();
    try {
      if (!authTransaction.verify) {
        throw new Error('otpVerify transaction is undefined');
      }
      const transaction = await authTransaction.verify({
        passCode: otp,
      });
      onSuccess(transaction);
    } catch (e) {
      if (!isOktaError(e)) {
        setIsInternalError(true);
      }
      setAuthError(e as OktaResponseError);
    }
  });

  async function resendCode() {
    resetForm();
    try {
      if (!authTransaction?.resend) {
        throw new Error('otpResend resend function is undefined');
      }
      const factorType = authTransaction.factor?.factorType;
      const transaction = await authTransaction.resend(factorType);
      setReSentOtpSuccess(true);
      onSuccess(transaction);
    } catch (e) {
      if (isOktaError(e)) {
        setAuthError(e);
      } else {
        throw e;
      }
    }
  }

  // AuthTransaction.factorType is incorrectly typed as a string in okta-auth-js
  const otpMethod = authTransaction?.factorType?.toLowerCase() as OTPFactor;
  const humanReadableOtpMethodMap: Record<OTPFactor, string> = {
    sms: 'SMS',
    call: 'Voice Call',
  };

  if (authError?.errorCode === 'E0000069') {
    return <LockedOut feature="Forgot Password: Recovery Challenge" />;
  }

  return (
    <LoginContainer header="Verify">
      <P3>
        We have sent a security code to the phone number associated with the
        email address you provided. Once you receive it, please enter it below.
      </P3>

      <Spacer size="xm" />

      <FormProvider {...form}>
        <HookFormTextField
          autoFocus
          maxLength={otpMethod === 'sms' ? 6 : 5}
          name="otp"
          label={`Enter verification code sent via ${humanReadableOtpMethodMap[otpMethod]}`}
          error={errors.otp?.message}
        />

        {authError && (
          <div className="mb4 mb5-ns">
            <Notification level="error">
              {isInternalError ?
                <>
                  Sorry there's an issue on our end. Resend your code or give us
                  a call at <PhoneLink phoneNumber={cardSupportPhone} />
                </>
              : invalidOtpErrorMessage}
            </Notification>
          </div>
        )}
        <Spacer size="m" />
        <LoginActionButtons
          submitButton={{
            onPress: handleSubmit,
            loading: isSubmitting,
            text: 'Verify Code',
            disabled: !isValid,
          }}
          onCancel={onCancel}
        />
        <LoginInfoSection
          headerText="Didn't receive your code?"
          message={<LoginLink onClick={resendCode}>Send a new one.</LoginLink>}
        />
        {reSentOtpSuccess && (
          <Notification level="success">
            A new code was sent to your device.
          </Notification>
        )}

        <ContactUsMessage />
      </FormProvider>

      <div className="mv5">
        <NewCustomerLink />
      </div>
    </LoginContainer>
  );
};

export default RecoveryChallenge;
