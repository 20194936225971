import { createContext, useContext, type PropsWithChildren } from 'react';
import { useParams } from 'react-router-dom';
import { GenericFallbackFullPage } from '../GenericFallbacks/GenericFallbackFull';
import { MLError } from '@core/services';

const AccountContext = createContext<{
  accountId: string;
}>({ accountId: '' });

export const AccountProvider = ({ children }: PropsWithChildren) => {
  const { accountId } = useParams();

  if (!accountId || isNaN(parseInt(accountId))) {
    MLError.report({
      name: 'No valid accountId was provided for an AccountRoute!',
    });
    // If we have no accountId, something went wrong. This is super edge casey.
    return <GenericFallbackFullPage />;
  }

  return (
    <AccountContext.Provider value={{ accountId }}>
      {children}
    </AccountContext.Provider>
  );
};

export const useAccount = () => useContext(AccountContext);
