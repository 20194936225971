import { ColorNames, Colors, Spacer, Icon } from '@missionlane/compass-ui';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import {
  NavLink as RouterNavLink,
  NavLinkProps as RouterNavLinkProps,
} from 'react-router-dom';
import cx from 'classnames';
import ResponsiveTypography from '../../General/ResponsiveTypography';
import {
  SIDEBAR_NAVIGATION_FEATURE_ID,
  SIDEBAR_NAVIGATION_EVENT_NAME_PREFIX,
} from './constants';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface SharedProps {
  text: string;
  /** Prefixed internally as the following -> "Sidebar Nav: {trackingName}"*/
  trackingName: string;
  color?: ColorNames;
  icon?: IconNames;
}
interface NavLinkProps
  extends Omit<RouterNavLinkProps, 'children' | 'style' | 'color'>,
    SharedProps {
  onClick?: () => void;
}

export const NavLink = ({
  to,
  text,
  trackingName,
  color,
  icon,
  onClick,
  ...otherProps
}: NavLinkProps) => {
  const { isMobile } = useUserDevice();

  const iconLinkProps = {
    text,
    trackingName,
    color,
    icon,
  };

  return (
    <RouterNavLink
      to={to}
      style={({ isActive }) => ({
        display: 'block',
        textDecorationLine: 'none',
        backgroundColor: !isMobile && isActive ? Colors.pine : '',
      })}
      {...otherProps}
    >
      <IconLink {...iconLinkProps} onClick={() => onClick?.()} />
    </RouterNavLink>
  );
};

interface IconLinkProps extends SharedProps {
  onClick: () => void;
}

export const IconLink = ({
  onClick,
  text,
  trackingName,
  color,
  icon,
}: IconLinkProps) => {
  const { isMobile } = useUserDevice();
  const { trackClick } = useTracking();
  const iconAndTextColor = color || (isMobile ? 'ink' : 'white');
  return (
    <div
      className={cx('flex pv3 pointer items-center', isMobile ? 'ph3' : 'ph4')}
      onClick={() => {
        onClick();
        trackClick({
          name: SIDEBAR_NAVIGATION_EVENT_NAME_PREFIX + trackingName,
          feature: SIDEBAR_NAVIGATION_FEATURE_ID,
        });
      }}
    >
      {icon && (
        <>
          <Icon name={icon} color={iconAndTextColor} size="s" />
          <Spacer direction="horizontal" size="s" />
        </>
      )}
      <ResponsiveTypography
        className={cx('flex', { mt1: !!icon })}
        type="PARAGRAPH"
        mobileLevel="P1"
        desktopLevel="P3"
        color={iconAndTextColor}
        style={{
          margin: 0,
          lineHeight: icon ? 0 : undefined,
        }}
      >
        {text}
      </ResponsiveTypography>
    </div>
  );
};
