import { Link } from 'react-router-dom';
import { H1 } from '@missionlane/compass-ui';

import missionLaneLogo from '../../assets/missionLaneLogo.svg';
import missionLaneLogoGreen from '../../assets/missionLaneLogoGreen.svg';
import { useTracking } from '@core/services/TrackService/useTracking';

interface HeaderLogoProps {
  /** color of the logo, defaults to white */
  color?: 'green' | 'white';
}

export const HeaderLogo = ({ color = 'white' }: HeaderLogoProps) => {
  const { trackClick } = useTracking();
  return (
    <H1>
      <Link
        to="/"
        onClick={() =>
          trackClick({
            name: 'Header: Mission Lane logo',
            feature: 'Mission Lane Logo',
          })
        }
      >
        <img
          src={color === 'green' ? missionLaneLogoGreen : missionLaneLogo}
          alt="Mission Lane Logo"
          style={{ width: 100 }}
        />
      </Link>
    </H1>
  );
};
