import { useFlags } from 'launchdarkly-react-client-sdk';
import cx from 'classnames';
import {
  CSSProperties,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import { loadVGSCollect } from '@vgs/collect-js';
import { BankCardField } from './BankCardField';
import {
  FieldId,
  FieldName,
  IBankCardDetails,
  SubmitCardPayment,
} from './types';
import './bankCardDetails.css';
import {
  FieldType,
  FieldValue,
  FormValues,
  Validation,
  VGSCollect,
  VGSCollectForm,
} from '@core/components/SecureForm/types';
import { MLError } from '@core/services';

interface BankCardDetailsProps {
  setValidCard: (valid: boolean) => void;
  path: string;
  cname?: string;
}

/**
 * Form for entry and submission of debit card data using Collect.js
 * https://www.verygoodsecurity.com/collect/collectjs
 *
 * Each field is presented to the user in an iframe. No card data is
 * managed or stored client-side. Upon submission, card data is sent
 * to a vault, where aliases are created referencing the secured PCI.
 */
const BankCardDetails = forwardRef<IBankCardDetails, BankCardDetailsProps>(
  ({ setValidCard, path, cname }, ref) => {
    const [collectForm, setCollectForm] = useState<any>();
    const [formValues, setFormValues] = useState<FormValues>();
    const { showPastDueExperience } = useFlags();

    /* allow form submission from parent components */
    useImperativeHandle(ref, () => ({
      submitCardPayment: submitForm,
    }));

    /**
     * Initialize a new VGS Collect instance using environment
     * settings and set the result in state.
     */
    async function initializeForm() {
      try {
        const collect: VGSCollect = (await loadVGSCollect({
          vaultId: process.env.REACT_APP_VGS_VAULT_ID!,
          environment: process.env.REACT_APP_VGS_ENVIRONMENT!,
          version: process.env.REACT_APP_VGS_COLLECT_VERSION!,
        })) as VGSCollect;
        const form = collect.init((state: FormValues) => setFormValues(state));
        form.useCname(cname);
        configureFields(form);
        setCollectForm(form);
      } catch (error) {
        if (error instanceof Error)
          MLError.report({
            name: error.name,
            error,
          });

        setCollectForm(undefined);
      }
    }

    /*
     * Initialize a VGS Collect form if none exists, unmounting
     * this form when the component is unmounted.
     */
    useEffect(() => {
      if (!collectForm) initializeForm();
      return () => collectForm?.unmount();
    }, []);

    useEffect(() => {
      if (formValues) {
        const fieldValues = Object.values<FieldValue>(formValues);
        const isValid = fieldValues.every((field) => field.isValid);
        setValidCard(isValid);
      }
    }, [formValues, setValidCard]);

    /**
     * Submit the Collect.js form to the VGS Inbound Connection
     * and return the result to the caller.
     */
    const submitForm: SubmitCardPayment = (data, onComplete) => {
      return collectForm?.submit(
        path,
        {
          withCredentials: true,
          data: data,
        },
        /*
         * Handle response from VGS Inbound
         * response argument should be the shape of the data from
         * MakeDebitCardPayment mutation
         */
        function (status: number, response: any) {
          /*
           * Since this request is a gql mutation, it will come back with a 200
           * as long as it was parsed by the gql server, even if the downstream
           * request had an error. Anything other than a 200 means the request
           * failed before it got to the gql server.
           */
          if (status !== 200 || response.errors) {
            onComplete({
              status,
              errors: response.errors,
            });
          } else {
            const {
              state,
              type,
              id,
              date,
              redirectURL,
              amount: actualAmount,
            } = response?.data?.makeDebitCardPayment;
            onComplete({
              state,
              id,
              date,
              amount: actualAmount,
              redirectURL,
              type,
            });
          }
        },
        /*
         * Handle CollectJS form input errors - called before
         * form submission if there are validation issues
         */
        function (errors: any) {
          onComplete({ errors });
        },
      );
    };

    return (
      <form
        id="BankCardDetails"
        className={cx(!showPastDueExperience && 'w-60-ns center mt1')}
      >
        <BankCardField
          fieldId={FieldId.HolderName}
          fieldValue={formValues?.[FieldName.HolderName]}
        />
        <BankCardField
          fieldId={FieldId.CardNumber}
          fieldValue={formValues?.[FieldName.CardNumber]}
        />
        <div className="BankCardInputGroup">
          <BankCardField
            fieldId={FieldId.Expiration}
            fieldValue={formValues?.[FieldName.Expiration]}
          />
          <BankCardField
            fieldId={FieldId.SecurityCode}
            fieldValue={formValues?.[FieldName.SecurityCode]}
          />
        </div>
        <BankCardField
          fieldId={FieldId.PostalCode}
          fieldValue={formValues?.[FieldName.PostalCode]}
        />
      </form>
    );
  },
);

BankCardDetails.displayName = 'BankCardDetails';

export default BankCardDetails;

function configureFields(form: VGSCollectForm) {
  form.field(FieldId.HolderName, {
    type: FieldType.Text,
    name: FieldName.HolderName,
    placeholder: 'Name on Card',
    validations: [Validation.Required],
    autoFocus: true,
    css: baseCss,
  });
  form.field(FieldId.CardNumber, {
    type: FieldType.CardNumber,
    name: FieldName.CardNumber,
    placeholder: 'Card number',
    validations: [Validation.Required, Validation.CardNumber],
    css: baseCss,
  });
  form.field(FieldId.Expiration, {
    type: FieldType.CardExpirationDate,
    name: FieldName.Expiration,
    placeholder: 'Expiration (MM/YY)',
    validations: [Validation.Required, Validation.CardExpirationDate],
    yearLength: '2',
    serializers: [
      {
        name: 'separate',
        options: {
          monthName: 'expMonth',
          yearName: 'expYear',
        },
      },
    ],
    css: baseCss,
  });
  form.field(FieldId.SecurityCode, {
    type: FieldType.CardSecurityCode,
    name: FieldName.SecurityCode,
    placeholder: 'CVV',
    maxLength: 4,
    css: baseCss,
    validations: [Validation.Required, Validation.CardSecurityCode],
  });
  form.field(FieldId.PostalCode, {
    type: FieldType.PostalCode,
    name: FieldName.PostalCode,
    placeholder: 'Zip code',
    css: baseCss,
    validations: [Validation.Required, Validation.PostalCode],
  });
}

const baseCss: CSSProperties & {
  '&:focus': CSSProperties;
  '&.invalid.touched': CSSProperties;
} = {
  fontFamily: '"Suisse Intl", sans-serif',
  fontStyle: 'normal',
  fontWeight: 450,
  fontSize: '16px',
  lineHeight: '24px',
  padding: '1rem',
  width: '100%',
  display: 'block',
  borderWidth: '0.125rem',
  borderRadius: '0.25rem',
  borderStyle: 'solid',
  outline: '0',
  backgroundColor: '#fff',
  borderColor: 'rgba(13, 11, 35, .2)',
  overflow: 'visible',
  height: '50px',
  margin: '0',
  color: '#122c26',
  transition: 'border-color .15s linear',
  boxSizing: 'border-box',
  '&:focus': {
    borderColor: '#005cc4',
  },
  '&.invalid.touched': {
    borderColor: '#b50b13',
  },
};
