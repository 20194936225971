import { PaymentMethod } from './types';
import PaymentMethodOptions from './PaymentMethodOptions';
import PageWrapper from '@core/components/Page/PageWrapper';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

export enum PaymentMethodName {
  ACH = 'ACH',
  DEBIT_CARD = 'DEBIT_CARD',
}

const paymentMethods: PaymentMethod[] = [
  {
    name: PaymentMethodName.ACH,
    url: 'make-ach-payment',
    icon: <i className="far fa-university" />,
    iconName: 'bank',
    blurb: 'Make one-time, recurring or scheduled payments.',
    title: 'Bank Account',
  },
  {
    name: PaymentMethodName.DEBIT_CARD,
    url: 'make-card-payment',
    icon: <i className="far fa-credit-card-front" />,
    iconName: 'creditCard',
    blurb: 'Use for one-time payments only.',
    title: 'Debit Card',
  },
];

const Pay = () => {
  return (
    <PageWrapper
      trackingProperties={{
        featureName: 'Payments: Make Payment',
        pageName: 'Make a Payment: Select Payment Method',
      }}
    >
      <ResponsiveTypography
        className="mb5"
        type="HEADER"
        mobileLevel="H2"
        desktopLevel="H3"
      >
        How'd you like to pay?
      </ResponsiveTypography>
      <PaymentMethodOptions availableMethods={paymentMethods} />
    </PageWrapper>
  );
};

export default Pay;
