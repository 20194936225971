import PropTypes from 'prop-types';
import classNames from 'classnames';
import { gql } from '@apollo/client';
import { BubbleIcon, H3, H4, P3 } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Kard from '@core/components/General/Kard/Kard';
import NextLink from '@core/components/General/NextLink';
import KardCopy from '@core/components/Kard/KardCopy';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import AUTOPAY_FIELDS from '@payments/graphql/AutopayFieldsFragment';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { IssuanceState } from '@core/graphql/globalTypes';
import { MLFlags } from 'flags';
import { useLocalStorage } from '@core/utils/hooks/useLocalStorage';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { useTracking } from '@core/services/TrackService/useTracking';

export const ONBOARDING_AUTOPAY_KARD_TEST_ID = 'OnboardingAutoPayKard';

export const ONBOARDING_AUTOPAY_QUERY = gql`
  query OnboardingAutoPay($accountId: String!) {
    account(accountId: $accountId) {
      id
      autopay {
        ...AutopayFields
      }
      cardDetails {
        last4
        issuanceState
      }
    }
  }
  ${AUTOPAY_FIELDS}
`;

interface OnboardingAutoPayKardProps {
  className: string;
  onDefaultDashboard?: boolean;
  trackingProperties?: Record<string, unknown>;
}

const defaultSetupTrackingProperties = {
  name: 'Set up',
  feature: 'Account Overview: Autopay offer',
};

export const SetUpAutoPayKard = ({
  className,
  trackingProperties,
  showDashboardAutopayTile,
}: OnboardingAutoPayKardProps & { showDashboardAutopayTile: boolean }) => {
  const { trackEvent, trackClick } = useTracking();
  const { set, get } = useLocalStorage();
  const [dismissed, setDismissed] = useState(
    Boolean(get('dismissedAutopayCardAt')),
  );

  const show = showDashboardAutopayTile && !dismissed;

  useEffect(() => {
    if (show) {
      trackEvent({
        eventName: 'Offer Viewed',
        name: 'Autopay offer',
        feature: 'Account Overview: Autopay offer',
      });
    }
  }, [show]);

  const handleDismissal = () => {
    const dismissedAt = dayjs().toISOString();
    trackClick({
      name: 'Close',
      feature: 'Account Overview: Autopay offer',
    });
    set('dismissedAutopayCardAt', dismissedAt);
    setDismissed(true);
  };

  if (!show) {
    return null;
  }

  return (
    <Kard
      className={classNames('mb3', className)}
      onDismiss={handleDismissal}
      testID={ONBOARDING_AUTOPAY_KARD_TEST_ID}
    >
      <div>
        <div className="flex flex-row items-center">
          <div className="pr3">
            <BubbleIcon
              name={'autopay'}
              iconColor="green"
              bubbleColor="greenWashed"
            />
          </div>
          <H3>Try Autopay</H3>
        </div>
        <div className="flex flex-column">
          <div className="mv4">
            <P3>
              Autopay offers an easy and convenient way to ensure your monthly
              payments are made on time.
            </P3>
          </div>
          <NextLink
            to="../payments/autopay"
            trackingName="Set up"
            trackingProperties={
              trackingProperties || defaultSetupTrackingProperties
            }
          >
            Set up Autopay
          </NextLink>
        </div>
      </div>
    </Kard>
  );
};

export const ManageAutoPayKard = ({
  className,
  onDefaultDashboard = false,
  trackingProperties,
}: OnboardingAutoPayKardProps) => {
  if (onDefaultDashboard) return null;
  return (
    <Kard className={className}>
      <div>
        <div className="flex flex-row items-center">
          <div className="pr3">
            <BubbleIcon
              name="autopay"
              iconColor="green"
              bubbleColor="greenWashed"
            />
          </div>
          <H4>Autopay is set up</H4>
        </div>
        <KardCopy>
          <p className="mv4">Making on-time payments just got easier.</p>
          <NextLink
            to="../payments/autopay/manage"
            trackingName="Manage auto pay"
            trackingProperties={trackingProperties}
          >
            Manage Autopay
          </NextLink>
        </KardCopy>
      </div>
    </Kard>
  );
};

const OnboardingAutoPayKard = ({ className }: OnboardingAutoPayKardProps) => {
  const { showDashboardAutopayTile } = useFlags<MLFlags>();
  const { data, loading } = useAccountIdQuery(ONBOARDING_AUTOPAY_QUERY, {
    skip: !showDashboardAutopayTile,
  });
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const {
    isPastDue,
    isPaymentPlanEligible,
    loading: paymentStatusLoading,
  } = usePaymentStatus();

  if (loading || paymentStatusLoading) {
    return (
      <Kard className="mb3">
        <LoadingSpinner />
      </Kard>
    );
  }

  if (isPastDue || isPaymentPlanEligible) {
    return null;
  }

  const onDefaultDashboard = [
    IssuanceState.Activated,
    IssuanceState.Canceled,
    IssuanceState.PreExpired,
  ].includes(data?.account?.cardDetails.issuanceState);

  return data?.account?.autopay.length ?
      <ManageAutoPayKard
        className={className}
        trackingProperties={customerAndAccountIds}
        onDefaultDashboard={onDefaultDashboard}
      />
    : <SetUpAutoPayKard
        className={className}
        showDashboardAutopayTile={showDashboardAutopayTile}
      />;
};

OnboardingAutoPayKard.defaultProps = {
  className: '',
};

OnboardingAutoPayKard.propTypes = {
  className: PropTypes.node,
};

export default OnboardingAutoPayKard;
