import cx from 'classnames';
import './PaymentFormLayout.css';
import { ApolloError } from '@apollo/client';
import { H4, Spacer } from '@missionlane/compass-ui';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { UpcomingPayments } from '@payments/components/UpcomingPayments';
import {
  MakePayment,
  UpcomingPayments as UpcomingPaymentsNamespace,
} from '@core/graphql/globalTypes';

interface PaymentFormLayoutProps {
  statuses: MakePayment.Statuses | undefined;
  upcomingPayments: UpcomingPaymentsNamespace.UpcomingPayments[] | undefined;
  autopay: MakePayment.Autopay | undefined;
  children: JSX.Element;
  error?: ApolloError;
  loading?: boolean;
}

export const PaymentFormLayout = ({
  statuses,
  upcomingPayments,
  autopay,
  children,
}: PaymentFormLayoutProps) => {
  const { isDesktopLarge } = useUserDevice();
  return (
    <div className={cx('make-payment-page-body')}>
      <div className="make-payment-form">{children}</div>
      {isDesktopLarge && (
        <div className="upcoming-payments-container">
          <H4>Scheduled</H4>
          <Spacer size="l" />
          <UpcomingPayments
            autopay={autopay}
            statuses={statuses}
            upcomingPayments={upcomingPayments}
          />
        </div>
      )}
    </div>
  );
};
