import { B, BubbleIcon, Button, List, ListItem } from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { IconNames } from '@missionlane/compass-ui/lib/typescript/components/Icon/Icon';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { PaymentPlanTransaction } from './contexts/PaymentPlanContext';
import ManageUpcomingPayment from './ManagePaymentPlan/ManageUpcomingPayment';
import { sortDates } from './utils/sortDates';
import {
  ScheduledPaymentListItem,
  ScheduledPaymentListItemProps,
} from '@payments/components/ScheduledPayments/ScheduledPaymentListItem';
import { centsToDollars } from '@core/utils/centsToDollars';
import TrackingLink from '@core/components/General/TrackingLink';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import {
  ActivityState,
  ServicingOffersTransaction,
} from '@core/graphql/globalTypes';
interface OfferTransactionsProps {
  transactions: (PaymentPlanTransaction | ServicingOffersTransaction)[];
  payments?: PaymentPlanTransaction[];
  onEdit?: (
    transaction: PaymentPlanTransaction | ServicingOffersTransaction,
  ) => void;
  onAdd?: () => void;
}

const OfferTransactions = ({
  transactions,
  payments = [],
  onEdit,
  onAdd,
}: OfferTransactionsProps) => {
  const { isMobile } = useUserDevice();
  const [showAllTx, setShowAllTx] = useState(false);
  const txDisplayLimit = isMobile ? 5 : 6;
  const [txListDisplay, setTxListDisplay] = useState([0, txDisplayLimit]);
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const upcomingPayment = transactions[0];
  const fullTransactionList = (
    [...payments, ...transactions.slice(1)] as PaymentPlanTransaction[]
  ).sort((tx1, tx2) => sortDates(tx1?.date, tx2?.date));

  useEffect(() => {
    showAllTx ? setTxListDisplay([0]) : setTxListDisplay([0, txDisplayLimit]);
  }, [showAllTx]);

  const getLinkComponent = (transaction: PaymentPlanTransaction) =>
    onEdit ?
      <TrackingLink
        onClick={() => {
          onEdit(transaction);
        }}
        trackingName="Edit payment plan"
        trackingProperties={customerAndAccountIds}
      >
        <B>Edit</B>
      </TrackingLink>
    : null;

  const getListItem = (transaction: Exclude<PaymentPlanTransaction, null>) => {
    let iconName: IconNames;
    let colorTheme: ScheduledPaymentListItemProps['colorTheme'];
    let label: string;
    let linkComponent = undefined;
    switch (transaction?.state) {
      case ActivityState.Returned:
        label = 'Returned Payment';
        iconName = 'calendarCircleExclamation';
        colorTheme = {
          color: 'red',
          colorWashed: 'redWashed',
          colorSecondaryLabel: true,
        };
        break;
      case ActivityState.Pending:
        iconName = 'calendarClock';
        label = 'Pending Payment';
        colorTheme = {
          color: 'mustard',
          colorWashed: 'mustardWashed',
          colorSecondaryLabel: true,
        };
        break;
      case ActivityState.Posted:
        iconName = 'calendarCheck';
        label = 'Posted Payment';
        colorTheme = {
          color: 'green',
          colorWashed: 'greenWashed',
          colorSecondaryLabel: true,
        };
        break;
      default:
        linkComponent = getLinkComponent(transaction);
        iconName = 'calendarCirclePlus';
        label = 'Scheduled Payment';
        colorTheme = {
          color: 'teal',
          colorWashed: 'tealWashed',
          colorSecondaryLabel: true,
        };
    }

    return (
      <ScheduledPaymentListItem
        iconName={iconName}
        label={label}
        secondaryLabel={centsToDollars(transaction.amount)}
        description={dayjs(transaction.date).format('MMMM D, YYYY')}
        linkComponent={linkComponent}
        colorTheme={colorTheme}
        testID={label}
      />
    );
  };

  return (
    <>
      {upcomingPayment && (
        <div className="mb3">
          <ManageUpcomingPayment
            amount={upcomingPayment.amount}
            date={upcomingPayment.date}
            onEdit={
              onEdit ?
                () => {
                  onEdit(upcomingPayment);
                }
              : undefined
            }
          />
        </div>
      )}
      {fullTransactionList.length > 0 && (
        <List variant="transaction">
          {fullTransactionList
            .slice(...txListDisplay)
            .map((transaction, key) => {
              if (transaction) {
                return (
                  <ListItem key={key}>{getListItem(transaction)}</ListItem>
                );
              }
              return null;
            })}
        </List>
      )}
      {onAdd && (
        <button
          className="bg-none b--none pointer flex flex-row items-center mt3"
          onClick={onAdd}
        >
          <BubbleIcon
            iconColor="white"
            bubbleColor="blue"
            name="plus"
            size="small"
          />
          <div className="ml2">
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              color="blue"
            >
              <B>Add</B>
            </ResponsiveTypography>
          </div>
        </button>
      )}
      {fullTransactionList.length > txDisplayLimit && (
        <div className="flex justify-end" style={{ marginRight: '-8px' }}>
          <Button
            variant="text"
            text={showAllTx ? 'See fewer payments' : 'See all payments'}
            onPress={() => {
              setShowAllTx(!showAllTx);
            }}
          />
        </div>
      )}
    </>
  );
};

export default OfferTransactions;
