import { gql, useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { PageTitleText, PageTitleWrapper } from './PageTitle';
import { GetLast4SecondaryTextQuery } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import { formatCardLast4 } from '@core/utils/formatters';

const GET_LAST_4_SECONDARY_TEXT_QUERY = gql`
  query GetLast4SecondaryText($accountId: String!) {
    accountDetails(accountId: $accountId) {
      id
      cardLast4
    }
  }
`;

interface Props {
  primaryText: string;
}

export const Last4PageTitle = ({ primaryText }: Props) => {
  const { accountId } = useParams();
  const { data, loading, error } = useQuery<GetLast4SecondaryTextQuery>(
    GET_LAST_4_SECONDARY_TEXT_QUERY,
    {
      variables: {
        accountId: accountId || '',
      },
      skip: !accountId,
    },
  );
  const cardLast4 = data?.accountDetails?.cardLast4;

  if (loading) return null;
  if (error) {
    MLError.report({ name: 'Last4PageTitle: Failed to fetch cardLast4' });
    return null;
  }

  return (
    <PageTitleWrapper>
      <PageTitleText color="pine">{primaryText}</PageTitleText>
      {cardLast4 && (
        <PageTitleText color="green">
          &nbsp;{formatCardLast4(cardLast4)}
        </PageTitleText>
      )}
    </PageTitleWrapper>
  );
};
