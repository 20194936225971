import { H3 } from '@missionlane/compass-ui';
import React from 'react';
import { Helmet } from 'react-helmet';
interface LoginContainerProps {
  children: React.ReactNode;
  header: string;
}

const LoginContainer = ({ children, header }: LoginContainerProps) => {
  return (
    <>
      <Helmet>
        <title>Log in to Your Mission Lane Account</title>
        <meta
          name="description"
          content="Log in to your Mission Lane account to see your balance, pay your bill, review your transactions, and more!"
        />
      </Helmet>
      <div className="flex justify-center items-center">
        <div style={{ width: '25rem' }} className="center ph0-l pt3 pt4-l pb3">
          <div className="mb4">
            <H3>{header}</H3>
          </div>
          <div className="mt4">{children}</div>
        </div>
      </div>
    </>
  );
};

export default LoginContainer;
