import { useEffect, type PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import cx from 'classnames';

import { gql } from '@apollo/client';
import { Button, H4, Icon, Spacing } from '@missionlane/compass-ui';

import AutoPayContainer from './AutoPayContainer/AutoPayContainer';
import TruckHeader from '@core/assets/truckHeader.png';
import Hourglass from '@core/assets/hourglass.png';
import ReturnedCard from '@core/assets/returnedCard.png';
import Exclamation from '@core/assets/exclamation.svg';

import CustomerAddress from '@core/components/General/CustomerAddress';
import KardCopy from '@core/components/Kard/KardCopy';
import NextLink from '@core/components/General/NextLink';
import TrackingLink from '@core/components/General/TrackingLink';
import LoadingSpinner from '@core/components/General/LoadingSpinner';

import { TrackService } from '@core/services';

import { CONTACT_US_LINK } from '@core/utils/constants';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import SupportPhoneLink from '@core/components/General/SupportPhoneLink';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import Kard from '@core/components/General/Kard/Kard';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

// TODO: refactor this file into separate components within an OnboardingDashboards directory.

export const CHARGED_OFF_QUERY = gql`
  query ChargedOff($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isChargedOff
      }
    }
  }
`;

const BlockQuote = ({ children }: PropsWithChildren) => (
  <div className="ml2 pv2 ph3 bl bw2 b--ink-10">{children}</div>
);

const MailingCopy = () => (
  <KardCopy>
    <p>
      We're mailing your credit card with activation instructions. It should
      arrive in 7-14 calendar days.
    </p>
    <p>
      <p>We&rsquo;re mailing it to:</p>
      <BlockQuote>
        <CustomerAddress />
      </BlockQuote>
    </p>
  </KardCopy>
);

const onClickToZendesk = () => {
  window.location.href = `${process.env.REACT_APP_ZENDESK_URL}/hc/en-us/requests`;
};

const SupportLink = ({ children }: PropsWithChildren) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  return (
    <TrackingLink
      trackingName="Support Link"
      trackingProperties={customerAndAccountIds}
      to={CONTACT_US_LINK}
    >
      {children}
    </TrackingLink>
  );
};

interface ScaffoldingProps {
  leftColumn: React.ReactNode;
  rightColumn: React.ReactNode;
}

export const Scaffolding = ({ leftColumn, rightColumn }: ScaffoldingProps) => {
  const { isDesktopLarge } = useUserDevice();
  return (
    <>
      <div
        className={cx(
          'flex flex-wrap flex-auto mt4',
          !isDesktopLarge && 'flex-column',
        )}
      >
        <div className={cx(isDesktopLarge ? 'w-50 pr3' : 'mb3')}>
          {leftColumn}
        </div>
        <div className={cx(isDesktopLarge && 'w-50 pl3')}>{rightColumn}</div>
      </div>
    </>
  );
};

interface OnboardingDashKardProps extends PropsWithChildren {
  heading: string;
  image: { src: string; alt: string };
  showAccountDetailsLink?: boolean;
}

const OnboardingDashKard = ({
  children,
  heading,
  image,
  showAccountDetailsLink = true,
}: OnboardingDashKardProps) => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  return (
    <Kard headline={<img className="w-100" {...image} />}>
      <div className="nb3 flex flex-wrap items-center-ns justify-between-ns flex-column flex-row-ns">
        {heading && <H4 style={{ marginBottom: Spacing.xm }}>{heading}</H4>}
        {showAccountDetailsLink && (
          <NextLink
            style={{ marginBottom: Spacing.xm }}
            trackingName="Account Summary: Account details link"
            trackingProperties={customerAndAccountIds}
            to="../details"
          >
            Account details
          </NextLink>
        )}
      </div>
      {children}
    </Kard>
  );
};

const ActivateCardCTA = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const navigate = useNavigate();
  return (
    <div>
      <H4>Have your card?</H4>
      <br />
      <div className="flex-ns">
        <Button
          text="Activate Card"
          onPress={() => {
            navigate('../activate');
            TrackService.click(
              'Account Summary: Activate card button',
              customerAndAccountIds,
            );
          }}
        />
      </div>
    </div>
  );
};

// TODO: redefine this experience with design.
export const NoAccountDashboard = () => {
  const { isDesktopLarge } = useUserDevice();
  const { customerId } = useCustomerAndAccountIdentifiers();
  useEffect(() => {
    if (customerId) TrackService.page('Dashboard No Account', { customerId });
  }, [customerId]);

  return (
    <div className={cx('flex', isDesktopLarge && 'w-50')}>
      <OnboardingDashKard
        image={{ src: TruckHeader, alt: 'truck' }}
        heading="Your new card is coming soon!"
        showAccountDetailsLink={false}
      >
        <MailingCopy />
      </OnboardingDashKard>
    </div>
  );
};

export const PreCardMailedDashboard = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  useEffect(() => {
    if (customerAndAccountIds.customerId) {
      TrackService.page(
        'Dashboard Pre-Mailed Activation',
        customerAndAccountIds,
      );
    }
  }, [customerAndAccountIds.customerId]);

  return (
    <AutoPayContainer>
      <OnboardingDashKard
        image={{ src: TruckHeader, alt: 'truck' }}
        heading="Your new card is coming soon!"
      >
        <KardCopy>
          <p>
            <p>We'll send it to</p>
            <BlockQuote>
              <CustomerAddress />
            </BlockQuote>
          </p>
        </KardCopy>
      </OnboardingDashKard>
    </AutoPayContainer>
  );
};

export const CardMailedDashboard = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  useEffect(() => {
    if (customerAndAccountIds.customerId) {
      TrackService.page('Dashboard Mailed Activation', customerAndAccountIds);
    }
  }, [customerAndAccountIds.customerId]);

  return (
    <AutoPayContainer>
      <OnboardingDashKard
        image={{ src: TruckHeader, alt: 'truck' }}
        heading="Your new card is coming soon!"
      >
        <KardCopy>
          <p>
            <p>We'll send it to</p>
            <BlockQuote>
              <CustomerAddress />
            </BlockQuote>
          </p>
        </KardCopy>
        <ActivateCardCTA />
      </OnboardingDashKard>
    </AutoPayContainer>
  );
};

export const CardDelayedDashboard = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { loading, data } = useAccountIdQuery(CHARGED_OFF_QUERY);
  const { isChargedOff } = data?.account?.statuses || {};

  useEffect(() => {
    if (customerAndAccountIds.customerId) {
      TrackService.page('Dashboard Delayed Activation', customerAndAccountIds);
    }
  }, [customerAndAccountIds.customerId]);

  if (loading) return <LoadingSpinner />;

  return (
    <AutoPayContainer>
      <OnboardingDashKard
        image={{ src: Hourglass, alt: 'hourglass' }}
        heading="Still waiting for your card?"
      >
        <KardCopy>
          <p>
            It&rsquo;s been 30 days since we issued your credit card so it
            should&rsquo;ve arrived by now.
          </p>
          <p>We mailed it to:</p>
          <BlockQuote>
            <CustomerAddress />
          </BlockQuote>
          <p>
            If you haven&rsquo;t received it,{' '}
            <SupportLink>send us a message</SupportLink> or call our customer
            support team at{' '}
            <SupportPhoneLink
              feature="Card Delayed"
              className="inline-link hover-indigo"
              shouldCallRecoveries={isChargedOff}
            />
            .
          </p>
          <ActivateCardCTA />
        </KardCopy>
      </OnboardingDashKard>
    </AutoPayContainer>
  );
};

export const DeliveryFailedDashboard = () => {
  const { isDesktopLarge } = useUserDevice();
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { loading, data } = useAccountIdQuery(CHARGED_OFF_QUERY);
  const { isChargedOff } = data?.account?.statuses || {};

  useEffect(() => {
    if (customerAndAccountIds.customerId) {
      TrackService.page(
        'Dashboard Delivery Failed Activation',
        customerAndAccountIds,
      );
    }
  }, [customerAndAccountIds.customerId]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className={cx('flex', isDesktopLarge && 'w6')}>
      <OnboardingDashKard
        image={{ src: ReturnedCard, alt: 'returned card' }}
        heading="We couldn't deliver your card"
      >
        <KardCopy>
          <p>
            Your credit card was sent back. Please{' '}
            <SupportLink>send us a message</SupportLink> or call our customer
            support team at{' '}
            <SupportPhoneLink
              feature="Card Delivery Failed"
              className="inline-link hover-indigo"
              shouldCallRecoveries={isChargedOff}
            />
            .
          </p>
          <p>We mailed it to:</p>
          <BlockQuote>
            <CustomerAddress />
          </BlockQuote>
          <div className="flex flex-wrap justify-between mt4">
            <NextLink
              trackingName="Send Message"
              trackingProperties={customerAndAccountIds}
              to={CONTACT_US_LINK}
            >
              Send us a message
            </NextLink>
            <SupportPhoneLink
              className="pv3 hover-indigo justify-between"
              shouldCallRecoveries={isChargedOff}
              hideNumber
              feature="Card Delivery Failed"
            >
              Call us
              <Icon name="forward" color="blue" />
            </SupportPhoneLink>
          </div>
        </KardCopy>
      </OnboardingDashKard>
    </div>
  );
};

export const CardActivationFraudDashboard = () => {
  const { isDesktopLarge } = useUserDevice();
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  useEffect(() => {
    if (customerAndAccountIds.customerId) {
      TrackService.page('Dashboard Fraud Activation', customerAndAccountIds);
    }
  }, [customerAndAccountIds.customerId]);

  return (
    <div className={cx('flex', isDesktopLarge && 'w6')}>
      <OnboardingDashKard
        heading=""
        image={{ src: Exclamation, alt: 'Exclamation' }}
        showAccountDetailsLink={false}
      >
        <KardCopy>
          <p>
            We take security and privacy very seriously at Mission Lane.
            That&apos;s why we take a second look at every account after
            application. During this review, we noticed some inconsistencies in
            your information, so, for your security,{' '}
            <strong>we&apos;ve temporarily restricted your account.</strong>
          </p>
          <p>
            To resolve this restriction so that you can activate and use your
            Mission Lane card, go here:
          </p>
          <div className="tc flex-ns">
            <Button text="Take Action" onPress={onClickToZendesk} />
          </div>
          <p>
            We&apos;re sorry for the inconvenience, and we want you to know that
            we&apos;re committed to safeguarding the security of all of our
            cardholders.
          </p>
        </KardCopy>
      </OnboardingDashKard>
    </div>
  );
};
