import { H5, P3 } from '@missionlane/compass-ui';

export const NoRedeemableRewardsMessage = () => (
  <div>
    <H5>You can’t redeem right now</H5>
    <P3>
      Looks like you fell behind on payments or have an account issue. Resolve
      either to redeem your Cash Back.
    </P3>
  </div>
);
