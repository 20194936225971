import currency from 'currency.js';

import ProgressBar from './ProgressBar';
import { useSpendBonus } from '@clip/SpendBonus/hooks';

export const COPY = {
  spent: 'Spent',
  goal: 'Goal',
};

export const SpendBonusProgressBar = () => {
  const spendBonusResult = useSpendBonus();

  if (
    spendBonusResult.isEnabled === false ||
    spendBonusResult.spendBonus.spendProgress == null ||
    spendBonusResult.showSpendBonusProgress === false // LM-5667
  ) {
    return null;
  }

  const { spendProgress, spendTarget } = spendBonusResult.spendBonus;

  return (
    <div className="mv2">
      <div className="mv2">
        <ProgressBar min={1} value={spendProgress} max={spendTarget} />
      </div>
      <div className="flex justify-between">
        <p className="mt2 mb0 ink b">
          {currency(spendProgress, { precision: 0 }).format()}
        </p>
        <p className="mt2 mb0 ink b">
          {currency(spendTarget, { precision: 0 }).format()}
        </p>
      </div>
      <div className="flex justify-between">
        <p className="mt0 mb2 f8">{COPY.spent}</p>
        <p className="mt0 mb2 f8">{COPY.goal}</p>
      </div>
    </div>
  );
};
