import { HttpOptions } from '@apollo/client';
import { GraphQLError } from 'graphql';
import { MLError, Sentry } from '../services';

const fetchMiddleware = (
  info: RequestInfo | URL,
  options: RequestInit,
): Promise<Response> => {
  try {
    const body = JSON.parse(options.body as string);
    const operation = body.operationName as string;

    MLError.addBreadcrumb(`GraphQL Operation: ${operation}`);
    Sentry.setTags({ 'gql.operation': operation });
  } catch (error) {
    const notifiableError =
      error instanceof Error ? error : (
        new GraphQLError('GraphQL body parse fail')
      );

    MLError.report({
      error: notifiableError,
      name: notifiableError.name,
    });
  }

  return fetch(info, options);
};

export const HttpLinkParams: HttpOptions = {
  uri: process.env.REACT_APP_APOLLO_URL,
  credentials: 'include',
  headers: {
    Accept: 'application/json',
    'x-lendup-card-channel': 'web',
  },
  fetch: fetchMiddleware,
};
