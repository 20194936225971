import { gql } from '@apollo/client';
import { B, Notification, P3, Spacer, Spacing } from '@missionlane/compass-ui';

import { Tile } from './TransactionTile';
import { TransactionStatus } from './TransactionStatus';
import { TransactionCategory } from './TransactionCategory';
import { MerchantDetails } from './MerchantDetails';
import { TransactionDate } from './TransactionDate';
import { TransactionAmount } from './TransactionAmount';
import { MerchantPhone } from './MerchantPhone';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import {
  ActivityState,
  ActivityType,
  TransactionDetailsQuery,
  TransactionDetailsQueryVariables,
} from '@core/graphql/globalTypes';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import TrackingLink from '@core/components/General/TrackingLink';
import {
  TestSeed,
  useTestProperties,
} from '@core/utils/hooks/useTestProperties';
import BackLink from '@core/components/General/BackLink';

export const TRANSACTION_DETAILS_QUERY = gql`
  query TransactionDetails($accountId: String!, $transactionId: String!) {
    transaction(accountId: $accountId, transactionId: $transactionId) {
      amount
      date
      description
      id
      state
      merchant {
        address {
          city
          postalCode
          state
        }
        phoneNumber
        name
        categoryDescription
      }
    }
  }
`;

interface TransactionDetailProps {
  transactionId: string;
  type: ActivityType;
}

const TransactionDetail = ({ transactionId, type }: TransactionDetailProps) => {
  const isCredit = type === ActivityType.Credit || type === ActivityType.Reward;
  const { data, loading, error } = useAccountIdQuery<
    TransactionDetailsQuery,
    TransactionDetailsQueryVariables
  >(TRANSACTION_DETAILS_QUERY, {
    variables: {
      transactionId: transactionId || '',
    },
  });

  const tx = data?.transaction;

  if (loading) return <LoadingSpinner />;

  if (error || !tx) {
    return (
      <Notification level="error">
        There was a problem loading your transaction. Please try again later.
      </Notification>
    );
  }

  return (
    <div>
      <div className="flex flex-column items-center mb3">
        <TransactionAmount
          amount={isCredit ? Math.abs(tx.amount) : tx.amount}
          positive={isCredit}
        />
        <Spacer size="xs" />
        <MerchantDetails merchant={tx.merchant} />
        <TransactionDate
          date={tx.date}
          isPending={tx.state === ActivityState.Pending}
          isPayment={false}
        />
      </div>
      <TransactionStatus status={tx.state} />
      {tx.merchant.categoryDescription && (
        <>
          <Spacer size="s" />
          <TransactionCategory category={tx.merchant.categoryDescription} />
        </>
      )}
      {tx.merchant.phoneNumber && (
        <>
          <Spacer size="s" />
          <Tile>
            <MerchantPhone
              name={tx.merchant.name}
              phoneNumber={tx.merchant.phoneNumber}
            />
          </Tile>
        </>
      )}
      <div style={{ marginTop: Spacing.xm }}>
        <TrackingLink
          target="_blank"
          style={{ textDecorationLine: 'none' }}
          to="https://support.missionlane.com/hc/en-us/articles/1500004356742-Transaction-Dispute"
          trackingName="Question about a transaction?"
        >
          <P3 color="blue">
            <B>Question about a transaction?</B>
          </P3>
        </TrackingLink>
      </div>
    </div>
  );
};

const TransactionDetailPage = ({
  transactionId,
  type,
}: TransactionDetailProps) => {
  const testProperties = useTestProperties(TestSeed.SEED_1);

  return (
    <PageWrapper
      isSingleColumn
      trackingProperties={{
        pageName: 'Transaction detail',
        featureName: 'Transactions',
        testProperties,
      }}
    >
      <BackLink className="pb3" />
      <TransactionDetail transactionId={transactionId} type={type} />
    </PageWrapper>
  );
};

export default TransactionDetailPage;
