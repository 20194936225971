import { gql, useMutation, useQuery } from '@apollo/client';
import { Button, P4, Notification, Spacer } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { STATEMENTS_QUERY } from '../StatementsPage';
import StatementsPreferencesConfirmEmail from './StatementsPreferencesConfirmEmail';
import StatementsPreferencesConfirmAddress from './StatementsPreferencesConfirmAddress';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import {
  StatementsPreferencesConfirmQuery,
  StatementsPreferencesConfirmQueryVariables,
  UpdateStatementPreferencesMutation,
  UpdateStatementPreferencesMutationVariables,
} from '@core/graphql/globalTypes';
import ControlledCheckbox from '@core/components/General/Checkbox/ControlledCheckbox';
import ExternalLink from '@core/components/General/ExternalLink';
import { useAccount } from '@core/components/Auth/AccountContext';
import { isErrorResult } from '@core/utils/isErrorResult';
import { MLError, TrackService } from '@core/services';
import useStatementPreference, {
  STATEMENTS_PREFERENCES_QUERY,
} from '@core/utils/hooks/useStatementPreference';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import BackLink from '@core/components/General/BackLink';
import PageTitle from '@core/components/Page/PageTitle/PageTitle';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

export const STATEMENTS_PREFERENCES_CONFIRM_QUERY = gql`
  query StatementsPreferencesConfirm {
    customer {
      id
      contactInfo {
        email
        address {
          street1
          street2
          city
          state
          postalCode
        }
        addressChangeEligibility {
          isEligible
        }
      }
    }
  }
`;

const UPDATE_STATEMENT_PREFERENCE_MUTATION = gql`
  mutation UpdateStatementPreferences(
    $accountId: String!
    $statementPreferences: [StatementPreferencesInput!]!
  ) {
    updateStatementPreferences(
      accountId: $accountId
      statementPreferences: $statementPreferences
    ) {
      ... on UpdateStatementPreferencesSuccess {
        response {
          accountId
          enrollEStatements
          success
        }
      }
      ... on ErrorResult {
        error {
          message
          statusCode
        }
      }
    }
  }
`;

const errorMessage =
  'Sorry, we couldn’t update your statement preferences. Please try again later.';

export const StatementsPreferencesConfirm = () => {
  const { isTachyonsNotSmall } = useUserDevice();
  const { accountId } = useAccount();
  const [isEmailChecked, setIsEmailChecked] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const {
    loading: statementPrefLoading,
    error: statementPrefError,
    isPaperlessStatement,
  } = useStatementPreference();
  const { data, loading, error } = useQuery<
    StatementsPreferencesConfirmQuery,
    StatementsPreferencesConfirmQueryVariables
  >(STATEMENTS_PREFERENCES_CONFIRM_QUERY);
  const [updateStatementPreferences] = useMutation<
    UpdateStatementPreferencesMutation,
    UpdateStatementPreferencesMutationVariables
  >(UPDATE_STATEMENT_PREFERENCE_MUTATION);

  if (loading || statementPrefLoading) return <LoadingSpinner />;

  if (error || statementPrefError) throw error;

  const handleSavePress = async () => {
    setIsSubmitting(true);
    setIsError(false);
    TrackService.click('Statement Preference (step 3): Save Preference');
    try {
      const results = await updateStatementPreferences({
        variables: {
          accountId,
          statementPreferences: [
            {
              id: accountId,
              enrollEStatements: !isPaperlessStatement,
            },
          ],
        },
        refetchQueries: [
          {
            query: STATEMENTS_PREFERENCES_CONFIRM_QUERY,
            variables: { accountId },
          },
          { query: STATEMENTS_QUERY, variables: { accountId } },
          { query: STATEMENTS_PREFERENCES_QUERY, variables: { accountId } },
        ],
      });

      if (results.errors) {
        setIsSubmitting(false);
        setIsError(true);
        throw results.errors;
      }

      if (isErrorResult(results.data?.updateStatementPreferences)) {
        setIsSubmitting(false);
        setIsError(true);
        const error = new Error(
          `${results.data?.updateStatementPreferences.error}`,
        );
        MLError.report({
          error: {
            name: 'Update Statement Pref Mutation Error Result',
            message:
              error.message ||
              'Apollo connection successful, mutation returned an error result',
          },
          name: 'Mutation Error',
        });
      }

      if (
        results.data?.updateStatementPreferences.__typename ===
        'UpdateStatementPreferencesSuccess'
      ) {
        const isUpdatedToPaperless = Boolean(
          results.data?.updateStatementPreferences.response?.find(
            (acct) => acct?.accountId === accountId,
          )?.enrollEStatements,
        );
        setIsSubmitting(false);
        navigate('../success', { state: { isUpdatedToPaperless } });
      }
    } catch (error) {
      setIsSubmitting(false);
      setIsError(true);
      if (error instanceof Error)
        MLError.report({
          error: {
            name: 'Update Statement Pref Apollo Error',
            message: error.message || 'Error sending to mutation to Apollo',
          },
          name: 'Apollo Connection Error',
        });
    }
  };
  const handleCancelPress = () => {
    TrackService.click('Statement Preference (step 3): Cancel');
    if (location.state?.fromStatements) {
      navigate('..');
    } else {
      navigate('../../../summary', { replace: true });
    }
  };

  const { customer } = data || {};
  const address = customer?.contactInfo?.address;
  const email = customer?.contactInfo.email;

  return (
    <>
      <BackLink
        className="mb3"
        destination="../"
        onGoBack={() =>
          TrackService.click('Statement Preference (step 3): Back')
        }
      />
      <PageTitle title="Statements Preferences" />
      {isPaperlessStatement ?
        <StatementsPreferencesConfirmAddress
          address={address}
          accountId={accountId}
          isEligible={
            customer?.contactInfo?.addressChangeEligibility?.isEligible
          }
        />
      : <StatementsPreferencesConfirmEmail email={email} />}

      <div className="mt5 mb2">
        {!isPaperlessStatement && (
          <div className="flex justify-center items-start mb3">
            <ControlledCheckbox
              data-testid="checkBox-confirm-paperless-email"
              onChange={() => {
                TrackService.click(
                  'Statement Preference (step 3): Consent checkbox',
                );
                setIsEmailChecked(!isEmailChecked);
              }}
              name="checkBox-confirm-paperless-email"
              id="checkBox-confirm-paperless-email"
              isChecked={isEmailChecked}
            />
            <P4 style={{ margin: 0 }}>
              I have read the <EmailConsentLink /> and agree to receive all
              communications from Mission Lane electronically rather than in
              paper and have the ability to do so.
            </P4>
          </div>
        )}
        {isError && !isSubmitting && (
          <div className="mt4 mb4">
            <Notification variant="inline" level="error">
              {errorMessage}
            </Notification>
          </div>
        )}
        <div className="flex flex-column-reverse flex-row-ns justify-end">
          <Button text="Cancel" variant="text" onPress={handleCancelPress} />
          <Spacer size={isTachyonsNotSmall ? 'l' : 'm'} />
          <Button
            text="Save Preferences"
            onPress={handleSavePress}
            disabled={!isPaperlessStatement && !isEmailChecked}
          />
        </div>
      </div>
    </>
  );
};

const EmailConsentLink = () => (
  <ExternalLink
    to="https://www.missionlane.com/legal/electronic-communications-disclosure-and-consent"
    className="inline-link"
    tracking={{ name: 'Statement Preference (step 3): Consent link' }}
  >
    Electronic Communications Disclosure and Consent
  </ExternalLink>
);

export const StatementsPreferencesConfirmPage = withPageWrapper(
  StatementsPreferencesConfirm,
  {
    isSingleColumn: true,
    trackingProperties: {
      pageName: 'Statement Preferences',
      featureName: 'Statements Preferences',
    },
  },
);

export default StatementsPreferencesConfirmPage;
