import { QueryLazyOptions } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import {
  PlaidLinkError,
  PlaidLinkOnExit,
  PlaidLinkOnSuccess,
  PlaidLinkOnExitMetadata,
  usePlaidLink,
  PlaidLinkOnEvent,
  PlaidLinkStableEvent,
} from 'react-plaid-link';
import { Button } from '@missionlane/compass-ui';
import { useAccount } from '@core/components/Auth/AccountContext';
import { AddAccountBannerStatuses } from '@payments/components/BankAccount/AddBankAccount/AddAccountBanner';
import { MLError, TrackService } from '@core/services';
import {
  PlaidLinkClosedEvent,
  PlaidLinkCompletedEvent,
  PlaidLinkOpenedEvent,
} from '@core/services/TrackService/models/events';
import { CFU_FEATURE_NAME } from '@core/utils/constants';
import { GetCashFlowUnderwritingProgressQueryVariables } from '@core/graphql/globalTypes';
import {
  useCreateCFUAssetReportMutation,
  usePlaidLinkTokenClipCfu,
} from '@clip/CashFlowUnderwritingExperience/hooks';

export interface PlaidClipCfuProps {
  getCFUProgress: (
    options?:
      | QueryLazyOptions<GetCashFlowUnderwritingProgressQueryVariables>
      | undefined,
  ) => void;
  setBannerStatus: (value: AddAccountBannerStatuses) => void;
  setPlaidLoading: (value: boolean) => void;
}

const PlaidClipCfu = ({
  getCFUProgress,
  setBannerStatus,
  setPlaidLoading,
}: PlaidClipCfuProps) => {
  const location = useLocation();
  const { accountId } = useAccount();

  const { data: plaidLinkTokenData, loading: plaidLinkTokenLoading } =
    usePlaidLinkTokenClipCfu({
      onError: () => setBannerStatus(AddAccountBannerStatuses.ERROR),
    });

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    (public_token: string, metadata) => {
      const accessTokenId =
        plaidLinkTokenData?.plaidLinkToken.productRegistration.productAccess[0]
          .accessTokenId;
      setPlaidLoading(true);
      createAssetReport({
        variables: {
          accountId,
          publicToken: public_token,
          accessTokenId: accessTokenId || '',
        },
      });
      TrackService.track(
        new PlaidLinkCompletedEvent({
          feature_name: CFU_FEATURE_NAME,
          institution: metadata.institution || undefined, // The institution the user selected.
        }),
      );
    },
    [plaidLinkTokenData],
  );

  const onExit = useCallback<PlaidLinkOnExit>(
    (error: PlaidLinkError | null, metadata: PlaidLinkOnExitMetadata) => {
      MLError.addBreadcrumb('PlaidOnExit', metadata);
      if (error?.error_code === 'INVALID_LINK_TOKEN') {
        setBannerStatus(AddAccountBannerStatuses.ERROR);
      }
      if (error) {
        MLError.report({
          name: error.error_code,
          message: error.display_message,
          errorMessage: error.error_message,
        });
      }
      TrackService.track(
        new PlaidLinkClosedEvent({
          feature_name: CFU_FEATURE_NAME,
          error: error?.display_message || error?.error_message, // Either a human readable error message or the error message if unrelated to user action
          status: metadata.status || undefined, // The point at which the user exited the Link flow.
          institution: metadata.institution || undefined, // The institution the user selected.
        }),
      );
    },
    [],
  );

  const onEvent = useCallback<PlaidLinkOnEvent>(
    (eventName: PlaidLinkStableEvent | string, metadata) => {
      MLError.addBreadcrumb(eventName, metadata);
    },
    [],
  );

  const { open } = usePlaidLink({
    // If null is passed in, user will receive banner notification when attempting
    // to initiate Plaid button. We are not throwing error immediately because for
    // some time before the page is interactive, the token will be undefined while
    // being fetched.
    token: plaidLinkTokenData?.plaidLinkToken.token || null,
    onSuccess,
    onExit,
    onEvent,
  });

  const launchPlaid = () => {
    //
    if (plaidLinkTokenData?.plaidLinkToken.token) {
      open();
      TrackService.track(
        new PlaidLinkOpenedEvent({
          feature_name: CFU_FEATURE_NAME,
        }),
      );
    } else {
      setBannerStatus(AddAccountBannerStatuses.ERROR);
    }
  };

  useEffect(() => {
    if (plaidLinkTokenData) {
      // for oauth flow
      sessionStorage.setItem(
        'plaidLinkTokenClipCfu',
        JSON.stringify(plaidLinkTokenData),
      );
      sessionStorage.setItem('redirectOAuthClipCfu', location.pathname);
      sessionStorage.setItem('accountId', accountId);
    }
  }, [plaidLinkTokenData]);

  const [createAssetReport] = useCreateCFUAssetReportMutation({
    onCompleted: () => {
      getCFUProgress({ variables: { accountId } });
    },
    onError: () => {
      setPlaidLoading(false);
      setBannerStatus(AddAccountBannerStatuses.ERROR);
    },
  });

  return (
    <div className="cfu-plaid-button-container">
      <Button
        variant="outlined"
        text="Connect with Plaid"
        onPress={launchPlaid}
        loading={plaidLinkTokenLoading}
      />
    </div>
  );
};

export default PlaidClipCfu;
