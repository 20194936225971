import {
  createContext,
  useContext,
  useState,
  type PropsWithChildren,
} from 'react';
import { gql } from '@apollo/client';
import { MakePaymentStepData } from './types';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import {
  MakePaymentContextQuery,
  MakePaymentContextQueryVariables,
} from '@core/graphql/globalTypes';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

export const MAKE_PAYMENT_CONTEXT_QUERY = gql`
  query MakePaymentContext($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        isDebitEligible
        isChargedOff
      }
    }
  }
`;

export interface PaymentValuesInterface extends MakePaymentStepData {
  cardName: string;
  last4: string;
  isFormFilled: boolean;
}

interface RemainingMinDueValuesInterface {
  isMinDueSchedulePrompt: boolean;
  optimisticMinDueAmount: number;
}

interface MakePaymentContextInterface {
  paymentValues: Partial<PaymentValuesInterface>;
  updatePaymentValues: (paymentValues: Partial<PaymentValuesInterface>) => void;
  remainingMinDueValues: Partial<RemainingMinDueValuesInterface>;
  updateRemainingMinDueValues: (
    remainingMinDueValues: Partial<RemainingMinDueValuesInterface>,
  ) => void;
  isDebitEligible: boolean;
  isChargedOff: boolean;
}

export const initialRemainingMinDueValues: RemainingMinDueValuesInterface = {
  isMinDueSchedulePrompt: false,
  optimisticMinDueAmount: 0,
};

export const initialPaymentValues: PaymentValuesInterface = {
  amount: 0,
  amountHelperText: '',
  amountId: null,
  cardName: '',
  customPaymentDate: null,
  last4: '',
  fundingAccount: null,
  paymentDate: null,
  paymentDateId: null,
  reference: null,
  isFormFilled: false,
};

export const MakePaymentContext = createContext<MakePaymentContextInterface>({
  paymentValues: initialPaymentValues,
  updatePaymentValues: () => {},
  remainingMinDueValues: initialRemainingMinDueValues,
  updateRemainingMinDueValues: () => {},
  isDebitEligible: false,
  isChargedOff: false,
});

const MakePaymentProvider = ({ children }: PropsWithChildren) => {
  const { data, loading } = useAccountIdQuery<
    MakePaymentContextQuery,
    MakePaymentContextQueryVariables
  >(MAKE_PAYMENT_CONTEXT_QUERY);

  const [paymentValues, setPaymentValues] =
    useState<Partial<PaymentValuesInterface>>(initialPaymentValues);

  const updatePaymentValues = (newValues: Partial<PaymentValuesInterface>) => {
    setPaymentValues({ ...paymentValues, ...newValues });
  };

  const [remainingMinDueValues, setRemainingMinDueValues] = useState<
    Partial<RemainingMinDueValuesInterface>
  >(initialRemainingMinDueValues);
  const updateRemainingMinDueValues = (
    newValues: Partial<RemainingMinDueValuesInterface>,
  ) => {
    setRemainingMinDueValues({ ...remainingMinDueValues, ...newValues });
  };

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  const { isDebitEligible, isChargedOff } = data?.account?.statuses || {};

  return (
    <MakePaymentContext.Provider
      value={{
        paymentValues,
        updatePaymentValues,
        remainingMinDueValues,
        updateRemainingMinDueValues,
        isDebitEligible: !!isDebitEligible,
        isChargedOff: !!isChargedOff,
      }}
    >
      {children}
    </MakePaymentContext.Provider>
  );
};

export const useMakePayment = () => useContext(MakePaymentContext);

export default MakePaymentProvider;
