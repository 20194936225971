import { gql } from '@apollo/client';
import {
  GetStatementPreferencesQuery,
  GetStatementPreferencesQueryVariables,
} from '@core/graphql/globalTypes';

import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';

export const STATEMENTS_PREFERENCES_QUERY = gql`
  query GetStatementPreferences($accountId: String!) {
    account(accountId: $accountId) {
      id
      statuses {
        canUpdateStatementPreference
      }
    }
    accountDetails(accountId: $accountId) {
      id
      isPaperlessStatement
      name
      cardLast4
    }
  }
`;

const useStatementPreference = () => {
  const { loading, data, error } = useAccountIdQuery<
    GetStatementPreferencesQuery,
    GetStatementPreferencesQueryVariables
  >(STATEMENTS_PREFERENCES_QUERY);

  const { account, accountDetails } = data || {};
  let cardNameWithLast4 = accountDetails?.name ?? 'Visa';

  if (accountDetails?.cardLast4) {
    cardNameWithLast4 += ` (...${accountDetails?.cardLast4})`;
  }

  return {
    error,
    loading,
    canUpdateStatementPreference: Boolean(
      account?.statuses.canUpdateStatementPreference,
    ),
    isPaperlessStatement: Boolean(accountDetails?.isPaperlessStatement),
    cardNameWithLast4,
  };
};

export default useStatementPreference;
