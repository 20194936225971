import { Spacer } from '@missionlane/compass-ui';
import AccountDetailsAccountSummary from './AccountDetailsAccountSummary/AccountDetailsAccountSummary';
import AccountDetailsCashAdvances from './AccountDetailsCashAdvances';
import AccountDetailsCardTerms from './AccountDetailsCardTerms';
import CagLink from './CagLink';
import CagDisclosureMessage from './CagDisclosureMessage';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { AccountDetailsPageQuery } from '@core/graphql/globalTypes';

interface Props {
  data?: AccountDetailsPageQuery;
  loading?: boolean;
}
const AccountDetailsBody = ({ data, loading }: Props) => {
  const {
    annualFee,
    availableCredit,
    balanceInfo,
    creditLimit,
    cashAdvance,
    cashAdvanceFeeExempt,
    paymentInfo,
    purchaseAprData,
    promotions,
    rewards,
    statuses,
  } = data?.account || {};
  const { canModifyPin } = statuses || {};
  const { url: cagUrl, bankDisclosure } = data?.cagByAccountId || {};

  if (loading) return <LoadingSpinner />;

  return (
    <>
      <AccountDetailsAccountSummary
        availableCredit={availableCredit}
        creditLimit={creditLimit || 0}
        balanceInfo={balanceInfo}
        paymentInfo={paymentInfo}
      />
      <SectionSeparator />
      <AccountDetailsCardTerms
        annualFee={annualFee}
        purchaseAprData={purchaseAprData}
        rewards={rewards}
        promotions={promotions}
      />
      <SectionSeparator />
      <AccountDetailsCashAdvances
        canModifyPin={canModifyPin !== false}
        cashAdvanceAvailableCreditLimit={
          balanceInfo?.cashAdvanceAvailableCreditLimit
        }
        cashAdvance={cashAdvance}
        cashAdvanceFeeExempt={cashAdvanceFeeExempt || false}
      />
      <SectionSeparator />
      <CagLink cagUrl={cagUrl} />
      <Spacer size="l" />
      <SectionSeparator />
      <CagDisclosureMessage bankDisclosure={bankDisclosure} />
    </>
  );
};

export default AccountDetailsBody;

const SectionSeparator = () => <div className="bb b--ink-10 bw1 mb5" />;
