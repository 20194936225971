import Lottie from 'lottie-react';
import loadingAnimation from '@core/assets/loadingAnimation.json';

const SubmittingPayment = () => {
  return (
    <div className="mw8 ph3 pb5 ph0-l mt4 center">
      <Lottie
        className={'vh-50 bg-center'}
        animationData={loadingAnimation}
        initialSegment={[20, 60]}
      />
    </div>
  );
};

export default SubmittingPayment;
