import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import LoadingSpinner from '../General/LoadingSpinner';
import Cvv from './Cvv';
import ChangePhoneSuccess from './ChangePhoneSuccess';
import ChangePhone from './ChangePhone/ChangePhone';
import { GET_CHANGE_PHONE_ELIGIBILITY_QUERY } from '@core/queries/getChangePhoneEligibilityQuery';
import {
  CvvVerification,
  GetChangePhoneEligibilityQuery,
  UpdatePhone,
} from '@core/graphql/globalTypes';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

const ChangePhonePage = () => {
  const { loading, data, error } = useQuery<GetChangePhoneEligibilityQuery>(
    GET_CHANGE_PHONE_ELIGIBILITY_QUERY,
  );

  const navigate = useNavigate();
  const [page, setPage] = useState<
    undefined | 'CVV' | 'CHANGE_PHONE' | 'CHANGE_PHONE_SUCCESS'
  >('CVV');

  const [tokenizedCvv, setTokenizedCvv] =
    useState<CvvVerification.CvvVerification['tokenizedCvv']>('');

  const [updatedPhoneNumber, setUpdatedPhoneNumber] =
    useState<UpdatePhone.UpdatePhone['phoneNumber']>('');

  if (loading) return <LoadingSpinner />;
  const creditCards = data?.creditCards || [];
  const hasMoreThanOneCard = data && creditCards.length > 1;

  if (
    error ||
    hasMoreThanOneCard ||
    !data?.customer?.contactInfo.fraudRulesEligibility.eligible
  ) {
    navigate('/', { replace: true });
    return null;
  }

  switch (page) {
    case 'CHANGE_PHONE':
      return (
        <ChangePhone
          tokenizedCvv={tokenizedCvv}
          onSuccess={(phoneNumber) => {
            setUpdatedPhoneNumber(phoneNumber);
            setPage('CHANGE_PHONE_SUCCESS');
          }}
        />
      );
    case 'CHANGE_PHONE_SUCCESS':
      return <ChangePhoneSuccess updatedPhoneNumber={updatedPhoneNumber} />;
    case 'CVV':
    default:
      return (
        <Cvv
          accountId={creditCards[0].id}
          onSuccess={(validTokenizedCvv) => {
            setTokenizedCvv(validTokenizedCvv);
            setPage('CHANGE_PHONE');
          }}
        />
      );
  }
};

const ChangePhoneFlow = withPageWrapper(ChangePhonePage, {
  isSingleColumn: true,
});

export default ChangePhoneFlow;
