import { LoadingIndicator } from '@missionlane/compass-ui';
import { PageHeader } from '../Page/PageHeader';
import Kard, { KardProps } from './Kard/Kard';
import PageWrapper, {
  PageWrapperProps,
} from '@core/components/Page/PageWrapper';
import Lockup from '@core/components/General/Lockup';

const LoadingSpinner = () => (
  <Lockup id="loading">
    <span data-testid="loading">
      <LoadingIndicator size="large" />
    </span>
  </Lockup>
);

interface Props extends Omit<PageWrapperProps, 'children'> {
  showHeader?: boolean;
}

export const LoadingSpinnerPage = ({
  showHeader,
  centerContent,
  ...otherProps
}: Props) => (
  <>
    {showHeader && <PageHeader centerContent={centerContent} />}
    <PageWrapper {...otherProps} centerContent={centerContent}>
      <LoadingSpinner />
    </PageWrapper>
  </>
);

export const LoadingSpinnerKard = (props?: Omit<KardProps, 'children'>) => (
  <Kard {...props}>
    <LoadingSpinner />
  </Kard>
);

export default LoadingSpinner;
