import { QueryHookOptions, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { OFFERS_QUERY } from '../gql/queries';
import { OfferType } from './OfferType';
import { OfferIneligibilityReason } from './types';
import {
  filterOffersByStatus,
  hasOnlyCanceledOffers,
  activeSettlementOfferStatuses,
} from '.';
import {
  OfferAggregate,
  OffersQuery,
  OffersQueryVariables,
  SettlementOfferState,
} from '@core/graphql/globalTypes';

export const useSettlementOffers = (
  options: QueryHookOptions<OffersQuery, OffersQueryVariables> = {},
) => {
  const { accountId } = useParams();
  const { loading, refetch, error, data } = useQuery<
    OffersQuery,
    OffersQueryVariables
  >(OFFERS_QUERY, {
    variables: {
      accountId: accountId || '',
      offerType: OfferType.Settlement,
    },
    skip: !accountId,
    notifyOnNetworkStatusChange: true,
    ...options,
  });

  let activeOffer: OfferAggregate | null | undefined = undefined;
  let latestCanceledOffer: OfferAggregate | null | undefined = undefined;
  let flowId: string | null | undefined = undefined;
  let isInKatabat: boolean | undefined = undefined;
  let isInDSCProgram: boolean | undefined = undefined;

  if (data?.offerEligibility) {
    if (data?.offerEligibility[0]?.flows) {
      flowId = data?.offerEligibility[0]?.flows[0]?.flowId;
    }

    const settlementOfferEligibility = data.offerEligibility.find(
      (offer) => offer.offerType === OfferType.Settlement,
    );

    if (settlementOfferEligibility && !settlementOfferEligibility.eligible) {
      isInKatabat = settlementOfferEligibility.ineligibleReasons.includes(
        OfferIneligibilityReason.IN_KATABAT,
      );
      isInDSCProgram = settlementOfferEligibility.ineligibleReasons.includes(
        OfferIneligibilityReason.IN_DSC_PROGRAM,
      );
    }
  }

  if (data?.offers) {
    // Should only be one active offer, but 🤷
    const offersWithActiveStatuses = filterOffersByStatus(
      data?.offers,
      activeSettlementOfferStatuses,
    );
    activeOffer =
      offersWithActiveStatuses.length ? offersWithActiveStatuses[0] : null;

    const canceledOffers = filterOffersByStatus(data?.offers, [
      SettlementOfferState.Canceled,
    ]);

    if (canceledOffers.length) {
      latestCanceledOffer = canceledOffers.reduce(
        (latestOffer, currentOffer) => {
          if (
            dayjs(currentOffer.updatedAt).isBefore(dayjs(latestOffer.updatedAt))
          ) {
            latestOffer = currentOffer;
          }
          return latestOffer;
        },
        canceledOffers[0],
      );
    } else {
      latestCanceledOffer = null;
    }
  }

  return {
    activeOffer,
    latestCanceledOffer,
    hasOnlyCanceledOffers: hasOnlyCanceledOffers(data?.offers),
    fundingAccounts: data?.fundingAccounts,
    isInKatabat,
    isInDSCProgram,
    cardDetails: data?.account?.cardDetails,
    currentBalance: data?.account?.balanceInfo?.currentBalance,
    flowId,
    loading,
    error,
    refetch,
  };
};
