import { H1 } from '@missionlane/compass-ui';
import { centsToDollars } from '@core/utils/centsToDollars';

interface Props {
  /** amount in cents */
  amount: number;
  /** displays the amount as positive, for payments */
  positive?: boolean;
}

export const TransactionAmount = ({ amount, positive }: Props) => (
  <H1 style={styles.amount}>
    {positive ? '+' : ''}
    {centsToDollars(amount)}
  </H1>
);

const styles = {
  amount: {
    fontSize: 48,
    lineHeight: 54,
  },
};
