import { Button, H4, P2 } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';

import { useCallback, useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import LoadingSpinner from '../General/LoadingSpinner';
import {
  GetReadyOnboardingQuery,
  GetReadyOnboardingQueryVariables,
} from '@core/graphql/globalTypes';
import { useTracking } from '@core/services/TrackService/useTracking';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import PersonOnCalendar from '@core/assets/personOnCalendar.svg';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { getDueDateFromNextStatementCloseDate } from '@core/utils/dueDate';

// required to format day of the month as ordinal (1st, 2nd, 3rd, etc.)
dayjs.extend(advancedFormat);

export const GET_READY_ONBOARDING_QUERY = gql`
  query GetReadyOnboarding {
    fundingAccounts {
      id
    }
  }
`;

const GetReady = () => {
  const navigate = useNavigate();
  const { trackClick, trackEvent } = useTracking();
  const accountSummaryPath = useAccountSummaryPath();
  const {
    loading: paymentStatusLoading,
    canEnrollInAutopay,
    hasAutopay,
    printDueDate,
    nextStatementCloseDate,
  } = usePaymentStatus();
  const { loading, data, error } = useQuery<
    GetReadyOnboardingQuery,
    GetReadyOnboardingQueryVariables
  >(GET_READY_ONBOARDING_QUERY);

  const { fundingAccounts } = data || {};

  const onNextClick = useCallback(() => {
    trackClick({
      name: 'Next',
      feature: 'Activation Success',
    });
    if (fundingAccounts?.length) {
      navigate('../../payments/autopay');
    } else {
      navigate('../../manage-bank-accounts');
    }
  }, [fundingAccounts]);

  const onGoHomeClick = () => {
    trackClick({
      name: 'Go home',
      feature: 'Activation Success',
    });
    navigate(accountSummaryPath, { replace: true });
  };

  useEffect(() => {
    trackEvent({
      eventName: `Viewed Post Activation Success Prompt - Get ready`,
    });
  }, []);

  if (error) {
    navigate('../../manage-bank-accounts', { replace: true });
  }
  if (loading || paymentStatusLoading) {
    return (
      <div className="pv7 flex flex-column items-center justify-center">
        <LoadingSpinner />
      </div>
    );
  }

  const hasFundingAccount = Boolean(data?.fundingAccounts?.length);
  let dueDateOrdinal = '';
  if (typeof printDueDate == 'string') {
    dueDateOrdinal = dayjs(printDueDate).format('Do');
  } else if (typeof nextStatementCloseDate == 'string') {
    const dueDate = getDueDateFromNextStatementCloseDate(
      nextStatementCloseDate,
    );
    dueDateOrdinal = dayjs(dueDate).format('Do');
  } else {
    // EDGE CASE HANDLING: if the usePaymentStatus hook succeeds but does not yet have the printDueDate or nextStatementCloseDate, bypass this screen
    if (hasFundingAccount && !hasAutopay && canEnrollInAutopay) {
      trackEvent({
        eventName: 'usePaymentStatus incomplete, Bypass to Add Autopay',
      });
      navigate('../../payments/autopay');
    } else {
      trackEvent({
        eventName:
          'usePaymentStatus incomplete, Bypass to Manage Bank Accounts',
      });
      navigate('../../manage-bank-accounts');
    }
  }

  return (
    <div className="pv7 flex flex-column items-center justify-center">
      <img src={PersonOnCalendar} alt="person on calendar" className="mb4" />
      <H4
        style={{ textAlign: 'center' }}
      >{`Your payment will be due\non the ${dueDateOrdinal} of each month`}</H4>
      {!hasFundingAccount && (
        <P2 style={{ textAlign: 'center' }}>
          Add a funding account and set up autopay.
        </P2>
      )}
      {hasAutopay || !canEnrollInAutopay ?
        <Button text="Go home" onPress={onGoHomeClick} />
      : <>
          <Button text="Next" onPress={onNextClick} />
          <Button text="Go home" onPress={onGoHomeClick} variant="text" />
        </>
      }
    </div>
  );
};

export default GetReady;
