import { useFlags } from 'launchdarkly-react-client-sdk';
import { useRef, useState } from 'react';
import { Link, LoadingIndicator, P3 } from '@missionlane/compass-ui';
import classNames from 'classnames';
import PlaidLinkClipCfu from '../../components/PlaidLink/PlaidClipCfu';
import { getCfuContentRenderer } from '../../components/getCfuContentRenderer';
import { getCfuHeaderRenderer } from '../../components/getCfuHeaderRenderer';
import { PlaidFAQModal } from '../../components/PlaidFAQModal';
import { CFUStatus } from '../../network/types';
import AddAccountBanner, {
  AddAccountBannerStatuses,
} from '@payments/components/BankAccount/AddBankAccount/AddAccountBanner';
import './CashFlowUnderwritingExperience.css';
import Kard from '@core/components/General/Kard/Kard';
import { DefaultError } from '@core/components/ErrorBoundary/DefaultError';
import { TrackService } from '@core/services';
import {
  ButtonClickedEvent,
  FeatureRenderedEvent,
  FeatureViewedEvent,
} from '@core/services/TrackService/models/events';
import { useVisibilityObserver } from '@core/utils/hooks/useVisibilityObserver';
import { CFU_FEATURE_NAME } from '@core/utils/constants';
import './CashFlowUnderwritingExperience.css';
import { useCfuProgress } from '@clip/CashFlowUnderwritingExperience/hooks';
import {
  isEvaluationStatusValid,
  isLineIncreaseAmountValid,
  isNextEvaluationDateValid,
  isOfferExpirationDateValid,
} from '@clip/CashFlowUnderwritingExperience/utils';

export const ctaCopy = "I've got questions about Plaid";
export const ctaEventProperties = {
  feature_name: CFU_FEATURE_NAME,
  button_name: 'Open Plaid FAQ',
  label: ctaCopy,
};
export const errorMessage =
  "We're sorry. Something went wrong. We failed to load your progress information.";
export const TestIDs = {
  container: 'CFUExperience.Container',
  loading: 'CFUExperience.Loading',
  plaidLoading: 'CFUExperience.PlaidLoading',
  error: 'CFUExperience.Error',
};

export const CashFlowUnderwritingExperience = () => {
  const { showClipCfuExperience, showPastDueExperience } = useFlags();
  const [plaidLoading, setPlaidLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [bannerStatus, setBannerStatus] = useState<AddAccountBannerStatuses>();
  const CFUExperienceRef = useRef<HTMLDivElement>();
  const featureHasBeenViewed = useRef(false);
  const featureHasBeenRendered = useRef(false);
  const {
    getCFUProgress,
    data: clipCfuProgressData,
    loading,
    error,
  } = useCfuProgress(() => {
    plaidLoading && setPlaidLoading(false);
    featureHasBeenRendered.current = false;
    featureHasBeenViewed.current = false;
  });

  const { startObservingElement } = useVisibilityObserver(() => {
    if (!featureHasBeenViewed.current) {
      TrackService.track(
        new FeatureViewedEvent({
          feature_name: CFU_FEATURE_NAME,
          page: 'Home',
          status:
            clipCfuProgressData?.cashFlowUnderwritingProgress
              ?.evaluationStatus || undefined,
        }),
      );
      featureHasBeenViewed.current = true;
    }
  });

  if (
    !showClipCfuExperience ||
    !clipCfuProgressData?.cashFlowUnderwritingProgress?.eligible
  ) {
    return null;
  }

  const setCFUExperienceRef = (instance: HTMLDivElement) => {
    CFUExperienceRef.current = instance;
    // Tapping into ref callback here to know when element for feature is rendered.
    if (!featureHasBeenRendered.current) {
      TrackService.track(
        new FeatureRenderedEvent({
          feature_name: CFU_FEATURE_NAME,
          page: 'Home',
          status:
            clipCfuProgressData?.cashFlowUnderwritingProgress
              ?.evaluationStatus || undefined,
        }),
      );
      featureHasBeenRendered.current = true;
      startObservingElement(instance);
    }
  };

  const closeBanner = () => {
    setBannerStatus(undefined);
  };

  const handleRelaunchPlaid = () => {
    CFUExperienceRef?.current?.scrollIntoView();
  };

  if (loading) {
    return (
      <Kard
        className={showPastDueExperience ? 'mv3' : 'mv5'}
        testID={TestIDs.loading}
      >
        <LoadingIndicator />
      </Kard>
    );
  }

  const {
    evaluationStatus,
    nextEvaluationDate,
    lineIncreaseAmount,
    offerExpirationDate,
  } = clipCfuProgressData?.cashFlowUnderwritingProgress || {};

  if (
    error ||
    !isEvaluationStatusValid(evaluationStatus) ||
    !isNextEvaluationDateValid(evaluationStatus, nextEvaluationDate) ||
    !isLineIncreaseAmountValid(evaluationStatus, lineIncreaseAmount) ||
    !isOfferExpirationDateValid(evaluationStatus, offerExpirationDate)
  ) {
    return (
      <Kard
        className={showPastDueExperience ? 'mv3' : 'mv5'}
        testID={TestIDs.error}
      >
        <DefaultError message={errorMessage} />
      </Kard>
    );
  }

  const getCfuHeader = getCfuHeaderRenderer(evaluationStatus as CFUStatus);
  const getCfuContent = getCfuContentRenderer(evaluationStatus as CFUStatus);

  return (
    <Kard
      className={showPastDueExperience ? 'mv3' : 'mv5'}
      testID={TestIDs.container}
    >
      {bannerStatus === AddAccountBannerStatuses.ERROR && (
        <AddAccountBanner
          bannerStatus={bannerStatus}
          closeBanner={closeBanner}
          setShowPlaidModal={handleRelaunchPlaid}
        />
      )}
      <div className="relative" ref={setCFUExperienceRef}>
        {getCfuHeader({
          lineIncreaseAmount,
        })}
        {getCfuContent({
          offerExpirationDate,
          nextEvaluationDate,
        })}
        {evaluationStatus === CFUStatus.INITIAL && (
          <PlaidLinkClipCfu
            getCFUProgress={getCFUProgress}
            setBannerStatus={setBannerStatus}
            setPlaidLoading={setPlaidLoading}
          />
        )}
        {(evaluationStatus === CFUStatus.INITIAL ||
          evaluationStatus === CFUStatus.IN_PROGRESS) && (
          <div
            className={classNames('mt4', {
              tc: evaluationStatus === CFUStatus.INITIAL,
            })}
          >
            <Link
              onPress={() => {
                TrackService.track(new ButtonClickedEvent(ctaEventProperties));
                setShowModal(true);
              }}
              accessibilityLabel="open frequently asked questions about Plaid modal"
            >
              <P3 color="blue">{ctaCopy}</P3>
            </Link>
          </div>
        )}
        {plaidLoading && (
          <div
            className="absolute absolute--fill bg-white flex justify-center"
            data-testid={TestIDs.plaidLoading}
          >
            <LoadingIndicator />
          </div>
        )}
        <PlaidFAQModal isOpen={showModal} setShowModal={setShowModal} />
      </div>
    </Kard>
  );
};
