import { B, Button, P3, Spacer } from '@missionlane/compass-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { faLeaf } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import ControlledCheckbox from '../../General/Checkbox/ControlledCheckbox';
import PaperStatementsModal from './PaperStatementsModal';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { TrackService } from '@core/services';
import useStatementPreference from '@core/utils/hooks/useStatementPreference';
import PageTitle from '@core/components/Page/PageTitle/PageTitle';
import BackLink from '@core/components/General/BackLink';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

export const StatementsPreferences = () => {
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { loading, error, isPaperlessStatement, cardNameWithLast4 } =
    useStatementPreference();

  useEffect(() => {
    setIsChecked(isPaperlessStatement);
  }, [isPaperlessStatement]);

  if (loading) return <LoadingSpinner />;

  if (error) throw error;

  type Destination = {
    relativePath: string;
    replace?: boolean;
  };
  const backLinkDestination: Destination =
    location.state?.fromStatements ?
      { relativePath: '..' }
    : { relativePath: '../../summary', replace: true };

  const handleContinuePress = () => {
    TrackService.click('Statement Preference (step 2): Continue');
    if (!isChecked) {
      setIsModalOpen(true);
    } else {
      navigate('./confirm');
    }
  };
  const handleCancelPress = () => {
    TrackService.click('Statement Preference (step 2): Cancel');
    if (location.state?.fromStatements) {
      navigate('..');
    } else {
      navigate('../../summary', { replace: true });
    }
  };
  const handleModalCancel = () => {
    setIsChecked(true);
    setIsModalOpen(false);
  };
  const handleModalConfirm = () => {
    setIsModalOpen(false);
    navigate('./confirm');
  };

  return (
    <>
      <BackLink
        className="mb3"
        destination={backLinkDestination.relativePath}
        replace={backLinkDestination.replace}
        onGoBack={() =>
          TrackService.click('Statement Preference (step 2): Back')
        }
      />
      <PageTitle title="Statements Preferences" />
      <P3>
        If you enroll in <P3 color="green">paperless billing</P3>, we’ll email
        you when each statement is ready online.{' '}
      </P3>
      <div className="mt3">
        <P3 color="ink">
          <B>
            Enroll in{' '}
            <P3 color="green">
              <B>paperless </B>
            </P3>
            <FontAwesomeIcon icon={faLeaf} size="sm" color="green" /> for:
          </B>
        </P3>
      </div>
      <div className="mt2 ba br2 b--ink-10 bw1 pa3">
        <ControlledCheckbox
          data-testid="checkBox-paperless"
          onChange={() => {
            TrackService.click(
              'Statement Preference (step 2): Enroll/unenroll checkbox',
            );
            setIsChecked(!isChecked);
          }}
          name="checkBox-paperless"
          id="checkBox-paperless"
          isChecked={isChecked}
          label={cardNameWithLast4}
        />
      </div>
      <div className="flex mt5 flex-column-reverse flex-row-ns justify-end-ns">
        <Button text="Cancel" variant="text" onPress={handleCancelPress} />
        <Spacer size="l" />
        <Button
          disabled={isPaperlessStatement === isChecked}
          text="Continue"
          onPress={handleContinuePress}
        />
      </div>
      <PaperStatementsModal
        isOpen={isModalOpen}
        onCancel={handleModalCancel}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleModalConfirm}
      />
    </>
  );
};

const StatementsPreferencesPage = withPageWrapper(StatementsPreferences, {
  isSingleColumn: true,
  trackingProperties: {
    pageName: 'Statement Preferences',
    featureName: 'Statements Preferences',
  },
});

export default StatementsPreferencesPage;
