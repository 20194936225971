import { gql } from '@apollo/client';
import { DescriptionList } from '../../../General/DescriptionList/DescriptionList';
import { CardTermsEarlySpendBonus } from '@core/graphql/globalTypes';

export const CARD_TERMS_EARLY_SPEND_BONUS = gql`
  fragment CardTermsEarlySpendBonus on Account {
    id
    promotions {
      ... on Promotions {
        earlySpendBonus {
          ... on EarlySpendBonus {
            description
          }
          ...ErrorFragment
        }
      }
      ...ErrorFragment
    }
  }
`;

const EARLY_SPEND_BONUS_LABEL = 'Early spend bonus';

interface EarlySpendBonusDetailProps {
  earlySpendBonus: CardTermsEarlySpendBonus.EarlySpendBonus;
}

const EarlySpendBonusDetail = ({
  earlySpendBonus,
}: EarlySpendBonusDetailProps) => {
  if (earlySpendBonus.__typename === 'EarlySpendBonus') {
    return (
      <DescriptionList.Item
        label={EARLY_SPEND_BONUS_LABEL}
        subTitle={earlySpendBonus.description}
      />
    );
  }

  // If there is an error getting the data for EarlySpendBonus, we don't display anything
  return null;
};

export default EarlySpendBonusDetail;
