import { B, P3 } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ChangePhoneEditButton from './ChangePhoneEditButton/ChangePhoneEditButton';
import { AccountSummaryCardholderInfo } from '@core/graphql/globalTypes';

interface PhoneStackedItemProps {
  phoneNumber:
    | AccountSummaryCardholderInfo.ContactInfo['phoneNumber']
    | undefined
    | null;
}

function PhoneStackedItem({ phoneNumber }: PhoneStackedItemProps) {
  const { showChangePhone } = useFlags();
  if (!phoneNumber) return null;
  return (
    <div className="flex flex-column">
      <P3 color="ink">
        <B>Phone number</B>
      </P3>

      <div className="flex items-baseline">
        <div className="address-text-container">
          <P3>{phoneNumber}</P3>
        </div>
        {showChangePhone && <ChangePhoneEditButton />}
      </div>
    </div>
  );
}

export default PhoneStackedItem;
