import { Notification, Link } from '@missionlane/compass-ui';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { LaunchDarklyBanner } from '../LaunchDarklyBanner/types';
import { TrackService } from '@core/services';

const MaintenanceBanner = () => {
  const { maintenanceBanner } = useFlags();

  const { headline, level, linkTo, linkToText } = (maintenanceBanner ||
    {}) as LaunchDarklyBanner;

  function handleLinkPress() {
    window.open(linkTo, '_blank');
    TrackService.click('Maintenance Banner link clicked.');
  }

  if (!headline) return null;

  return (
    <Notification level={level || 'error'} show variant="banner">
      {headline}
      {linkToText && linkTo && (
        <>
          {' '}
          <Link onPress={handleLinkPress}>{linkToText}</Link>
        </>
      )}
    </Notification>
  );
};

export default MaintenanceBanner;
