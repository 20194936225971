import { H2, P2, Spacing } from '@missionlane/compass-ui';
import { StyleProp, TextStyle } from 'react-native';
import AnimatedCurrency from '../AnimatedCurrency';
import ResponsiveTypography from '../General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { centsToDollars } from '@core/utils/centsToDollars';

const setHeaderStyle = (
  num: number,
  isMobile: boolean,
  primary: boolean,
): StyleProp<TextStyle> => {
  const lWebTextSize = primary ? 48 : 32;
  const mWebTextSize = primary ? 44 : 28;
  const sWebTextSize = primary ? 40 : 24;
  const lMobileSize = primary ? 32 : 20;
  const mMobileSize = primary ? 26 : 18;
  const sMobileSize = primary ? 24 : 16;

  const margin = {
    marginTop: isMobile ? Spacing.xs : undefined,
  };

  const largeStyle = {
    ...margin,
    fontSize: isMobile ? lMobileSize : lWebTextSize,
    lineHeight: isMobile && !primary ? 24 : undefined,
  };
  const mediumStyle = {
    ...margin,
    fontSize: isMobile ? mMobileSize : mWebTextSize,
    lineHeight: isMobile && !primary ? 22 : undefined,
  };
  const smallStyle = {
    ...margin,
    fontSize: isMobile ? sMobileSize : sWebTextSize,
    lineHeight: isMobile && !primary ? 20 : undefined,
  };

  const digits = num.toString().length;

  if (digits <= 5) {
    return largeStyle;
  } else if (digits >= 7) {
    return smallStyle;
  } else {
    return mediumStyle;
  }
};

interface AccountSummaryContentProps {
  currentBalanceAmount: number;
  availableCreditAmount: number;
  isLightTheme: boolean;
}

function AccountSummaryContent({
  currentBalanceAmount,
  availableCreditAmount,
  isLightTheme,
}: AccountSummaryContentProps) {
  const { isMobile } = useUserDevice();

  return (
    <div
      style={{
        justifyContent: 'space-between',
        height: '92%',
      }}
      className="flex flex-column"
    >
      {/** Balance container */}
      <div>
        <H2
          style={setHeaderStyle(currentBalanceAmount, isMobile, true)}
          color={isLightTheme ? 'ink' : 'white'}
        >
          {centsToDollars(currentBalanceAmount)}
        </H2>
        <P2 style={styles.label} color={isLightTheme ? 'inkLight' : 'white'}>
          Balance
        </P2>
      </div>
      {/** Available credit container */}
      <div
        style={{
          alignSelf: 'end',
          textAlign: 'right',
        }}
      >
        <ResponsiveTypography
          type="HEADER"
          mobileLevel="H1"
          desktopLevel="H3"
          color={isLightTheme ? 'ink' : 'white'}
          style={[
            styles.availableAmount,
            setHeaderStyle(availableCreditAmount, isMobile, false),
          ]}
        >
          <span
            className="flex justify-end"
            style={{
              flex: 1,
            }}
          >
            {availableCreditAmount > 0 ?
              <AnimatedCurrency targetValue={availableCreditAmount} />
            : '$0.00'}
          </span>
        </ResponsiveTypography>
        <P2 color={isLightTheme ? 'inkLight' : 'white'} style={styles.label}>
          Available
        </P2>
      </div>
    </div>
  );
}

export default AccountSummaryContent;

const styles = {
  label: {
    lineHeight: 0,
    margin: 0,
  } as StyleProp<TextStyle>,
  availableAmount: {
    display: undefined,
    alignSelf: 'flex-end',
  } as StyleProp<TextStyle>,
};
