import { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { MLFlags } from 'flags';

interface FeatureRouteProps {
  flag: keyof MLFlags;
}

/** Controls a feature-flagged route. If the flag value is false, we
 * navigate back away from the page.
 */
export const FeatureRoute = ({ flag }: FeatureRouteProps) => {
  const navigate = useNavigate();
  const flagValue = useFlags<MLFlags>();

  useEffect(() => {
    if (flagValue[flag] === false) {
      navigate(-1);
    }
  }, [flag]);

  return <Outlet />;
};
