import { gql } from '@apollo/client';
import { Navigate, useNavigate } from 'react-router-dom';
import AutoPayPaymentDisplay from '../AutoPayPaymentDisplay';
import { useAutopay } from '../AutopayContext';
import {
  ManageAutoPayQuery,
  ManageAutoPayQueryVariables,
} from '@core/graphql/globalTypes';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import PageWrapper from '@core/components/Page/PageWrapper';
import { useCustomerAndAccountIdentifiers } from '@core/utils/hooks/useCustomerAndAccountIdentifiers';
import { TrackService } from '@core/services';
import { PaymentConfirmationButtons } from '@payments/components/MakePayment/PaymentConfirmationButtons';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';

export const MANAGE_AUTO_PAY_QUERY = gql`
  query ManageAutoPay($accountId: String!) {
    account(accountId: $accountId) {
      id
      cardDetails {
        last4
        issuanceType
        issuanceState
      }
      autopay {
        id
        amount
        paymentType
        dayOfMonth
        eftaUrl
        nextPaymentDate
        fundingAccount {
          id
          bankName
          numberLast4
        }
      }
    }
  }
`;

const ManageAutoPay = () => {
  const customerAndAccountIds = useCustomerAndAccountIdentifiers();
  const { setAutopayFlowType } = useAutopay();
  const navigate = useNavigate();
  const { data, loading } = useAccountIdQuery<
    ManageAutoPayQuery,
    ManageAutoPayQueryVariables
  >(MANAGE_AUTO_PAY_QUERY);

  setAutopayFlowType('MODIFY');

  if (loading) {
    return <LoadingSpinnerPage />;
  }

  const { autopay: autopays = [] } = data?.account || {};
  const autopay = autopays[0];

  if (!autopay) {
    return <Navigate to="/" />;
  }

  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Your Autopay Plan', useLast4: true }}
      trackingProperties={{
        featureName: 'Payments: Autopay',
        pageName: 'Manage Autopay',
      }}
    >
      <AutoPayPaymentDisplay
        active
        amount={autopay.amount}
        paymentType={autopay.paymentType}
        dayOfMonth={autopay.dayOfMonth}
        fundingAccount={autopay.fundingAccount}
        paymentDateLabel="Next Payment"
        nextPaymentDate={autopay.nextPaymentDate}
      />
      <PaymentConfirmationButtons
        onSubmit={() => {
          TrackService.click('Modify Auto Pay', customerAndAccountIds);
          navigate('..');
        }}
        onCancel={() => {
          TrackService.click('Cancel Auto Pay', customerAndAccountIds);
          navigate('../cancel');
        }}
        text="Modify"
        cancelButtonText="Cancel Autopay"
        leftAlign
      />
    </PageWrapper>
  );
};
export default ManageAutoPay;
