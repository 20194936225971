import cx from 'classnames';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';

interface Props {
  text: string;
}

export const NavSectionHeader = ({ text }: Props) => {
  const { isMobile } = useUserDevice();
  return (
    <ResponsiveTypography
      color={isMobile ? 'green' : 'greenLightest'}
      type="PARAGRAPH"
      mobileLevel="P1"
      desktopLevel="P3"
      className={cx('mt5 mb3', isMobile ? 'ph3' : 'ph4')}
    >
      {text.toUpperCase()}
    </ResponsiveTypography>
  );
};
