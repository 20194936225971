import { useState } from 'react';
import { Button } from '@missionlane/compass-ui';
import OfferAnswerInput, {
  AnswerInputType,
  ExtendedAnswerInputTypes,
  OfferAnswerProps,
} from './OfferAnswerInput';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { CreateOfferFlowQuestionType } from '@core/graphql/globalTypes';

interface BaseOfferQuestionProps {
  questionText: string;
  onAnswerSubmitted?: (answer: string) => void;
}

interface OfferQuestionSelectInput extends BaseOfferQuestionProps {
  inputType: CreateOfferFlowQuestionType.Select;
  selectOptions: OfferAnswerProps['options'];
  minStartDaysFromNow?: never;
  unavailableDates?: never;
}

interface OfferQuestionDateInput extends BaseOfferQuestionProps {
  inputType: ExtendedAnswerInputTypes.Date;
  selectOptions?: never;
  minStartDaysFromNow?: number;
  unavailableDates?: Date[];
}

interface OfferQuestionOtherInput extends BaseOfferQuestionProps {
  inputType: Exclude<AnswerInputType, 'SELECT' | ExtendedAnswerInputTypes.Date>;
  selectOptions?: never;
  minStartDaysFromNow?: never;
  unavailableDates?: never;
}

export type OfferQuestionProps =
  | OfferQuestionSelectInput
  | OfferQuestionDateInput
  | OfferQuestionOtherInput;

const OfferQuestion = ({
  questionText,
  inputType,
  onAnswerSubmitted,
  selectOptions,
  minStartDaysFromNow,
  unavailableDates,
}: OfferQuestionProps) => {
  if (!questionText || !inputType) {
    return null;
  }
  const [answer, setAnswer] = useState<string>('');
  const [error, setError] = useState(false);

  return (
    <>
      <div className="mb3">
        <ResponsiveTypography type="HEADER" mobileLevel="H2" desktopLevel="H3">
          {questionText}
        </ResponsiveTypography>
      </div>
      <div className="mv5" style={{ maxWidth: 'fit-content' }}>
        <OfferAnswerInput
          value={answer}
          onChange={setAnswer}
          options={selectOptions}
          type={inputType}
          name={questionText}
          minStartDaysFromNow={minStartDaysFromNow}
          unavailableDates={unavailableDates}
          error={error}
          setError={(e) => setError(e)}
        />
      </div>
      <div className="mt1 flex-ns">
        <Button
          onPress={() => onAnswerSubmitted?.(answer)}
          disabled={!answer || error}
          text="Continue"
        />
      </div>
    </>
  );
};

export default OfferQuestion;
