import { useState } from 'react';
import {
  BubbleIcon,
  List,
  ListItem,
  Notification,
  P3,
  TextField,
} from '@missionlane/compass-ui';
import { PaymentType } from '@core/graphql/globalTypes';

interface Props {
  className?: string;
  amount?: number;
  error?: string;
  onAmountChange: (amount: number | undefined) => void;
  onPaymentTypeChange: (paymentType: PaymentType) => void;
  selected?: PaymentType;
}

const AutoPayAmount = ({
  amount,
  selected,
  error,
  onPaymentTypeChange,
  onAmountChange,
}: Props) => {
  const [displayAmount, setDisplayAmount] = useState<string>(
    amount ? amount.toFixed(2) : '',
  );

  const fixedAmountWarning = () => {
    if (selected !== PaymentType.MonthlyFixed || Number(displayAmount) < 25) {
      return null;
    }

    const warningText = `If your fixed payment amount ${
      displayAmount ? `($${displayAmount})` : ''
    } is less than your minimum payment due, we'll round up so you can meet the minimum payment.`;

    return (
      <Notification variant="inline" level="warning">
        {warningText}
      </Notification>
    );
  };

  const handlePaymentTypeChange = (id: PaymentType) => {
    if (id === selected) {
      return;
    }
    onPaymentTypeChange(id);
    setDisplayAmount('');
  };

  const handleAmountChange = (e: string) => {
    const value = Number(e.replace('.', ''));
    if (!isNaN(value) && value < 500000 && value >= 0) {
      setDisplayAmount((value / 100).toFixed(2).toString());
      onAmountChange(value);
    }
  };

  return (
    <div>
      <List
        variant="selectable"
        onChange={handlePaymentTypeChange}
        selected={selected}
      >
        <ListItem
          id={PaymentType.MonthlyMinimum}
          description="Make your monthly minimum."
          label="Minimum Payment"
          iconProps={{
            icon: 'dollarSign',
            iconColor: 'green',
          }}
        />
        <ListItem
          id={PaymentType.MonthlyStatementBalance}
          description="Pay your full statement balance"
          label="Statement Balance"
          iconProps={{
            icon: 'dollarSign',
            iconColor: 'green',
          }}
        />
        <ListItem id={PaymentType.MonthlyFixed}>
          <div className="flex flex-row items-center">
            <div className="pr3">
              <BubbleIcon
                name="dollarSign"
                iconColor="green"
                bubbleColor="greenWashed"
              />
            </div>
            <div className="w-75 w-100-ns">
              <div className="mb2">
                <P3 color="ink" style={{ fontWeight: '600' }}>
                  Fixed Amount
                </P3>
              </div>
              <TextField
                accessibilityLabel="input"
                icon="dollarSign"
                name="textField"
                value={displayAmount}
                placeholder="0.00"
                onChangeText={handleAmountChange}
              />
            </div>
          </div>
        </ListItem>
      </List>
      {fixedAmountWarning()}
      {error && (
        <div className="pt2">
          <Notification variant="inline" level="error">
            {error}
          </Notification>
        </div>
      )}
    </div>
  );
};

export default AutoPayAmount;
