import { useOktaAuth } from '@okta/okta-react';
import { serializeError } from 'serialize-error';
import { useEffect, useState } from 'react';
import { AuthState } from '@okta/okta-auth-js';
import { MissionLaneOktaUser, OktaErrorMetadata } from './types';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import LoadingSpinner from '@core/components/General/LoadingSpinner';
import { MLError } from '@core/services';
import { PageHeader } from '@core/components/Page/PageHeader';
import PageWrapper from '@core/components/Page/PageWrapper';

const LoginCallback = () => {
  const { oktaAuth, authState } = useOktaAuth();
  const [tokenError, setTokenError] = useState<Error>();
  const [errorMetadata, setErrorMetadata] = useState<OktaErrorMetadata>();

  const authError = authState?.error;
  const displayError = tokenError || authError;

  const storeTokensFromRedirect = async () => {
    try {
      await oktaAuth.storeTokensFromRedirect();
    } catch (error) {
      if (error instanceof Error) {
        const user = await oktaAuth.getUser();
        const errorMetadata = getLoginCallbackErrorMetadata(user, authState);
        setErrorMetadata(errorMetadata);
        setTokenError(error);
      } else {
        MLError.report({ name: serializeError(error).toString() });
      }
    }
  };

  useEffect(() => {
    /**
     * The default behavior of okta-react's LoginCallback component is to call oktaAuth.handleLoginRedirect,
     * which stores the tokens from the callback URL (oktaAuth.storeTokensFromRedirect), and then
     * calls the function defined in Security's restoreOriginalUri prop (see App.tsx).
     *
     * However, because we are calling RiskAuth after getting the Okta token and before we allow the user to
     * view the authenticated experience, we do not want to call oktaAuth.handleLoginRedirect yet.
     *
     * Instead, we use a custom Callback component which grabs the tokens from the signin/callback url
     * (okta.storeTokensFromRedirect) and then wait for RiskAuth to validate the user,
     * then navigate to the appropriate location in the RiskAuthProvider
     */
    storeTokensFromRedirect();
  }, []);

  useEffect(() => {
    if (displayError) {
      MLError.report({
        name: displayError.name,
        displayError,
      });
    }
  }, [displayError]);

  if (displayError) {
    return (
      <GenericFallbackFullPage
        showHeader
        centerContent
        withFooter
        eventTrackingProperties={{
          error: serializeError(displayError),
          ...errorMetadata,
        }}
      />
    );
  }

  return (
    <>
      <PageHeader centerContent />
      <PageWrapper centerContent withFooter>
        <LoadingSpinner />
      </PageWrapper>
    </>
  );
};

export default LoginCallback;

const getLoginCallbackErrorMetadata = (
  user: MissionLaneOktaUser,
  authState: AuthState | null,
): OktaErrorMetadata => {
  // catches errors to get metadata regardless of failed okta calls
  const isAuthenticated = !!authState?.isAuthenticated;
  const email = user?.email;
  const customerId = user?.requester_id;
  const mfa = user?.mfa;
  const hasAccessToken = !!authState?.accessToken;
  const hasIdToken = !!authState?.idToken;

  const metaData = {
    errorType: 'callbackError',
    email,
    customerId,
    isAuthenticated,
    hasAccessToken,
    hasIdToken,
    mfa,
  };

  return metaData;
};
