import { useEffect, useState } from 'react';
import { H4 } from '@missionlane/compass-ui';
import { useNavigate, useLocation } from 'react-router-dom';
import VerifyRequestCode from './VerifyRequestCode/VerifyRequestCode';
import { VerifyEnterCode } from './VerifyEnterCode/VerifyEnterCode';
import {
  MultifactorAuthenticationState,
  OtpFactor,
} from '@core/services/RiskAuthService';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

const FEATURES_WITH_MFA = ['/change-address'];

const MfaVerifyFlow = () => {
  const [mfaState, setMfaState] = useState<MultifactorAuthenticationState>();
  const [selectedOtpFactor, setSelectedOtpFactor] = useState<OtpFactor>();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!FEATURES_WITH_MFA.includes(location.state?.from)) {
      navigate('/');
    }
  }, []);

  const renderStep = () => {
    switch (mfaState) {
      case 'MFA_CHALLENGE': {
        return (
          <VerifyEnterCode
            onSuccess={handleSuccess}
            selectedOtpFactor={selectedOtpFactor}
          />
        );
      }
      default:
        return (
          <VerifyRequestCode
            onChangeMfaState={handleChangeMfaState}
            onSetSelectedOtpFactor={handleSetSelectedOtpFactor}
          />
        );
    }
  };

  const handleChangeMfaState = (
    newMfaState: MultifactorAuthenticationState,
  ) => {
    setMfaState(newMfaState);
  };

  const handleSuccess = () => {
    const redirect = location.state.from;
    if (redirect) {
      const preservedPrevState = location.state;
      navigate(redirect, { state: preservedPrevState });
    }
  };

  const handleSetSelectedOtpFactor = (newSelectedOtpFactor: OtpFactor) => {
    setSelectedOtpFactor(newSelectedOtpFactor);
  };

  return (
    <>
      <H4>Verify</H4>
      <div className="mt4">{renderStep()}</div>
    </>
  );
};

const MfaVerifyFlowWithWrapper = withPageWrapper(MfaVerifyFlow, {
  pageTitle: 'Edit address',
  contentClassName: 'w6',
});

export default MfaVerifyFlowWithWrapper;
