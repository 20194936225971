import { useQuery } from '@apollo/client';
import dayjs from 'dayjs';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import { useAccount } from '@core/components/Auth/AccountContext';
import Carousel, {
  CarouselTile,
  OnDismiss,
} from '@core/components/Carousel/Carousel';
import Kard from '@core/components/General/Kard/Kard';
import {
  OpportunityTilesQueryVariables,
  OpportunityTilesQuery,
} from '@core/graphql/globalTypes';
import { TrackService } from '@core/services';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import { MLFlags } from 'flags';

import { getDismissedAt, opportunityTilesComponentsMap } from './helpers';
import { opportunityTilesQuery } from './opportunityTilesQuery';

export const OPPORTUNITY_TILE_CAROUSEL_TEST_ID = 'opportunity-tile-carousel';

const OpportunityTileCarousel = () => {
  // TODO: EEC-183 - Remove those flags once we get the copy from Apollo
  const { creditProtectionMarketing, referralsMarketing } = useFlags<MLFlags>();
  const [carouselTiles, setCarouselTiles] = useState<CarouselTile[]>([]);
  const { isMobile } = useUserDevice();
  const { accountId } = useAccount();

  const { loading, error, data } = useQuery<
    OpportunityTilesQuery,
    OpportunityTilesQueryVariables
  >(opportunityTilesQuery, {
    skip: !accountId,
    variables: {
      dismissedAt: getDismissedAt({
        creditProtectionMarketing,
        referralsMarketing,
      }),
      accountId,
    },
  });

  const variant = data?.opportunityTiles.variant;
  const tiles = data?.opportunityTiles.tiles;

  useEffect(() => {
    if (!tiles) return;

    TrackService.trackEvent('Opportunity Tile Carousel Viewed', {
      variant,
    });

    setCarouselTiles(
      tiles?.map(({ id }) => ({
        id,
        component: opportunityTilesComponentsMap[id],
        // TODO: EEC-183 - add copy props here
      })),
    );
  }, [tiles, variant]);

  if (loading || error || carouselTiles.length === 0) return null;

  const handleDismiss: OnDismiss = (eventName, options = {}) => {
    if (!options.skipLocalStorageUpdate) {
      TrackService.trackClick(`Dismiss ${eventName} tile`, {
        ...options.trackingProperties,
        feature: eventName,
      });

      localStorage.setItem(eventName, dayjs().toString());
    }

    setCarouselTiles((prev) => prev.filter((tile) => tile.id !== eventName));
  };

  const carouselContent = (
    <div data-testid={OPPORTUNITY_TILE_CAROUSEL_TEST_ID}>
      <Carousel
        data={carouselTiles}
        onDismiss={handleDismiss}
        carouselFragmentClassnames="mw6"
      />
    </div>
  );

  if (isMobile) {
    return <div className="mb4">{carouselContent}</div>;
  }

  return <Kard className="mb4">{carouselContent}</Kard>;
};

export default OpportunityTileCarousel;
