import dayjs from 'dayjs';
import { B, P3 } from '@missionlane/compass-ui';
import {
  ActivityState,
  ActivityType,
  PaymentType,
} from '@core/graphql/globalTypes';
import { ScheduledPayment } from '@core/utils/convertAutopayToScheduledPayment';
import { centsToDollars } from '@core/utils/centsToDollars';
import getEffectivePaymentDate from '@core/utils/getEffectivePaymentDate';

export const AUTOPAY_TYPES = [
  'MONTHLY_CURRENT_BALANCE',
  'MONTHLY_FIXED',
  'MONTHLY_MINIMUM',
  'MONTHLY_STATEMENT_BALANCE',
];

const getPaymentAmount = (payment: ScheduledPayment) => {
  if (payment.type === PaymentType.MonthlyMinimum) {
    return 'Minimum payment';
  }
  if (payment.type === PaymentType.MonthlyStatementBalance) {
    return 'Statement balance';
  }
  return centsToDollars(payment.amount * -1);
};

interface Props {
  payment: ScheduledPayment;
}

const PaymentsOverviewItem = ({ payment }: Props) => {
  const formattedPaymentDate =
    payment.state === ActivityState.Pending ?
      getEffectivePaymentDate(payment.date).format('MMM D, YYYY')
    : dayjs(payment.date).format('MMM D, YYYY');

  const paymentAmount = getPaymentAmount(payment);

  const isReturned =
    payment.type === ActivityType.Purchase &&
    payment.state === ActivityState.Posted;
  const isPending = payment.state === ActivityState.Pending;
  const isPosted = payment.state === ActivityState.Posted;
  const textColor =
    isReturned ? 'red'
    : isPosted ? 'green'
    : 'ink';

  return (
    <li className="list bb b--ink-20 pv3">
      <div className="flex justify-between mb1">
        <P3 style={{ margin: 0 }} color={textColor}>
          <B>Payment {isReturned && 'Return'}</B>
        </P3>
        <P3 style={{ margin: 0 }} color={textColor}>
          {paymentAmount}
        </P3>
      </div>
      <P3>{isPending ? 'Pending' : formattedPaymentDate}</P3>
    </li>
  );
};

export default PaymentsOverviewItem;
