import { MutationHookOptions, useQuery } from '@apollo/client';
import { GET_PLAID_LINK_TOKEN_CLIP_CFU } from '../network/queries';
import { GetPlaidLinkTokenClipCfuQuery } from '@core/graphql/globalTypes';

interface GetPladLinkTokenClipCfuCallbacks {
  onError: MutationHookOptions['onError'];
}

export const usePlaidLinkTokenClipCfu = (
  callbacks: GetPladLinkTokenClipCfuCallbacks,
) =>
  useQuery<GetPlaidLinkTokenClipCfuQuery>(
    GET_PLAID_LINK_TOKEN_CLIP_CFU,
    callbacks,
  );
