import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { B, Button, Notification, P3 } from '@missionlane/compass-ui';
import { GET_ACTIVE_CARD_STATUS } from './queries';
import { useAutopay } from './AutopayContext';
import AutoPayPaymentDisplay from '@payments/components/Autopay/AutoPayPaymentDisplay';
import hasUnactivatedCard from '@core/utils/hasUnactivatedCard';
import { GenericFallbackFullPage } from '@core/components/GenericFallbacks/GenericFallbackFull';
import { useAccountIdQuery } from '@core/utils/hooks/useAccountIdQuery';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import { useAccountSummaryPath } from '@core/utils/hooks/useAccountSummaryPath';
import PageWrapper from '@core/components/Page/PageWrapper';

const AutopaySuccess = () => {
  const { autopayContext, autopayLoading } = useAutopay();
  const { data, error, loading } = useAccountIdQuery(GET_ACTIVE_CARD_STATUS);
  const navigate = useNavigate();
  const summaryPath = useAccountSummaryPath();
  const onBackToSummary = () => navigate(summaryPath);
  const { nextPaymentDate, amount, dayOfMonth, fundingAccount, paymentType } =
    autopayContext?.autopay || {};

  if (loading || autopayLoading) return <LoadingSpinnerPage />;

  if (
    error ||
    amount === undefined ||
    !dayOfMonth ||
    !paymentType ||
    !fundingAccount
  ) {
    return <GenericFallbackFullPage />;
  }

  const cardIsActivated = !hasUnactivatedCard(
    data?.account?.cardDetails?.issuanceType,
  );
  return (
    <PageWrapper
      isSingleColumn
      pageTitle={{ primaryText: 'Autopay Set!', useLast4: true }}
      trackingProperties={{
        pageName: 'Set Up Autopay: Success',
        featureName: 'Payments: Autopay',
      }}
    >
      <div className="mb4">
        {cardIsActivated && (
          <P3>
            Your first payment date will be on
            <B> {dayjs(nextPaymentDate).format('MMM D, YYYY')}. </B>
          </P3>
        )}
      </div>
      <div>
        <AutoPayPaymentDisplay
          amount={amount}
          paymentType={paymentType}
          dayOfMonth={dayOfMonth}
          fundingAccount={fundingAccount}
          paymentDateLabel="First payment"
        />
      </div>
      <Notification variant="inline" level="success">
        We've emailed your autopay schedule to you. Your confirmation number is{' '}
        {autopayContext?.confirmationId}
      </Notification>
      <div className="mt4 w-25-ns">
        <Button onPress={onBackToSummary} variant="contained" text="Done" />
      </div>
    </PageWrapper>
  );
};

export default AutopaySuccess;
