import {
  Button,
  H4,
  List,
  Notification,
  Spacer,
} from '@missionlane/compass-ui';
import dayjs from 'dayjs';
import { useState } from 'react';
import cx from 'classnames';
import NavLinks from '../NavLinks';
import { PaymentPlanStepProps } from './CreatePaymentPlanFlow';
import { ServicingOffersTransaction } from '@core/graphql/globalTypes';
import { centsToDollars } from '@core/utils/centsToDollars';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { useUserDevice } from '@core/utils/hooks/useUserDevice';
import './PaymentPlanFlow.css';
import { ScheduledPaymentListItem } from '@payments/components/ScheduledPayments/ScheduledPaymentListItem';

interface Props extends PaymentPlanStepProps {
  paymentList?: ServicingOffersTransaction[] | null;
}

const PaymentSchedule = ({ paymentList, nextStep, previousStep }: Props) => {
  const { isMobile } = useUserDevice();
  const PAYMENTS_DISPLAYED_INITIALLY = isMobile ? 4 : 5;
  if (!paymentList || paymentList.length === 0) {
    return null;
  }
  const [displayAllPayments, setDisplayAllPayments] = useState(false);

  const firstPayment = paymentList[0];
  const paymentsDisplayedInitially = paymentList.filter(
    (_, index) => index > 0 && index <= PAYMENTS_DISPLAYED_INITIALLY,
  );
  const paymentsHiddenInitially = paymentList.filter(
    (_, index) => index > PAYMENTS_DISPLAYED_INITIALLY,
  );
  return (
    <div className={cx(!isMobile && 'w6')}>
      <NavLinks showFAQ={false} onGoBack={previousStep} />
      {firstPayment && (
        <div className="bg-haze-lightest mv3 br3 pa3">
          <H4>Upcoming Payment</H4>
          <div className="mv2">
            <ResponsiveTypography
              type="HEADER"
              mobileLevel="H1"
              desktopLevel="H2"
              testID="FirstPaymentAmount"
            >
              {centsToDollars(firstPayment.amount)}
            </ResponsiveTypography>
          </div>
          {firstPayment.date && (
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              testID="FirstPaymentDate"
            >
              Scheduled for {dayjs(firstPayment.date).format('MMMM DD, YYYY')}
            </ResponsiveTypography>
          )}
        </div>
      )}
      {Boolean(paymentsDisplayedInitially?.length) && (
        <List variant="transaction">
          {paymentsDisplayedInitially.map((payment) => {
            if (!payment) return null;
            return (
              <ScheduledPaymentListItem
                key={payment.date}
                iconName="calendar"
                label="Scheduled Payment"
                secondaryLabel={centsToDollars(payment.amount)}
                description={dayjs(payment.date).format('MMMM D, YYYY')}
                colorTheme={{
                  color: 'teal',
                  colorWashed: 'tealWashed',
                }}
              />
            );
          })}
          {displayAllPayments &&
            paymentsHiddenInitially?.map((payment) => {
              if (!payment) return null;
              return (
                <ScheduledPaymentListItem
                  key={payment.date}
                  iconName="calendar"
                  label="Scheduled Payment"
                  secondaryLabel={centsToDollars(payment.amount)}
                  description={dayjs(payment.date).format('MMMM D, YYYY')}
                  colorTheme={{
                    color: 'teal',
                    colorWashed: 'tealWashed',
                  }}
                />
              );
            })}
        </List>
      )}
      {paymentsHiddenInitially?.length > 0 && (
        <div className="flex justify-end">
          <Button
            text={
              displayAllPayments ? 'See fewer payments' : 'See all payments'
            }
            variant="text"
            onPress={() => setDisplayAllPayments((prev) => !prev)}
          />
        </div>
      )}
      <Spacer size="s" />
      <div className="mb4">
        <Notification level="info" show={true}>
          We’re flexible! You’ll be able to edit payment dates and amounts once
          you accept the offer.
        </Notification>
      </div>
      <div className={cx({ flex: !isMobile })}>
        <Button onPress={() => nextStep?.()} text="Continue" />
      </div>
    </div>
  );
};

export default PaymentSchedule;
