import dayjs from 'dayjs';

/**
 * Add thousands separators to a decimal or integer, appending
 * a dollar symbol to the result
 */
export const formatUSD = (n?: string): string | undefined => {
  if (!n) return;
  const arr = n.split('.');
  arr[0] = arr[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `$${arr.join('.')}`;
};

export const formatCardLast4 = (cardLast4: string): string =>
  `(...${cardLast4})`;

/**
 * uses dayjs to format a date value to a date template
 *
 * template defaults to `MMM DD, YYYY`
 */
export function formatDate(
  date: string | Date | number,
  format = 'MMM DD, YYYY',
) {
  return dayjs(date).format(format);
}

export const pluralize = (word: string, count: number) =>
  count > 1 ? word + 's' : word;
