import { useEffect } from 'react';
import dayjs from 'dayjs';

import { DismissOfferButton } from '../DismissOfferButton';
import track, { EventNames } from '../track';
import { TEST_ID, COPY } from './consts';
import { useSpendBonus } from '@clip/SpendBonus/hooks';
import Kard from '@core/components/General/Kard/Kard';
import { RewardType, SpendBonusStatus } from '@core/graphql/globalTypes';

import '../../assets/SpendBonus.css';

export const SpendBonusMissedPayment = () => {
  const spendBonusResult = useSpendBonus();

  useEffect(() => {
    track({
      eventName: EventNames.OfferViewed,
      status: SpendBonusStatus.MissedPayment,
      rewardType: RewardType.Clip,
    });
  }, []);

  if (spendBonusResult.isEnabled === false) {
    return null;
  }

  const {
    spendBonus: { dueDate },
  } = spendBonusResult;

  const formattedDueDate = dayjs(dueDate).format('MMMM');

  return (
    <Kard
      header={{ textPrimary: COPY.TITLE, level: 'H4', className: 'mb2 mr5' }}
      testID={TEST_ID.SPEND_BONUS__MISSED_PAYMENT}
      className="mb3 w-100 relative"
    >
      <DismissOfferButton />
      <p
        className="spend-bonus__message"
        data-testid={TEST_ID.SPEND_BONUS__MISSED_PAYMENT__MESSAGE__CLIP}
      >
        Because you missed a payment, your account isn’t eligible for a credit
        limit increase in {formattedDueDate}. Make sure your account is in good
        standing to be eligible for future offers.
      </p>
    </Kard>
  );
};
