import ResponsiveTypography from '@core/components/General/ResponsiveTypography';

interface Props {
  CallToAction?: React.ReactNode;
  Disclaimer?: React.ReactNode;
}

const ChargedOffDashboardFooter = ({
  CallToAction,
  Disclaimer = <DefaultDisclaimer />,
}: Props) => {
  return (
    <div className="flex flex-column mt5 items-start-ns">
      {CallToAction}
      <div
        className="w-100"
        style={{
          borderBottom: '1px solid var(--ink-lightest)',
        }}
      />
      {Disclaimer}
    </div>
  );
};

const DefaultDisclaimer = () => (
  <ResponsiveTypography
    className="flex"
    type="PARAGRAPH"
    mobileLevel="P3"
    desktopLevel="P4"
  >
    This is an attempt to collect a debt and any information obtained will be
    used for that purpose.
  </ResponsiveTypography>
);

export default ChargedOffDashboardFooter;
