import { useFlags } from 'launchdarkly-react-client-sdk';
import CreditScore from '../CreditScore/CreditScore';
import { FEATURE_NAME } from '../MyProgressOnboarding/const';
import CreditLimitKard from './CreditLimitKard';
import { GoodStandingTips } from '@clip/GoodStandingTips/features';
import { SpendBonus } from '@clip/SpendBonus';
import ClipOffer from '@clip/ClipProgress/kards/ClipOffer/ClipOffer';
import { MLFlags } from 'flags';
import { withPageWrapper } from '@core/utils/hoc/withPageWrapper';

const PAGE_NAME = 'My Progress';

const MyProgressPage = () => {
  const { showCreditScore } = useFlags<MLFlags>();

  return (
    <div className="flex w-100 flex-row-l flex-column">
      {/** left side */}
      <div className="w-50-l mr2-l">
        <CreditLimitKard />
        <ClipOffer />
        <SpendBonus />
      </div>

      {/** right side */}
      <div className="w-50-l ml2-l">
        {showCreditScore && <CreditScore className="mb3" />}
        <GoodStandingTips />
      </div>
    </div>
  );
};

const MyProgressPageWithPageWrapper = withPageWrapper(MyProgressPage, {
  pageTitle: PAGE_NAME,
  trackingProperties: {
    featureName: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
    pageName: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: My Progress`,
  },
});

export default MyProgressPageWithPageWrapper;
