import cx from 'classnames';
import { B, Button, Spacing } from '@missionlane/compass-ui';
import { useNavigate } from 'react-router-dom';
import { useSettlementOffers } from '../utils/useSettlementOffers';
import { SETTLEMENTS_FEATURE_NAME } from '../utils/events';
import Kard from '@core/components/General/Kard/Kard';
import ResponsiveTypography from '@core/components/General/ResponsiveTypography';
import { usePaymentStatus } from '@payments/hooks/usePaymentStatus';
import { SettlementOfferCanceledReason } from '@core/graphql/globalTypes';
import { cardSupportPhone } from '@core/utils/contact';
import { TrackService } from '@core/services';

const BuildPaymentPlanKard = () => {
  const navigate = useNavigate();
  const { hasOnlyCanceledOffers, latestCanceledOffer } = useSettlementOffers();
  const { isDebitEligible } = usePaymentStatus();
  const canceledDueToInvalidFundingAccount =
    latestCanceledOffer?.data.canceledReason ===
    SettlementOfferCanceledReason.FundingAccountInvalid;

  return (
    <Kard testID="BuildPaymentPlanKard">
      <div className="flex flex-column">
        <ResponsiveTypography
          type="HEADER"
          mobileLevel="H2"
          desktopLevel="H3"
          testID="payment-plan-kard-header"
        >
          <span>
            Let's build a{' '}
            <span className="green">
              {hasOnlyCanceledOffers ? 'new ' : ''}payment plan
            </span>{' '}
            that works for you.
          </span>
        </ResponsiveTypography>
        <div className="flex flex-column mt3">
          <EncouragingMessage
            showNewPlanMessage={hasOnlyCanceledOffers}
            showCanceledFundingAccountMessage={
              canceledDueToInvalidFundingAccount
            }
          />

          <div
            className={cx('mt3', { mb5: !canceledDueToInvalidFundingAccount })}
          >
            <ResponsiveTypography
              type="PARAGRAPH"
              mobileLevel="P1"
              desktopLevel="P2"
              color="inkLight"
              testID="payment-plan-kard-body"
            >
              {canceledDueToInvalidFundingAccount ?
                <>
                  You can <B color="ink">set up a new plan</B> with another
                  funding account, or contact us at {cardSupportPhone} for more
                  options
                </>
              : <>
                  Setting up a plan takes only <B color="ink">5 minutes.</B>
                </>
              }
            </ResponsiveTypography>
          </div>
          {!canceledDueToInvalidFundingAccount && (
            <div className="b--ink bl bw1 pl3">
              <PlanStep number="01" text="Answer a few questions" />
              <PlanStep number="02" text="We'll make you an offer" />
              <PlanStep number="03" text="Pay off your debt over time" />
            </div>
          )}
        </div>
        <div className="flex flex-row-ns flex-column items-center mt4">
          <div className="w-50-ns w-100">
            <Button
              size="small"
              onPress={() => {
                TrackService.trackClick('Start Settlement Program', {
                  feature: SETTLEMENTS_FEATURE_NAME,
                });
                navigate('../payment-plan/create');
              }}
              text="Get Started"
            />
          </div>
          <div className="ml3-ns mt0-ns mt3 self-center">
            <Button
              variant="text"
              size="small"
              text="Make a One-Time Payment"
              onPress={() => {
                TrackService.trackClick('Make a One-Time Payment', {
                  feature: SETTLEMENTS_FEATURE_NAME,
                });

                isDebitEligible ?
                  navigate('../payments/pay')
                : navigate('../payments/pay/make-ach-payment');
              }}
            />
          </div>
        </div>
      </div>
    </Kard>
  );
};

interface EncouragingMessageProps {
  showNewPlanMessage: boolean;
  showCanceledFundingAccountMessage: boolean;
}

const EncouragingMessage = ({
  showNewPlanMessage,
  showCanceledFundingAccountMessage,
}: EncouragingMessageProps) => {
  if (showNewPlanMessage) {
    if (showCanceledFundingAccountMessage) {
      TrackService.trackEvent(
        'Charged Off Dashboard - Offer Cancelled via Deactiviated Funding Account Banner',
        {
          feature: SETTLEMENTS_FEATURE_NAME,
        },
      );

      return (
        <ResponsiveTypography
          type="PARAGRAPH"
          mobileLevel="P1"
          desktopLevel="P2"
          testID="fa-payment-plan-kard-encouraging-message"
        >
          It looks like your recent payment plan was canceled due to a
          deactivated funding account.
        </ResponsiveTypography>
      );
    }

    TrackService.trackEvent(
      'Charged Off Dashboard - Offer Did Not Complete Banner',
      {
        feature: SETTLEMENTS_FEATURE_NAME,
      },
    );

    return (
      <ResponsiveTypography
        type="PARAGRAPH"
        mobileLevel="P1"
        desktopLevel="P2"
        testID="new-payment-plan-kard-encouraging-message"
      >
        It looks like your recent payment plan isn’t complete. With a little
        more info, we can help you set up a new, flexible plan to{' '}
        <B color="ink">make paying off your balance more manageable.</B>
      </ResponsiveTypography>
    );
  }

  return (
    <ResponsiveTypography
      type="PARAGRAPH"
      mobileLevel="P1"
      desktopLevel="P2"
      color="inkLight"
      testID="payment-plan-kard-encouraging-message"
    >
      Paying off your balance can be overwhelming. With a little more
      information, we can find a flexible payment plan that'll{' '}
      <B color="ink">make it more manageable.</B>
    </ResponsiveTypography>
  );
};

interface PlanStepProps {
  number: string;
  text: string;
}

const PlanStep = ({ number, text }: PlanStepProps) => {
  return (
    <ResponsiveTypography
      type="PARAGRAPH"
      mobileLevel="P1"
      desktopLevel="P2"
      testID="payment-plan-kard-step"
    >
      <div
        className="flex mb2"
        style={{
          gap: Spacing.s,
        }}
      >
        <span className="monospace tc" style={{ minWidth: '1.5rem' }}>
          {number}
        </span>
        <span>&bull;</span>
        <B color="ink">{text}</B>
      </div>
    </ResponsiveTypography>
  );
};

export default BuildPaymentPlanKard;
