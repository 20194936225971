import { Spacer } from '@missionlane/compass-ui';
import EditLink, {
  EditLinkProps,
} from '../AccountDetailsPage/EditLink/EditLink';

export const MyProfileEditLink = (props: EditLinkProps) => (
  <>
    <Spacer size="xs" />
    <Spacer size="s" />
    <EditLink {...props} />
  </>
);
