import { Button, H2, P1, Spacing } from '@missionlane/compass-ui';
import { useEffect } from 'react';
import { FEATURE_NAME } from './const';
import ConfettiCone from '@core/assets/confettiConeSmall.svg';
import { useTracking } from '@core/services/TrackService/useTracking';
import { BASE_Z_INDEX } from '@core/utils/zIndexes';

interface MyProgressOnboardingSuccessOverlayProps {
  onDismiss: () => void;
}

const MyProgressOnboardingSuccessOverlay = ({
  onDismiss,
}: MyProgressOnboardingSuccessOverlayProps) => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    trackEvent({
      eventName: 'Overlay Viewed',
      name: `${FEATURE_NAME.MY_PROGRESS_ONBOARDING}: Success Overlay`,
      feature: FEATURE_NAME.MY_PROGRESS_ONBOARDING,
    });
  }, []);

  return (
    <div
      data-testid="Success-Overlay"
      className="flex justify-center items-center"
      style={{
        position: 'absolute',
        zIndex: BASE_Z_INDEX,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.9)',
      }}
    >
      <div
        className="flex flex-column items-center "
        style={{
          maxWidth: 380,
          paddingTop: Spacing.xm,
          paddingBottom: Spacing.xm,
        }}
      >
        <img style={{ maxHeight: 200 }} src={ConfettiCone} />
        <H2>All set up!</H2>
        <P1 style={{ textAlign: 'center' }}>
          This page will now help track your financial progress over time.
        </P1>
        <Button testID="Onboarding-Success" text="Got it" onPress={onDismiss} />
      </div>
    </div>
  );
};

export default MyProgressOnboardingSuccessOverlay;
