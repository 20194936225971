import { gql, useQuery } from '@apollo/client';
import { useOktaAuth } from '@okta/okta-react';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FullStory } from '@fullstory/browser';
import PageWrapper from '../../Page/PageWrapper';
import { identifyLDUser } from '../helpers/identifyLDUser';
import { CustomerProvider } from '../CustomerContext';
import { AccountRoutes } from './AccountRoutes';
import { CustomerRoutes } from './CustomerRoutes';
import { NoAccountDashboard } from '@core/components/AccountSummaryPage/Dashboard/OnboardingDashboards';
import { LoadingSpinnerPage } from '@core/components/General/LoadingSpinner';
import HomePage from '@core/components/HomePage/HomePage';
import { AccountProvider } from '@core/components/Auth/AccountContext';
import AccountInformationProvider from '@core/components/AccountInformation/AccountInformationProvider';
import { CustomerRoutesQuery } from '@core/graphql/globalTypes';
import { MLError } from '@core/services';
import NavContainer from '@core/components/Navigation/NavContainer';
import { PageHeaderWithSignOut } from '@core/components/Page/PageHeader';

const CUSTOMER_ROUTES_QUERY = gql`
  query CustomerRoutes {
    customer {
      id
      contactInfo {
        email
        firstName
        lastName
      }
      accounts {
        id
        isEligibleForActivation
      }
    }
  }
`;

export const AuthenticatedRoutes = () => {
  const ldClient = useLDClient();
  const { authState } = useOktaAuth();

  const { data, loading, error } = useQuery<CustomerRoutesQuery>(
    CUSTOMER_ROUTES_QUERY,
    {
      errorPolicy: 'all',
    },
  );
  const { customer } = data || {};
  const { accounts = [] } = customer || {};

  // This feels like a weird place to do these tracking identification updates
  // maybe we can move them to the login flow?
  useEffect(() => {
    if (customer) {
      MLError.setUser(customer);
      identifyLDUser(ldClient, customer);
      FullStory('setIdentity', {
        uid: customer.id,
        properties: {
          email: customer.contactInfo.email || 'Customer email is not defined',
        },
      });
    }
  }, [customer?.id]);

  if (loading) {
    return <LoadingSpinnerPage centerContent withFooter />;
  }

  const noAccountsFound =
    !error && authState?.isAuthenticated && !accounts.length;

  // TODO: Redefine this experience with design. Since the side nav is dependent on
  // the customer having an account, we cannot display it here
  if (noAccountsFound) {
    return (
      <>
        <PageHeaderWithSignOut centerContent />
        <PageWrapper centerContent withFooter>
          <NoAccountDashboard />
        </PageWrapper>
      </>
    );
  }

  const activationEligibleAccounts = data?.customer?.accounts.find(
    (account) => account.isEligibleForActivation,
  );

  const activationAccount = activationEligibleAccounts || accounts[0];

  return (
    <CustomerProvider>
      <Routes>
        <Route index element={<HomePage />} />
        {/** This is a path made specifically for communications */}
        <Route path="/redirect" element={<HomePage />} />

        <Route element={<NavContainer />}>
          <Route
            path="/account/:accountId/*"
            element={
              <AccountProvider>
                <AccountInformationProvider>
                  <AccountRoutes />
                </AccountInformationProvider>
              </AccountProvider>
            }
          />
          <Route
            index
            path="/activate"
            element={
              <Navigate to={`/account/${activationAccount.id}/activate`} />
            }
          />
        </Route>
        <Route path="/*" element={<CustomerRoutes />} />
      </Routes>
    </CustomerProvider>
  );
};
